import React, { FC } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import I18n from '../../i18n';
import { ANTrainingSchedule, Day } from '../../model/ANTrainingSchedule';
import { days } from '../../screen/profile/ScheduleTraining/useScheduleTrainingNotification';
import ANFont from '../../theme/ANFont';
import { MetricsSizes } from '../../theme/ANMetrics';
import { hapticSelection } from '../../util/utils';
import DayAddTime from '../DayAddTime';
import DayPicker from '../DayPicker';
import DayTimePicker from '../DayTimePicker';
import useTrainingSchedule from './useTrainingSchedule';

type Props = {
  scheduledNotifications: ANTrainingSchedule[];
  onScheduleChanged: (newSchedule: ANTrainingSchedule[]) => void;
  contentContainerStyle?: ViewStyle;
};

const TrainingSchedule: FC<Props> = ({
  scheduledNotifications,
  contentContainerStyle,
  onScheduleChanged,
}) => {
  const { scheduledTimesForDay, updateTime, removeTime, dayIsSelected, addNewTime } =
    useTrainingSchedule(scheduledNotifications, onScheduleChanged);

  const addTimePressed = (day: Day) => {
    hapticSelection();
    addNewTime(day);
  };

  return (
    <FlatList
      contentContainerStyle={contentContainerStyle}
      data={days}
      ListHeaderComponent={
        <Text style={[ANFont.textLargeRegular, styles.description]}>
          {I18n.t('program_notifications.description')}
        </Text>
      }
      renderItem={({ item: day }) => {
        return (
          <DayPicker
            style={styles.dayPicker}
            day={Day.localizedLabel(day)}
            selected={dayIsSelected(day)}
            times={scheduledTimesForDay(day)}
          >
            <View style={styles.dayContainer}>
              {scheduledTimesForDay(day)?.map(time => {
                return (
                  <DayTimePicker
                    key={time.id}
                    selectedHours={time.hour}
                    selectedMinutes={time.minute}
                    id={time.id}
                    day={day}
                    style={styles.timePicker}
                    onDelete={day => removeTime(day, time.id)}
                    onTimeChanged={(day, newHour, newMinute) =>
                      updateTime(day, time.id, newHour, newMinute)
                    }
                  />
                );
              })}

              <DayAddTime
                onPress={() => {
                  addTimePressed(day);
                }}
              />
            </View>
          </DayPicker>
        );
      }}
      keyExtractor={day => {
        return String(day);
      }}
    />
  );
};

export default TrainingSchedule;

const styles = StyleSheet.create({
  dayPicker: {
    marginVertical: 4,
  },

  dayContainer: {
    marginLeft: MetricsSizes.regular,
  },
  timePicker: {
    marginVertical: 4,
  },
  description: {
    textAlign: 'center',
    alignSelf: 'center',
    marginVertical: MetricsSizes.regular,
  },
});
