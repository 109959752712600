import React, { FC, useCallback, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { useAnimatedScrollHandler, useSharedValue } from 'react-native-reanimated';

import ANButton from '../../../component/ANButton';
import TrainingPresentationHeader from '../Dashboard/component/TrainingPresentationHeader';

import TrainingExerciceListItem from '../../../component/TrainingExerciceListItem';
import TrainingEndModal from '../../../component/modal/TrainingEndModal';
import VideoLevelSelectionModal from '../../../component/modal/VideoLevelSelectionModal.web';
import WebModal from '../../../component/modal/WebModal';
import I18n from '../../../i18n';
import ANPlaylist from '../../../model/ANPlaylist';
import { ANColor } from '../../../theme/ANColor';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { logEvent } from '../../../util/analytics';
import TrainingSessionScreen from '../TrainingSession/TrainingSessionScreen.web';
import VideoWebPlayerModal from '../VideoPlayerScreen/VideoWebPlayerModal';
import TrainingListHeader from './TrainingListHeader';
import useTrainingPresentationScreen from './useTrainingPresentationScreen';

export interface TrainingPresentationScreenProps {
  //trainingId: number //This will be the normal use, fetch data with id
  title: string;
  playlist: ANPlaylist;
}

const TrainingPresentationScreen: FC<TrainingPresentationScreenProps> = () => {
  const [isLevelSelectionModalVisible, setLevelSelectionModalVisible] = useState(false);
  const [isTrainingEndModalVisible, setIsTrainingEndModalVisible] = useState(false);
  const [isVideoModalVisible, setVideoModalVisible] = useState(false);

  const openLevelSelectionModal = () => {
    setLevelSelectionModalVisible(true);
  };

  const onCloseLevelSelectionModal = () => {
    setLevelSelectionModalVisible(false);
  };

  const openTrainingEndModal = () => {
    setIsTrainingEndModalVisible(true);
  };
  const onCloseTrainingEndModal = (eventKey?: string) => {
    console.log('close training end modal');
    if (eventKey) logEvent(eventKey);
    setIsTrainingEndModalVisible(false);
  };

  const closeVideoModal = useCallback(() => {
    setVideoModalVisible(false);
  }, []);

  const openVideoModal = useCallback(() => {
    setVideoModalVisible(true);
  }, []);

  const {
    videoRef,
    videoToPlay,
    playlist,
    videoIsLoading,
    videoGroupToSwitch,
    exercices,
    useRestrictedMode,
    isInSession,
    openVideo,
    onSessionStartTapped,
    onLevelTapped,
    switchRestrictedModeSelected,
    onSessionCompleted,
    onSessionCancelled,
  } = useTrainingPresentationScreen(
    undefined,
    openLevelSelectionModal,
    openTrainingEndModal,
    openVideoModal,
  );

  const offsetY = useSharedValue(0);

  const scrollHandler = useAnimatedScrollHandler({
    onScroll: event => {
      offsetY.value = event.contentOffset.y;
    },
  });

  const expiryDate = useMemo(() => {
    return playlist?.expiryDate;
  }, [playlist]);

  return (
    <View style={ANLayout.screenContaineNoPadding}>
      {videoToPlay && (
        <VideoWebPlayerModal
          mediaId={videoToPlay.mediaId}
          uri={videoToPlay.uri}
          shouldDismiss={closeVideoModal}
          modalVisible={isVideoModalVisible}
        />
      )}

      <Animated.FlatList
        onScroll={scrollHandler}
        bounces={false}
        scrollEventThrottle={16}
        contentContainerStyle={[styles.listContainer]}
        ListHeaderComponent={
          <View>
            <TrainingPresentationHeader
              duration={playlist?.duration}
              numberOfCompletion={playlist?.completed}
              title={I18n.t('training.screen_title')}
              style={styles.header}
            />

            <TrainingListHeader restrictedMode={useRestrictedMode} expiry={expiryDate} />
          </View>
        }
        ListFooterComponent={
          <View style={styles.actions}>
            <ANButton
              title={
                useRestrictedMode
                  ? I18n.t('training.switch_mode_normal_action')
                  : I18n.t('training.switch_mode_restricted_action')
              }
              eventKey="switch_training_mode"
              theme="secondary"
              onPress={switchRestrictedModeSelected}
              style={styles.modeSelectionButton}
            />
            <ANButton title={I18n.t('common.start_button')} onPress={onSessionStartTapped} />
          </View>
        }
        data={exercices}
        renderItem={({ item, index }) => {
          return (
            <View style={styles.listItem}>
              <TrainingExerciceListItem
                index={index}
                exercice={item}
                useRestrictedMode={useRestrictedMode}
                onCardTapped={openVideo}
                onLevelTapped={onLevelTapped}
              />
            </View>
          );
        }}
        keyExtractor={({ id }) => {
          return String(id);
        }}
      />

      <VideoLevelSelectionModal
        isVisible={isLevelSelectionModalVisible}
        exercice={videoGroupToSwitch}
        onClose={onCloseLevelSelectionModal}
        useLimitedMode={useRestrictedMode}
      />
      <TrainingEndModal
        shouldDismiss={onCloseTrainingEndModal}
        isVisible={isTrainingEndModalVisible}
      />

      <WebModal
        isVisible={isInSession}
        shouldDismissWebModal={() => {}}
        shouldDisplayCloseIcon={false}
        content={
          <TrainingSessionScreen
            useRestrictedMode={useRestrictedMode}
            onWebModalShouldDismiss={cancelled => {
              if (cancelled) {
                onSessionCancelled();
              } else {
                onSessionCompleted();
              }
            }}
          />
        }
      />
    </View>
  );
};

export default TrainingPresentationScreen;

const styles = StyleSheet.create({
  listContainer: {
    backgroundColor: ANColor.primary100,
  },
  listItem: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  modeSelectionButton: {
    marginBottom: MetricsSizes.singlePadding,
  },
  actions: {
    alignItems: 'center',
    marginVertical: MetricsSizes.big,
  },
  expiryLabel: {
    marginBottom: MetricsSizes.big,
    marginLeft: MetricsSizes.screenHorizontalPadding,
    marginTop: MetricsSizes.small,
  },
  header: {
    borderRadius: MetricsSizes.webCornerRadius,
    paddingVertical: MetricsSizes.big,
  },
});
