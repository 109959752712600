import React, { FC } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { NativeSafeAreaViewProps } from 'react-native-safe-area-context';

type Props = {
  ignoreTopInset?: boolean;
} & NativeSafeAreaViewProps;

const ANSafeAreView: FC<Props> = ({ ignoreTopInset, children, ...props }) => {
  return (
    <SafeAreaView
      edges={ignoreTopInset ? ['bottom', 'left', 'right'] : props.edges}
      style={[props.style]}
    >
      {children}
    </SafeAreaView>
  );
};

export default ANSafeAreView;

const styles = StyleSheet.create({});
