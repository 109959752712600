import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import ANButton from '../../../component/ANButton';
import ExpoIcon from '../../../component/ExpoIcon';
import I18n from '../../../i18n';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';

type Props = {
  top: number;
  bottom: number;
  notificationAllawed: boolean;
  onNotificationPermissionRequest: () => void;
};

const OnboardingSlideNotifications: FC<Props> = ({
  top,
  bottom,
  notificationAllawed,
  onNotificationPermissionRequest,
}) => {
  return (
    <View
      style={[
        ANLayout.columnHVCenter,
        styles.container,
        { paddingTop: top, paddingBottom: bottom },
      ]}
    >
      <Animated.Text style={[ANFont.h5Bold, styles.title]} entering={FadeIn.delay(200)}>
        {I18n.t('onboarding.slide_two.title')}
      </Animated.Text>
      <Animated.Text style={[ANFont.textLargeRegular, styles.body]} entering={FadeIn.delay(500)}>
        {I18n.t('onboarding.slide_two.body')}
      </Animated.Text>
      <Animated.View entering={FadeIn.delay(800)}>
        <ExpoIcon
          name="notifications-active"
          size={100}
          color={ANColor.tertiary500}
          style={styles.icon}
        />
      </Animated.View>
      <Animated.View entering={FadeIn.delay(1000)}>
        <ANButton
          title={
            notificationAllawed
              ? I18n.t('onboarding.slide_two.action_already_activated')
              : I18n.t('onboarding.slide_two.action')
          }
          style={styles.action}
          eventKey="onboarding_allow_notifications"
          disabled={notificationAllawed}
          onPress={onNotificationPermissionRequest}
        />
      </Animated.View>
    </View>
  );
};

export default OnboardingSlideNotifications;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  icon: {
    paddingBottom: 25,
    alignSelf: 'center',
  },
  scrollContainer: {
    flex: 1,
    paddingHorizontal: 8,
    paddingTop: MetricsSizes.singlePadding,
  },
  title: {
    textAlign: 'center',
    marginVertical: MetricsSizes.singlePadding,
    color: ANColor.tertiary500,
  },

  body: {
    marginVertical: MetricsSizes.singlePadding,
    textAlign: 'center',
  },
  action: {
    marginBottom: 50,
  },
});
