import { useNavigation } from '@react-navigation/native';
import { StatusBar } from 'expo-status-bar';
import React, { useContext, useEffect, useState } from 'react';
import { FlatList, Platform, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import EmptyList from '../../component/EmptyList';
import HeaderInfoButton from '../../component/HeaderInfoButton';
import ListItemSeparator from '../../component/ListItemSeparator';
import ANScreenContainer from '../../component/ScreenContainer';
import TagPickerListScroll from '../../component/TagPickerListScroll';
import ExplorerAudioWebPlayerModal from '../../component/modal/ExplorerAudioWebPlayerModal';
import UserContext from '../../context/UserContext';
import usePaginationLocalData from '../../hooks/usePaginationLocalData';
import { ANColor } from '../../theme/ANColor';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import { openUrlInBrowser } from '../../util/utils';
import VideoWebPlayerModal from '../home/VideoPlayerScreen/VideoWebPlayerModal';
import useMediaExplorerList from './component/useMediaExplorerList';
import useExplorerTopicScreen from './useExplorerTopicScreen';

export type ExplorerTopicProps = {
  id: number;
};

const ExplorerTopicScreen = () => {
  const { bottom } = useSafeAreaInsets();
  const navigation = useNavigation();

  const {
    topic,
    isLoading,
    userFavorites,
    infoUrl,
    difficulties,
    otherTags,
    filteredMedia,
    title,
    videoToPlay,
    isVideoWebModalVisible,
    setVideoWebModalVisible,
    onVideoExit,
    difficultySelected,
    selectedTag,
    tagSelected,
    selectedDifficulty,
    selectedAuthor,
    authors,
    defaultAuthor,
    authorSelected,
    favoriteTapped,
    onMediaPressed,
    audioToPlayWeb,
    videoIsLoading,
    onAudioExit,
  } = useExplorerTopicScreen();

  const { isInvitedMode } = useContext(UserContext);

  const { listHeader, numberOfColumns, _renderItem, onLayout, mediasToDisplay } =
    useMediaExplorerList(
      topic,
      filteredMedia,
      isInvitedMode,
      videoIsLoading,
      favoriteTapped,
      userFavorites,
      onMediaPressed,
    );

  const { loadNextPage } = usePaginationLocalData(topic?.medias);

  const openInfoPage = () => openUrlInBrowser(infoUrl);
  const [isAudioWebModalVisible, setAudioWebModalVisible] = useState(false);

  if (Platform.OS === 'web') {
    useEffect(() => {
      if (videoToPlay) {
        setVideoWebModalVisible(true);
      } else {
        setVideoWebModalVisible(false);
      }
    }, [videoToPlay]);

    useEffect(() => {
      if (audioToPlayWeb) {
        setAudioWebModalVisible(true);
      } else {
        setAudioWebModalVisible(false);
      }
    }, [audioToPlayWeb]);
  }

  const onWebModalDismiss = () => {
    setVideoWebModalVisible(false);
  };
  useEffect(() => {
    navigation.setOptions({
      headerTitle: title,
      headerRight: () => {
        return infoUrl && <HeaderInfoButton onPress={openInfoPage} />;
      },
    });
  }, [title]);

  return (
    <ANScreenContainer useHorizontalPadding={false} onLayout={onLayout}>
      <StatusBar backgroundColor={ANColor.primary300} />

      <FlatList
        key={numberOfColumns}
        data={mediasToDisplay}
        scrollEventThrottle={16}
        numColumns={numberOfColumns}
        ItemSeparatorComponent={() => {
          return <ListItemSeparator />;
        }}
        ListEmptyComponent={<EmptyList isLoading={isLoading} />}
        ListHeaderComponent={
          <View>
            {listHeader(title, authors, selectedAuthor, authorSelected, defaultAuthor)}
            <View style={styles.filterListContainer}>
              {difficulties && (
                <TagPickerListScroll
                  tags={difficulties ?? []}
                  selectedTag={selectedDifficulty}
                  i18nprefix="common.difficulty."
                  onTagSelected={difficultySelected}
                />
              )}
              {otherTags && (
                <TagPickerListScroll
                  tags={otherTags ?? []}
                  selectedTag={selectedTag}
                  i18nprefix="tag."
                  onTagSelected={tagSelected}
                  style={{ marginTop: MetricsSizes.small }}
                />
              )}
            </View>
          </View>
        }
        ListFooterComponent={<View style={{ height: Math.max(MetricsSizes.regular, bottom) }} />}
        renderItem={({ item, index }) => {
          return _renderItem(item, index);
        }}
        keyExtractor={({ id }) => {
          return String(id);
        }}
        onEndReachedThreshold={0.9}
        onEndReached={loadNextPage}
      />

      {!isDevice() && (
        <ExplorerAudioWebPlayerModal
          media={audioToPlayWeb}
          timeFlag={audioToPlayWeb?.timeFlagInMilli}
          modalVisible={isAudioWebModalVisible}
          shouldDismiss={() => onAudioExit()}
        />
      )}

      {!isDevice() && videoToPlay && (
        <VideoWebPlayerModal
          mediaId={videoToPlay.mediaId}
          uri={videoToPlay.uri}
          onExit={onVideoExit}
          modalVisible={isVideoWebModalVisible}
          shouldDismiss={onWebModalDismiss}
        />
      )}
    </ANScreenContainer>
  );
};

export default ExplorerTopicScreen;

const styles = StyleSheet.create({
  filterListContainer: {
    marginVertical: MetricsSizes.regular,
  },
});
