import Analytics from '../FirebaseAnalytics';
import { isDevice } from './Device';

export const setAnalyticsUserId = (userId: string) => {
  if (isDevice()) Analytics().setUserId(userId);
};

export const logEvent = (name: string, params?: any) => {
  if (isDevice()) Analytics().logEvent(name, params);
  if (__DEV__) {
    console.log('📊 Event logged: ', name, params);
  }
};

export const setUserTrainingCount = (count: number) => {
  if (isDevice()) Analytics().setUserProperty('training_count', count.toString());
};

export const setUserIsLogged = (authenticated: boolean) => {
  if (isDevice()) Analytics().setUserProperty('is_authenticated', authenticated ? 'true' : 'false');
};
export const setAnalyticsCurrentScreen = (screenName: string) => {
  console.log('screen view', screenName);
  if (isDevice()) Analytics().logScreenView({ screen_name: screenName });
};

export const formatAnalyticsEvent = (key: string) => {
  try {
    const formattedKey = key.toLowerCase().replace(/ /g, '_');
    if (formattedKey.length > 40) {
      console.log('🐛 Event name is too long: ' + formattedKey);
      return undefined;
    } else {
      return formattedKey;
    }
  } catch (error) {
    console.log('🐛 error with normalizeString', key, error);
  }
};

export enum EventKey {
  health_checkup_validate = 'health_checkup_validate',
  health_checkup_send = 'health_checkup_send',
  health_checkup_cancelled = 'health_checkup_cancelled',
  health_checkup_appointment = 'health_checkup_appointment',
  dashboard_health_checkup_tapped = 'dashboard_health_checkup_tapped',
  dashboard_health_checkup_completed = 'dashboard_health_checkup_completed',
  dashboard_health_checkup_cancelled = 'dashboard_health_checkup_cancelled',
  difficulty_review_validate = 'difficulty_review_validate',
  difficulty_review_send = 'difficulty_review_send',
  difficulty_review_cancelled = 'difficulty_review_cancelled',
  patient_logged_in = 'patient_logged_in',
  add_to_favorites = 'add_to_favorites',
  removed_from_favorites = 'removed_from_favorites',
  open_podcast_screen = 'open_podcast_screen',
  start_training_session = 'start_training_session',
  training_session_congrats_closed = 'training_session_congrats_closed',
  training_session_completed = 'training_session_completed',
  training_session_cancelled = 'training_session_cancelled',
  listen_to_audio = 'listen_to_audio',
  video_view = 'video_view',
  video_exit = 'video_exit',
  video_completed = 'video_completed',
  intro_video_view = 'intro_video_view',
  intro_video_completed = 'intro_video_completed',
  intro_video_exit = 'intro_video_exit',
  teaser_video_view = 'teaser_video_view',
  teaser_video_completed = 'teaser_video_completed',
  teaser_video_exit = 'teaser_video_exit',
  breathing_exercice_started = 'breathing_exercice_started',
  breathing_exercice_completed = 'breathing_exercice_completed',
  onboarding_screen_opened = 'onboarding_screen_opened',
  training_fullvideo_played = 'training_fullvideo_played',
  opened_topic_screen = 'opened_topic_screen',
  explorer_filter_tapped = 'explorer_filter_tapped',
  share_app = 'share_app',
}
