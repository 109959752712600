import React, { createContext, FC } from 'react';
import { View } from 'react-native';
import SnackBarError from '../component/SnackBarError';
import SnackBarInfo from '../component/SnackBarInfo';
import I18n from '../i18n';
import sentry from '../sentry';

type Props = {
  errorToDisplay?: string;
  showError: boolean;
  setShowError: (show: boolean) => void;
  displaySnackBarError: (messag?: string) => void;
  infoToDisplay?: string;
  showInfo: boolean;
  setShowInfo: (show: boolean) => void;
  displaySnackBarInfo: (message: string) => void;
};

const SnackMessageContext = createContext<Props>({
  errorToDisplay: undefined,
  showError: false,
  displaySnackBarError: () => {},
  setShowError: () => {},
  infoToDisplay: undefined,
  showInfo: false,
  setShowInfo: () => {},
  displaySnackBarInfo: () => {},
});

export const SnackMessageProvider: FC<Props> = ({ children }) => {
  const [errorToDisplay, setErrorToDisplay] = React.useState('');
  const [showError, setShowError] = React.useState(false);

  const [infoToDisplay, setInfoToDisplay] = React.useState('');
  const [showInfo, setShowInfo] = React.useState(false);

  const displaySnackBarError = (message?: string) => {
    const error = message ?? I18n.t('error.unknown');
    console.log('Error to display', error);
    setErrorToDisplay(error);
    setShowError(true);
    //if (sentry) sentry.captureException(error);
  };

  const displaySnackBarInfo = (message: string) => {
    setInfoToDisplay(message);
    setShowInfo(true);
  };

  return (
    <SnackMessageContext.Provider
      value={{
        errorToDisplay,
        showError,
        displaySnackBarError,
        setShowError,
        infoToDisplay,
        showInfo,
        setShowInfo,
        displaySnackBarInfo,
      }}
    >
      <View style={{ flex: 1 }}>
        {children}
        <View>
          <SnackBarError
            text={errorToDisplay ?? ''}
            isVisible={showError}
            onDismiss={() => setShowError(false)}
          />
          <SnackBarInfo
            text={infoToDisplay ?? ''}
            isVisible={showInfo}
            onDismiss={() => setShowInfo(false)}
          />
        </View>
      </View>
    </SnackMessageContext.Provider>
  );
};

export default SnackMessageContext;
