import React from 'react';
import { StyleSheet, Text } from 'react-native';
import { interpolate, useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import I18n from '../../../i18n';
import ANFont from '../../../theme/ANFont';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';

const AnimatedBreathDescription = () => {
  const explanationAnimationValue = useSharedValue(0);

  const explanationStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(explanationAnimationValue.value, [0, 1], [0, 1]),
    };
  });

  /**
   * Start explanation animation
   */
  // useEffect(() => {
  //   explanationAnimationValue.value = 0;
  //   explanationAnimationValue.value = withDelay(
  //     500,
  //     withRepeat(
  //       withTiming(1, {
  //         duration: 3000,
  //         easing: Easing.linear,
  //       }),
  //       2,
  //       true,
  //     ),
  //   );
  // }, []);

  return (
    <Text
      style={[
        isDevice() ? ANFont.textLargeRegular : ANFont.h6Bold,
        styles.description,
        //explanationStyle,
      ]}
    >
      {I18n.t('breath_exercice.description')}
    </Text>
  );
};

export default AnimatedBreathDescription;

const styles = StyleSheet.create({
  description: {
    textAlign: 'center',
    marginBottom: MetricsSizes.big,
  },
});
