import { useContext, useMemo } from 'react';
import { useMutation } from 'react-query';
import { putExerciceLevelUpdate } from '../../api/request/user/putExerciceLevelUpdate';
import SnackMessageContext from '../../context/SnackMessageContext';
import UserContext from '../../context/UserContext';
import I18n from '../../i18n';
import ANVideo from '../../model/ANVideo';
import ANExercice from '../../model/ANVideoGroup';

type PostBody = {
  exerciceId: number;
  newVideoId: number;
};

const useVideoLevelSelection = (exercice: ANExercice | undefined, useLimitedMode: boolean) => {
  const { displaySnackBarError, displaySnackBarInfo } = useContext(SnackMessageContext);

  const { updateExercice } = useContext(UserContext);

  const { mutateAsync } = useMutation((body: PostBody) => {
    return putExerciceLevelUpdate(body.exerciceId, body.newVideoId);
  });

  const selectableVideos = useMemo(() => {
    console.log('Selectable videos', exercice?.videos);
    console.log('useLimitedMode', useLimitedMode);
    const videos = exercice?.videos.filter(video => video.limitedMode == useLimitedMode);

    videos?.forEach(video => console.log('video', video));
    return exercice?.videos.filter(video => video.limitedMode === useLimitedMode);
  }, [exercice]);

  const onLevelSelected = async (selectedVideo: ANVideo) => {
    console.log(selectedVideo);
    if (!exercice) return;
    try {
      const newExercice = await mutateAsync({
        exerciceId: exercice.id,
        newVideoId: selectedVideo.id,
      });

      updateExercice(newExercice);
      displaySnackBarInfo(I18n.t('training.level_updated'));
    } catch (error) {
      displaySnackBarError(I18n.t('error.unknown'));
    }
  };

  return {
    onLevelSelected,
    selectableVideos,
  };
};

export default useVideoLevelSelection;
