import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { FC, useContext } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMutation } from 'react-query';
import {
  DifficultyCheck,
  postDifficultyReport,
} from '../../../api/request/user/postDifficultyReport';
import ANImages from '../../../assets/image/ANimages';

import FooterOverlayButton from '../../../component/FooterOverlayButton';
import SnackMessageContext from '../../../context/SnackMessageContext';
import UserContext from '../../../context/UserContext';
import I18n from '../../../i18n';
import ANVideo from '../../../model/ANVideo';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { EventKey } from '../../../util/analytics';
import { isDevice } from '../../../util/Device';

type Props = {};

type Body = {
  answer: DifficultyCheck;
  videoIds?: number[];
};

type ScreenRouteProp = RouteProp<RootStackParamList, Route.difficultyCheckThanksScreen>;

export type DifficultyCheckThanksScreenProps = {
  difficulty: DifficultyCheck;
  shouldDismissWebModal?: () => void;
  videos?: ANVideo[]; //FOr normal difficulty, no video provided
};

const DifficultyReportThanksScreen: FC<DifficultyCheckThanksScreenProps> = (props: Props) => {
  const { top, bottom } = useSafeAreaInsets();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { updateLastReviews } = useContext(UserContext);
  const { displaySnackBarError } = useContext(SnackMessageContext);
  const {
    params: { difficulty, videos, shouldDismissWebModal },
  } = useRoute<ScreenRouteProp>();

  const { mutateAsync, isLoading } = useMutation((body: Body) => {
    return postDifficultyReport(body.answer, body.videoIds);
  });

  const postReport = async () => {
    try {
      const result = await mutateAsync({
        answer: difficulty,
        videoIds: videos?.map(video => video.id),
      });
      if (result) {
        updateLastReviews(result);

        if (shouldDismissWebModal && !isDevice()) {
          shouldDismissWebModal();
        } else {
          navigation.getParent()?.goBack();
        }
      } else {
        displaySnackBarError();
      }
    } catch (e) {
      displaySnackBarError();
    }
  };

  const doneTapped = () => {
    postReport();
  };

  return (
    <View style={ANLayout.screenContaineNoPadding}>
      <View style={[styles.subContainer, { paddingTop: top + MetricsSizes.regular }]}>
        <ScrollView bounces={false} contentContainerStyle={styles.scrollContainer}>
          <Text style={[ANFont.h6Bold, styles.title]}>
            {I18n.t('difficulty_check.thanks.title')}
          </Text>
          <Text style={[ANFont.textLargeBold, styles.subtitle]}>
            {I18n.t('difficulty_check.thanks.subtitle')}
          </Text>
          <Text style={[ANFont.textLargeRegular, styles.body]}>
            {I18n.t('difficulty_check.thanks.body')}
          </Text>
          <Image source={ANImages.ExerciceIllustration} style={styles.imagePlaceholder} />
        </ScrollView>
      </View>
      <FooterOverlayButton
        onPress={doneTapped}
        paddingBottom={bottom}
        eventKey={EventKey.difficulty_review_send}
        type="send"
        disabled={isLoading}
      />
    </View>
  );
};

export default DifficultyReportThanksScreen;

const styles = StyleSheet.create({
  subContainer: {
    flex: 1,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  scrollContainer: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.big,
  },
  subtitle: {
    textAlign: 'center',
    color: ANColor.commonBlack,
    marginBottom: MetricsSizes.small,
  },
  body: {
    textAlign: 'center',
    color: ANColor.commonBlack,
    marginBottom: MetricsSizes.regular,
  },
  imagePlaceholder: {
    resizeMode: 'contain',
    width: '80%',
    height: 200,
    marginTop: 63,
  },
});
