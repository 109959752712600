import { useNavigation } from '@react-navigation/native';
import React, { FC, memo } from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';
import { ANColor } from '../theme/ANColor';
import { ExpoIconPressable } from './ExpoIcon';

type Props = {
  color?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

const HIT_SLOP = { bottom: 15, right: 15, left: 15, top: 15 };

const BackNavigationButton: FC<Props> = ({ color = ANColor.commonBlack, onPress, style }) => {
  const navigation = useNavigation();
  const goBack = () => {
    navigation.goBack();
  };

  return (
    <ExpoIconPressable
      name="chevron-left"
      color={color}
      size={35}
      hitSlop={HIT_SLOP}
      onPress={onPress ? onPress : goBack}
      style={[style, { marginRight: Platform.OS == 'android' ? 16 : 0 }]}
    />
  );
};

export default memo(BackNavigationButton);
