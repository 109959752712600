import React, { FC } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import { ANColor } from '../theme/ANColor';
import { hapticSelection } from '../util/utils';
import { ExpoIconDesign, ExpoIconPressable } from './ExpoIcon';

type Props = {
  isFavorite: boolean;
  style?: ViewStyle;
  onPress: () => void;
};

const HIT_SLOP = { bottom: 30, right: 30, left: 30, top: 30 };

const HeartButton: FC<Props> = ({ isFavorite, style, onPress }) => {
  const favoriteTapped = () => {
    onPress();
    hapticSelection();
  };

  return (
    <ExpoIconPressable
      hitSlop={HIT_SLOP}
      onPress={favoriteTapped}
      name={isFavorite ? 'heart' : 'heart-outline'}
      design={ExpoIconDesign.MaterialCommunity}
      color={ANColor.primary500}
      style={style}
    />
  );
};

export default HeartButton;

const styles = StyleSheet.create({});
