const useHour = () => {
  const date = new Date();
  const hour = date.getHours();
  const isNight: boolean = date.getHours() > 19;

  /**
   * Create a chrono string of type MM:SS (if hour is 0) or HH:MM:SS if more than an hour
   * @param timeInMillis time in milliseconds
   * @returns
   */
  const createChronoString = (timeInMillis: number) => {
    let sec = timeInMillis / 1000;
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = Math.floor(sec - hours * 3600 - minutes * 60); //  get seconds

    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
        seconds,
      ).padStart(2, '0')}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  };

  /***
   * Create a time string of type HH:MM
   */
  const createDayTimeStyleString = (hours: number, minutes: number) => {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  const createDurationString = (durationInMillis: number) => {
    return createChronoString(durationInMillis);
  };

  return {
    hour,
    isNight,
    createChronoString,
    createTimeString: createDayTimeStyleString,
    createDurationString,
  };
};

export default useHour;
