import React, { FC } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import ExpoIcon from './ExpoIcon';

type Props = {
  title: string;
  selected: boolean;
  style?: ViewStyle;
  onPress: () => void;
};

const ItemPicker: FC<Props> = ({ title, selected, style, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[ANLayout.rowHCenter, styles.container, style]}>
        <ExpoIcon
          name={selected ? 'radio-button-on' : 'radio-button-off'}
          color={selected ? ANColor.primary500 : ANColor.common400}
        />
        <Text style={[ANFont.textMediumRegular, styles.label]}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default ItemPicker;

const styles = StyleSheet.create({
  container: {
    padding: MetricsSizes.singlePadding,
    backgroundColor: ANColor.commonWhite,
    overflow: 'hidden',
    borderRadius: MetricsSizes.cornerRadius,
  },
  label: {
    marginLeft: MetricsSizes.singlePadding,
  },
});
