import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Animated, { FadeIn } from 'react-native-reanimated';
import { useMutation } from 'react-query';
import { postForgotPassword } from '../../api/request/user/postForgotPassword';
import LogoLong from '../../assets/svg/LogoLong';
import ANButton from '../../component/ANButton';
import FormView from '../../component/form/FormView';
import { InputGroup } from '../../component/form/InputGroup';
import ANScreenContainer from '../../component/ScreenContainer';
import I18n from '../../i18n';
import { RootStackParamList } from '../../navigation/Route';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import { default as Layout } from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import { resetPasswordValidationScheme } from '../../util/validationScheme';
type Props = {};

type FormValues = {
  email: string;
};

const AuthResetPasswordScreen = (props: Props) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const [emailSent, setEmailSent] = useState(false);

  const { control, getValues, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(resetPasswordValidationScheme),
    mode: 'onBlur',
  });

  const sendResetPasswordEmail = () => {
    // if (__DEV__) {
    //   debugReset();
    //   return;
    // }
    const email = getValues('email');
    console.log(email);
    mutateAsync(getValues('email'));
    console.log('Send email');
    setEmailSent(true);
  };

  const debugReset = () => {
    setEmailSent(true);
  };

  const { mutateAsync, isLoading } = useMutation((email: string) => {
    return postForgotPassword(email);
  });

  const goBack = () => {
    if (isDevice()) {
      navigation.goBack();
    } else {
      window.location.href = '/';
    }
  };

  if (emailSent) {
    return (
      <View style={Layout.screenContainer}>
        <View style={styles.confirmationContainer}>
          <Animated.Text style={[styles.title, ANFont.h5Bold]} entering={FadeIn}>
            {I18n.t('auth.reset_password_success_title')}
          </Animated.Text>
          <Animated.View entering={FadeIn.delay(500)} style={styles.endButton}>
            <ANButton onPress={goBack} title={I18n.t('auth.reset_password_back_login')} />
          </Animated.View>
        </View>
      </View>
    );
  }
  return (
    <ANScreenContainer useHorizontalPadding useSafeArea edges={['bottom']}>
      <View style={isDevice() ? styles.deviceContainer : styles.webContainer}>
        <KeyboardAwareScrollView bounces={false} showsVerticalScrollIndicator={false}>
          {!isDevice() && <LogoLong style={styles.webLogo} />}

          <Text style={[styles.title, ANFont.h5Bold]}>
            {I18n.t('auth.reset_password_email_title')}
          </Text>
          <FormView>
            <View style={styles.controllersContainer}>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                  <InputGroup
                    label={I18n.t('auth.email')}
                    value={value}
                    placeholder={I18n.t('auth.email_placeholder')}
                    onChangeText={onChange}
                    type="email-address"
                    errorDetails={error?.message}
                    onBlur={onBlur}
                    returnKeyType="done"
                    onSubmit={handleSubmit(sendResetPasswordEmail)}
                  />
                )}
              />
            </View>
          </FormView>
        </KeyboardAwareScrollView>
        <ANButton
          onPress={handleSubmit(sendResetPasswordEmail)}
          style={styles.button}
          wrap={!isDevice()}
          eventKey="auth_reset_password_tapped"
          title={I18n.t('auth.reset_password_send_button')}
        />
      </View>
    </ANScreenContainer>
  );
};

const styles = StyleSheet.create({
  webContainer: {
    justifyContent: 'flex-start',
  },
  deviceContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 100,
    color: ANColor.tertiary500,
  },
  controllersContainer: {
    marginTop: 70,
  },
  button: {
    marginBottom: MetricsSizes.regular,
    marginTop: isDevice() ? 0 : 80,
  },
  confirmationContainer: {
    paddingVertical: 50,
    justifyContent: isDevice() ? 'space-between' : 'flex-start',
  },
  endButton: {
    marginTop: 50,
  },
  webLogo: {
    width: 214,
    alignSelf: 'center',
  },
});

export default AuthResetPasswordScreen;
