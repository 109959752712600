import React, { FC, useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import ANImages from '../../../../assets/image/ANimages';
import ANButton from '../../../../component/ANButton';
import ANImageView from '../../../../component/Image';
import UserContext from '../../../../context/UserContext';
import I18n from '../../../../i18n';
import { ANColor, withOpacity } from '../../../../theme/ANColor';
import Font from '../../../../theme/ANFont';
import Layout from '../../../../theme/ANLayout';
import { MetricsSizes } from '../../../../theme/ANMetrics';
import { isDevice } from '../../../../util/Device';
import { padding } from '../../../../util/padding';

type Props = {
  style?: StyleProp<ViewStyle>;
  discoverOsteow: () => void;
  onStartTapped: () => void;
};

const MyTrainingSessionCard: FC<Props> = ({ style, onStartTapped, discoverOsteow }) => {
  const { user, isInvitedMode } = useContext(UserContext);

  const exists = useMemo(() => {
    return user?.trainingPlaylist !== undefined;
  }, [user]);

  const hasExpired = useMemo(() => {
    return user?.trainingPlaylist?.expired;
  }, [user]);

  const previewImage = useMemo(() => {
    const exercices = user?.trainingPlaylist?.exercices.filter(exercice => exercice.selectedVideo);
    const nonLimitedVideos = exercices
      ?.map(exercice => exercice.selectedVideo)
      .filter(video => !video?.limitedMode);
    if (nonLimitedVideos && nonLimitedVideos.length > 0) {
      return nonLimitedVideos[0].previewLarge;
    }
  }, [user]);

  const imageSource = useMemo(() => {
    return previewImage ? { uri: previewImage } : ANImages.PlaylistPlaceholder;
  }, [previewImage]);

  if (isInvitedMode) {
    return (
      <View style={[Layout.card, styles.card, style]}>
        <ANImageView source={ANImages.PlaylistPlaceholder} style={styles.image} />
        <View style={styles.shade}>
          <Text style={[Font.textLargeBold, styles.reviewText]}>
            {I18n.t('training.card.invited_mode_label')}
          </Text>
          <ANButton
            eventKey="training_card_discover_andrew_tapped"
            title={I18n.t('common.discover_andrew')}
            onPress={discoverOsteow}
            style={styles.expiredInvitedButton}
            theme="tertiary"
            wrap={true}
          />
        </View>
      </View>
    );
  }

  if (!exists || hasExpired) {
    return (
      <View style={[Layout.card, styles.card, style]}>
        <ANImageView source={imageSource} style={styles.image} />

        <View style={styles.shade}>
          <Text style={styles.reviewText}>
            {hasExpired
              ? I18n.t('training.card.training_expired')
              : I18n.t('training.card.missing_training_program')}
          </Text>
        </View>
      </View>
    );
  }

  return (
    <View style={[Layout.card, styles.card, style]}>
      <ANImageView source={imageSource} style={styles.image} />

      <View style={styles.container}>
        <ANButton
          title={I18n.t('training.card.open_my_session')}
          eventKey="training_card_open_session_tapped"
          onPress={onStartTapped}
          style={styles.accessTrainingButton}
        />
      </View>
    </View>
  );
};

export default MyTrainingSessionCard;

const styles = StyleSheet.create({
  card: {
    ...padding(0),
    width: isDevice() ? '100%' : 650,
    backgroundColor: ANColor.primary200,
    aspectRatio: 2,
  },
  image: {
    height: '100%',
    width: '100%',
    resizeMode: 'cover',
  },
  container: {
    marginHorizontal: MetricsSizes.screenHorizontalPadding,
    alignItems: 'center',
  },
  shade: {
    height: '100%',
    width: '100%',
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: withOpacity(60, ANColor.commonBlack),
    position: 'absolute',
  },
  reviewText: {
    color: ANColor.commonWhite,
    textAlign: 'center',
    maxWidth: 300,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  reviewButton: {
    marginTop: MetricsSizes.singlePadding,
  },
  accessTrainingButton: {
    bottom: MetricsSizes.regular,
    position: 'absolute',
  },
  expiredInvitedButton: {
    marginTop: MetricsSizes.singlePadding,
  },
});
