import React, { FC, useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import ANImages from '../assets/image/ANimages';
import I18n from '../i18n';
import ANExercice from '../model/ANVideoGroup';
import { ANColor } from '../theme/ANColor';
import { default as ANFont, ANFontFamily, default as Font } from '../theme/ANFont';
import { default as Layout } from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import ANButton from './ANButton';
import ExpoIcon, { ExpoIconDesign } from './ExpoIcon';
import ANImageView from './Image';

type TrainingExerciceListItem = {
  index: number;
  exercice: ANExercice;
  useRestrictedMode: boolean;
  displayLevel?: boolean;
  style?: ViewStyle;
  onCardTapped?: (id: number, uri: string) => Promise<void>;
  onLevelTapped?: (id: number) => void;
};

const TrainingExerciceListItem: FC<TrainingExerciceListItem> = ({
  index,
  exercice,
  useRestrictedMode = false,
  displayLevel = true,
  style,
  onCardTapped,
  onLevelTapped,
}) => {
  const {
    id,
    previewThumb,
    level,
    urlFull: urlLong,
  } = useMemo(() => {
    return exercice.selectedVideo;
  }, [exercice]);

  const hasSeveralLevels = useMemo(() => {
    const videos = exercice.videos.filter(video => video.limitedMode == useRestrictedMode);
    return videos.length > 1;
  }, [exercice, useRestrictedMode]);

  const title = useMemo(() => {
    return exercice.title;
  }, [exercice]);

  const cardPressed = useCallback(() => {
    console.log('card pressed', previewThumb, urlLong);
    if (onCardTapped) onCardTapped(id, urlLong);
  }, [exercice]);

  const levelPressed = () => {
    if (hasSeveralLevels && onLevelTapped) onLevelTapped(exercice.id);
  };

  const imageSource = useMemo(() => {
    return previewThumb ? { uri: previewThumb } : ANImages.ExercicePlaceholder;
  }, [previewThumb]);
  console.log('useRestrictedMode', useRestrictedMode);
  return (
    <View style={[Layout.roundedBackground, Layout.row, styles.backgroundContainer, style]}>
      <View style={[Layout.rowHCenter, styles.container]}>
        <TouchableOpacity onPress={cardPressed} style={styles.videoTouchZone}>
          <View style={styles.cover}>
            <ANImageView source={imageSource} style={styles.image} />
            <View
              style={[StyleSheet.absoluteFill, { justifyContent: 'center', alignItems: 'center' }]}
            >
              <ExpoIcon
                name="play-circle"
                design={ExpoIconDesign.MaterialCommunity}
                color={ANColor.common800}
              />
            </View>
          </View>
          <View style={styles.textsContainer}>
            <Text style={[ANFont.textMediumBold]}>Exercice {index + 1}</Text>
            <Text style={[Font.textLargeRegular, styles.titleLabel]}>{title}</Text>
          </View>
        </TouchableOpacity>
        {displayLevel && !useRestrictedMode && (
          <ANButton
            title={I18n.t('training.exercice_level', {
              level: level,
            })}
            titleStyle={styles.levelLabel}
            style={styles.levelButton}
            mobileInnerPadding={MetricsSizes.small}
            cornerType={'light'}
            wrap={true}
            rightAccessory={hasSeveralLevels && <ExpoIcon name="keyboard-arrow-down" size={20} />}
            onPress={levelPressed}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundContainer: {
    backgroundColor: ANColor.primary100,
    borderWidth: 1,
    borderColor: ANColor.tertiary500,
    marginVertical: 4,
  },
  videoTouchZone: {
    flex: 1,
    flexDirection: 'row',
  },
  container: {
    flex: 1,
  },
  textsContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginLeft: MetricsSizes.singlePadding,
  },

  cover: {
    width: 70,
    height: 70,
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    opacity: 0.7,
  },

  titleLabel: {
    marginBottom: 0,
  },
  levelButton: {
    backgroundColor: ANColor.primary200,
  },
  levelLabel: {
    fontSize: 12,
    fontFamily: ANFontFamily.MontserratBold,
    color: ANColor.commonBlack,
  },
});
export default TrainingExerciceListItem;
