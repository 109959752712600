import { useNavigation } from '@react-navigation/native';
import React, { FC, memo } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ANColor } from '../theme/ANColor';
import { MetricsSizes } from '../theme/ANMetrics';
import { isDevice } from '../util/Device';
import ExpoIcon from './ExpoIcon';

type Props = {
  color?: string;
  size?: number;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

const HIT_SLOP = { bottom: 15, right: 15, left: 15, top: 15 };

const CloseModaleButton: FC<Props> = ({ color = ANColor.common400, onPress, style, size }) => {
  if (isDevice()) {
    const { top } = useSafeAreaInsets();
    const navigation = useNavigation();
    const goBack = () => {
      navigation.goBack();
    };

    return (
      <View style={[styles.close, { marginTop: top }, style]}>
        <TouchableOpacity hitSlop={HIT_SLOP} onPress={onPress ? onPress : goBack} style={style}>
          <ExpoIcon name="close" color={color} size={size} />
        </TouchableOpacity>
      </View>
    );
  } else {
    //WEB version
    return (
      <View style={[style]}>
        <TouchableOpacity hitSlop={HIT_SLOP} onPress={onPress} style={style}>
          <ExpoIcon name="close" color={color} size={40} />
        </TouchableOpacity>
      </View>
    );
  }
};

export default memo(CloseModaleButton);

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    top: MetricsSizes.regular,
    right: MetricsSizes.regular,
  },
});
