import React, { FC, useCallback, useMemo } from 'react';
import { LayoutChangeEvent, Platform, StyleSheet, View } from 'react-native';
import I18n from '../i18n';
import { ANColor } from '../theme/ANColor';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { formatAnalyticsEvent, logEvent } from '../util/analytics';
import { hapticSelection } from '../util/utils';
import ANButton, { ANButtonTheme } from './ANButton';

type Props = {
  type: 'done' | 'continue' | 'validate' | 'start' | 'send' | 'start-session';
  paddingBottom: number;
  disabled?: boolean;
  theme?: ANButtonTheme;
  eventKey?: string;
  useAbsoluteOverlay?: boolean;
  onPress: () => void;
  onHeightCalculated?: (height: number) => void;
};

/**
 * A footer button to overlay a scrollview or flatlist
 */
const FooterOverlayButton: FC<Props> = ({
  type,
  paddingBottom,
  disabled = false,
  useAbsoluteOverlay = true,
  children,
  theme = 'primary',
  eventKey,
  onPress,
  onHeightCalculated,
}) => {
  const title = useMemo(() => {
    switch (type) {
      case 'done':
        return I18n.t('common.done_button');
      case 'continue':
        return I18n.t('common.continue_button');
      case 'validate':
        return I18n.t('common.validate_button');
      case 'start':
        return I18n.t('common.start_button');
      case 'start-session':
        return I18n.t('training.start_button');
      case 'send':
        return I18n.t('common.send_button');
    }
  }, [type]);

  const onLayout = (event: LayoutChangeEvent) => {
    const { height: screenheight } = event.nativeEvent.layout;
    if (onHeightCalculated) onHeightCalculated(screenheight);
  };

  const paddingBottomProcessed = useMemo(() => {
    return paddingBottom > 0 ? paddingBottom : MetricsSizes.regular;
  }, [paddingBottom]);

  const formattedAnalyticsEvent: string | undefined = useMemo(() => {
    if (!eventKey) return undefined;
    return formatAnalyticsEvent(eventKey);
  }, [eventKey]);

  const pressed = useCallback(() => {
    hapticSelection();
    onPress();
    if (formattedAnalyticsEvent) logEvent(formattedAnalyticsEvent);
  }, [formattedAnalyticsEvent, onPress]);

  return (
    <View
      style={[
        useAbsoluteOverlay ? ANLayout.absoluteFooter : ANLayout.relativeFooter,
        Platform.OS === 'ios' && ANLayout.footerShadow,
        { paddingBottom: paddingBottomProcessed },
        Platform.OS === 'android' && styles.androidContainer, //On android, shadow does not ronder. Replace by a light top border
      ]}
      onLayout={onLayout}
    >
      {children}
      <ANButton title={title} onPress={pressed} disabled={disabled} theme={theme} />
    </View>
  );
};

export default FooterOverlayButton;

const styles = StyleSheet.create({
  androidContainer: {
    borderTopWidth: 1,
    borderTopColor: ANColor.common300,
  },
});
