import { useMemo, useState } from 'react';
import ANMedia from '../model/ANMedia';

const usePaginationLocalData = (data?: any[]) => {
  const [lastLoadedPage, setLastLoadedPage] = useState<number>(0);

  const pages = useMemo(() => {
    const items = data;
    if (!items) return;
    //Slice items into pages of 10
    const pages: ANMedia[][] = [];
    for (let i = 0; i < items?.length; i += 10) {
      pages.push(items.slice(i, i + 10));
    }
    return pages;
  }, [data]);

  const loadNextPage = () => {
    if (pages && lastLoadedPage < pages.length) {
      setLastLoadedPage(lastLoadedPage + 1);
    }
  };
  const dataToDisplay = useMemo(() => {
    if (!pages) return;
    const pagesToReturn = pages.slice(0, lastLoadedPage + 1);
    return pagesToReturn.flat();
  }, [pages, lastLoadedPage]);

  return {
    loadNextPage,
    dataToDisplay,
  };
};

export default usePaginationLocalData;
