import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useContext, useState } from 'react';
import { logUserMediaUsed } from '../api/request/user/explorer/getTopics';
import UserContext from '../context/UserContext';
import ANMedia from '../model/ANMedia';
import { RootStackParamList, Route } from '../navigation/Route';
import { isDevice } from './../util/Device';
import { EventKey, logEvent } from './../util/analytics';
import useFavoriteStore from './useFavoritesStore';
import useWebModaleVideoPlayer from './useWebModaleVideoPlayer';

const useExplorerMediaPlayer = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const [mediaVideoIDBeingPlayed, setMediaVideoIDBeingPlayed] = useState<number | undefined>(
    undefined,
  );

  const { isInvitedMode } = useContext(UserContext);
  const favoritesIds = useFavoriteStore(state => state.favoritesIds);
  const addNewFavorite = useFavoriteStore(state => state.addNewFavorite);
  const removeFavorite = useFavoriteStore(state => state.removeFavorite);

  const { videoToPlay, isVideoWebModalVisible, setVideoWebModalVisible, onVideoTapped } =
    useWebModaleVideoPlayer();

  const [audioToPlayWeb, setAudioToPlayWeb] = useState<ANMedia | undefined>(undefined);

  const favoriteTapped = (mediaId: number) => {
    //Add or Remove from favorites
    const shouldAddToFavorite = !favoritesIds.includes(mediaId);
    if (shouldAddToFavorite) {
      logEvent(EventKey.add_to_favorites, { item_id: mediaId.toString() });
      addNewFavorite(mediaId);
    } else {
      logEvent(EventKey.removed_from_favorites, { item_id: mediaId.toString() });
      removeFavorite(mediaId);
    }
  };

  const onMediaPressed = (media: ANMedia) => {
    if (media.type === 'audio') {
      console.log('tapped on audio', media);
      if (isDevice()) {
        navigation.navigate(Route.explorerAudioPlayerScreen, {
          internalMediaId: media.id,
          timeFlag: media.timeFlagInMilli,
        });
      } else {
        console.log('Audio to play', media);
        setAudioToPlayWeb(media);
      }
    } else if (media.type === 'video') {
      if (isDevice()) {
        navigation.navigate(Route.videoPlayerFullScreen, {
          uri: media.url,
          explorerMediaId: media.id,
          timeFlagInMilli: media.timeFlagInMilli,
        });
      } else {
        //Display player web modale
        console.log('Display player web modale');
        setMediaVideoIDBeingPlayed(media.id);
        onVideoTapped(media.id, media.url, media.timeFlagInMilli);
      }
    } else if (media.type === 'podcast') {
      if (isDevice()) {
        navigation.navigate(Route.explorerAudioPlayerScreen, {
          podcast: media,
        });
      } else {
        setAudioToPlayWeb(media);
      }
    }
  };

  const onAudioExit = () => {
    setAudioToPlayWeb(undefined);
  };

  const onVideoExit = (positionMillis?: number, completed?: boolean) => {
    console.log('onVideoExit', positionMillis, completed);
    if (mediaVideoIDBeingPlayed) {
      logUserMediaUsed({
        mediaId: mediaVideoIDBeingPlayed,
        timeFlagInMilli: positionMillis ?? 0,
        completed: completed ?? false,
        isAuthenticated: !isInvitedMode,
      });
    }
  };

  return {
    audioToPlayWeb,
    favoriteTapped,
    onMediaPressed,
    onVideoExit,
    videoToPlay,
    isVideoWebModalVisible,
    setVideoWebModalVisible,
    onVideoTapped,
    onAudioExit,
  };
};

export default useExplorerMediaPlayer;
