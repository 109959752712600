import { isDevice } from './../util/Device';
//import { BASE_URL } from '@env';
import axios from 'axios';
import Constants from 'expo-constants';
import { DeviceEventEmitter, Platform } from 'react-native';
import { unauthorizedAccessKey } from '../util/eventEmitter';

export interface ApiErrorResponse {
  response: {
    error: {
      status: number;
      name: string;
    };
  };
}

//const url = (__DEV__ ? process.env.BASE_URL_DEV : process.env.BASE_URL) + '/api/';
//const url = 'https://api.staging.andrewapp.fr/api/';
const url = 'https://api.andrewapp.fr/api/';

console.log('base url', url);

const currentVersion = Constants.manifest?.version;

const config = isDevice()
  ? {
      baseURL: url,
      headers: {
        'device-type': Platform.OS,
        version: currentVersion ?? 'unknown',
      },
    }
  : {
      baseURL: url,
    };

export const Api = axios.create(config);

export const setHeaderAuthorization = (token: string) => {
  Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const removeHeaderAuthorization = () => {
  delete Api.defaults.headers.common['Authorization'];
};

Api.interceptors.response.use(
  response => {
    // Si tout va bien
    return response;
  },
  error => {
    console.log('Response error', JSON.stringify(error, null, 2));
    if (error.response.status === 401) {
      console.log(error.response.status, 'received : Will logout user');
      DeviceEventEmitter.emit(unauthorizedAccessKey);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);
