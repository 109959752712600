import create from 'zustand';
import {
  addMediaFromFavorite,
  removeMediaFromFavorite,
} from '../api/request/user/explorer/favorites';

export interface FavoriteState {
  favoritesIds: number[];
  addNewFavorite: (id: number) => void;
  removeFavorite: (id: number) => void;
  initFavorites: (ids: number[]) => void;
}

const useFavoriteStore = create<FavoriteState>()(set => ({
  favoritesIds: [],
  addNewFavorite: (id: number) => {
    //Update the state, and push the id to the API to store the data
    set(state => ({ favoritesIds: [...state.favoritesIds, id] }));
    addMediaFromFavorite(id);
  },
  removeFavorite: (id: number) => {
    //Update the state, and push the id to the API to store the data
    set(state => ({
      favoritesIds: state.favoritesIds.filter(favoriteId => favoriteId !== id),
    }));
    removeMediaFromFavorite(id);
  },

  initFavorites: (ids: number[]) => set({ favoritesIds: ids }),
}));

export default useFavoriteStore;
