import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import { ANColor } from '../theme/ANColor';
import { MetricsSizes } from '../theme/ANMetrics';
import { isDevice } from '../util/Device';
import { ExpoIconPressable } from './ExpoIcon';

type Props = {
  onPress: () => void;
};

const HeaderInfoButton: FC<Props> = ({ onPress }) => {
  return (
    <ExpoIconPressable
      name="info"
      onPress={onPress}
      color={ANColor.tertiary500}
      size={isDevice() ? 24 : 36}
      style={!isDevice() && { marginRight: MetricsSizes.regular }}
    />
  );
};

export default HeaderInfoButton;

const styles = StyleSheet.create({});
