import { Api } from '../../apiclient';
import { parseAPIError } from '../../data/ResponseError';

export const getRefreshToken = async () => {
  try {
    console.log('Refresh token');

    const { data } = await Api.get('/refresh-token');

    const result: string = data.jwt;
    return result;
  } catch (err) {
    parseAPIError(err); //Do not throw error for refresh-token, just log it
  }
};
