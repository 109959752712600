import React, { FC, useMemo } from 'react';
import { Dimensions, Image, StyleSheet, Text, View } from 'react-native';
import ANImages from '../../assets/image/ANimages';
import CloseModaleButton from '../../component/CloseModaleButton';
import ExpandingCircle from '../../component/ExpandingCircle';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import AudioController from './AudioController';

type Props = {
  waveMaxWidth?: number;
  audioUrl?: string;
  title: string;
  itunesDuration?: string;
  timeFlagInMilli?: number;
  onFinished: (positionInMillis: number) => void;
  onCanceled: () => void;
  updatePositionInMillis: (positionInMillis: number) => void;
};

const windowWidth = 1.5 * Dimensions.get('window').width;

const ExplorerAudioPlayer: FC<Props> = ({
  title,
  waveMaxWidth,
  audioUrl,
  itunesDuration,
  timeFlagInMilli,
  onCanceled,
  onFinished,
  updatePositionInMillis,
}) => {
  const waveWidth = useMemo(() => {
    return waveMaxWidth ?? windowWidth;
  }, [waveMaxWidth]);
  return (
    <View style={styles.container}>
      <View
        style={{
          aspectRatio: 1,
          marginBottom: 24,
          width: waveWidth,
          borderRadius: waveWidth,
        }}
      >
        {[...Array(4).keys()].map(index => {
          return (
            <ExpandingCircle
              delay={index * 2000}
              maxWidth={waveWidth}
              duration={8000}
              key={index}
              style={{
                backgroundColor: ANColor.tertiary900,
                width: waveWidth,
                height: waveWidth,
                borderRadius: waveWidth,
              }}
            />
          );
        })}
      </View>
      <View style={[StyleSheet.absoluteFill, ANLayout.columnHVCenter]}>
        <View style={styles.imageContainer}>
          <Image source={ANImages.ListenIllustration} style={[styles.backgroundImage]} />
        </View>
      </View>
      <View style={styles.audioPresentationContainer}>
        <Text style={[ANFont.h6Bold, styles.title]}>{title}</Text>
        <AudioController
          audioUrl={audioUrl}
          timeFlagInMilli={timeFlagInMilli}
          itunesDuration={itunesDuration}
          onMediaEnd={onFinished}
          positionMillisUpdated={updatePositionInMillis}
        />
      </View>
      <CloseModaleButton color={ANColor.commonWhite} onPress={onCanceled} />
    </View>
  );
};

export default ExplorerAudioPlayer;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ANColor.primary400,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  audioPresentationContainer: {
    position: 'absolute',
    bottom: 48,
    left: MetricsSizes.screenHorizontalPadding,
    right: MetricsSizes.screenHorizontalPadding,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    height: '100%',
    width: isDevice() ? '100%' : '50%',
    marginBottom: 24,
  },
  backgroundImage: {
    resizeMode: 'contain',
    width: '100%',
    height: '100%',
  },
  title: {
    textAlign: 'center',
    color: ANColor.commonWhite,
    marginBottom: MetricsSizes.giant,
  },
});
