import { MotiView } from 'moti';
import React, { FC, useCallback } from 'react';
import { LayoutChangeEvent, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { default as I18n } from '../i18n';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import { openUrlInBrowser } from '../util/utils';
import ANButton from './ANButton';
import AvatarImage from './AvatarImage';
import ExpandableText from './ExpandableText';
import ExpoIcon, { ExpoIconDesign } from './ExpoIcon';

type Props = {
  name: string;
  avatarURL?: string;
  redirectUrl?: string;
  description?: string;
  style?: ViewStyle;
  onHeightCalculated?: (height: number) => void;
};

const AuthorContainer: FC<Props> = ({
  name,
  avatarURL,
  description,
  redirectUrl,
  style,
  onHeightCalculated,
}: Props) => {
  const onLayout = useCallback((event: LayoutChangeEvent) => {
    const { height: screenheight } = event.nativeEvent.layout;
    if (onHeightCalculated) onHeightCalculated(screenheight);
  }, []);

  const openURL = async () => {
    openUrlInBrowser(redirectUrl);
  };

  return (
    <MotiView
      style={[styles.container, style]}
      onLayout={onLayout}
      from={{ translateY: -80 }}
      animate={{ translateY: 0 }}
      transition={{
        type: 'timing',
      }}
      exit={{ translateY: -80 }}
    >
      <View style={{ alignItems: 'center' }}>
        <AvatarImage url={avatarURL} size={53} />
        {redirectUrl && (
          <ANButton
            wrap
            title={I18n.t('explorer.author_url_button')}
            onPress={openURL}
            mobileInnerPadding={2}
            style={styles.linkButton}
            titleStyle={styles.linkButtonTitle}
            theme="tertiary"
            leftAccessory={
              <ExpoIcon
                name="web"
                design={ExpoIconDesign.MaterialCommunity}
                color={ANColor.primary500}
              />
            }
          />
        )}
      </View>
      <View style={styles.textContainer}>
        <Text style={ANFont.h6Bold}>{name}</Text>
        <ExpandableText text={description} />
      </View>
    </MotiView>
  );
};

export default AuthorContainer;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginRight: MetricsSizes.singlePadding,
  },
  textContainer: {
    marginHorizontal: MetricsSizes.singlePadding,
    maxWidth: 600,
    flexShrink: 1,
    flex: 1,
  },

  seeMoreLess: {
    marginTop: MetricsSizes.small,
    textDecorationLine: 'underline',
  },
  linkButton: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    marginTop: MetricsSizes.small,
  },
  linkButtonTitle: {
    fontSize: 12,
    paddingHorizontal: 4,
  },
});
