import { useCallback, useContext } from 'react';
import UserContext from '../context/UserContext';

const useTrainingChecker = () => {
  const { user } = useContext(UserContext);

  /**
   * Check if the user should fill difficulty report
   * Only triggered once per day
   * @returns true or false
   */
  const canTriggerDifficultyReport = () => {
    if (user?.trainingPlaylist === undefined) return false; //Only trigger if a playlist is defined
    return isOlderThan10Days(user?.lastReviews?.lastDifficultyReport);
  };

  /**
   * Check if the user should fill the health check up form.
   * Only triggered once per day maximum
   * @returns true or false
   */
  const canTriggerHealthCheckup = useCallback(() => {
    console.log(
      'canTriggerHealthCheckup',
      user?.lastReviews?.lastHealthCheck,
      user?.trainingPlaylist,
    );
    if (user?.trainingPlaylist === undefined) return false; //Only trigger Health checkup if a playlist is defined
    const lastHealthCheckup = user?.lastReviews?.lastHealthCheck;
    if (lastHealthCheckup) {
      const lastWasToday = checkIsToday(lastHealthCheckup);
      return !lastWasToday; //Return true if last checkup is not today
    }

    //If user did not complete any checkup yet, return true
    return true;
  }, [user?.lastReviews?.lastHealthCheck, user?.trainingPlaylist]);

  /**
   * Check if the date is today
   * @param date Date to check
   * */
  const checkIsToday = (date: string) => {
    const dateToCheck = new Date(date);
    const today = new Date();
    return today.getDate() === dateToCheck.getDate() && today.getMonth() === dateToCheck.getMonth();
  };

  /**
   * Check if the date is older than 10 days
   */
  const isOlderThan10Days = (date: string | undefined) => {
    if (date) {
      const today = new Date();
      const dateToCheck = new Date(date);
      const diff = Math.abs(today.getTime() - dateToCheck.getTime());
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      return diffDays > 10;
    }
    return true;
  };

  return {
    canTriggerDifficultyReport,
    canTriggerHealthCheckup,
  };
};

export default useTrainingChecker;
