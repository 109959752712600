import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { DifficultyCheck } from '../../../api/request/user/postDifficultyReport';
import ANButton from '../../../component/ANButton';
import ANSafeAreView from '../../../component/ANSafeAreView';
import ItemPicker from '../../../component/ItemPicker';
import I18n from '../../../i18n';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';

const DifficultyReportScreen = () => {
  const [selectedDifficulty, setDifficulty] = React.useState<DifficultyCheck | undefined>(
    undefined,
  );

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const continueTapped = () => {
    if (selectedDifficulty === DifficultyCheck.normal) {
      navigation.navigate(Route.difficultyCheckThanksScreen, { difficulty: selectedDifficulty });
    } else {
      navigation.navigate(Route.difficultyExercicePickerScreen, {
        difficulty: selectedDifficulty,
      });
    }
  };

  return (
    <ANSafeAreView style={styles.safearea} edges={['bottom']}>
      <View style={ANLayout.screenContainer}>
        <View style={styles.subcontainer}>
          <Text style={styles.title}>{I18n.t('difficulty_check.title')}</Text>

          <ItemPicker
            style={styles.picker}
            title={I18n.t('difficulty_check.too_easy')}
            selected={selectedDifficulty === DifficultyCheck.easy}
            onPress={() => setDifficulty(DifficultyCheck.easy)}
          />

          <ItemPicker
            style={styles.picker}
            title={I18n.t('difficulty_check.normal')}
            selected={selectedDifficulty === DifficultyCheck.normal}
            onPress={() => setDifficulty(DifficultyCheck.normal)}
          />

          <ItemPicker
            style={styles.picker}
            title={I18n.t('difficulty_check.too_hard')}
            selected={selectedDifficulty === DifficultyCheck.hard}
            onPress={() => setDifficulty(DifficultyCheck.hard)}
          />
        </View>

        <ANButton
          title={I18n.t('common.continue_button')}
          onPress={continueTapped}
          disabled={selectedDifficulty === undefined}
        />
      </View>
    </ANSafeAreView>
  );
};

export default DifficultyReportScreen;

const styles = StyleSheet.create({
  safearea: {
    flex: 1,
    backgroundColor: ANColor.primary100,
  },

  subcontainer: {
    flex: 1,
    justifyContent: 'center',
  },
  title: {
    ...ANFont.h6Bold,
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: 50,
  },
  picker: {
    marginTop: MetricsSizes.small,
  },
});
