import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextInput } from 'react-native';
import { APIError } from '../../../api/data/ResponseError';
import { postSignin } from '../../../api/request/user/postSignin';
import SnackMessageContext from '../../../context/SnackMessageContext';
import UserContext from '../../../context/UserContext';
import I18n from '../../../i18n';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { loginFormValidationScheme } from '../../../util/validationScheme';
import { AuthResponse } from './../../../api/data/AuthResponse';
import { EventKey, logEvent } from './../../../util/analytics';

type FormValues = {
  email: string;
  password: string;
};

const useAuthLogin = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const { loadingProfile, userLoggedIn, setUserAsGuest } = useContext(UserContext);

  const [signingAPIError, setSigningAPIError] = useState('');
  const [showSigninError, setShowSigninError] = useState(false);

  const passwordRef = useRef<TextInput>(); //Used to give focus

  const { displaySnackBarError } = useContext(SnackMessageContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({
    resolver: yupResolver(loginFormValidationScheme),
    mode: 'onBlur',
  });

  type Login = {
    email: string;
    password: string;
  };

  const [isLoading, setIsLoading] = useState(false);
  const onSigninSuccess = async ({ jwt, user }: AuthResponse) => {
    logEvent(EventKey.patient_logged_in);
    userLoggedIn(jwt, !user.passwordInitiated);
    //If account has not been initiated, ask to define a new password
    if (!user.passwordInitiated) {
      firstSigninDefinePassword(user.email);
    }
  };

  const displaySigninError = (error: any) => {
    const apiError = APIError.fromString(error);
    if (apiError === APIError.ValidationError) {
      displaySnackBarError(I18n.t('auth_error.login_incorect'));
    } else {
      displaySnackBarError(I18n.t('error.unknown'));
    }
  };

  // const { mutateAsync: loginRequest, isLoading } = useMutation((login: Login) => {
  //   return postSignin(login.email, login.password);
  // });

  const signin = async (email: string, password: string) => {
    console.log('Will sign in');
    try {
      setIsLoading(true);
      const result = await postSignin(email, password);
      if (result) {
        onSigninSuccess(result);
      }
    } catch (error) {
      displaySigninError(error);
    }
    setIsLoading(false);
  };

  const signinTapped = async () => {
    signin(getValues('email'), getValues('password'));
  };

  const signinDebug = async () => {
    console.log('Will debug in');
    signin('k.gochgarian@gmail.com', 'Karl120593');
  };

  const firstSigninDefinePassword = (email: string) => {
    console.log('Will define password for user', email);
    navigation.navigate(Route.authDefinePassword, { email: email });
  };

  const continueAnonymously = async () => {
    console.log('Will continue anonymously');
    setUserAsGuest();
  };

  const onPasswordForgottonTapped = () => {
    navigation.navigate(Route.authResetPassword);
  };

  return {
    control,
    passwordRef,
    isLoading,
    loadingProfile,
    handleSubmit,
    signinTapped,
    onPasswordForgottonTapped,
    signinDebug,
    continueAnonymously,
    firstSigninDefinePassword,
    signingAPIError,
    setShowSigninError,
    showSigninError,
  };
};

export default useAuthLogin;
