import * as Yup from 'yup';
import I18n from '../i18n';

const passwordRegEx = new RegExp(
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d!&$*?-_()+#=~@%,.:;]{8,}$/,
);

export const loginFormValidationScheme = Yup.object({
  email: Yup.string()
    .email(I18n.t('auth_error.email_format'))
    .required(I18n.t('auth_error.email_required')),
  password: Yup.string().required(I18n.t('auth_error.password_required')),
}).required();

export const resetPasswordValidationScheme = Yup.object({
  email: Yup.string()
    .email(I18n.t('auth_error.email_format'))
    .required(I18n.t('auth_error.email_required')),
}).required();

export const validatePasswordDifferentFromEmail = (password: string, email: string) => {
  console.log('check is different', password !== email);
  return password !== email;
};

export const defineNewPasswordScheme = Yup.object({
  password: Yup.string()
    .min(8, I18n.t('auth_error.password_format'))
    .matches(passwordRegEx, I18n.t('auth_error.password_format'))
    .required(I18n.t('auth_error.password_required')),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Le mot de passe ne correspond pas')
    .required(I18n.t('auth_error.password_required')),
  cgu_validation: Yup.boolean()
    .oneOf([true], I18n.t('auth_error.cgu_missing'))
    .required(I18n.t('auth_error.cgu_missing')),
}).required();

export const updatePasswordScheme = Yup.object({
  password: Yup.string()
    .min(6, I18n.t('auth_error.password_format')) //6 and not 8, because temporary password are 6 car
    .required('Veuillez saisir un mot de passe'),
  new_password: Yup.string()
    .min(8, I18n.t('auth_error.password_format'))
    .matches(passwordRegEx, I18n.t('auth_error.password_format'))
    .required(I18n.t('auth_error.password_required')),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('new_password'), null], I18n.t('auth_error.confirm_password_not_equals'))
    .required('Veuillez saisir un mot de passe'),
}).required();

export const reviewScheme = Yup.object({
  comment: Yup.string().max(250, I18n.t('review_osteo.max_length_error')),
});

export const checkUpSheme = Yup.object({
  pain: Yup.number().required(I18n.t('checkup_review.required_field')),
  mobility: Yup.number().required(I18n.t('checkup_review.required_field')),
  frequency: Yup.number().required(I18n.t('checkup_review.required_field')),
  stress: Yup.number().required(I18n.t('checkup_review.required_field')),
});
