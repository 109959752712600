import React, { FC, useEffect } from 'react';
import { StyleSheet, ViewStyle } from 'react-native';
import Animated, {
  Easing,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { ANColor } from '../theme/ANColor';

type Props = {
  maxWidth: number;
  duration: number;
  delay: number;
  style?: ViewStyle;
};

const ExpandingCircle: FC<Props> = ({ maxWidth, duration, delay, style = styles.circle }) => {
  const animationValue = useSharedValue(0);

  const rStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(animationValue.value, [0, 1], [0.8, 0]),
      transform: [
        {
          scale: interpolate(animationValue.value, [0, 1], [0, 1]),
        },
      ],
    };
  });

  useEffect(() => {
    console.log('Animate ring');
    animationValue.value = 0;
    animationValue.value = withDelay(
      delay,
      withRepeat(
        withTiming(1, {
          duration: duration,
          easing: Easing.linear,
        }),
        -1,
        false,
      ),
    );
  }, []);

  return (
    <Animated.View
      style={[
        StyleSheet.absoluteFill,
        style,
        { width: maxWidth, height: maxWidth, borderRadius: maxWidth },
        rStyle,
      ]}
    />
  );
};

export default ExpandingCircle;

const styles = StyleSheet.create({
  circle: {
    backgroundColor: ANColor.tertiary900,
  },
});
