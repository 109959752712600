import React, { FC } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { padding } from '../util/padding';

type Props = {
  level: number;
  selected: boolean;
  onPress: () => void;
};

const VideoLevelPicker: FC<Props> = ({ level, selected, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={selected ? [styles.container, styles.selectedContainer] : [styles.container]}>
        <Text style={selected ? [styles.label, styles.selectedLabel] : [styles.label]}>
          Niveau {level}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default VideoLevelPicker;

const styles = StyleSheet.create({
  container: {
    ...ANLayout.rowHCenter,
    ...padding(MetricsSizes.singlePadding),
    backgroundColor: ANColor.commonWhite,
    overflow: 'hidden',
    borderRadius: MetricsSizes.cornerRadius,
    borderColor: ANColor.primary100,
    borderWidth: 1,
    marginBottom: MetricsSizes.singlePadding,
  },
  selectedContainer: {
    backgroundColor: ANColor.primary100,
  },
  label: {
    ...ANFont.textMediumRegular,
    marginLeft: MetricsSizes.singlePadding,
  },
  selectedLabel: {
    color: ANColor.primary500,
  },
});
