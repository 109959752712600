import I18n from '../i18n';

export type ANTrainingSchedule = {
  weekday: Day;
  times: Time[];
};

/**
 * Day of the week, with int value being set in official order used by calendars (must be in sync so Local Notifications trigger properly)
 */
export enum Day { //In expo notifications, the day of the week is 1-indexed, with 1 being Sunday and 7 being Saturday
  Dimanche = 1,
  Lundi = 2,
  Mardi = 3,
  Mercredi = 4,
  Jeudi = 5,
  Vendredi = 6,
  Samedi = 7,
}

export namespace Day {
  export const localizedLabel = (day: Day) => {
    switch (day) {
      case Day.Lundi:
        return I18n.t('common.monday');
      case Day.Mardi:
        return I18n.t('common.tuesday');
      case Day.Mercredi:
        return I18n.t('common.wednesday');
      case Day.Jeudi:
        return I18n.t('common.thursday');
      case Day.Vendredi:
        return I18n.t('common.friday');
      case Day.Samedi:
        return I18n.t('common.saturday');
      case Day.Dimanche:
        return I18n.t('common.sunday');
    }
  };
}

export type Time = {
  id: string;
  hour: number;
  minute: number;
};
