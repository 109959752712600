import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import React, { FC, useCallback, useMemo } from 'react';
import { ViewProps } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { ANColor } from '../theme/ANColor';
import { formatAnalyticsEvent, logEvent } from '../util/analytics';

export enum ExpoIconDesign {
  Material,
  MaterialCommunity,
  FontAwesome,
}

type Props = {
  name:
    | keyof typeof MaterialIcons.glyphMap
    | keyof typeof FontAwesome.glyphMap
    | keyof typeof MaterialCommunityIcons.glyphMap;
  size?: number;
  color?: string;
  design?: ExpoIconDesign;
} & ViewProps;

const ExpoIcon: FC<Props> = ({
  name,
  size = 24,
  color = ANColor.commonBlack,
  design = ExpoIconDesign.Material,
  ...props
}) => {
  if (design === ExpoIconDesign.FontAwesome) {
    return <FontAwesome name={name} size={size} color={color} {...props} />;
  } else if (design === ExpoIconDesign.MaterialCommunity) {
    return <MaterialCommunityIcons name={name} size={size} color={color} {...props} />;
  } else {
    return <MaterialIcons name={name} size={size} color={color} {...props} />;
  }
};

type TouchableProps = {
  eventKey?: string;
  onPress?: () => void;
} & Props &
  ViewProps;

export const ExpoIconPressable: FC<TouchableProps> = ({
  name,
  size = 24,
  eventKey,
  color = ANColor.commonBlack,
  design = ExpoIconDesign.Material,
  onPress,
  ...props
}) => {
  const formattedAnalyticsEvent: string | undefined = useMemo(() => {
    if (!eventKey) return undefined;
    return formatAnalyticsEvent(eventKey);
  }, [eventKey]);

  const pressed = useCallback(() => {
    if (onPress) onPress();
    if (formattedAnalyticsEvent) logEvent(formattedAnalyticsEvent);
  }, [formattedAnalyticsEvent, onPress]);

  return (
    <TouchableOpacity onPress={pressed}>
      <ExpoIcon name={name} size={size} color={color} design={design} {...props} />
    </TouchableOpacity>
  );
};

export default ExpoIcon;
