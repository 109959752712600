import * as Font from 'expo-font';
import { ANFontFamily } from '../theme/ANFont';

const useCustomFonts = () => {
  // return Font.useFonts({
  //   [ANFontFamily.MontserratRegular]: require('.././assets/fonts/Montserrat-Regular.ttf'),
  //   [ANFontFamily.MontserratMedium]: require('.././assets/fonts/Montserrat-Medium.ttf'),
  //   [ANFontFamily.MontserratBold]: require('.././assets/fonts/Montserrat-Bold.ttf'),
  //   [ANFontFamily.MontserratSemiBold]: require('.././assets/fonts/Montserrat-SemiBold.ttf'),
  //   [ANFontFamily.MulishRegular]: require('.././assets/fonts/Mulish-Regular.ttf'),
  //   [ANFontFamily.MulishMedium]: require('.././assets/fonts/Mulish-Medium.ttf'),
  //   [ANFontFamily.MulishBold]: require('.././assets/fonts/Mulish-Bold.ttf'),
  // });
  return Font.loadAsync({
    [ANFontFamily.MontserratRegular]: require('.././assets/fonts/Montserrat-Regular.ttf'),
    [ANFontFamily.MontserratMedium]: require('.././assets/fonts/Montserrat-Medium.ttf'),
    [ANFontFamily.MontserratBold]: require('.././assets/fonts/Montserrat-Bold.ttf'),
    [ANFontFamily.MontserratSemiBold]: require('.././assets/fonts/Montserrat-SemiBold.ttf'),
    [ANFontFamily.MulishRegular]: require('.././assets/fonts/Mulish-Regular.ttf'),
    [ANFontFamily.MulishMedium]: require('.././assets/fonts/Mulish-Medium.ttf'),
    [ANFontFamily.MulishBold]: require('.././assets/fonts/Mulish-Bold.ttf'),
  });
};

export default useCustomFonts;
