import Slider from '@react-native-community/slider';
import React, { FC, useCallback, useState } from 'react';
import { Platform, StyleSheet, Text, View, ViewStyle } from 'react-native';
import useHour from '../hooks/useHour';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';

type Props = {
  totalDuration?: number;
  playableDurationMillis?: number;
  positionMillis: number;
  style?: ViewStyle;
  onSeek: (value: number) => void;
  onSliding?: () => void;
};

/**
 * A component to display a slider to seek in the video or audio
 * @param param
 * @returns
 */
const PlayerSlider: FC<Props> = ({
  totalDuration,
  positionMillis,
  playableDurationMillis,
  onSeek,
  onSliding,
  style,
}) => {
  const [isSliding, setIsSliding] = useState(false);
  const [slidingPosition, setSlidingPosition] = useState(0);
  const { createChronoString } = useHour();

  const onValueChange = useCallback(
    (value: number) => {
      if (Platform.OS === 'ios' && totalDuration) {
        // iOS FIX : the seek bar is not able to reach every position for long duration mp3. Bug rported in https://github.com/expo/expo/issues/19348
        //Solution is to move the sliding position only to the playableDurationMillis. Then this should trigger the buffering of the rest of the file
        const minimumAllowedPosition = playableDurationMillis ?? totalDuration;
        if (value < minimumAllowedPosition) {
          setSlidingPosition(value);
        } else {
          setSlidingPosition(minimumAllowedPosition);
        }
      } else {
        setSlidingPosition(value);
      }
      onSliding && onSliding();
    },
    [totalDuration, playableDurationMillis, onSliding, positionMillis],
  );

  const onSlidingComplete = useCallback(
    (value: number) => {
      // {
      //   Platform.OS === 'ios' && setIsSliding(false);
      // }

      if (Platform.OS === 'ios' && playableDurationMillis) {
        // iOS FIX : the seek bar is not able to reach every position for long duration mp3. Bug reported in https://github.com/expo/expo/issues/19348
        //Solution is to move the sliding position only to the playableDurationMillis. Then this should trigger the buffering of the rest of the file
        if (value < playableDurationMillis) {
          onSeek(value);
        } else {
          onSeek(playableDurationMillis);
        }
      } else {
        onSeek(value);
      }

      setIsSliding(false);
    },
    [playableDurationMillis, onSeek],
  );

  const onSlidingStart = useCallback(() => {
    setIsSliding(true);
  }, []);
  return (
    <View style={style}>
      <View
        pointerEvents={totalDuration ? undefined : 'none'}
        style={[styles.slider, !Boolean(totalDuration) && { opacity: 0.5 }]}
      >
        <Slider
          minimumValue={0}
          maximumValue={totalDuration}
          onValueChange={onValueChange}
          onSlidingComplete={onSlidingComplete}
          onSlidingStart={onSlidingStart}
          value={isSliding ? slidingPosition : positionMillis}
          style={styles.slider}
          maximumTrackTintColor={ANColor.primary100}
          minimumTrackTintColor={ANColor.primary500}
          thumbTintColor={ANColor.commonWhite}
        />
      </View>

      <View style={styles.timeContainer}>
        <Text style={styles.time}>
          {createChronoString(isSliding ? slidingPosition : positionMillis)}
        </Text>
        {totalDuration && <Text style={styles.time}>{createChronoString(totalDuration)}</Text>}
      </View>
    </View>
  );
};

export default PlayerSlider;

const styles = StyleSheet.create({
  slider: {
    marginTop: MetricsSizes.small,
  },
  timeContainer: {
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
  },
  time: {
    ...ANFont.textSmallRegular,
    textAlign: 'center',
    color: ANColor.commonWhite,
  },
});
