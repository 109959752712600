import InAppReview from 'react-native-in-app-review';
import { isDevice } from './Device';

/**
 * Check if the conditions are met to ask the user to rate the app
 * @param numberOfSessions number of times the user has opened the app
 */
export const checkConditionsAndRateTheApp = async (numberOfSessions: number) => {
  if (!isDevice()) {
    return;
  }
  if (shouldRequestReview(numberOfSessions) === false) {
    return;
  }
  console.log('Will ask to rate the app');
  await InAppReview.RequestInAppReview();
};

/**
 * Call to rate the app
 */
export const rateTheApp = async () => {
  await InAppReview.RequestInAppReview();
};

const shouldRequestReview = (numberOfSessions: number) => {
  //Ask to rate every 3 sessions
  const result = numberOfSessions % 3 === 0;
  console.log('shouldRequestReview', result);
  return result;
};
