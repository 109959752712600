import React, { useCallback } from 'react';
import { Image, Linking, StyleSheet, Text, View } from 'react-native';
import ANImages from '../assets/image/ANimages';
import I18n from '../i18n';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import ANButton from './ANButton';

type Props = {};

const DownloadMobileApp = (props: Props) => {
  const redirectPlaystoreAppstore = useCallback(() => {
    //Open OneLink to redirect to the right platform (iOS or Android)
    Linking.openURL('https://onelink.to/px26j6');
  }, []);

  return (
    <View style={[ANLayout.screenContainer, ANLayout.columnHVCenter]}>
      <Image source={ANImages.LogoLongHeader} />
      <Text style={[ANFont.h4Bold, { color: ANColor.tertiary500 }]}>
        {I18n.t('web_download_mobile_app.title')}
      </Text>
      <Text style={[ANFont.textLargeBold, styles.downloadAppBody]}>
        {I18n.t('web_download_mobile_app.description')}
      </Text>
      <ANButton
        title={I18n.t('web_download_mobile_app.action')}
        onPress={redirectPlaystoreAppstore}
      />
    </View>
  );
};

export default DownloadMobileApp;

const styles = StyleSheet.create({
  downloadAppBody: {
    marginVertical: MetricsSizes.regular,
  },
});
