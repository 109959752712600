import { BottomSheetBackdrop, BottomSheetModal } from '@gorhom/bottom-sheet';
import DateTimePicker, { DateTimePickerEvent } from '@react-native-community/datetimepicker';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import I18n from '../../i18n';
import { ANColor } from '../../theme/ANColor';
import Font from '../../theme/ANFont';
import Layout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import ANButton from '../ANButton';

interface Props {
  selectedDate: Date;
  sheetRef: React.RefObject<BottomSheetModal>;
  onTimeSelected: (date: Date) => void;
}

//Bottom sheet utilisé pour noter la dernière consultation avec son oste
const TimePickerBottomsheet: React.FunctionComponent<Props> = ({
  selectedDate,
  sheetRef,
  onTimeSelected,
}) => {
  const snapPoints = useMemo(() => ['40%', '40%'], []);

  const { bottom } = useSafeAreaInsets();
  const [date, setDate] = useState<Date>(selectedDate);

  const renderBackdrop = useCallback(props => {
    return <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />;
  }, []);

  const onChange = (event: DateTimePickerEvent, date?: Date) => {
    if (date) setDate(date);
  };

  return (
    <BottomSheetModal
      ref={sheetRef}
      index={1}
      backgroundStyle={{ backgroundColor: ANColor.tertiary100 }}
      snapPoints={snapPoints}
      backdropComponent={renderBackdrop}
    >
      <View style={{ ...styles.reviewSheetContainer, marginBottom: bottom }}>
        <Text style={styles.title}>{I18n.t('program_notifications.define_hour')}</Text>

        <DateTimePicker
          style={{ flex: 1, width: '100%', alignSelf: 'center' }}
          mode="time"
          display="spinner"
          //minuteInterval={5}
          is24Hour
          value={date}
          onChange={onChange}
        />

        <ANButton
          title={I18n.t('common.validate_button')}
          style={styles.validateButton}
          onPress={() => onTimeSelected(date)}
        />
      </View>
    </BottomSheetModal>
  );
};

export default TimePickerBottomsheet;

const styles = StyleSheet.create({
  reviewSheetContainer: {
    ...Layout.screenContainer,
    alignItems: 'center',
  },
  title: {
    ...Font.h6Bold,
    textAlign: 'center',
    marginBottom: MetricsSizes.singlePadding,
  },
  validateButton: {
    marginTop: MetricsSizes.singlePadding,
  },
});
