import React, { useContext } from 'react';
import { FlatList, StyleSheet } from 'react-native';
import { useQuery } from 'react-query';
import { getAllAppointments } from '../../api/request/user/getAppointments';
import ANSafeAreView from '../../component/ANSafeAreView';
import EmptyList from '../../component/EmptyList';
import AppointmentHistoryItem from '../../component/profile/AppointmentHistoryItem';
import UserContext from '../../context/UserContext';
import ANOsteo from '../../model/ANOsteo';
import { ANColor } from '../../theme/ANColor';
import Layout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import {
  osteoAppointmentURL,
  osteoMainDisplayName,
  osteoShouldDisplayOsteoName,
} from '../../util/osteo';

type Props = {};

const AppointmentsHistoryScreen = (props: Props) => {
  const { user } = useContext(UserContext);
  const { isLoading, data } = useQuery(['getAllAppointements', user?.id.toString()], () =>
    getAllAppointments(),
  );

  const mainDisplayName = (osteo: ANOsteo) => {
    return osteoMainDisplayName(osteo);
  };

  const url = (osteo: ANOsteo) => {
    return osteoAppointmentURL(osteo);
  };

  return (
    <ANSafeAreView style={Layout.screenContainer} ignoreTopInset>
      <FlatList
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={<EmptyList isLoading={isLoading} />}
        contentContainerStyle={styles.content}
        data={data}
        keyExtractor={item => item.id.toString()}
        renderItem={({ item }) => {
          return (
            <AppointmentHistoryItem
              date={item.date}
              mainName={mainDisplayName(item.osteo) ?? ''}
              subtitle={
                osteoShouldDisplayOsteoName(item.osteo) ? item.osteo.displayname : undefined
              }
              redirectionUrl={url(item.osteo)}
            />
          );
        }}
      />
    </ANSafeAreView>
  );
};

export default AppointmentsHistoryScreen;

const styles = StyleSheet.create({
  content: {
    marginTop: MetricsSizes.big,
    marginHorizontal: MetricsSizes.screenHorizontalPadding,
    backgroundColor: ANColor.primary100,
  },
});
