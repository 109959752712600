import React, { FC, memo, useEffect } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import Animated, {
  Easing,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import Cloud, { cloudWidth } from '../assets/svg/Cloud';

import ANLayout from '../theme/ANLayout';

type Props = {
  duration?: number;
  cloud1TopInset?: string;
  cloud2BottomInset?: string;
};

const windowWidth = Dimensions.get('window').width;

/**
 * A component that renders two clouds to be animated in a screen background. Translate animation on X axis
 * @param param0
 * @returns
 */
const CloudsAnimated: FC<Props> = ({
  duration = 15000,
  cloud1TopInset = '20%',
  cloud2BottomInset = '20%',
}) => {
  const animatedValue = useSharedValue(0);

  const animatedStyleCloud1 = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: interpolate(animatedValue.value, [0, 1], [-cloudWidth, windowWidth]),
        },
      ],
    };
  });

  const animatedStyleCloud2 = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateX: interpolate(animatedValue.value, [0, 1], [cloudWidth, -windowWidth]),
        },
      ],
    };
  });

  useEffect(() => {
    animatedValue.value = 0;
    animatedValue.value = withRepeat(
      withDelay(
        1000,
        withTiming(1, {
          duration: duration,
          easing: Easing.linear,
        }),
      ),
      -1,
    );
  }, []);

  return (
    <View style={[ANLayout.container, StyleSheet.absoluteFill]}>
      <Animated.View style={[styles.cloud1, { top: cloud1TopInset }, animatedStyleCloud1]}>
        <Cloud />
      </Animated.View>
      <Animated.View style={[styles.cloud2, { bottom: cloud2BottomInset }, animatedStyleCloud2]}>
        <Cloud />
      </Animated.View>
    </View>
  );
};

export default memo(CloudsAnimated); //Memoize because it should not rerender if parent rerenders

const styles = StyleSheet.create({
  cloud1: {
    flex: 1,
    position: 'absolute',
    left: 0,
  },
  cloud2: {
    flex: 1,
    position: 'absolute',
    right: 0,
  },
});
