import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import ANAvatar from '../assets/svg/ANAvatar';
import { ANColor, withOpacity } from '../theme/ANColor';
import { MetricsSizes } from '../theme/ANMetrics';
import ANImageView from './Image';

type Props = { url?: string; size?: number; fullRounded?: boolean };

const AvatarImage: FC<Props> = ({ size = 53, url, fullRounded = false }) => {
  return url ? (
    <ANImageView
      style={[
        styles.avatar,
        { height: size, width: size },
        fullRounded && { borderRadius: size / 2 },
      ]}
      source={{
        uri: url,
        headers: {
          referrer: 'https://www.andrewapp.fr/',
        },
      }}
    />
  ) : (
    <View
      style={[
        styles.avatar,
        styles.placeholder,
        {
          width: size,
          height: size,
        },
      ]}
    >
      <ANAvatar style={{ justifyContent: 'center' }} />
    </View>
  );
};

export default AvatarImage;

const styles = StyleSheet.create({
  avatar: {
    borderRadius: MetricsSizes.cornerRadius,
    overflow: 'hidden',
    height: 53,
    width: 53,
  },
  placeholder: {
    backgroundColor: withOpacity(10, ANColor.common900),
    borderRadius: MetricsSizes.cornerRadius,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
