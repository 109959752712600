import Slider from '@react-native-community/slider';
import { LinearGradient } from 'expo-linear-gradient';
import { throttle } from 'lodash';
import React, { FC, useCallback, useState } from 'react';
import { Platform, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Colors } from 'react-native-paper';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { hapticSelection } from '../util/utils';
type Props = {
  title: string;
  subtitle?: string;
  style?: ViewStyle;
  errorDetails?: string;
  setValue: (value: number) => void;
};

const SliderRating: FC<Props> = ({ title, subtitle, style, errorDetails, setValue }) => {
  const localValue = React.useRef<number>(); //Used to track the value change and only make a haptic feedback on int change

  const [width, setWidth] = React.useState<number | undefined>(undefined);
  const [roundedValue, setRoundedValue] = React.useState<number>(0);

  ///Throttle the calculation of the rounded value because the SliderRating returns to many result in a short time. This caused some crash because for each value we update the left position of the thumb value
  const throttled = useCallback(
    throttle((value, calculateRoundedValue) => calculateRoundedValue(value), 1000 / 60),
    [],
  );

  //Slider return value rounded to int
  const calculateRoundedValue = useCallback(
    (value: number) => {
      const rounded = Math.round(value);
      if (localValue.current != rounded) {
        hapticSelection();
      }
      setValue(rounded);
      setRoundedValue(rounded);
      localValue.current = rounded;
      if (width) {
        const left = (value * width) / 11;
        setLeft(left);
      }
    },
    [width, localValue],
  );

  const [left, setLeft] = useState(0);

  ///Calculate the rating width, in order to position the thumb
  const onViewLayout = useCallback(async (event: any) => {
    const { width } = event.nativeEvent.layout;
    setWidth(width);
  }, []);

  return (
    <View style={style} onLayout={onViewLayout}>
      <Text style={[ANFont.textMediumBold, styles.title]}>{title}</Text>
      {Boolean(subtitle) && (
        <Text style={[ANFont.textMediumRegular, styles.subtitle]}>{subtitle}</Text>
      )}
      <View style={[styles.thumbRating, { left: left }]}>
        <Text style={[ANLayout.absoluteCenter, ANFont.textMediumBold, styles.tumbRatingText]}>
          {roundedValue}
        </Text>
      </View>
      <View style={styles.scaleLabels}>
        <Text style={[ANFont.textSmallRegular, styles.minLabel]}>0</Text>
        <Text style={[ANFont.textSmallRegular, styles.mediumLabel]}>5</Text>
        <Text style={[ANFont.textSmallRegular, styles.maxLabel]}>10</Text>
      </View>
      <View>
        <View style={[styles.tick, { left: 0 }]} />
        <View style={[styles.tick, { left: '10%' }]} />
        <View style={[styles.tick, { left: '20%' }]} />
        <View style={[styles.tick, { left: '30%' }]} />
        <View style={[styles.tick, { left: '40%' }]} />
        <View style={[styles.tick, { left: '50%' }]} />
        <View style={[styles.tick, { left: '60%' }]} />
        <View style={[styles.tick, { left: '70%' }]} />
        <View style={[styles.tick, { left: '80%' }]} />
        <View style={[styles.tick, { left: '90%' }]} />
        <View style={[styles.tick, { right: 0 }]} />

        <LinearGradient
          colors={ANColor.sliderLinear}
          style={styles.track}
          start={[0, 1]}
          end={[1, 0]}
        />

        <Slider
          minimumValue={0}
          maximumValue={10}
          onValueChange={val => throttled(val, calculateRoundedValue)}
          minimumTrackTintColor={Colors.transparent}
          maximumTrackTintColor={Colors.transparent}
        />
      </View>

      {Boolean(errorDetails) && (
        <Text style={[ANFont.textSmallRegular, styles.errorLabel]}>{errorDetails}</Text>
      )}
    </View>
  );
};

export default SliderRating;

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    marginBottom: MetricsSizes.regular,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: MetricsSizes.regular,
  },
  track: {
    height: 4,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: Platform.OS === 'ios' ? 18 : 8,
  },
  tick: {
    height: 8,
    width: 1,
    backgroundColor: ANColor.commonBlack,
    position: 'absolute',
    bottom: Platform.OS === 'ios' ? 16 : 6,
  },
  errorLabel: {
    color: ANColor.statusWarning500,
    marginBottom: 10,
  },
  minLabel: {
    textAlign: 'center',
  },
  maxLabel: {
    textAlign: 'center',
  },
  mediumLabel: {
    textAlign: 'center',
    marginLeft: 8,
  },
  scaleLabels: {
    position: 'absolute',
    bottom: Platform.OS === 'ios' ? 0 : -10,
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
  },
  thumbRating: {
    width: 30,
    height: 30,
    borderRadius: 15,
    overflow: 'hidden',
    backgroundColor: ANColor.primary500,
  },
  tumbRatingText: {
    textAlign: 'center',
    paddingTop: Platform.OS === 'android' ? 4 : 5,
    color: Colors.white,
  },
});
