export enum APIError {
  UnknownError = 'UnknownError',
  InvalidBody = 'InvalidBody',
  NotFoundError = 'NotFoundError',
  UserAlreadyExists = 'UserAlreadyExists',
  ValidationError = 'ValidationError',
  ForbiddenError = 'ForbiddenError',
  BadRequestError = 'BadRequestError',
}

export namespace APIError {
  export function fromString(value: string) {
    const indexOfS = Object.values(APIError).indexOf(value as unknown as APIError);

    const key = Object.keys(APIError)[indexOfS];

    return key === undefined ? APIError.UnknownError : key;
  }
}

export const parseAPIError = (err: any, path?: string) => {
  console.log('PARSE ERROR', err);
  if (err.name === 'AxiosError') {
    //Default errors throw from Strapi will use the name field
    const name = err.response?.data?.error?.name;
    const error = err.response?.data?.error;
    //While custom errors will use the message field (e.g. UserAlreadyExists)
    // const message = err.response?.data?.error?.message;
    // if (message) throw APIError.fromString(message);
    const errorType = APIError.fromString(name);
    console.log('🐛 API error', path, errorType, error);
    return errorType;
  } else {
    console.log('🐛 Logic error', path, err);
    return APIError.UnknownError;
  }
};
