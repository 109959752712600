import { MotiView } from 'moti';
import React, { useCallback, useEffect, useMemo } from 'react';
import { LayoutChangeEvent } from 'react-native';
import useLocalMediaLogs from '../../../hooks/useLocalMediaLogs';
import I18n from '../../../i18n';
import { ANAuthor } from '../../../model/ANAuthor';
import ANMedia from '../../../model/ANMedia';
import ANTopicDetailed from '../../../model/ANTopicDetailed';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';
import AudioRowExplorerItem from '../AudioRowExplorerItem';
import ExplorerTopicHeader from '../ExplorerTopicHeader';
import VideoRowExplorerItem from '../VideoRowExplorerItem';

type Props = {};

const useMediaExplorerList = (
  topic: ANTopicDetailed | undefined,
  filteredMedia: ANMedia[] | undefined,
  isInvitedMode: boolean,
  videoIsLoading: boolean,
  favoriteTapped: (mediaId: number) => void,
  userFavorites: number[],
  onMediaPressed: (media: ANMedia, onDone: (media: ANMedia, positionMilli: number) => void) => void,
) => {
  const _fadeDuration = 150;

  const [layoutWidth, setLayoutWidth] = React.useState<number>(928);

  const [currentTopic, setCurrentTopic] = React.useState<ANTopicDetailed | undefined>(topic); //Pourquoi ça ne suffit pas pour init currentTopic ? La valeur est undefined la 1ere fois qu ej'ouvre le component. Seule solution : ajouter un useEfect
  const mediaLogs = useLocalMediaLogs(state => state.mediaLogs);

  useEffect(() => {
    if (topic === undefined) {
      return;
    }
    const updatedData = topic?.medias?.map(media => {
      const log = mediaLogs.find(log => log.mediaId === media.id);
      if (log) {
        media.timeFlagInMilli = log.timeFlagInMilli;
      }
      return media;
    });

    setCurrentTopic({
      ...topic,
      medias: updatedData ?? [],
    });
  }, [topic, mediaLogs]);

  /**
   * Listen to changes into the logs to update the time flag of the medias
   */
  useEffect(() => {
    const unsubLogs = useLocalMediaLogs.subscribe(state => state.mediaLogs);
    return () => {
      unsubLogs();
    };
  }, []);

  const mediasToDisplay = useMemo(() => {
    console.log('Update media to display', currentTopic);
    return filteredMedia ?? currentTopic?.medias;
  }, [currentTopic, filteredMedia]);

  const numberOfColumns = useMemo(() => {
    if (isDevice()) {
      return 1;
    } else {
      if (mediasToDisplay && (mediasToDisplay?.length ?? 0) > 0) {
        return mediasToDisplay[0].type === 'audio' ? 1 : 3;
      }
    }
  }, [mediasToDisplay, topic]);

  const videoItemWidth = useMemo(() => {
    if (isDevice()) {
      return undefined;
    } else {
      return layoutWidth / (numberOfColumns ?? 1) - 2 * MetricsSizes.regular;
    }
  }, [layoutWidth, numberOfColumns]);

  const columnStyle = useCallback(
    (index: number) => {
      if (numberOfColumns !== 1) {
        if (index % 3 == 0) {
          return [{ marginRight: MetricsSizes.regular }];
        } else if ((index - 1) % 3 == 0) {
          return undefined; //Colonne du milieu
        } else {
          return [{ marginLeft: MetricsSizes.regular }];
        }
      } else {
        return [{ marginHorizontal: MetricsSizes.regular }];
      }
    },
    [numberOfColumns],
  );

  const listHeader = useCallback(
    (
      title: string,
      authors: ANAuthor[],
      selectedAuthor: ANAuthor,
      authorSelected: (author: ANAuthor) => void,
      defaultAuthor: ANAuthor,
    ) => {
      if (authors && authors.length > 0) {
        return (
          <ExplorerTopicHeader
            title={title}
            authors={authors}
            selectedAuthor={defaultAuthor ? defaultAuthor : selectedAuthor}
            onAuthorSelected={authorSelected}
          />
        );
      } else {
        return undefined;
      }
    },
    [],
  );

  const _renderItem = (item: ANMedia, index: number) => {
    if (item.type === 'audio') {
      return (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'timing',
            delay: 100 + index * _fadeDuration,
            duration: _fadeDuration,
          }}
          exit={{ opacity: 0 }}
        >
          <AudioRowExplorerItem
            title={item.title}
            duration={item.duration}
            timeFlagInMilli={item.timeFlagInMilli}
            useInvitedMode={isInvitedMode}
            isLockedForGuests={item.requiresAuthentication}
            isFavorite={userFavorites.includes(item.id)}
            onFavoriteTapped={() => favoriteTapped(item.id)}
            onPress={() => onMediaPressed(item)}
            style={{
              marginHorizontal: MetricsSizes.regular,
            }}
          />
        </MotiView>
      );
    } else {
      return (
        <VideoRowExplorerItem
          isLoading={videoIsLoading}
          title={item.title}
          duration={item.duration}
          timeFlagInMilli={item.timeFlagInMilli}
          useInvitedMode={isInvitedMode}
          isLockedForGuests={item.requiresAuthentication}
          difficulty={item.difficulty && I18n.t('common.difficulty.' + item.difficulty)}
          author={item.author}
          previewUrl={item.previewUrl}
          isFavorite={userFavorites.includes(item.id)}
          onFavoriteTapped={() => favoriteTapped(item.id)}
          onPress={() => onMediaPressed(item)}
          itemWidth={videoItemWidth}
          style={columnStyle(index)}
        />
      );
    }
  };

  const onLayout = (event: LayoutChangeEvent) => {
    const { width: screenWidth } = event.nativeEvent.layout;
    setLayoutWidth(screenWidth);
  };

  return {
    onLayout,
    _renderItem,
    listHeader,
    mediasToDisplay,
    numberOfColumns,
    columnStyle,
    videoItemWidth,
  };
};

export default useMediaExplorerList;
