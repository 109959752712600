import React, { FC } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import ANExercice from '../model/ANVideoGroup';
import { ANColor } from '../theme/ANColor';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import ExpoIcon from './ExpoIcon';
import TrainingExerciceListItem from './TrainingExerciceListItem';

type Props = {
  index: number;
  selected: boolean;
  exercice: ANExercice;
  style?: ViewStyle;
  onPress: () => void;
};

const ExercicePicker: FC<Props> = ({ index, exercice, selected, style, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[ANLayout.rowHCenter, style]}>
        <ExpoIcon
          name={selected ? 'radio-button-on' : 'radio-button-off'}
          color={selected ? ANColor.primary500 : ANColor.common400}
        />
        <TrainingExerciceListItem
          displayLevel={false}
          index={index}
          exercice={exercice}
          style={styles.card}
          onCardTapped={onPress}
        />
      </View>
    </TouchableOpacity>
  );
};

export default ExercicePicker;

const styles = StyleSheet.create({
  test: {
    flex: 1,
    height: 10,
  },
  card: {
    marginLeft: MetricsSizes.singlePadding,
  },
});
