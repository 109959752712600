import { Api } from '../../apiclient';
import { AuthResponse } from '../../data/AuthResponse';
import { parseAPIError } from '../../data/ResponseError';
import { removeHeaderAuthorization } from './../../apiclient';
import { RoleResponse } from './../../data/AuthResponse';

export const postSignin = async (email: string, password: string) => {
  const route = '/auth/local';
  console.log('Request auth login');
  removeHeaderAuthorization(); //To make sure we don't send the old token

  try {
    const response = await Api.post(route, {
      identifier: email,
      password: password,
    });
    const result: AuthResponse = response.data;
    return result;
  } catch (err) {
    throw parseAPIError(err, route);
  }
};

export const getUserRole = async () => {
  const route = '/users/me?populate=*';

  console.log('Request user Role');

  try {
    const response = await Api.get(route);
    const result: RoleResponse = response.data;
    return result;
  } catch (err) {
    throw parseAPIError(err, route);
  }
};
