import I18n from 'i18n-js';
import fr from './fr';

// Set the key-value pairs for the different languages you want to support.
I18n.translations = {
  fr: fr,
};

// Set the locale once at the beginning of your app.
I18n.locale = 'fr';

export default I18n;
