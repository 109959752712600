import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import I18n from '../i18n';
import BreathingExerciceScreen from '../screen/explorer/BreathExercice/BreathingExerciceScreen.web';
import ExplorerAudioPlayerScreen from '../screen/explorer/ExplorerAudioPlayerScreen';
import ExplorerPodcastScreen from '../screen/explorer/ExplorerPodcastScreen';
import ExplorerPodcastsListScreen from '../screen/explorer/ExplorerPodcastsListScreen';
import ExplorerScreen from '../screen/explorer/ExplorerScreen.web';
import ExplorerTopicScreen from '../screen/explorer/ExplorerTopicScreen';
import DashboardScreen from '../screen/home/Dashboard/DashboardScreen.web';
import InvitedModeScreen from '../screen/home/InvitedModeScreen';
import { OnboardingNavigator } from '../screen/home/Onboarding/OnboardingNavigator';
import TrainingPresentationScreen from '../screen/home/TrainingPresentationScreen/TrainingPresentationScreen.web';
import TrainingSessionScreen from '../screen/home/TrainingSession/TrainingSessionScreen.web';
import AppointmentsHistoryScreen from '../screen/profile/AppointmentsHistoryScreen';
import UpdatePasswordScreen from '../screen/profile/UpdatePasswordScreen';
import { ANColor } from '../theme/ANColor';
import { ANFontFamily } from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { RootStackParamList, Route } from './Route';
import WebSideBar from './WebSideBar.web';
import { screenOptions } from './options';

const Stack = createNativeStackNavigator<RootStackParamList>();

export const AppNavigator = () => {
  return (
    <View style={[ANLayout.row, { paddingHorizontal: 24 }]}>
      <WebSideBar />
      <View style={[ANLayout.screenContaineNoPadding, styles.navigatorContainer]}>
        <Stack.Navigator
          screenOptions={{
            ...screenOptions,
            headerTitle: '',
            headerLeft: undefined,
            title: '',
            headerTitleStyle: {
              fontFamily: ANFontFamily.MontserratBold,
              fontSize: 26,
            },
          }}
        >
          <Stack.Screen
            name={Route.dashboardScreen}
            component={DashboardScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Route.explorerScreen}
            component={ExplorerScreen}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name={Route.onboardingNavigator}
            component={OnboardingNavigator}
            options={{ headerShown: false }}
          />

          <Stack.Screen
            name={Route.invitedModeScreen}
            component={InvitedModeScreen}
            options={{ headerShown: false, presentation: 'containedModal' }}
          />

          {/** TRAINING STACK */}
          <Stack.Screen
            name={Route.trainingPresentation}
            component={TrainingPresentationScreen}
            options={{
              headerShown: true,
              title: '',
            }}
          />
          <Stack.Screen
            name={Route.trainingSession}
            component={TrainingSessionScreen}
            options={{
              headerShown: false,
              presentation: 'fullScreenModal',
            }}
          />

          {/** Explorer STACK */}
          <Stack.Screen
            name={Route.explorerTopicScreen}
            component={ExplorerTopicScreen}
            options={{
              headerStyle: {
                backgroundColor: ANColor.primary300,
              },
            }}
          />

          <Stack.Screen
            name={Route.explorerAudioPlayerScreen}
            component={ExplorerAudioPlayerScreen}
          />
          <Stack.Screen
            name={Route.explorerPodcastScreen}
            component={ExplorerPodcastScreen}
            options={{
              headerStyle: {
                backgroundColor: ANColor.primary300,
              },
            }}
          />

          <Stack.Screen
            name={Route.explorerPodcastsListScreen}
            component={ExplorerPodcastsListScreen}
            options={{
              headerShown: true,
              headerTitle: I18n.t('explorer.podcasts'),
              headerStyle: {
                backgroundColor: ANColor.primary300,
              },
            }}
          />

          <Stack.Screen
            name={Route.breathingExerciceScreen}
            component={BreathingExerciceScreen}
            options={{
              headerTitle: I18n.t('breath_exercice.title'),
              headerTitleStyle: {
                color: ANColor.tertiary500,
                fontFamily: ANFontFamily.MontserratBold,
                fontSize: 26,
              },
            }}
          />

          {/** PROFILE STACK */}
          <Stack.Screen
            name={Route.appointmentsHistoryScreen}
            component={AppointmentsHistoryScreen}
            options={{
              headerTitle: I18n.t('profile.appointments_history'),
              headerLeft: () => null,
            }}
          />
          <Stack.Screen
            name={Route.updatePasswordScreen}
            component={UpdatePasswordScreen}
            options={{
              headerTitle: I18n.t('profile.update_password'),
              headerLeft: () => null,
            }}
          />
        </Stack.Navigator>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navigatorContainer: {
    marginTop: 40,
    borderTopLeftRadius: MetricsSizes.webCornerRadius,
    borderTopRightRadius: MetricsSizes.webCornerRadius,
    overflow: 'hidden',
  },
});
