import { AnimatePresence, MotiView } from 'moti';
import React, { FC, useEffect } from 'react';
import { Dimensions, StyleSheet, Text } from 'react-native';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import ExpoIcon from './ExpoIcon';

type Props = { text: string; isVisible: boolean; onDismiss: () => void };

const windowWidth = Dimensions.get('window').width;

const SnackBarInfo: FC<Props> = ({ text, isVisible, onDismiss }) => {
  useEffect(() => {
    setTimeout(() => {
      onDismiss();
    }, 5000);
  }, [isVisible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <MotiView
          style={[ANLayout.roundedLabel, styles.motiContainer]}
          from={{ translateX: -windowWidth, opacity: 0 }}
          animate={{ translateX: 0, opacity: 1 }}
          exit={{ opacity: 0, translateX: windowWidth }}
        >
          <ExpoIcon name="check" />
          <Text style={[ANFont.textMediumRegular, styles.text]}>{text}</Text>
        </MotiView>
      )}
    </AnimatePresence>
  );
};

export default SnackBarInfo;

const styles = StyleSheet.create({
  snack: {
    backgroundColor: ANColor.secondary500,
  },
  motiContainer: {
    flexDirection: 'row',
    paddingHorizontal: MetricsSizes.regular,
    alignItems: 'center',
    borderRadius: 9,
    borderWidth: 1,
    borderColor: ANColor.secondary500,
    backgroundColor: ANColor.secondary100,
    position: 'absolute',
    bottom: 60,
    left: 0,
    right: 0,
    marginHorizontal: 16,
  },
  text: {
    marginHorizontal: MetricsSizes.singlePadding,
    textAlign: 'center',
  },
});
