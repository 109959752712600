import React from 'react';
import { StyleSheet, View } from 'react-native';
import ANSafeAreView from '../../../component/ANSafeAreView';
import FooterOverlayButton from '../../../component/FooterOverlayButton';
import TrainingSchedule from '../../../component/TraininSchedule/TrainingSchedule';
import TrainingSchedulePermissionMissing from '../../../component/TraininSchedule/TrainingSchedulePermissionMissing';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import useValidateScheduleTrainingNotification from './useScheduleTrainingNotification';

export interface ScheduleTrainingNotificationsScreenProps {
  openedInOnboarding: boolean;
}

const ScheduleTrainingNotificationsScreen = () => {
  const {
    bottom,
    openedInOnboarding,
    isNotificationAllowed,
    isSaving,
    scheduledNotifications,
    validateSelection,
    requestNotification,
    onScheduleChanged,
    dismiss,
  } = useValidateScheduleTrainingNotification();

  return (
    <ANSafeAreView style={styles.container} ignoreTopInset>
      <View style={styles.subContainer}>
        {isNotificationAllowed ? (
          <View>
            <TrainingSchedule
              onScheduleChanged={onScheduleChanged}
              scheduledNotifications={scheduledNotifications}
              contentContainerStyle={styles.timePickerContainer}
            />
          </View>
        ) : (
          <TrainingSchedulePermissionMissing
            onRequestPermission={requestNotification}
            onDismiss={dismiss}
          />
        )}
      </View>
      {isNotificationAllowed && (
        <FooterOverlayButton
          paddingBottom={bottom}
          type="validate"
          onPress={validateSelection}
          disabled={isSaving}
        ></FooterOverlayButton>
      )}
      {/* {openedInOnboarding && (
        <ANButton
          title={I18n.t('app_promotion_invited.maybe_later')}
          theme="tertiary"
          eventKey="skip_training_schedule_onboarding"
          style={styles.later}
          onPress={dismiss}
        />
      )} */}
    </ANSafeAreView>
  );
};

export default ScheduleTrainingNotificationsScreen;

const styles = StyleSheet.create({
  container: {
    ...ANLayout.screenContainer,
    paddingTop: MetricsSizes.big,
    paddingHorizontal: 0,
  },
  subContainer: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    flex: 1,
  },
  timePickerContainer: {
    paddingBottom: 100, //Used to avoid colliion with the footer button
  },
});
