import React, { useCallback, useEffect, useRef } from 'react';

import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { ANLocalVideos } from '../../../assets/video';
import GuestPromotionModal from '../../../component/modal/GuestPromotionModal';
import OnboardingModal from '../../../component/modal/OnboardingModal';
import WebModal from '../../../component/modal/WebModal';
import I18n from '../../../i18n';
import { RootStackParamList } from '../../../navigation/Route';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import HealthCheckUpScreen from '../HealthCheckUp/HealthCheckUpScreen';
import IntroVideoPlayer from '../VideoPlayerScreen/IntroVideoPlayer';
import DashboardHeader from './component/DashboardHeader';
import DashboardHealthCheckupCard from './component/DashboardHealthCheckup';
import MyTrainingSessionCard from './component/MyTrainingSessionCard';
import OsteoAdviceCard from './component/OsteoAdviceCard';
import useDashboard from './useDashboard';

const DashboardScreen = () => {
  const [isWebOnboardingModalVisible, setWebOnboardingModalVisible] = React.useState(false);
  const [isWebGuestModalVisible, setWebGuestModalVisible] = React.useState(false);
  const [isWebIntroVideoModalVisible, setWebIntroVideoModalVisible] = React.useState(false);
  const [isWebCheckupModalVisible, setWebCheckupModalVisible] = React.useState(false);
  const navigationRef = useRef(useNavigation<NativeStackNavigationProp<RootStackParamList>>()); //WARNING needs to be a Ref here, so it can work in setTimeout

  const {
    isInvitedMode,
    hasPlaylist,
    onboardingSeen,
    canTriggerHealthCheckup,
    onDiscoverOsteowTapped,
    renderFavoritesSection,
    openTrainingPlaylistScreen,
  } = useDashboard(navigationRef);

  const answerToHealthCheckup = useCallback(() => {
    //open modal checkup on web
    setWebCheckupModalVisible(true);
  }, []);

  useEffect(() => {
    //Add a little timeout because navigation is not ready yet on useEffect.
    setTimeout(async () => {
      //For guests, display a dedicated screen

      if (isInvitedMode) {
        console.log('Show invited screen');
        setWebGuestModalVisible(true);
        return;
      }

      //For registered user, check if they already saw the onboarding. Else show them
      console.log('Onboarding seen from dashboard screen: ', onboardingSeen);
      if (!onboardingSeen) {
        setWebOnboardingModalVisible(true);
        return;
      }
    }, 100);
  }, []);

  return (
    <View style={ANLayout.container}>
      <ScrollView style={styles.scrollContainer}>
        <DashboardHeader style={styles.header} />
        {hasPlaylist && (
          <DashboardHealthCheckupCard
            onAnswerTapped={answerToHealthCheckup}
            answeredToday={!canTriggerHealthCheckup()}
          />
        )}
        <View style={[ANLayout.column]}>
          <Text style={[ANFont.h6Bold, styles.title]}>{I18n.t('training.screen_title')}</Text>
          <View style={[ANLayout.row]}>
            <MyTrainingSessionCard
              onStartTapped={openTrainingPlaylistScreen}
              discoverOsteow={onDiscoverOsteowTapped}
              style={[styles.trainingCardStyle]}
            />
            <View>
              <OsteoAdviceCard style={{ flex: 1 }} />
            </View>
          </View>
          {!isInvitedMode && (
            <View style={styles.favoritesContainer}>
              <Text style={[ANFont.h6Bold, styles.favoritesTitle]}>
                {I18n.t('explorer.my_favorites')}
              </Text>
              {renderFavoritesSection()}
            </View>
          )}
        </View>
      </ScrollView>

      <OnboardingModal
        isVisible={isWebOnboardingModalVisible}
        shouldDismissWebModal={() => {
          setWebOnboardingModalVisible(false);
          setWebIntroVideoModalVisible(true);
        }}
      />

      <WebModal
        isVisible={isWebIntroVideoModalVisible}
        shouldDismissWebModal={() => setWebIntroVideoModalVisible(false)}
        shouldDisplayCloseIcon
        content={
          <IntroVideoPlayer
            localVideo={isInvitedMode ? ANLocalVideos.teaser : ANLocalVideos.introduction}
          />
        }
      />

      <WebModal
        isVisible={isWebCheckupModalVisible}
        shouldDisplayCloseIcon
        shouldDismissWebModal={() => setWebCheckupModalVisible(false)}
        content={
          <ScrollView>
            <HealthCheckUpScreen
              shouldDismissWebModal={() => {
                setWebCheckupModalVisible(false);
              }}
            />
          </ScrollView>
        }
      />
      <GuestPromotionModal
        isVisible={isWebGuestModalVisible}
        shouldDismissWebModal={() => {
          setWebGuestModalVisible(false);
          setWebIntroVideoModalVisible(true);
        }}
      />
    </View>
  );
};

export default DashboardScreen;

const styles = StyleSheet.create({
  title: {
    color: ANColor.tertiary500,
    marginTop: MetricsSizes.big,
    marginBottom: MetricsSizes.regular,
  },
  scrollContainer: {
    backgroundColor: ANColor.primary100,
    paddingBottom: MetricsSizes.big,
    paddingRight: MetricsSizes.regular,
  },
  trainingCardStyle: {
    flex: 1,
    maxWidth: 700,
    width: '100%',
    height: '100%',
    marginRight: MetricsSizes.extraLarge,
  },
  favoritesContainer: {
    marginTop: MetricsSizes.big,
  },
  favoritesTitle: {
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.regular,
  },
  header: {
    borderRadius: 9,
  },
});
