import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { StyleSheet } from 'react-native';
import SliderRating from '../../../component/SliderRating';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { RatingFormValues } from './HealthCheckupQuestions';

type Props = {
  name: string;
  title: string;
  subtitle: string;
  control: Control<RatingFormValues, any>;
};

const SliderRatingController: FC<Props> = ({ control, name, title, subtitle }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <SliderRating
          style={styles.slider}
          title={title}
          subtitle={subtitle}
          errorDetails={error?.message}
          setValue={onChange}
        />
      )}
    />
  );
};

export default SliderRatingController;

const styles = StyleSheet.create({
  slider: {
    marginVertical: MetricsSizes.singlePadding,
    marginHorizontal: MetricsSizes.singlePadding,
  },
});
