import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { removeHeaderAuthorization, setHeaderAuthorization } from '../api/apiclient';
import { APIError } from '../api/data/ResponseError';
import { getMyProfile } from '../api/request/user/getMyProfile';
import { getRefreshToken } from '../api/request/user/getRefreshToken';
import ANAppointment from '../model/ANAppointment';
import ANLastReviews from '../model/ANLastReviews';
import ANPlaylist from '../model/ANPlaylist';
import { ANTrainingSchedule } from '../model/ANTrainingSchedule';
import ANUser from '../model/ANUser';
import ANExercice from '../model/ANVideoGroup';
import { setUserIsLogged } from '../util/analytics';
import {
  deleteLocalJWT,
  deleteLocalUser,
  getLocalIsGuest,
  getLocalJWT,
  getLocalUser,
  isGuestOnboardingSeen,
  isOnboardingSeen,
  storeGuestOnboardingSeen,
  storeIsGuest,
  storeJWT,
  storeOnboardingSeen,
  storeUser,
} from '../util/ANStorage';

import { getExplorerMediaLogs, getExplorerPodcastLogs } from '../api/request/user/explorer/getLogs';
import useFavoriteStore from '../hooks/useFavoritesStore';
import useLocalMediaLogs from '../hooks/useLocalMediaLogs';
import useLocalPodcastLogs from '../hooks/useLocalPodcastLogs';
import { setSentryUserIdentifier } from '../sentry/sentry';
import { isJWTExpired } from '../util/jwt-utils';
import { checkAndSendFCMToken } from '../util/notifications';
import SnackMessageContext from './SnackMessageContext';

type Props = {
  user?: ANUser;
  isInvitedMode: boolean;
  onboardingSeen: boolean;
  guestOnboardingSeen: boolean;
  loadingProfile: boolean;
  isJWTset: boolean;
  isUsingWrongRole: boolean;
  hasPlaylist: boolean;
  setJWT: (jwt: string) => void;
  updateLastMeeting: (lastMeeting: ANAppointment) => void;
  userLoggedIn: (jwt: string, mustDefinePassword: boolean) => void;
  userLoggedOut: () => void;
  initUser: () => void;
  onboardingTriggered: () => void;
  guestOnboardingTriggered: () => void;
  updateLastReviews: (reviews: ANLastReviews) => void;
  incrementTotalTrainingSession: () => number | undefined;
  updateExercice: (exercice?: ANExercice) => void;
  updateTrainingSchedule: (schedule: ANTrainingSchedule[]) => void;
  setUserAsGuest: () => void;
  disconnectGuest: () => void;
  fetchUser: () => void;
  exercicesToPlay: (useRestrictedMode: boolean) => ANExercice[] | undefined;
};

const UserContext = createContext<Props>({
  isInvitedMode: false,
  onboardingSeen: false,
  loadingProfile: false,
  isJWTset: false,
  guestOnboardingSeen: false,
  isUsingWrongRole: false,
  hasPlaylist: false,
  setOnBoardingSeen: () => {},
  setGuestOnBoardingSeen: () => {},
  setJWT: () => {},
  updateLastMeeting: () => {},
  userLoggedIn: () => {},
  onboardingTriggered: () => {},
  userLoggedOut: () => {},
  initUser: () => {},
  updateLastReviews: () => {},
  incrementTotalTrainingSession: () => {
    return undefined;
  },
  updateExercice: () => {},
  updateTrainingSchedule: () => {},
  setUserAsGuest: () => {},
  disconnectGuest: () => {},
  fetchUser: () => {},
  exercicesToPlay: () => [],
});

export const UserProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<ANUser | undefined>();
  const [jwt, setJWT] = useState<string | undefined>();
  const [onboardingSeen, setOnBoardingSeen] = useState<boolean>(false);
  const [isUsingWrongRole, setUsingWrongRole] = useState<boolean>(false);
  const [guestOnboardingSeen, setGuestOnboardingSeen] = useState<boolean>(false);

  const [isInvitedMode, setInvitedMode] = useState(false);
  const initLogs = useLocalMediaLogs(state => state.initLogs);
  const initPodcastLogs = useLocalPodcastLogs(state => state.initLogs);
  const initFavorites = useFavoriteStore(state => state.initFavorites);

  const { displaySnackBarError } = useContext(SnackMessageContext);

  const { isLoading: loadingProfile, refetch: fetchUser } = useQuery(
    ['profile'],
    () => getMyProfile(),
    {
      onSuccess: data => {
        if (data !== undefined) {
          const newUserData = { ...user, ...data };
          setUser(newUserData);
          fetchLogs();
          fetchPodcastLogs();
          initFavorites(newUserData.mediaFavorites.map(favorite => favorite.id));
        }
      },
      onError: error => {
        console.log('🐛 Error while fetching user', error);
        if (error === APIError.ForbiddenError) {
          console.log('🐛 User is not logged in anymore');
          setUsingWrongRole(true);
        } else {
          displaySnackBarError();
        }
      },
      enabled: false, // disable this query from automatically running
      retry: false,
    },
  );

  const { refetch: fetchLogs } = useQuery(['profile-media-logs'], () => getExplorerMediaLogs(), {
    onSuccess: data => {
      if (data !== undefined) {
        initLogs(data);
      }
    },
    onError: error => {
      console.log('🐛 Error while fetching user logs', error);
    },
    enabled: false, // disable this query from automatically running
  });

  const { refetch: fetchPodcastLogs } = useQuery(
    ['profile-media-podcast'],
    () => getExplorerPodcastLogs(),
    {
      onSuccess: data => {
        if (data !== undefined) {
          initPodcastLogs(data);
        }
      },
      onError: error => {
        console.log('🐛 Error while fetching user logs', error);
      },
      enabled: false, // disable this query from automatically running
    },
  );

  const { refetch: refreshTokenQuery } = useQuery(['refresh-token'], () => getRefreshToken(), {
    onSuccess: data => {
      if (data !== undefined) {
        setJWT(data);
      }
    },
    enabled: false, // disable this query from automatically running
  });

  const refreshToken = () => {
    refreshTokenQuery();
  };

  const onboardingTriggered = useCallback(() => {
    storeOnboardingSeen(true);
    setOnBoardingSeen(true);
  }, []);

  const guestOnboardingTriggered = useCallback(() => {
    storeGuestOnboardingSeen(true);
    setGuestOnboardingSeen(true);
  }, []);

  const isJWTset = useMemo(() => {
    return jwt !== undefined;
  }, [jwt]);

  const initUser = useCallback(async () => {
    const cachedUser = await getLocalUser();
    const cachedJwt = await getLocalJWT();
    const onboardingSeen = await isOnboardingSeen();
    const guestOnboardingSeen = await isGuestOnboardingSeen();
    setOnBoardingSeen(onboardingSeen);
    setGuestOnboardingSeen(guestOnboardingSeen);
    const isGuest = await getLocalIsGuest();
    setInvitedMode(isGuest);

    if (cachedUser) {
      setUser(cachedUser);
    } else {
      console.log('locale user undefined -> log out');
      userLoggedOut();
      return;
    }

    if (cachedJwt && isJWTExpired(cachedJwt)) {
      console.log('JWT expired. Log out user');
      userLoggedOut();
      return;
    }

    if (cachedJwt && cachedUser) {
      console.log('Locale user found. Refresh with API request');
      setJWT(cachedJwt);
      setHeaderAuthorization(cachedJwt); //Don't wait for the useEffect because the delay causes the next request to miss the auth header
      refreshToken(); //Refresh user token. No need to await because token should still be valid
      fetchUser();
      checkAndSendFCMToken(true);
    }
  }, []);

  useEffect(() => {
    storeIsGuest(isInvitedMode);
  }, [isInvitedMode]);

  //Update localy stored user on data set
  useEffect(() => {
    if (user) {
      storeUser(user);
      setSentryUserIdentifier(user.id.toString(), user.email);
    } else {
      deleteLocalUser();
    }
  }, [user]);

  //Update localy stored jwt and header authorization
  useEffect(() => {
    if (jwt) {
      storeJWT(jwt);
      setHeaderAuthorization(jwt);
    }
  }, [jwt]);

  const hasPlaylist = useMemo(() => {
    return (user?.trainingPlaylist?.exercices?.length ?? 0) > 0;
  }, [user, user?.trainingPlaylist]);

  /**
   * Return an array of exercices to play. If restricted mode is enabled, only return exercices that are restricted without considering the level selection (restricted version are only available in one level). Otherwise, return all exercices
   * @param useRestrictedMode
   * @returns
   */
  const exercicesToPlay = useCallback(
    (useRestrictedMode: boolean) => {
      console.log('Compute training with restricted mode ?', useRestrictedMode);
      const playlist = user?.trainingPlaylist;
      if (playlist === undefined) return [];
      if (useRestrictedMode === true) {
        //WARNING, for WEB, on refresh, condition does not work if not using === true. WHY? I dunno.
        //In restricted mode, we only display the videos that are restricted. There's no user selection in that case, and pick the first video of each exercice that is in limited
        const limitedExercices = playlist?.exercices
          .filter(exercice => exercice.videos.find(video => video.limitedMode))
          .map(exercice => ({
            ...exercice,
            selectedVideo: exercice.videos.find(video => video.limitedMode),
          }));
        console.log('restricted exercices');
        return limitedExercices;
      } else {
        //In non restricted mode, display videos that are not restricted
        const exercices = playlist?.exercices.filter(
          exercice => !exercice.selectedVideo?.limitedMode,
        );
        console.log('Return non restricted exercices');
        return exercices;
      }
    },
    [user],
  );

  /**
   * Call when user log in.
   * @param jwt : user token received on sign in success
   * @param mustDefinePassword : true if the user must define a password (on first sign in). False if the user already his own password.
   */
  const userLoggedIn = useCallback(
    async (jwt: string, mustDefinePassword: boolean) => {
      setHeaderAuthorization(jwt); //also set in useEffect[jwt] but the little delay cause fetchUser to fail

      try {
        //Check immediately if the user has the right role. If not, throw an error
        // const roleCheck = await getUserRole();
        // checkRole(roleCheck);
        //Role permission allowed, we can continue

        setJWT(jwt); //set the jwt in the context
        setUserIsLogged(true);
        setInvitedMode(false);
        if (!mustDefinePassword) {
          console.log('logged in and password already defined. Fetch user');
          fetchUser(); //If the password has already been redefined, fetch the full user data (auth response return an incomplete user data, without populate)
        }
      } catch (e) {
        removeHeaderAuthorization();
      }

      //else, the user will be fetched when he will define his password
    },
    [jwt],
  );

  /**
   * To update the last meeting when the user review it
   */
  const updateExercice = useCallback(
    (exercice?: ANExercice) => {
      if (!exercice) return;
      const exercices = user?.trainingPlaylist?.exercices;
      if (!exercices) return;
      //replace exercice with the new one
      const updatedExercices = (newExercice: ANExercice) => {
        const newExercices = [...exercices];
        const index = newExercices.findIndex(e => e.id === exercice.id);
        newExercices[index] = newExercice;
        return newExercices;
      };

      const newExercices = updatedExercices(exercice);
      //Update the playlist
      const trainingPlaylist: ANPlaylist = {
        ...user.trainingPlaylist!,
        exercices: newExercices,
      };

      //Update the user with the new playlist
      setUser({
        ...user,
        trainingPlaylist,
      });
    },
    [user],
  );

  const setUserAsGuest = () => {
    console.log('Set user as guest');
    setInvitedMode(true);
    setUserIsLogged(false);
    setUser(undefined);
  };

  const disconnectGuest = () => {
    setInvitedMode(false);
    setUser(undefined);
  };

  /**
   * To update the last meeting when the user review it
   */
  const updateLastMeeting = useCallback(
    (lastMeeting: ANAppointment) => {
      if (user) {
        setUser({
          ...user,
          lastMeeting,
        });
      }
    },
    [user],
  );

  /**
   * Call to increment the number of completed training sessions
   */
  const incrementTotalTrainingSession = useCallback(() => {
    if (!user) return;
    let count = user.trainingPlaylist?.completed ?? 0;
    //increment the number of completed sessions
    const newCount = count + 1;
    console.log('new count: ' + newCount);
    const updatedPlaylist: ANPlaylist = {
      ...user.trainingPlaylist!,
      completed: newCount,
    };
    setUser({
      ...user,
      trainingPlaylist: updatedPlaylist,
    });

    return newCount;
  }, [user]);

  /**
   * Update the user last reviews
   */
  const updateLastReviews = useCallback(
    (lastReviews: ANLastReviews) => {
      if (user) {
        setUser({
          ...user,
          lastReviews,
        });
      }
    },
    [user],
  );

  const updateTrainingSchedule = useCallback(
    (trainingSchedule: ANTrainingSchedule[]) => {
      if (user) {
        setUser({
          ...user,
          notificationsSchedule: trainingSchedule,
        });
      }
    },
    [user],
  );

  const deleteJWT = useCallback(() => {
    removeHeaderAuthorization();
    deleteLocalJWT();
    setJWT(undefined);
  }, []);

  const userLoggedOut = useCallback(() => {
    console.log('Will log out user');
    setUser(undefined);
    deleteJWT();
    deleteLocalUser();
    setUsingWrongRole(false);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        isInvitedMode,
        onboardingSeen,
        loadingProfile,
        isJWTset,
        guestOnboardingSeen,
        isUsingWrongRole,
        hasPlaylist,
        setJWT,
        updateLastMeeting,
        userLoggedIn,
        userLoggedOut,
        initUser,
        incrementTotalTrainingSession,
        updateLastReviews,
        updateExercice,
        updateTrainingSchedule,
        setUserAsGuest,
        disconnectGuest,
        onboardingTriggered,
        guestOnboardingTriggered,
        fetchUser,
        exercicesToPlay,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
