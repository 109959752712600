import React, { FC } from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import ANImages from '../../../assets/image/ANimages';
import I18n from '../../../i18n';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';

type Props = {
  top: number;
  bottom: number;
};

const OnboardingSlide1: FC<Props> = ({ top, bottom }) => {
  return (
    <View style={[ANLayout.container]}>
      <ScrollView
        style={styles.scroll}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[{ paddingTop: top, marginBottom: bottom + 100, flex: 1 }]}
      >
        <View style={[ANLayout.columnHVCenter, styles.slide]}>
          <Animated.Text style={[ANFont.h4Bold, styles.title]} entering={FadeIn.delay(200)}>
            {I18n.t('onboarding.slide_one.title')}
          </Animated.Text>
          <Animated.Text
            style={[ANFont.textLargeRegular, styles.body]}
            entering={FadeIn.delay(500)}
          >
            {I18n.t('onboarding.slide_one.body')}
          </Animated.Text>
          <Animated.View entering={FadeIn.delay(800)}>
            <Image
              source={ANImages.ThumbUpIllustration}
              style={!isDevice() && { width: 500, height: 400 }}
            />
          </Animated.View>
        </View>
      </ScrollView>
    </View>
  );
};

export default OnboardingSlide1;

const styles = StyleSheet.create({
  scroll: {
    backgroundColor: ANColor.primary100,
  },
  scrollContainer: {
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: MetricsSizes.extraLarge,
  },
  slide: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  title: {
    textAlign: 'center',
    marginVertical: MetricsSizes.singlePadding,
    color: ANColor.tertiary500,
  },

  body: {
    marginVertical: MetricsSizes.singlePadding,
    textAlign: 'center',
    fontSize: 20,
  },
});
