import * as React from 'react';
import { View, ViewProps } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';
import ANLayout from '../../theme/ANLayout';

export const cloudWidth = 237;
const Cloud = (props: SvgProps & ViewProps) => {
  return (
    <View style={[ANLayout.container, props.style]}>
      <Svg width={cloudWidth} height={141} fill="none" {...props}>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.407 140.338H66.947c-36.688 0-66.483-29.511-66.942-66.09A32.05 32.05 0 0 1 0 73.392h.001c.188-22.32 23.662-40.37 52.592-40.37 8.168 0 15.902 1.438 22.799 4.006C81.004 15.775 101.752 0 126.489 0c27.172 0 49.532 19.034 52.303 43.455a57.545 57.545 0 0 1 5.615-.274c29.046 0 52.593 21.75 52.593 48.579 0 3.515-.404 6.944-1.172 10.248-3.112 21.673-21.756 38.33-44.29 38.33h-7.131Z"
          fill="#EF7560"
          fillOpacity={0.16}
        />
      </Svg>
    </View>
  );
};

export default Cloud;
