import React, { FC } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ANImages from '../../../assets/image/ANimages';
import ANButton from '../../../component/ANButton';
import I18n from '../../../i18n';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';

type Props = {
  onDone: () => void;
};

const BreathCongrats: FC<Props> = ({ onDone }) => {
  const { bottom } = useSafeAreaInsets();

  return (
    <View
      style={[
        ANLayout.screenContainer,
        ANLayout.columnHVCenter,
        {
          justifyContent: isDevice() ? 'space-between' : 'center',
          paddingTop: MetricsSizes.giant,
          paddingBottom: bottom + MetricsSizes.regular,
        },
      ]}
    >
      <View>
        <Text style={[ANFont.h6Bold, styles.congratsTitle]}>
          {I18n.t('breath_exercice.congrats.title')}
        </Text>
        <Text style={[ANFont.textLargeRegular, styles.congratsDescription]}>
          {I18n.t('breath_exercice.congrats.description')}
        </Text>
      </View>
      <Image
        source={ANImages.ZenIllustration}
        style={!isDevice() && { resizeMode: 'contain', width: 500, height: 400 }}
      />

      <ANButton
        //style={styles.footer}
        title={I18n.t('common.done_button')}
        onPress={onDone}
      />
    </View>
  );
};

export default BreathCongrats;

const styles = StyleSheet.create({
  congratsTitle: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.regular,
  },
  congratsDescription: {
    textAlign: 'center',
    marginBottom: MetricsSizes.big,
  },
});
