import { Video } from 'expo-av';
import React, { FC } from 'react';
import ANVideoView from '../../../component/ANVideoView';
import useIntroductionVideo from './useIntroductionVideo';

type Props = {
  localVideo: any; //Must be one of ANLocalVideos
  onExitPlayerFullscreen?: (completed: boolean) => void;
};

const IntroVideoPlayer: FC<Props> = ({ localVideo, onExitPlayerFullscreen }) => {
  const videoRef = React.useRef<Video>(null);

  const { onFullscreenUpdate, onPlayBackUpdate, onVideoReadyToDisplay } = useIntroductionVideo({
    videoRef,
    onExitPlayerFullscreen,
  });

  return (
    <ANVideoView
      videoRef={videoRef}
      localVideo={localVideo}
      shouldPlay={true}
      isMuted
      style={{ backgroundColor: 'black', height: 550 }}
      onFullscreenUpdate={onFullscreenUpdate}
      onPlaybackStatusUpdate={onPlayBackUpdate}
      onReadyForDisplay={onVideoReadyToDisplay}
      useControls={true}
    />
  );
};

export default IntroVideoPlayer;
