import React, { useContext } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import ANImages from '../../assets/image/ANimages';
import LogoLong from '../../assets/svg/LogoLong';
import ANButton from '../../component/ANButton';
import ANSafeAreView from '../../component/ANSafeAreView.native';
import UserContext from '../../context/UserContext';
import I18n from '../../i18n';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';

type Props = {};

const UsingWrongRoleScreen = (props: Props) => {
  const { userLoggedOut } = useContext(UserContext);
  console.log('UsingWrongRoleScreen');
  return (
    <ANSafeAreView style={ANLayout.screenContainer}>
      <View style={[ANLayout.screenContainer, { alignItems: 'center' }]}>
        <LogoLong style={styles.logo} />
        <View>
          <Text style={[ANFont.h5Bold, styles.title]}>{I18n.t('wrong_role.title')}</Text>
          <Text style={[ANFont.textLargeRegular, , styles.description]}>
            {I18n.t('wrong_role.description')}
          </Text>
        </View>
        <Image source={ANImages.AndrewPro} style={styles.image} />
        <ANButton
          title={I18n.t('common.continue_button')}
          onPress={() => {
            userLoggedOut();
            if (!isDevice()) {
              //Redirect to url pro.andrewapp.fr
              window.location.href = 'https://pro.andrewapp.fr';
            }
          }}
        />
      </View>
    </ANSafeAreView>
  );
};

export default UsingWrongRoleScreen;

const styles = StyleSheet.create({
  logo: {
    width: 110,
    alignSelf: 'center',
  },
  title: {
    marginTop: 54,
    color: ANColor.tertiary500,
  },
  description: {
    marginTop: MetricsSizes.big,
    color: ANColor.tertiary500,
  },
  image: {
    resizeMode: 'contain',
    width: '100%',
    maxWidth: 300,
    flex: 1,
    marginTop: MetricsSizes.regular,
  },
});
