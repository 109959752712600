import {
  Academie,
  Appointment,
  Center,
  Exercice,
  Osteo,
  Podcast,
  PodcastItem,
  PodcastsResponseData,
  TrainingVideo,
} from '../api/data/APIDataResponse';
import {
  ExplorerAuthor,
  ExplorerMedia,
  LastReviews,
  TopicDetailed,
} from './../api/data/APIDataResponse';
import ANAppointment from './ANAppointment';
import { ANAuthor } from './ANAuthor';
import ANLastReviews from './ANLastReviews';
import ANMedia from './ANMedia';
import ANOsteo from './ANOsteo';
import { ANPodcast, ANPodcastDetailed } from './ANPodcast';
import ANTopicDetailed from './ANTopicDetailed';
import ANVideo from './ANVideo';
import ANExercice from './ANVideoGroup';

/**
 * parse the patient osteo data. Note that the center & academie data are optional, and only passed here when fetching the data from the patient last appointement.
 * @param osteo osteo data
 * @param center if the osteo has met the patient in a center
 * @param academie if the osteo has met the patient in an academie
 * @returns
 */
export const parseOsteo: (osteo: Osteo, center?: Center, academie?: Academie) => ANOsteo = (
  osteo: Osteo,
  center?: Center,
  academie?: Academie,
) => {
  const academieName = academie?.locations[0]?.name ?? academie?.name;
  const academieAvatar =
    academie?.locations[0]?.avatar?.formats?.thumbnail?.url ?? academie?.locations[0]?.avatar?.url;

  return {
    firstname: osteo.firstname,
    lastname: osteo.lastname,
    displayname: osteo.firstname + ' ' + osteo.lastname,
    avatarThumb: osteo.avatar?.formats?.thumbnail?.url ?? osteo.avatar?.url,
    urlDoctolib: osteo.urlDoctolib,
    centerName: center?.name,
    centerAvatar: center?.avatar?.formats?.thumbnail?.url ?? center?.avatar?.url,
    centerURL: center?.url,
    academieName: academieName,
    academieAvatar: academieAvatar,
    academieURL: academie?.url,
    shouldDisplayStudentNameToPatients: academie?.shouldDisplayStudentNameToPatients ?? true,
  };
};

export const parseAppointment: (appointment: Appointment) => ANAppointment = (
  appointment: Appointment,
) => {
  const osteo = parseOsteo(appointment.osteo, appointment.center, appointment.academie);
  // const osteo = parseOsteo(
  //   appointment.osteo,
  //   // {
  //   //   avatar: {
  //   //     url: 'https://andrew-osteow-s3.s3.eu-west-3.amazonaws.com/logo_Atsa_ff8d44eaec.jpg',
  //   //   },
  //   //   name: "ATSA - Ostéopathie de maurice l'exil",
  //   //   id: 1,
  //   // },
  //   appointment.academie,
  // );
  return {
    id: appointment.id,
    osteoAdvice: appointment.osteoAdvice,
    hasBeenRated: appointment.hasBeenRated,
    date: appointment.dateFormatted,
    osteo: osteo,
  };
};

export const parseExercice = (item: Exercice) => {
  try {
    const result: ANExercice = {
      id: item.id,
      title: item.videoGroup.title,
      videos: item.videoGroup.videos.map(video => parseVideo(video, item.duration)),
      selectedVideo: parseVideo(item.userSelection ?? item.osteoSelection, item.duration),
    };
    return result;
  } catch (e) {
    const error = 'Error parsing exercice ' + item.id + ' : ' + e;
    console.log(error);
    return undefined;
  }
};

export const parseLastReviews = (item?: LastReviews) => {
  const lastHealthDate = item?.lastHealthCheckDate;
  const lastDifficultyReportDate = item?.lastDifficultyReportDate;
  const result: ANLastReviews = {
    lastHealthCheck: lastHealthDate,
    lastDifficultyReport: lastDifficultyReportDate,
  };
  return result;
};

export const parseTopicDetailed = (item: TopicDetailed) => {
  const medias: ANMedia[] = item.medias.map(media => parseMedia(media, item.id));
  const result: ANTopicDetailed = {
    id: item.id,
    title: item.title,
    key: item.key,
    type: item.type,
    infoUrl: item.infoUrl,
    availableTags: item.availableTags?.map(tag => tag.key),
    medias,
  };
  return result;
};
export const parseVideo = (video: TrainingVideo, durationInSeconds: number) => {
  try {
    const result: ANVideo = {
      id: video.id,
      title: video.title,
      urlFull: video.fullVideo.url,
      urlLoop: video.loopVideo.url,
      urlIntroduction: video.introductionVideo?.url,
      urlLoopAlternative: video.loopAlternativeVideo?.url,
      urlIntroductionAlternative: video.introductionAlternativeVideo?.url,
      previewLarge: video.preview?.formats.large.url,
      previewThumb: video.preview?.formats.thumbnail.url,
      level: video.level,
      duration: durationInSeconds * 1000,
      timeFlag: undefined,
      limitedMode: video.limitedMode,
    };
    return result;
  } catch (e) {
    const error = 'Error parsing exercice ' + video.id + ' : ' + e;
    console.log(error);
    throw error;
  }
};

export const parseExplorerAuthor = (author: ExplorerAuthor) => {
  const result: ANAuthor = {
    id: author.id,
    name: author.displayName,
    avatarURL: author.avatar.formats?.thumbnail?.url,
    description: author.description,
    redirectURL: author.redirectUrl,
  };
  return result;
};

export const parseMedia = (item: ExplorerMedia, topicId?: number) => {
  const result: ANMedia = {
    id: item.id,
    title: item.title,
    duration: item.duration,
    url: item.media?.url,
    type: item.type,
    previewUrl: item.cover?.formats?.large.url,
    requiresAuthentication: item.requiresAuthentication,
    author: item.author && parseExplorerAuthor(item.author),
    difficulty: item.difficulty,
    tag: item.tag?.key,
    topicId: topicId,
  };
  return result;
};

export const parsePodcast = (podcast: Podcast, topicId: number) => {
  const author: ANAuthor = {
    id: -1,
    name: podcast.info.authorName ?? '',
    avatarURL: podcast.info.image,
    description: podcast.info.description,
    redirectURL: podcast.info.link,
  };
  const data: ANPodcastDetailed = {
    items: podcast.items.map(item => parsePodcastItems(item, topicId)),
    author: author,
    name: podcast.info.title,
  };
  return data;
};

export const parsePodcastList = (podcasts: PodcastsResponseData[]) => {
  const result: ANPodcast[] = podcasts.map(podcast => {
    return {
      id: podcast.id,
      title: podcast.attributes.title,
      illustrationUrl: podcast.attributes.illustrationUrl,
    };
  });
  return result;
};

export const convertItunesDurationStringToMillis = (duration?: string) => {
  if (!duration) return undefined;

  //Check if the duration has one or two : in it
  let totalSeconds = 0;
  const rows = duration.split(':');
  if (rows.length === 3) {
    const [hours, minutes, seconds] = rows;
    totalSeconds = Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
  } else {
    const [minutes, seconds] = rows;
    totalSeconds = Number(minutes) * 60 + Number(seconds);
  }
  return totalSeconds * 1000;
};

const parsePodcastItems = (item: PodcastItem, topicId: number) => {
  const result: ANMedia = {
    id: -1,
    topicId: topicId,
    title: item.title,
    duration: convertItunesDurationStringToMillis(item.duration),
    url: item.url,
    type: 'podcast',
    timeFlagInMilli: Number(item.lastLog?.timeFlagInMilli ?? '0'),
    requiresAuthentication: false,
  };
  return result;
};
