import { useLinkTo, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  exploreCategories,
  ExplorerCategory,
  ExplorerTopic,
} from '../../api/data/ExplorerCategory';
import { getFreshestMedias, getTopics } from '../../api/request/user/explorer/getTopics';
import { RootStackParamList, Route } from '../../navigation/Route';
import { isDevice } from '../../util/Device';

const useExplorerScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const linkTo = useLinkTo();

  const onTopicPressed = useCallback((topic: ExplorerTopic) => {
    const params = {
      id: topic.id,
    };

    if (topic.key === 'coherence_cardiaque') {
      if (isDevice()) {
        navigation.navigate(Route.breathingExerciceScreen, { infoUrl: topic.infoUrl });
      } else {
        //linkTo('/' + Route.breathingExerciceScreen, );
        linkTo({ screen: Route.breathingExerciceScreen, params: params });
      }

      return;
    }
    if (topic.key === 'podcast') {
      if (isDevice()) {
        navigation.navigate(Route.explorerPodcastsListScreen);
      } else {
        linkTo({ screen: Route.explorerPodcastsListScreen });
      }
    } else {
      if (isDevice()) {
        navigation.navigate(Route.explorerTopicScreen, params);
      } else {
        linkTo({ screen: Route.explorerTopicScreen, params: params });
      }
    }
  }, []);

  const {
    isLoading: isLoadingNewestMedis,
    data: mostRecentMedias,
    refetch: refreshMedias,
  } = useQuery(['getFreshestMedias'], getFreshestMedias, {
    staleTime: 1000 * 60 * 60 * 1,
    cacheTime: 1000 * 60 * 60 * 1,
  });

  const {
    isLoading: isLoadingTopics,
    data: categoriesWithTopics,
    refetch: refreshTopics,
  } = useQuery(['getTopics'], getTopics, {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });

  const [filteredCategories, setFilteredCategories] = React.useState<ExplorerCategory[]>();
  const [selectedCategory, setSelectedCategory] = React.useState<ExplorerCategory | undefined>(
    undefined,
  );

  const categoriesData = useMemo(() => {
    return categoriesWithTopics ?? exploreCategories;
  }, [categoriesWithTopics]);

  return {
    isLoadingNewestMedis,
    isLoadingTopics,
    categoriesData,
    mostRecentMedias,
    filteredCategories,
    selectedCategory,
    onTopicPressed,
    setFilteredCategories,
    setSelectedCategory,
    refreshMedias,
    refreshTopics,
  };
};
export default useExplorerScreen;
