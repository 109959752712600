import { MaterialIcons } from '@expo/vector-icons';
import React, { FC, useCallback, useMemo } from 'react';
import { Pressable, StyleProp, View, ViewStyle } from 'react-native';
import { ANColor } from '../theme/ANColor';
import Layout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { formatAnalyticsEvent, logEvent } from '../util/analytics';
import { hapticSelection } from '../util/utils';
import ExpoIcon from './ExpoIcon';

type Props = {
  iconName: keyof typeof MaterialIcons.glyphMap;
  backgroundColor?: string;
  borderRadius?: number;
  iconColor?: string;
  size?: number;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  eventKey: string;
};

const TrainingSessionController: FC<Props> = ({
  iconName,
  eventKey,
  backgroundColor = ANColor.primary300,
  iconColor,
  borderRadius = MetricsSizes.largeCornerRadius,
  style,
  size = 44,
  onPress,
}) => {
  const formattedAnalyticsEvent: string | undefined = useMemo(() => {
    if (!eventKey) return undefined;
    return formatAnalyticsEvent(eventKey);
  }, [eventKey]);

  const pressed = useCallback(() => {
    hapticSelection();
    onPress();
    if (formattedAnalyticsEvent) logEvent(formattedAnalyticsEvent);
  }, [formattedAnalyticsEvent, onPress]);

  return (
    <Pressable onPress={pressed} style={style}>
      <View
        style={[
          Layout.roundedBackground,
          {
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            height: size,
            width: size,
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
      >
        <ExpoIcon name={iconName} color={iconColor ?? 'black'} />
      </View>
    </Pressable>
  );
};

export default TrainingSessionController;
