export const ANImages = {
  LogoLong: require('./logo_long/logo_long.png'),
  LogoLongHeader: require('./logo_long_header/logo_long_header.png'),
  TrainingIllustration: require('./illustration/illustration1.png'),
  CongratsIllustration: require('./illustration/congrats/andrew_congrats.png'),
  ThumbUpIllustration: require('./illustration/thumb_up/thumb_up.png'),
  ListenIllustration: require('./illustration/listen_audio/Illustration_listen.png'),
  ZenIllustration: require('./illustration/zen/andrew_zen_illustration.png'),
  BodyExplorer: require('./illustration/body/body-illustration.png'),
  MindExplorer: require('./illustration/mind/mind-illustration.png'),
  PodcastExplorer: require('./illustration/podcasts/podcasts-illustration.png'),
  ExerciceIllustration: require('./illustration/exercice/exercice-illustration.png'),
  ExercicePlaceholder: require('./illustration/exercice_placeholder/exercice-placeholder.png'),
  PlaylistPlaceholder: require('./illustration/playlist_placeholder/training-playlist-placeholder.png'),
  Login1: require('./illustration/login1/login1_illustration.png'),
  Login2: require('./illustration/login2/login2_illustration.png'),
  AndrewPro: require('./illustration/andrew_pro/andrew_pro_illustration.png'),
  AndrewWaving: require('./illustration/andrew_waving/andrew_waving_illustration.png'),
};

export default ANImages;
