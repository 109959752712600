import Checkbox from 'expo-checkbox';
import React, { FC, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Hyperlink from 'react-native-hyperlink';

import I18n from '../../i18n';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { hapticSelection, openUrlInBrowser } from '../../util/utils';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  errorMessage: string | undefined;
};

const CGUCheckbox: FC<Props> = ({ value, errorMessage, onChange }) => {
  const openCGU = useCallback(() => {
    const cgu = I18n.t('auth.cgu_url');
    openUrlInBrowser(cgu);
  }, []);

  console.log(errorMessage);
  const onCheckClicked = useCallback((value: boolean) => {
    onChange(value);
    hapticSelection();
  }, []);

  return (
    <View>
      <View style={ANLayout.row}>
        <Checkbox value={value} onValueChange={onCheckClicked} color={ANColor.commonBlack} />
        <Hyperlink
          style={{ marginHorizontal: MetricsSizes.regular, marginTop: -3 }}
          linkStyle={{ color: ANColor.tertiary500, textDecorationLine: 'underline' }}
          linkText={url => (url === I18n.t('auth.cgu_url') ? I18n.t('auth.cgu') : url)}
          onPress={(url, text) => openCGU()}
        >
          <Text>{I18n.t('auth.validate_cgu')}</Text>
        </Hyperlink>
      </View>
      {Boolean(errorMessage) && (
        <Text style={[ANFont.textSmallRegular, styles.cguErrorLabel]}>{errorMessage}</Text>
      )}
    </View>
  );
};

export default CGUCheckbox;

const styles = StyleSheet.create({
  cguErrorLabel: {
    color: ANColor.statusWarning500,
    marginVertical: 10,
  },
});
