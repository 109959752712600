import React, { FC, useContext, useRef } from 'react';
import { StyleSheet } from 'react-native';
import UserContext from '../../context/UserContext';
import useLogMedia from '../../hooks/useLogMedia';
import ANMedia from '../../model/ANMedia';
import ExplorerAudioPlayerScreen, {
  ExplorerAudioPlayerProps,
} from '../../screen/explorer/ExplorerAudioPlayerScreen';
import { ANColor } from '../../theme/ANColor';
import ANLayout from '../../theme/ANLayout';
import { padding } from '../../util/padding';
import WebModal from './WebModal';

type Props = {
  modalVisible: boolean;
  media?: ANMedia;
  shouldDismiss?: () => void;
} & ExplorerAudioPlayerProps;

const modalSize = 800;
const ExplorerAudioWebPlayerModal: FC<Props> = ({
  media,
  modalVisible,
  timeFlag,
  shouldDismiss,
}) => {
  const { saveLogMedia } = useLogMedia();
  const { isInvitedMode } = useContext(UserContext);
  const webModalePositionInMilli = useRef<number>(0);

  const dismissSafelyModal = (completed: boolean) => {
    if (shouldDismiss) {
      shouldDismiss();
      console.log('Audio id', media.id, 'time ref', webModalePositionInMilli);
      saveLogMedia({
        mediaId: media.id,
        timeFlagInMilli: webModalePositionInMilli?.current ?? 0,
        completed: completed,
        isAuthenticated: !isInvitedMode,
      });
    }
  };

  return (
    <WebModal
      isVisible={modalVisible}
      shouldDismissWebModal={() => dismissSafelyModal(false)}
      contentContainerStyle={[ANLayout.modalContainer, styles.container]}
      content={
        media && (
          <ExplorerAudioPlayerScreen
            id={media.id}
            podcast={media}
            shouldDimiss={() => dismissSafelyModal(true)}
            waveMaxWidth={modalSize}
            timeFlag={timeFlag}
            currentTimeDidChange={(time: any) => {
              webModalePositionInMilli.current = time;
            }}
          />
        )
      }
    />
  );
};

export default ExplorerAudioWebPlayerModal;

const styles = StyleSheet.create({
  container: {
    width: modalSize,
    height: modalSize,
    ...padding(0),
    backgroundColor: ANColor.primary400,
  },
});
