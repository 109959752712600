import React, { FC, useCallback } from 'react';
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native';
import ANProgressBar from '../../component/ANProgressBar';
import ExpoIcon, { ExpoIconDesign } from '../../component/ExpoIcon';
import HeartButton from '../../component/HeartButton';
import LockedForGuestsOverlay from '../../component/LockedForGuestsOverlay';
import useHour from '../../hooks/useHour';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { padding } from '../../util/padding';
import { hapticSelection } from '../../util/utils';

type Props = {
  title: string;
  duration?: number;
  timeFlagInMilli?: number;
  isFavorite?: boolean;
  isLockedForGuests: boolean;
  style?: ViewStyle;
  useInvitedMode: boolean;
  onFavoriteTapped?: () => void;
  onPress: () => void;
};

const AudioRowExplorerItem: FC<Props> = ({
  title,
  duration = 0,
  timeFlagInMilli = 0,
  style,
  isLockedForGuests = false,
  useInvitedMode,
  onPress,
  isFavorite = false,
  onFavoriteTapped,
}) => {
  const { createDurationString } = useHour();

  const pressed = useCallback(() => {
    hapticSelection();
    onPress();
  }, [onPress]);

  return (
    <View style={[ANLayout.roundedBackground, styles.container, style]}>
      <Pressable onPress={pressed} style={styles.infoContainer}>
        <ExpoIcon
          name="play-circle"
          design={ExpoIconDesign.MaterialCommunity}
          size={40}
          color={ANColor.tertiary500}
        />
        <View style={styles.textContainer}>
          <Text style={[ANFont.textMediumBold]}>{title}</Text>
          <Text style={[ANFont.textMediumRegular, styles.duration]}>
            {createDurationString(duration * 1000)}
          </Text>
          {timeFlagInMilli !== null && timeFlagInMilli != 0 && (
            <ANProgressBar
              totalDurationInSeconds={duration ?? 0}
              flaggedDurationInMilli={timeFlagInMilli}
              style={styles.progressbar}
            />
          )}
        </View>
      </Pressable>
      {!useInvitedMode && onFavoriteTapped && (
        <View style={styles.heartContainer}>
          <HeartButton isFavorite={isFavorite} onPress={onFavoriteTapped} style={styles.heart} />
        </View>
      )}
      {isLockedForGuests && useInvitedMode && <LockedForGuestsOverlay />}
    </View>
  );
};

export default AudioRowExplorerItem;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    ...padding(MetricsSizes.regular),
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    marginRight: MetricsSizes.regular,
  },
  textContainer: {
    flex: 1,
    marginHorizontal: MetricsSizes.big,
  },
  progressbar: {
    marginTop: MetricsSizes.small,
  },
  duration: {
    marginTop: MetricsSizes.small,
  },
  heartContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  heart: {
    ...padding(MetricsSizes.regular),
  },
});
