import React, { FC } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { ANColor } from '../theme/ANColor';

type Props = {
  totalDurationInSeconds: number;
  flaggedDurationInMilli?: number;
  style?: ViewStyle;
};

const totalWidth = 80;

const ANProgressBar: FC<Props> = ({
  totalDurationInSeconds: totalDuration,
  flaggedDurationInMilli = 0,
  style,
}) => {
  const completion = flaggedDurationInMilli / (totalDuration * 1000);
  const width = completion * totalWidth;
  return (
    <View style={[styles.container, style]}>
      <View style={[styles.progress, { width }]} />
    </View>
  );
};

export default ANProgressBar;

const styles = StyleSheet.create({
  container: {
    backgroundColor: ANColor.common200,
    borderRadius: 3,
    height: 3,
    width: totalWidth,
  },
  progress: {
    backgroundColor: ANColor.primary500,
    height: 3,
  },
});
