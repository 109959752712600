import React, { FC } from 'react';
import { LayoutAnimation, Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native';
import uuid from 'react-native-uuid';
import useHour from '../hooks/useHour';
import { Time } from '../model/ANTrainingSchedule';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { padding } from '../util/padding';
import { hapticSelection } from '../util/utils';
import ExpoIcon from './ExpoIcon';

type Props = {
  day: string;
  selected?: boolean;
  times: Time[];
  style: ViewStyle;
};

const DayPicker: FC<Props> = ({ day, style, selected = false, times, children }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { createTimeString } = useHour();

  const switchExpanded = () => {
    hapticSelection();
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpanded(!expanded);
  };

  return (
    <View>
      <View
        style={
          selected ? [styles.container, styles.selectedContainer, style] : [styles.container, style]
        }
      >
        <Pressable onPress={switchExpanded} style={{ ...ANLayout.rowHCenter, flex: 1 }}>
          <ExpoIcon
            name={selected ? 'radio-button-on' : 'radio-button-off'}
            color={selected ? ANColor.primary500 : ANColor.common400}
          />
          <Text style={styles.label}>{day}</Text>
          {times.length > 0 &&
            !expanded &&
            times.map(time => {
              return (
                <Text style={styles.collapsedTimeLabel} key={String(uuid.v4())}>
                  {createTimeString(time.hour, time.minute)}
                </Text>
              );
            })}
        </Pressable>
        <Pressable onPress={switchExpanded} style={{ ...ANLayout.rowHCenter }}>
          <ExpoIcon
            name={'chevron-left'}
            style={{ transform: [{ rotate: expanded ? '90deg' : '-90deg' }] }}
            color={ANColor.common400}
          />
        </Pressable>
      </View>
      {expanded && children}
    </View>
  );
};

export default DayPicker;

const styles = StyleSheet.create({
  container: {
    ...ANLayout.rowHCenter,
    ...padding(MetricsSizes.singlePadding),
    backgroundColor: ANColor.commonWhite,
    overflow: 'hidden',
    borderRadius: MetricsSizes.cornerRadius,
  },
  selectedContainer: {
    backgroundColor: ANColor.primary200,
    borderWidth: 1,
    borderColor: ANColor.primary500,
  },
  label: {
    ...ANFont.textMediumRegular,
    marginLeft: MetricsSizes.singlePadding,
  },
  collapsedTimeLabel: {
    ...ANFont.textMediumBold,
    marginLeft: MetricsSizes.singlePadding,
  },
});
