import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import OnboardingScreen from '../../screen/home/Onboarding/OnboardingScreen';
import { ANColor } from '../../theme/ANColor';
import WebModal from './WebModal';

type Props = {
  isVisible: boolean;
  shouldDismissWebModal: () => void;
};

const OnboardingModal: FC<Props> = ({ isVisible, shouldDismissWebModal }) => {
  return (
    <WebModal
      isVisible={isVisible}
      shouldDismissWebModal={shouldDismissWebModal}
      content={<OnboardingScreen shouldDismissWebModal={shouldDismissWebModal} />}
    />
  );
};

export default OnboardingModal;

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    alignItems: 'center',
  },
  container: {
    width: 500,
    backgroundColor: ANColor.primary100,
    maxHeight: '60%',
  },
});
