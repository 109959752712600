import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ANSafeAreView from '../../../component/ANSafeAreView.native';
import FooterOverlayButton from '../../../component/FooterOverlayButton';
import SnackMessageContext from '../../../context/SnackMessageContext';
import UserContext from '../../../context/UserContext';
import ANLastReviews from '../../../model/ANLastReviews';
import { ANColor } from '../../../theme/ANColor';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';
import { EventKey } from '../../../util/analytics';
import { openUrlInBrowser } from '../../../util/utils';
import HealthCheckupQuestions from './HealthCheckupQuestions';
import HealthCheckupThanks from './HealthCheckupThanks';

/**
 * Ecran permettant de faire saisir à l'utilisateur l'évolution de ses symptômes.
 */

type Props = {
  shouldDismissWebModal?: (eventKey?: string) => void;
};
const HealthCheckUpScreen = ({ shouldDismissWebModal }: Props) => {
  const navigation = isDevice() ? useNavigation() : undefined;

  const { user, updateLastReviews } = React.useContext(UserContext);

  const mainReason = useMemo(() => {
    return user?.trainingPlaylist?.mainReason;
  }, [user]);

  const otherReasons = useMemo(() => {
    return user?.trainingPlaylist?.reasons;
  }, [user]);

  /**
   * To switch between the form view and the "Thank you" view state
   */
  const [isReportSent, setIsReportSent] = React.useState(false);
  const { bottom } = isDevice() ? useSafeAreaInsets() : { bottom: 0 };

  const { displaySnackBarError } = useContext(SnackMessageContext);

  const onReportSent = useCallback((result?: ANLastReviews) => {
    console.log('onReportSent');
    if (result) {
      updateLastReviews(result);
      setIsReportSent(true);
      console.log('Sent ', result);
    } else {
      displaySnackBarError();
    }
  }, []);

  const onShouldDismiss = () => {
    if (shouldDismissWebModal && !isDevice()) {
      console.log('shouldDismissWebModal');
      shouldDismissWebModal();
    } else {
      navigation?.getParent()?.goBack();
    }
  };

  const displayRate = (value?: number) => {
    if (value === undefined) {
      return ''; //Warning,do not use ternary operator here, because 0 is considered as undefined (wtf?)
    } else {
      return ' : ' + value + ' / 10';
    }
  };

  const openOsteoAppointment = useCallback(async () => {
    const url = user?.osteo?.urlDoctolib;

    openUrlInBrowser(url);
  }, [user]);

  const questionnaryCheckupRef = useRef();

  return (
    <ANSafeAreView style={[ANLayout.screenContaineNoPadding, styles.container]} ignoreTopInset>
      <ScrollView>
        {!isReportSent && (
          <HealthCheckupQuestions
            ref={questionnaryCheckupRef}
            mainReason={mainReason}
            otherReasons={otherReasons}
            onCheckupPosted={onReportSent}
          />
        )}
      </ScrollView>
      {isReportSent && (
        <HealthCheckupThanks
          onShouldDismiss={onShouldDismiss}
          onTakeAppointementTapped={openOsteoAppointment}
        />
      )}
      {isDevice() && (
        <FooterOverlayButton
          onPress={() => {
            if (isReportSent) {
              onShouldDismiss();
            } else {
              questionnaryCheckupRef.current?.submit();
            }
          }} // handleSubmit(postCheckup)
          useAbsoluteOverlay
          type={isReportSent ? 'done' : 'send'}
          paddingBottom={bottom}
          eventKey={EventKey.health_checkup_validate}
        />
      )}
    </ANSafeAreView>
  );
};

export default HealthCheckUpScreen;

const styles = StyleSheet.create({
  container: {
    paddingTop: MetricsSizes.regular,
    justifyContent: 'center',
  },
  subContainer: {
    paddingHorizontal: 24,
  },
  scrollContent: {
    paddingBottom: 200,
  },
  title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.regular,
  },
  symptom: {
    textAlign: 'center',
    marginBottom: MetricsSizes.big,
  },
  ratingContainer: {
    flex: 1,
  },
  webButton: {
    marginTop: MetricsSizes.big,
  },
  slider: {
    marginVertical: MetricsSizes.singlePadding,
    marginHorizontal: MetricsSizes.singlePadding,
  },
});
