import React, { FC, useContext, useMemo } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';
import ExpoIcon, { ExpoIconDesign } from '../../../../component/ExpoIcon';
import OsteoContainer from '../../../../component/OsteoContainer';
import TrainingAttribution from '../../../../component/TrainingAttribution';
import UserContext from '../../../../context/UserContext';
import I18n from '../../../../i18n';
import { ANColor } from '../../../../theme/ANColor';
import ANFont from '../../../../theme/ANFont';
import ANLayout from '../../../../theme/ANLayout';
import { MetricsSizes } from '../../../../theme/ANMetrics';
import { osteoAvatarURL } from '../../../../util/osteo';

type Props = {
  title?: string;
  duration?: number;
  numberOfCompletion?: number;
  style?: ViewStyle;
};

const TrainingPresentationHeader: FC<Props> = ({ title, duration, numberOfCompletion, style }) => {
  const { user } = useContext(UserContext);

  const attributionViewStyle = () => {
    return [ANLayout.rowHCenter, styles.records];
  };

  const displayName = useMemo(() => {
    if (user?.osteo?.academieName !== undefined) {
      //For académies, we use the academie name
      return user?.osteo?.academieName;
    } else {
      //Else, we use the osteo name
      return user?.osteo?.displayname;
    }
  }, [user]);

  const avatarURL = useMemo(() => {
    //Will return the osteo avatar, or center or academie avatar
    return osteoAvatarURL(user?.osteo);
  }, [user]);

  return (
    <Animated.View style={[styles.containerView, style]}>
      {title && <Text style={ANFont.h4Bold}>{I18n.t('training.screen_title')}</Text>}
      <OsteoContainer
        style={styles.osteo}
        osteoName={displayName}
        avatarURL={avatarURL}
        introductionLabel={I18n.t('training.recommended_by')}
      />

      <View style={attributionViewStyle()}>
        <TrainingAttribution
          icon={<ExpoIcon name="timer" />}
          label={I18n.t('training.duration', {
            minutes: duration,
          })}
        />
        <TrainingAttribution
          icon={<ExpoIcon name="medal-outline" design={ExpoIconDesign.MaterialCommunity} />}
          label={I18n.t('training.completion_count', {
            count: numberOfCompletion,
          })}
        />
      </View>
    </Animated.View>
  );
};

export default TrainingPresentationHeader;

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: ANColor.primary300,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    overflow: 'hidden',
    width: '100%',
  },
  titleView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: MetricsSizes.regular,
    marginTop: 12,
  },
  osteo: {
    marginBottom: MetricsSizes.regular,
    marginTop: MetricsSizes.big,
  },
  records: {
    flex: 1,
    justifyContent: 'space-between',
    marginTop: MetricsSizes.big,
    marginBottom: 24,
  },
});
