import { Api } from '../../../apiclient';
import {
  ExplorerMediaLogResponse,
  ExplorerPodcastLogResponse,
} from '../../../data/APIDataResponse';
import { parseAPIError } from '../../../data/ResponseError';

/**
 * @returns the user logs for each media, only with the most recent log for each media
 */
export const getExplorerMediaLogs = async () => {
  const path = `/explorer-logs/me`;
  try {
    const response = await Api.get(path);
    const result: ExplorerMediaLogResponse[] = response.data;
    return result;
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const getExplorerPodcastLogs = async () => {
  const path = `/podcast-logs/me`;
  try {
    const response = await Api.get(path);
    console.log('Fetched podcast logs', response.data);
    const result: ExplorerPodcastLogResponse[] = response.data;
    return result;
  } catch (err) {
    throw parseAPIError(err, path);
  }
};
