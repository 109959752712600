import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import I18n from '../../i18n';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import ANButton from '../ANButton';
import ANClearButton from '../ANClearButton';
import ExpoIcon from '../ExpoIcon';

type Props = {
  onRequestPermission: () => void;
  onDismiss: () => void;
};

const TrainingSchedulePermissionMissing: FC<Props> = ({ onRequestPermission, onDismiss }) => {
  return (
    <View style={styles.noficationsOffContainer}>
      <ExpoIcon name="notifications-off" size={50} color={ANColor.statusDanger600} />
      <Text style={styles.body}>{I18n.t('program_notifications.notifications_denied_body')}</Text>
      <ANButton
        title={I18n.t('common.allow_notifications')}
        theme="tertiary"
        onPress={onRequestPermission}
      />
      <ANClearButton
        title={I18n.t('common.maybe_later')}
        onPress={onDismiss}
        style={styles.later}
      />
    </View>
  );
};

export default TrainingSchedulePermissionMissing;

const styles = StyleSheet.create({
  noficationsOffContainer: {
    ...ANLayout.columnHCenter,
    justifyContent: 'center',
    marginBottom: 60,
  },
  body: {
    ...ANFont.textLargeBold,
    textAlign: 'center',
    marginTop: MetricsSizes.giant,
    marginBottom: MetricsSizes.regular,
  },
  later: {
    marginVertical: MetricsSizes.regular,
  },
});
