export default {
  common: {
    ok: 'OK pour moi !',
    exit_button: 'Quitter',
    resume_button: 'Reprendre',
    start_button: 'Commencer',
    continue_button: 'Continuer',
    cancel_button: 'Annuler',
    done_button: 'Terminer',
    validate_button: 'Valider',
    send_button: 'Envoyer',
    take_appointment: 'Prendre rendez-vous',
    discover_andrew: 'Découvrir Andrew®',
    my_osteo: 'Mon thérapeute',
    hello: 'Bonjour %{firstname} !',
    hello_late: 'Bonsoir %{firstname} !',
    lorem: 'Lorem ispum dolor sit amet Lorem ispum dolor sit amet Lorem ispum dolor sit amet',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    allow_notifications: 'Activer les notifications',
    maybe_later: 'Peut-être plus tard',
    invited_mode: `Bienvenue sur\nAndrew®`,
    duration_with_value: '%{minutes} mn %{seconds}',
    see_more: 'Voir plus',
    see_less: 'Voir moins',
    no_data_list: 'Aucune donnée disponible',
    seen_by: 'Vu par',
    difficulty: {
      easy: 'Débutant',
      normal: 'Intermédiaire',
      hard: 'Confirmé',
    },
    rate_the_app: 'Noter l’application',
    academy: 'Académie',
    center: 'Centre',
  },
  onboarding: {
    next_button: 'Suivant',
    prev_button: 'Précédent',
    skip_button: 'Quitter',
    done_button: 'Terminer',
    continue_button: 'Continuer',
    slide_one: {
      title: 'Bienvenue sur Andrew®',
      body: 'le thérapeute qui vous suit partout !',
    },
    slide_two: {
      title: 'Activez les notifications',
      body: 'Ne zappez pas vos exercices : autorisez Andrew® à vous envoyer des notifications !',
      action: 'Activer les notifications',
      action_already_activated: 'Notifications actives',
    },
  },
  error: {
    unknown: 'Oups, une erreur est survenue ! Vérifiez votre connexion internet et réessayez.',
  },
  auth_error: {
    email_format: "Pas si vite ! L'adresse e-mail indiquée n'est pas au bon format.",
    password_format:
      'Pas si vite ! Votre mot de passe doit contenir au minimum 8 caractères, dont au moins :\n• 1 majuscule ;\n• 1 minuscule ;\n• 1 chiffre.\nVoici les caractères spéciaux autorisés :  ! , & $ * ? - _ ( ) + # = @ % . ; :',
    email_required: 'Pas si vite ! Quelle est votre adresse e-mail ?',
    password_required: "Pas si vite ! On a besoin d'un mot de passe.",
    confirm_password_not_equals: 'Pas si vite ! Les mots de passe ne correspondent pas.',
    login_incorect: "Zut, l'identifiant ou le mot de passe est incorrect. Essayez autre chose !",
    cgu_missing: "Pas si vite ! Vous devez valider les conditions d'utilisation pour continuer.",
    error_password_same_as_email:
      'Pas si vite ! Votre mot de passe doit être différent de votre adresse e-mail.',
  },
  auth: {
    login_screen_title: 'Connexion',
    continue_as_guest: 'Continuer sans connexion',
    update_password_screen_title: 'Choisissez un mot de passe',
    password_format:
      'Le mot de passe doit contenir minimum 8 caractères comprenant au moins une majuscule, une minuscule et un chiffre.',
    validate_cgu:
      'En cochant cette case, je reconnais avoir pris connaissance des https://www.andrewapp.fr/cgu et les accepte', //ATTENTION cette url ici doit concider exactement avec celle de cgu_url
    cgu: "Conditions générales d'utilisation",
    cgu_url: 'https://www.andrewapp.fr/cgu', //ATTENTION cette url ici doit coincider exactement avec celle de validate_cgu
    reset_password_email_title:
      'Rappelez-nous votre adresse e-mail, on va réinitialiser votre mot de passe.',
    email: 'E-mail',
    password: 'Mot de passe',
    new_password: 'Nouveau mot de passe',
    password_confirm: 'Confirmer le mot de passe',
    password_placeholder: 'Mot de passe (minimum 8 caractères)',
    email_placeholder: 'Adresse e-mail',
    signin: 'Se connecter',
    forgotten_password: 'Mot de passe oublié',
    reset_password_description:
      'Saisissez votre e-mail. Si vous avez déjà créé un compte à cette adresse, on vous enverra un lien de réinitialisation par e-mail.',
    reset_password_confirmation:
      'Merci ! Si vous avez déjà créé un compte à cette adresse, vous allez recevoir un e-mail comportant un lien pour réinitialiser votre mot de passe.',
    reset_password_send_button: 'Réinitialiser le mot de passe',
    reset_password_back_login: "Retour à l'écran de connexion",
    reset_password_success_title: 'On vous a envoyé un e-mail de réinitialisation.',
    reset_password_success_description:
      "Si vous ne le trouvez pas, pensez à vérifier dans vos spams. Enfin, assurez-vous qu'un compte existe bien à cette adresse !",
    reset: 'Réinitialiser',
  },
  wrong_role: {
    title: 'Oups ! Ce n’est pas ici que ça se passe',
    description:
      'Cette application est destinée à vos patients. Pour utiliser votre compte Andrew® Pro, rendez-vous sur votre ordinateur à l’adresse\npro.andrewapp.fr\n\nÀ tout de suite !',
  },
  health_checkup_card: {
    title: "Comment vous sentez-vous aujourd'hui ?",
    button: 'Répondre au questionnaire',
    after_answer:
      'Revenez dès demain pour mettre à jour vos réponses et nous permettre de suivre votre état de santé.',
  },
  profile: {
    logout: 'Me déconnecter',
    update_password: 'Modifier mon mot de passe',
    appointments_history: 'Historique des consultations',
    training_reminder_program: "Modifier mon planning d'exercices",
    notifications_preferences: 'Notifications',
    current_password: 'Mot de passe actuel',
    password_updated: 'Entendu, on a bien mis à jour votre mot de passe',
    replay_onboard: "Revoir l'introduction",
    guest_mode:
      "Vous n'êtes pas connecté.e. Connectez-vous profiter de toutes les fonctionnalités de l'application.\n\nPour obtenir un compte, il suffit de prendre rendez-vous avec un thérapeute Andrew®.",
    delete_account_button: 'Supprimer mon compte',
    share_app: 'Partager Andrew®',
    share_app_message:
      'Découvre Andrew®, le thérapeute qui te suit partout !\nhttps://onelink.to/px26j6',
    account_deletion: {
      title: 'Suppression de votre compte',
      headline: 'Attention, cette action est irréversible',
      bullet_point_1:
        'L’historique de vos consultations sera perdu et votre thérapeute ne pourra plus accéder à l’évolution de votre état de santé',
      bullet_point_2: 'Vous n’aurez plus accès à vos exercices',
      bullet_point_3:
        'Vous n’aurez plus accès aux vidéos de nos coachs : yoga, Pilates, sophrologie, méditation, hypnothérapie...',
      cancel: 'Conserver mon compte',
      confirm_deletion_action: 'Supprimer mon compte',
      alert_delete_button: 'Supprimer définitivement',
      confirmation_label:
        'Votre compte a été supprimé avec succès. Andrew® vous dit au revoir et vous souhaite bonne route !',
    },
  },
  program_notifications: {
    title: 'Planning d’exercices',
    training_time: 'Horaire',
    add_time: 'Ajouter un horaire',
    define_hour: 'Définir l’horaire',
    update_success: 'OK, vos horaires d’exercices ont été mis à jour !',
    notifications_denied_body:
      "Oups, vous n'avez pas autorisé les notifications.\n\nRendez-vous dans les réglages de l’application et autorisez les notifications Andrew® pour recevoir vos rappels d’exercices.",
    notification_title: 'Andrew®',
    notification_body: 'Prenez quelques secondes pour faire vos exercices !',
    description: 'Andrew® vous enverra un rappel aux horaires choisis ci-dessous :',
  },
  training: {
    card: {
      title: 'Vos exercices recommandés',
      review_first: 'Notez votre consultation pour accéder à vos exercices personnalisés',
      open_my_session: 'Voir mes exercices',
      training_expired:
        'Les exercices recommandés par votre thérapeute ne sont peut-être plus adaptés à votre situation. Merci de consulter votre thérapeute pour définir de nouveaux exercices adaptés à vos besoins.',
      invited_mode_label:
        "Connectez-vous ou devenez patient d'un thérapeute Andrew® et obtenez des recommandations d'exercices personnalisés",
      missing_training_program:
        "Vous n'avez pas encore de programme d'exercices personnalisé. Votre thérapeute vous en proposera un prochainement.",
    },
    recommended_by: 'Recommandés par',
    screen_title: 'Mes exercices recommandés',
    duration: 'Durée : %{minutes} mn',
    completion_count: 'Réalisés %{count} fois',
    start_button: 'Démarrer mes exercices',
    osteo_advice: 'Conseil de votre thérapeute',
    videos_list_title: 'Vos exercices',
    videos_list_description:
      'Visionnez chaque vidéo pour bien comprendre les instructions,\npuis tapez sur « Démarrer mes exercices »',
    videos_list_restricted_description:
      'Vous pouvez réaliser vos exercices en position assise où que vous soyez.',
    prepare_for_next_exercice: 'Préparez-vous',
    exit_training: 'Souhaitez-vous vraiment interrompre cette vidéo ?',
    exercice_level: `Niveau %{level}`,
    level_updated: 'Le niveau a été mis à jour',
    expiry_date: "Disponible jusqu'au %{date}",
    switch_mode_restricted_action: 'Voir les exercices en position assise',
    switch_mode_normal_action: 'Voir la version par défaut',
    switch_level: {
      title: 'Changer de niveau',
      description:
        "Vous allez modifier le niveau de difficulté que votre thérapeute avait défini pour vous. N'hésitez pas à revenir en arrière si les exercices deviennent trop faciles ou trop difficiles !",
      no_other_level: "Cet exercice ne possède qu'un seul niveau de difficulté", //NEW
    },
    congrats: {
      title: 'Félicitations !',
      description:
        'Vous avez réalisé tous vos exercices !\n\nContinuez comme ça, votre corps vous dira merci !',
    },
  },
  review_osteo: {
    title: 'Merci pour votre consultation avec %{osteo_displayname} !',
    headline:
      "Votre thérapeute vous recommande une sélection d'exercices à effectuer en toute autonomie.",
    body: "Donnez-nous votre avis sur votre consultation avant d'accéder à vos exercices",
    action_button: 'Accéder aux vidéos',
    comment: 'Commentaire',
    comment_placeholder: '250 caractères maximum.',
    max_length_error:
      "Merci, mais c'est un peu long ! Pouvez-vous nous dire la même chose en moins de 250 caractères, s'il vous plaît ?",
  },
  checkup_review: {
    title: 'Andrew® vient aux nouvelles concernant votre %{reason}...',
    pain: 'Douleur',
    pain_subtitle:
      "Comment jugeriez-vous l'intensité de vos douleurs ? (0 : pas de douleur ; 10 : douleur insuportable).",
    frequency: 'Fréquence',
    frequency_subtitle:
      'Comment qualifierez-vous la fréquence de vos douleurs ? (0 : pas de douleur ;  10 : constantes)',
    mobility: 'Mobilité',
    mobility_subtitle:
      'Vous sentez vous limité.e dans vos mouvements ? (0 : pas du tout limité.e ; 10 : très limité.e)',
    stress: 'Stress',
    stress_subtitle:
      'Comment évaluez-vous votre niveau de stress ? (0 : pas de stress ; 10 : très stressé)', //NEW
    comment: 'Commentaire (facultatif)',
    required_field: 'Veuillez choisir une note',
    exit_modal_message:
      "Pourriez-vous prendre le temps de répondre à ce questionnaire ?\nCela aiderait votre thérapeute à suivre l'évolution de votre état pour mieux vous soigner.",
    thanks: {
      title: 'Merci pour vos retours !',
      body: 'Si besoin, vous pouvez prendre rendez-vous avec votre thérapeute',
      contact_button: 'Prendre rendez-vous',
    },
  },
  difficulty_check: {
    title: 'Comment avez-vous trouvé les exercices ?',
    too_easy: 'Trop faciles',
    normal: 'Parfaits pour moi',
    too_hard: 'Trop difficiles',
    too_hard_screen_title: 'Quel(s) exercice(s) avez-vous trouvé(s) trop difficile(s) ?',
    too_easy_screen_title: 'Quel(s) exercice(s) avez-vous trouvé(s) trop facile(s) ?',
    remark: 'Cela nous aide à améliorer nos exercices',
    exit_modal_message:
      'Pourriez-vous prendre le temps de répondre à ce questionnaire ?\nCela nous aide à vous proposer les meilleurs exercices possibles.',
    thanks: {
      title: 'Merci pour votre retour !',
      subtitle: 'Le saviez-vous ?',
      body: 'Certains exercices peuvent être adaptés selon différents niveaux de difficulté.\n\nPour modifier la difficulté des exercices, rendez-vous dans vos exercices et tapez sur le niveau',
    },
  },
  bottombar: {
    home: 'Accueil',
    explorer: 'Explorer',
    profile: 'Profil',
    appointment: 'Rendez-vous',
  },
  osteo_advice_card: {
    title: 'Le conseil de mon thérapeute',
    invited_mode:
      'Pour bénéficier de conseils personnalisés, veuillez d’abord prendre rendez-vous avec un thérapeute Andrew®.',
  },
  explorer: {
    title: 'Explorer',
    mind: "L'esprit",
    body: 'Le corps',
    podcasts: 'Podcasts',
    podcasts_bis: 'Podcasts',
    podcasts_card: 'Podcasts et émissions',
    play_audio: 'Lire l’audio',
    my_favorites: 'Mes favoris',
    favorites_empty: "Vos médias favoris s'afficheront ici",
    freshest: 'Notre sélection',
    author_url_button: 'Lien', //Attention garder le minimum de caractère ici, max 8 caractère. Note une icone WEB aide à comprendre le contexte
    add_favorite_label:
      "Marquez les contenus de l'onglet « Explorer » en favoris et retrouvez-les facilement ici !",
  },
  app_promotion_invited: {
    title: 'Bienvenue sur Andrew®,',
    headline: 'le thérapeute qui vous suit partout !',
    unlock_all_features_title: 'Débloquez toutes les fonctionnalités',
    unlock_all_features_headline:
      "Pour profiter de tous les contenus et fonctionnalités de l'application, rendez-vous chez un thérapeute Andrew®.",
    join_andrew_title: 'Andrew®, c’est ...',
    andrew_benefit_1:
      'une application pensée pour maximiser les bénéfices de votre consultation et améliorer votre santé',
    andrew_benefit_2:
      "des recommandations d'exercices personnalisés pour chaque patient et un suivi post-consultation",
    andrew_benefit_3:
      'des centaines de vidéos exclusives et des podcasts pour booster votre bien-être',
    andrew_benefit_4: 'un service exclusivement réservé aux patients des thérapeutes Andrew®',
    maybe_later: 'Peut-être plus tard',
    discover: 'Trouver un thérapeute Andrew®',
  },
  breath_exercice: {
    title: 'Cohérence cardiaque',
    description: "Inspirez et expirez au rythme de l'animation pour vous aider à vous détendre.",
    define_minute: 'Définir la durée',
    action: 'Commencer',
    congrats: {
      title: 'Félicitations !',
      description:
        "Revenez souvent pour réaliser cet exercice !\n\nOn conseille entre 2 et 3 séances de cohérence cardiaque chaque jour pour diminuer le stresse et l'anxiété.",
    },
  },
  update_app: {
    title: "Mettez à jour l'application",
    body: "Andrew® évolue ! Téléchargez la nouvelle version de l'application pour accéder aux dernières fonctionnalités développées pour vous.",
    update: 'Mettre à jour',
  },
  web_download_mobile_app: {
    title: 'Version mobile disponible',
    description:
      "L'application Andrew® est téléchargeable sur votre mobile depuis depuis l'App Store et le PlayStore.",
    action: 'Télécharger',
  },
  tag: {
    first_trimester: '1er trimestre',
    second_trimester: '2ème trimestre',
    third_trimester: '3ème trimestre',
  },
};
