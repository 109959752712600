import React, { FC, useMemo } from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { Colors } from 'react-native-paper';
import { ANColor } from '../theme/ANColor';
import { ANFontFamily } from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import { formatAnalyticsEvent, logEvent } from '../util/analytics';
import { hapticSelection } from '../util/utils';

type Props = {
  title: string;
  eventKey?: string;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  cornerType?: 'full' | 'light';
};

const ANClearButton: FC<Props> = ({ title, eventKey, style, titleStyle, cornerType, onPress }) => {
  const containerStyle = [styles.container, style];
  if (cornerType === 'light') containerStyle.push(styles.lightCorner);

  const formattedAnalyticsEvent: string | undefined = useMemo(() => {
    if (!eventKey) return undefined;
    return formatAnalyticsEvent(eventKey);
  }, [eventKey]);

  const pressed = () => {
    hapticSelection();
    onPress();
    if (formattedAnalyticsEvent) logEvent(formattedAnalyticsEvent);
  };

  return (
    <View style={containerStyle}>
      <TouchableOpacity onPress={pressed}>
        <Text style={[styles.title, titleStyle]}>{title}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Colors.transparent,
  },
  lightCorner: {
    borderRadius: MetricsSizes.cornerRadius,
  },
  title: {
    fontSize: 16,
    fontFamily: ANFontFamily.MontserratSemiBold,
    paddingBottom: 0,
    textDecorationLine: 'underline',
    color: ANColor.primary500,
    alignItems: 'center',
    paddingHorizontal: 10,
    textAlign: 'center',
  },
});

export default ANClearButton;
