import { RouteProp } from '@react-navigation/native';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { getMediasById } from '../../api/request/user/explorer/getTopics';
import UserContext from '../../context/UserContext';
import useLogMedia from '../../hooks/useLogMedia';
import ANMedia from '../../model/ANMedia';
import { RootStackParamList, Route } from '../../navigation/Route';
import { logEvent } from '../../util/analytics';
import { EventKey } from './../../util/analytics';

type ScreenRouteProp = RouteProp<RootStackParamList, Route.explorerAudioPlayerScreen>;

/**
 *
 * MUST provided an internal media Id OR an external media Object
 * Internal media ID will be used to fetch the media object from the server
 * External media object will be used to play the media directly based on its external URL (podcast)
 * Optionnaly, if the media data is already known when opening the modal, it can be passed as a second parameter
 * @returns
 */
const useExplorerAudioScreen = (id?: number, knownMedia?: ANMedia) => {
  const media = useRef<ANMedia | undefined>(knownMedia);

  useEffect(() => {
    logEvent(EventKey.listen_to_audio, { item_id: id ?? media.current?.id });
  }, []);

  const { saveLogMedia, saveLogPodcast } = useLogMedia();

  //Init the media to be the optional podcast passed by the parent.
  //If no podcast, then use the id to fetch the media from the server.

  const { refetch } = useQuery(['media', id], () => getMediasById(id), {
    onSuccess(data) {
      console.log('Media fetched');
      media.current = data;
    },
    enabled: false,
  });

  if (!media.current) {
    console.log('No media passed, will fetch from server');
    refetch();
  }

  const { isInvitedMode } = useContext(UserContext);
  const positionInMilli = useRef<number>(0);

  const mediaFinished = useCallback(
    (positionMillis: number) => {
      if (!media.current) return;
      if (media.current.type === 'podcast') {
        saveLogPodcast({
          title: media.current.title,
          topicId: media.current.topicId,
          timeFlagInMilli: positionMillis,
          completed: true,
          isAuthenticated: !isInvitedMode,
        });
      } else {
        saveLogMedia({
          mediaId: media.current.id,
          timeFlagInMilli: positionMillis,
          completed: true,
          isAuthenticated: !isInvitedMode,
        });
      }
    },
    [media.current, positionInMilli, isInvitedMode],
  );

  const updatePositionInMillis = (milli: number) => {
    positionInMilli.current = milli;
  };

  const audioUrl = useMemo(() => {
    const url = media.current?.url;
    return url;
  }, [media.current]);

  const onCancelPlayer = useCallback(() => {
    if (media.current === undefined) return;
    console.log('Media type to log: ', media.current.type);
    console.log('timeFlag ', positionInMilli.current);

    if (media.current?.type === 'podcast') {
      saveLogPodcast({
        title: media.current.title,
        topicId: media.current.topicId,
        timeFlagInMilli: positionInMilli.current ?? 0,
        completed: false,
        isAuthenticated: !isInvitedMode,
      });
    } else {
      saveLogMedia({
        mediaId: media.current.id,
        timeFlagInMilli: positionInMilli.current ?? 0,
        completed: false,
        isAuthenticated: !isInvitedMode,
      });
    }
  }, [media.current, positionInMilli, isInvitedMode]);

  return {
    media: media.current,
    audioUrl,
    positionInMilli,
    updatePositionInMillis,
    mediaFinished,
    onCancelPlayer,
  };
};

export default useExplorerAudioScreen;
