/**
 * Metrics Sizes
 */

export const MetricsSizes = {
  small: 8,
  singlePadding: 10,
  regular: 16,
  big: 24,
  extraLarge: 32,
  giant: 48,
  cornerRadius: 6,
  webCornerRadius: 10,
  largeCornerRadius: 10,
  headerCornerRadius: 30,
  screenHorizontalPadding: 16,
  itemMaximumWidth: 800,
};
