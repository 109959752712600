import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { FC } from 'react';
import { ExpoIconPressable } from '../component/ExpoIcon';
import DifficultyReportExercicePickerScreen from '../screen/home/DifficultyReport/DifficultyReportExercicePickerScreen';
import DifficultyReportScreen from '../screen/home/DifficultyReport/DifficultyReportScreen';
import DifficultyReportThanksScreen from '../screen/home/DifficultyReport/DifficultyReportThanksScreen';
import HealthCheckUpScreen from '../screen/home/HealthCheckUp/HealthCheckUpScreen';
import TrainingCongratsScreen from '../screen/home/TrainingSession/TrainingCongratsScreen';
import { ANColor } from '../theme/ANColor';
import { EventKey } from '../util/analytics';
import { RootStackParamList, Route } from './Route';
import { screenOptions } from './options';

const Stack = createNativeStackNavigator<RootStackParamList>();

export type TrainingEndNavigatorProps = {
  shouldDismissWebModal: (eventKey?: string) => void;
};

export const TrainingSessionEndNavigator: FC<TrainingEndNavigatorProps> = ({
  shouldDismissWebModal,
}) => {
  return (
    <NavigationContainer>
      <Stack.Navigator screenOptions={{ ...screenOptions, headerTitle: '' }}>
        <Stack.Screen
          name={Route.trainingCongratsScreen}
          component={TrainingCongratsScreen}
          options={{ headerShown: false }}
          initialParams={{ shouldDismissWebModal }}
        />
        <Stack.Screen
          name={Route.checkUpReviewScreen}
          component={HealthCheckUpScreen}
          options={{
            headerStyle: {
              backgroundColor: ANColor.primary100,
            },
            headerRight: () => (
              <ExpoIconPressable
                name="close"
                onPress={() => {
                  console.log('on press close', shouldDismissWebModal);
                  if (shouldDismissWebModal !== undefined) {
                    console.log('should dismiss web modal');
                    shouldDismissWebModal(EventKey.health_checkup_cancelled);
                  }
                }}
              />
            ),
          }}
        />

        <Stack.Screen
          name={Route.difficultyCheckScreen}
          component={DifficultyReportScreen}
          options={{
            headerRight: () => (
              <ExpoIconPressable
                name="close"
                onPress={() => {
                  if (shouldDismissWebModal) {
                    shouldDismissWebModal(EventKey.difficulty_review_cancelled);
                  }
                }}
              />
            ),
          }}
        />

        <Stack.Screen
          name={Route.difficultyExercicePickerScreen}
          component={DifficultyReportExercicePickerScreen}
          options={{
            headerRight: () => (
              <ExpoIconPressable
                name="close"
                onPress={() => {
                  if (shouldDismissWebModal) {
                    shouldDismissWebModal(EventKey.difficulty_review_cancelled);
                  }
                }}
              />
            ),
          }}
        />

        <Stack.Screen
          name={Route.difficultyCheckThanksScreen}
          component={DifficultyReportThanksScreen}
          initialParams={{ shouldDismissWebModal }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};
