export const withOpacity = (percentage: number, color: string): string => {
  if (color.length !== 7) {
    throw new Error('color parameter should be a valid hexadecimal value eg: #FFFFFF');
  }
  const tempHex = color.replace('#', '');
  const r = parseInt(tempHex.substring(0, 2), 16);
  const g = parseInt(tempHex.substring(2, 4), 16);
  const b = parseInt(tempHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${percentage / 100})`;
};

export const ANColor = {
  primary500: '#ef7560',
  primary100: '#feeddf',
  primary200: '#fdd7c0',
  primary700: '#ac3031',
  primary800: '#8a1e28',
  primary900: '#721222',
  primary400: '#f5a087',
  primary300: '#fabba0',
  primary600: '#cd4f46',
  secondary100: '#f1fdf3',
  secondary200: '#e4fbea',
  secondary300: '#d2f3de',
  secondary600: '#7ab9a4',
  secondary400: '#c1e7d2',
  secondary500: '#a8d8c3',
  secondary700: '#549b8a',
  secondary800: '#357d73',
  secondary900: '#206764',
  tertiary100: '#f9e9da',
  tertiary500: '#974837',
  tertiary200: '#f4cfb6',
  tertiary300: '#dfa68a',
  tertiary800: '#571115',
  tertiary600: '#813128',
  tertiary900: '#480a12',
  tertiary700: '#6c1d1b',
  tertiary400: '#c07b64',
  commonWhite: '#ffffff',
  commonBlack: '#000000',
  common500: '#5c5d5f',
  common100: '#f5f6f7',
  common200: '#ebedee',
  common300: '#cbcccf',
  common400: '#9b9c9f',
  common700: '#2e3444',
  common600: '#434751',
  common800: '#1d2337',
  common900: '#11172d',
  statusValid500: '#107c30',
  statusValid100: '#d1f8cd',
  statusValid200: '#9df19d',
  statusValid400: '#3db054',
  statusValid300: '#66d772',
  statusValid600: '#0b6a31',
  statusValid700: '#085930',
  statusValid800: '#05472c',
  statusValid900: '#033b29',
  statusDanger500: '#d16704',
  statusDanger100: '#fcedcb',
  statusDanger200: '#fad698',
  statusDanger300: '#f1b663',
  statusDanger400: '#e3953c',
  statusDanger600: '#b34f02',
  statusDanger700: '#963a02',
  statusDanger800: '#792801',
  statusDanger900: '#641c00',
  statusWarning500: '#cc202e',
  statusWarning100: '#fcddd1',
  statusWarning200: '#f9b5a5',
  statusWarning300: '#ef8276',
  statusWarning400: '#e05352',
  statusWarning600: '#af1731',
  statusWarning700: '#921032',
  statusWarning800: '#760a30',
  statusWarning900: '#61062e',
  buttonBackgrounOverlay: withOpacity(30, '#11172D'),
  starRatingFillColor: '#f1b663',
  sliderLinear: ['#10A10D', '#E0B20D', '#970808'],
  grayToTransparendOverlay: ['#000000', withOpacity(0, '#000000')],
};
