import { RouteProp, useRoute } from '@react-navigation/native';
import { useContext, useEffect, useMemo, useState } from 'react';
import { LayoutAnimation } from 'react-native';
import { useQuery } from 'react-query';
import { mediaDifficulties } from '../../api/data/APIDataResponse';
import { getTopic } from '../../api/request/user/explorer/getTopics';
import SnackMessageContext from '../../context/SnackMessageContext';
import useExplorerMediaPlayer from '../../hooks/useExplorerMedia';
import useFavoriteStore from '../../hooks/useFavoritesStore';
import { ANAuthor } from '../../model/ANAuthor';
import ANMedia from '../../model/ANMedia';
import { RootStackParamList, Route } from '../../navigation/Route';
import { EventKey, logEvent } from '../../util/analytics';

type ScreenRouteProp = RouteProp<RootStackParamList, Route.explorerTopicScreen>;

const useExplorerTopicScreen = () => {
  const {
    params: { id },
  } = useRoute<ScreenRouteProp>();

  const favoritesIds = useFavoriteStore(state => state.favoritesIds);

  const { displaySnackBarError } = useContext(SnackMessageContext);
  const {
    favoriteTapped,
    onMediaPressed,
    videoToPlay,
    onVideoExit,
    isVideoWebModalVisible,
    setVideoWebModalVisible,
    onVideoTapped,
    audioToPlayWeb,
    onAudioExit,
  } = useExplorerMediaPlayer();

  ///Generate a random key to reload cache each time, in case a media timeFlag has changed

  const { isLoading, data: topic } = useQuery(['getTopic', id], () => getTopic(id), {
    onError: error => displaySnackBarError(),
  });

  useEffect(() => {
    logEvent(EventKey.opened_topic_screen, { item_id: id });
  }, []);

  const [filteredMedia, setFilteredMedia] = useState<ANMedia[] | undefined>(undefined);
  const [selectedAuthor, setSelectedAuthor] = useState<ANAuthor | undefined>(undefined);
  const [selectedDifficulty, setSelectedDifficulty] = useState<string | undefined>(undefined);
  const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);

  /**
   * Extract all authors from current medias. Filter to make sure we don't have duplicates.
   */
  const authors: ANAuthor[] | undefined = useMemo(() => {
    const nonNullAuthor = topic?.medias
      ?.map(item => item.author)
      ?.filter((item): item is ANAuthor => item !== undefined); //Remove undefined authors

    const uniqueAuthors = nonNullAuthor?.filter(
      (value, index, self) => index === self.findIndex(t => t?.id === value?.id),
    );
    return uniqueAuthors;
  }, [topic]);

  /**
   * A default author is set if there's only one author in the current medias.
   * Else return undefined.
   */
  const defaultAuthor = useMemo(() => {
    if (authors && authors?.length == 1) {
      return authors[0];
    }
    return undefined;
  }, [topic]);

  const difficulties = useMemo(() => {
    console.log('update levels for selected');
    var medias;
    if (selectedAuthor) {
      medias = topic?.medias?.filter(media => media.author?.id == selectedAuthor?.id);
    } else {
      medias = topic?.medias;
    }
    const levels = medias?.map(media => media.difficulty);

    if (levels) {
      const existingLevels = mediaDifficulties
        .filter(level => levels?.includes(level))
        .map(level => String(level));
      //If an author is selected and has no media matching the selected tag, add it in the available list anyway, so the user can unselect it.
      if (selectedDifficulty && !existingLevels?.includes(selectedDifficulty)) {
        existingLevels?.push(selectedDifficulty);
      }
      return existingLevels;
    }
  }, [topic, selectedAuthor, selectedDifficulty]);

  /**
   * Videos can have custom tags. Extract them from the current medias.
   */
  const otherTags = useMemo(() => {
    var medias;
    if (selectedAuthor) {
      medias = topic?.medias?.filter(media => media.author?.id == selectedAuthor?.id);
    } else {
      medias = topic?.medias;
    }

    //Remove undefined tags, and map to create a list of available tags.
    const tags = medias?.filter(media => media.tag).map(media => media.tag);

    if (tags) {
      const existingTags = topic?.availableTags?.filter(tag => tags?.includes(tag));
      //If an author is selected and has no media matching the selected tag, add it in the available list anyway, so the user can unselect it.
      if (selectedTag && !existingTags?.includes(selectedTag)) {
        existingTags?.push(selectedTag);
      }
      return existingTags;
    }
  }, [topic, selectedAuthor, selectedTag]);

  const title = useMemo(() => {
    return topic?.title;
  }, [topic]);

  const infoUrl = useMemo(() => {
    return topic?.infoUrl;
  }, [topic]);

  /**
   * Filter medias by author.
   * @param author optional author to filter by. If undefined, clear filter
   */
  const authorSelected = (author?: ANAuthor) => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    if (!author || author.id === selectedAuthor?.id) {
      setFilteredMedia(undefined);
      setSelectedAuthor(defaultAuthor);
    } else {
      setSelectedAuthor(author);
    }
  };

  /**
   * Filter medias by difficulty.
   * @param difficulty
   */
  const difficultySelected = (difficulty?: string) => {
    if (!difficulty || difficulty === selectedDifficulty) {
      setFilteredMedia(undefined);
      setSelectedDifficulty(undefined);
    } else {
      setSelectedDifficulty(difficulty);
    }
  };

  /**
   * Filter medias by tag.
   * @param tag
   */
  const tagSelected = (tag?: string) => {
    if (!tag || tag === selectedTag) {
      setFilteredMedia(undefined);
      setSelectedTag(undefined);
    } else {
      setSelectedTag(tag);
    }
  };

  useEffect(() => {
    setFilteredMedia(
      topic?.medias?.filter(media => {
        let tagOrDifficultyFilter = true;

        if (selectedTag && selectedDifficulty) {
          tagOrDifficultyFilter =
            media.tag === selectedTag && media.difficulty === selectedDifficulty;
        } else if (selectedDifficulty) {
          tagOrDifficultyFilter = media.difficulty === selectedDifficulty;
        } else if (selectedTag) {
          tagOrDifficultyFilter = media.tag === selectedTag;
        }

        let authorCondition = true;
        if (selectedAuthor) {
          authorCondition = media.author?.id === selectedAuthor.id;
        }

        return tagOrDifficultyFilter && authorCondition;
      }),
    );
  }, [selectedAuthor, selectedDifficulty, selectedTag]);

  return {
    topic,
    isLoading,
    userFavorites: favoritesIds,
    title,
    infoUrl,
    difficulties,
    otherTags,
    filteredMedia,
    difficultySelected,
    selectedDifficulty,
    selectedTag,
    tagSelected,
    authors,
    selectedAuthor,
    defaultAuthor,
    authorSelected,
    favoriteTapped,
    onMediaPressed,
    videoToPlay,
    onVideoExit,
    isVideoWebModalVisible,
    setVideoWebModalVisible,
    onVideoTapped,
    audioToPlayWeb,
    onAudioExit,
  };
};

export default useExplorerTopicScreen;
