import React, { FC, ReactNode } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { padding } from '../util/padding';

type Props = {
  icon: ReactNode;
  label: string;
};

const TrainingAttribution: FC<Props> = ({ icon, label }) => {
  return (
    <View style={[ANLayout.rowCenter, ANLayout.roundedBackground, styles.scoreAndDuration]}>
      {icon}
      <Text style={[ANFont.textMediumRegular, styles.timerAndCompletionLabel]}>{label}</Text>
    </View>
  );
};

export default TrainingAttribution;

const styles = StyleSheet.create({
  scoreAndDuration: {
    ...padding(MetricsSizes.small),
    backgroundColor: ANColor.primary100,
  },
  timerAndCompletionLabel: {
    marginLeft: MetricsSizes.small,
  },
});
