import React, { FC, useCallback, useContext, useMemo } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import ANButton from '../../../../component/ANButton';
import ExpandableText from '../../../../component/ExpandableText';
import OsteoContainer from '../../../../component/OsteoContainer';
import UserContext from '../../../../context/UserContext';
import I18n from '../../../../i18n';
import { ANColor } from '../../../../theme/ANColor';
import { default as ANFont } from '../../../../theme/ANFont';
import Layout from '../../../../theme/ANLayout';
import { MetricsSizes } from '../../../../theme/ANMetrics';
import { isDevice } from '../../../../util/Device';
import {
  osteoAppointmentURL,
  osteoAvatarURL,
  osteoMainDisplayName,
  osteoShouldDisplayOsteoName,
} from '../../../../util/osteo';
import { padding } from '../../../../util/padding';
import { openUrlInBrowser } from '../../../../util/utils';
import OsteoAdviceGuestMode from './OsteoAdviceGuestMode';

type Props = {
  style?: ViewStyle;
};

const OsteoAdviceCard: FC<Props> = ({ style }) => {
  const { user, isInvitedMode } = useContext(UserContext);

  /**
   * The avatar to display in the card. Depending on the context, it will be the therapist avatar, or the academie avatar, or the center avatar.
   */
  const avatarURL = useMemo(() => {
    return osteoAvatarURL(user?.osteo);
  }, [user]);

  /**
   * The main name to display in the card. Depending on the context, it will be the therapist name, or the academie name, or the center name.
   */
  const mainDisplayName = useMemo(() => {
    return osteoMainDisplayName(user?.osteo);
  }, [user]);

  /**
   * If the last appointment was made in a center or an academie, we'll add an extra label to give the therapist name in addition of the center/academie name
   */
  const displaySubtitle = useMemo(() => {
    return osteoShouldDisplayOsteoName(user?.osteo);
  }, [user]);

  /**
   * The header label, giving context about the main name (academie, center, or therapist)
   */
  const introductionLabel = useMemo(() => {
    if (user?.osteo?.academieName !== undefined) {
      return I18n.t('common.academy');
    }

    if (user?.osteo?.centerName !== undefined) {
      return I18n.t('common.center');
    }

    return undefined;
  }, [user]);

  /**
   * The URL to open when the user wants to take an appointment
   */
  const takeAppointmentURL = useMemo(() => {
    return osteoAppointmentURL(user?.osteo);
  }, [user]);

  /**
   * On tap on the button, we open the URL in the browser
   */
  const openOsteoAppointment = useCallback(async () => {
    const url = takeAppointmentURL;
    openUrlInBrowser(url);
  }, [user]);

  const advice = useMemo(() => {
    return user?.lastMeeting?.osteoAdvice;
  }, [user]);

  return (
    <View style={[Layout.card, styles.card, style]}>
      {isInvitedMode ? (
        <OsteoAdviceGuestMode />
      ) : (
        <View style={styles.osteoWithAdviceContainer}>
          <OsteoContainer
            osteoName={mainDisplayName}
            avatarURL={avatarURL}
            introductionLabel={introductionLabel}
          />
          {displaySubtitle && (
            <Text style={[styles.osteoSubtitleName, ANFont.textSmallBold]}>
              {'Vu par ' + user?.osteo?.displayname}
            </Text>
          )}
          <ANButton
            title={I18n.t('common.take_appointment')}
            theme="secondary"
            eventKey="osteo_take_appointment"
            style={styles.takeAppointment}
            onPress={openOsteoAppointment}
          />
          {advice !== null && advice !== undefined && advice !== '' && (
            <View>
              <Text style={[ANFont.h6Bold, styles.adviceTitle]}>
                {I18n.t('osteo_advice_card.title')}
              </Text>
              <ExpandableText
                text={user?.lastMeeting?.osteoAdvice}
                wrapperStyle={{ marginBottom: MetricsSizes.regular }}
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default OsteoAdviceCard;

const styles = StyleSheet.create({
  card: {
    borderWidth: 1,
    flex: undefined,
    width: isDevice() ? undefined : 350,
    backgroundColor: ANColor.secondary100,
    borderColor: ANColor.secondary500,
    ...padding(MetricsSizes.regular),
  },
  adviceTitle: {
    marginVertical: MetricsSizes.regular,
  },
  takeAppointment: {
    marginTop: MetricsSizes.regular,
    alignSelf: 'center',
  },
  osteoWithAdviceContainer: {
    // flex: 1,
    // justifyContent: 'space-between',
  },
  osteoSubtitleName: {
    marginVertical: MetricsSizes.small,
  },
});
