import React, { FC, useContext } from 'react';
import { StyleSheet } from 'react-native';
import ANVideoView from '../../../component/ANVideoView';
import WebModal from '../../../component/modal/WebModal';
import UserContext from '../../../context/UserContext';
import useLogMedia from '../../../hooks/useLogMedia';
import useWebModaleVideoPlayer from '../../../hooks/useWebModaleVideoPlayer';
import { padding } from '../../../util/padding';
import useWebVideoPlayer from './useWebVideoPlayerModal';

export type Props = {
  uri: string;
  mediaId: number;
  timeFlag?: number;
  onExit?: (positionMillis?: number, completed?: boolean) => void;
  modalVisible?: boolean;
  shouldDismiss?: () => void;
};

const VideoWebPlayerModal: FC<Props> = ({
  uri,
  mediaId,
  modalVisible = true,
  timeFlag,
  shouldDismiss,
}) => {
  const { saveLogMedia } = useLogMedia();
  const { isInvitedMode } = useContext(UserContext);

  const dismissSafelyModal = (completed: boolean) => {
    const positionMillis = videoRef.current?.currentTime * 1000;
    if (shouldDismiss) {
      shouldDismiss();
      saveLogMedia({
        mediaId: mediaId,
        timeFlagInMilli: positionMillis,
        completed: completed,
        isAuthenticated: !isInvitedMode,
      });
    }
  };

  const { videoRef } = useWebModaleVideoPlayer();

  useWebVideoPlayer({
    uri,
    videoRef,
  });

  return (
    <WebModal
      isVisible={modalVisible}
      shouldDismissWebModal={() => dismissSafelyModal(false)}
      contentContainerStyle={[styles.container]}
      content={
        <ANVideoView
          videoRef={videoRef}
          useControls={true}
          shouldPlay
          timeFlagInMilli={timeFlag}
          style={{
            width: '100%',
            height: 500,
            backgroundColor: 'black',
          }}
          uri={uri}
          onPlaybackStatusUpdate={status => {
            //Dismiss the modal
            if (status.didJustFinish) {
              console.log('Status just finished', status);
              dismissSafelyModal(true);
            }
          }}
        />
      }
    />
  );
};

export default VideoWebPlayerModal;

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    alignItems: 'center',
  },
  container: {
    width: 800,
  },
  closeIconContainer: {
    position: 'absolute',
    top: 0,
    right: 8,
  },
  closeIcon: {
    ...padding(8),
  },
  videoContainer: {
    backgroundColor: 'black',
  },
});
