import { LinearGradient } from 'expo-linear-gradient';
import React, { FC, useMemo } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { ExplorerCategory } from '../../api/data/ExplorerCategory';
import ANImages from '../../assets/image/ANimages';
import ANImageView from '../../component/Image';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { padding } from '../../util/padding';

type Props = {
  category: ExplorerCategory;
  selected: boolean;
  isFiltering: boolean;
  onPress: () => void;
};

const ExplorerCategoryCard: FC<Props> = ({ category, selected, isFiltering, onPress }) => {
  const image = useMemo(() => {
    switch (category.key) {
      case 'body':
        return ANImages.BodyExplorer;
      case 'mind':
        return ANImages.MindExplorer;
      case 'podcast':
        return ANImages.PodcastExplorer;
    }
  }, []);
  return (
    <Pressable
      style={[ANLayout.roundedBackground, styles.card, selected && styles.selectedCard]}
      onPress={onPress}
    >
      <ANImageView
        style={[{ width: '100%', height: '100%', opacity: isFiltering && !selected ? 0.5 : 1 }]}
        source={image}
      />
      <LinearGradient
        colors={ANColor.grayToTransparendOverlay}
        style={styles.overlay}
        start={[0, 1]}
        end={[0, 0]}
      />
      <Text style={[ANFont.h6Bold, styles.title]}>{category.title}</Text>
    </Pressable>
  );
};

export default ExplorerCategoryCard;

const styles = StyleSheet.create({
  card: {
    width: 120,
    aspectRatio: 140 / 150,
    backgroundColor: ANColor.primary400,
    ...padding(0),
  },
  selectedCard: {
    borderColor: ANColor.primary800,
    borderWidth: 5,
  },
  title: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    right: 8,
    fontSize: 16,
    color: ANColor.commonWhite,
    alignSelf: 'center',
    textAlign: 'center',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '30%',
  },
});
