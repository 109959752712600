import React, { FC, useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { Portal } from 'react-native-paper';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import { ANColor, withOpacity } from '../theme/ANColor';
import { MetricsSizes } from '../theme/ANMetrics';
import TrainingSessionController from './TrainingSessionController';

type Props = {
  canDisplayCancel?: boolean;
  onCancelLoadingTapped?: () => void;
};

const FullLoadingOverlay: FC<Props> = ({ canDisplayCancel = true, onCancelLoadingTapped }) => {
  //Will show after x seconds a button to exit the loading screen if it takes too long.
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const timer = React.useRef<NodeJS.Timer>();

  useEffect(() => {
    timer.current = setTimeout(() => {
      setShowCancel(true);
      console.log('Show cancel');
    }, 2000);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <Portal>
      <Animated.View style={styles.loadingScreen} entering={FadeIn} exiting={FadeOut}>
        {canDisplayCancel && showCancel && (
          <TrainingSessionController
            iconName="close"
            style={styles.close}
            onPress={() => {
              if (onCancelLoadingTapped) onCancelLoadingTapped();
            }}
            iconColor={ANColor.commonWhite}
            backgroundColor={ANColor.buttonBackgrounOverlay}
          />
        )}
        <ActivityIndicator
          style={styles.activityIndicator}
          size="large"
          color={ANColor.commonWhite}
        />
      </Animated.View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  loadingScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: withOpacity(50, ANColor.commonBlack),
    zIndex: 1000,
  },
  activityIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    zIndex: 1,
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
    marginRight: MetricsSizes.screenHorizontalPadding,
    top: 40,
  },
});

export default FullLoadingOverlay;
