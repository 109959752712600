import { Audio } from 'expo-av';
import React, { FC, useEffect } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { ANSound, exerciceFinishedDurationInMs } from '../assets/audio/ANSound';
import useHour from '../hooks/useHour';
import I18n from '../i18n';
import { ANColor } from '../theme/ANColor';
import Font from '../theme/ANFont';
import Layout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import ExpoIcon from './ExpoIcon';

type Props = {
  totalTime: number; //in Milliseconds
  increment: number;
  isBuffering: boolean;
  countDownOccurence: number;
  paused?: boolean;
  prepareNextExerciceState?: boolean;
  backgroundColor?: string;
  onFinish: () => void;
};

const TrainingSessionCountDown: FC<Props> = ({
  totalTime,
  increment,
  paused = false,
  isBuffering = false,
  prepareNextExerciceState,
  countDownOccurence,
  backgroundColor = ANColor.primary200,
  onFinish,
}) => {
  const [timeLeftString, setTimeLeftString] = React.useState<string>('00:00');
  const [timeLeft, setTimeLeft] = React.useState<number>(totalTime);
  const elapsedTime = React.useRef<number>(0);
  const totalTimeRef = React.useRef<number>(totalTime);
  const timer = React.useRef<NodeJS.Timer>();
  const { createChronoString } = useHour();

  useEffect(() => {
    return () => {
      clearInterval(timer.current); //Stop timer from running on Unmount
    };
  }, []);

  useEffect(() => {
    if (paused) clearInterval(timer.current);
    else {
      timer.current = setInterval(updateTimer, increment);
    }
  }, [paused]);

  useEffect(() => {
    clearInterval(timer.current);
    elapsedTime.current = 0;
    setTimeLeft(totalTime);
    timer.current = setInterval(updateTimer, increment);
    totalTimeRef.current = totalTime;
  }, [countDownOccurence]);

  const updateTimer = () => {
    if (paused) return; //Skip increment in pause mode

    elapsedTime.current += increment;
    const remainingTime = totalTimeRef.current - elapsedTime.current;
    setTimeLeft(remainingTime);

    if (elapsedTime.current === totalTimeRef.current) {
      clearInterval(timer.current);
      onFinish();
    }
  };

  useEffect(() => {
    const chronoLabel = createChronoString(timeLeft);
    setTimeLeftString(chronoLabel);
    if (timeLeft === exerciceFinishedDurationInMs && !prepareNextExerciceState) {
      Audio.Sound.createAsync(ANSound.ExerciceEnded, { shouldPlay: true });
    }
  }, [timeLeft]);

  return (
    <View
      style={[
        Layout.rowCenter,
        Layout.roundedLabel,
        styles.container,
        { backgroundColor: backgroundColor },
      ]}
    >
      <ExpoIcon name="timer" style={styles.timerIcon} />
      {prepareNextExerciceState && (
        <Text style={[Font.h6Regular, styles.prepare]}>
          {I18n.t('training.prepare_for_next_exercice')}
        </Text>
      )}
      <Text style={[Font.h5Bold, styles.chronoLabel]}>{timeLeftString}</Text>
      {isBuffering && <ActivityIndicator color={ANColor.common700} />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
  },
  timerIcon: {
    marginRight: MetricsSizes.singlePadding,
  },
  chronoLabel: {
    fontSize: 24,
    color: ANColor.commonBlack,
  },
  prepare: {
    color: ANColor.commonBlack,
    marginRight: MetricsSizes.singlePadding,
  },
});

export default TrainingSessionCountDown;
