import React, { FC, useCallback, useMemo } from 'react';
import {
  LayoutAnimation,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import i18n from '../i18n';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import { isDevice } from '../util/Device';

type Props = {
  text: string | undefined;
  wrapperStyle?: StyleProp<ViewStyle>;
  linesToTruncate?: number;
};

const ExpandableText: FC<Props> = ({ text, wrapperStyle, linesToTruncate = 3 }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [needsExpand, setNeedsExpand] = React.useState(false);
  const [onReady, setOnReady] = React.useState(false);

  const expand = useCallback(() => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setExpanded(!expanded);
  }, [expanded]);

  /**
   * Calculate the number of line. For the first frame, we'll use number of lines illimited to know how much lines the text view requires.
   * Once the test layout is ready, we check if the number of lines exceed th elimit (3)  to know if we need an expand feature.
   */
  const numberOfLines = () => {
    if (!onReady) return 0; //to calculated the number of required lines
    return expanded ? 0 : linesToTruncate;
  };

  const shouldUseAbsoluteWhileLoading = useMemo(() => {
    if (isDevice()) {
      return !onReady;
    }
    return false;
  }, [onReady]);

  return (
    <View style={wrapperStyle}>
      <Text
        numberOfLines={numberOfLines()}
        style={[ANFont.textMediumRegular]}
        onTextLayout={event => {
          setOnReady(true);
          if (event.nativeEvent.lines.length > 3) {
            setNeedsExpand(true);
          }
        }}
      >
        {text}
      </Text>

      {text && needsExpand && onReady && (
        <TouchableOpacity onPress={expand}>
          <Text style={styles.seeMoreLess}>
            {expanded ? i18n.t('common.see_less') : i18n.t('common.see_more')}
          </Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default ExpandableText;

const styles = StyleSheet.create({
  seeMoreLess: {
    marginTop: MetricsSizes.small,
    textDecorationLine: 'underline',
  },
});
