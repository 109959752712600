import { StyleSheet } from 'react-native';
import { padding } from '../util/padding';
import { ANColor } from './ANColor';
import Font from './ANFont';
import { MetricsSizes } from './ANMetrics';

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  containerHPadding: {
    flex: 1,
    flexDirection: 'column',
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  screenContainer: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: ANColor.primary100,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    paddingBottom: MetricsSizes.regular,
  },
  screenContaineNoPadding: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: ANColor.primary100,
  },
  modalContainer: {
    paddingHorizontal: MetricsSizes.regular,
    backgroundColor: 'white',

    borderRadius: MetricsSizes.cornerRadius,
  },
  column: {
    flex: 1,
    flexDirection: 'column',
  },
  columnHCenter: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  columnVCenter: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  columnHVCenter: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  absolute: {
    position: 'absolute',
  },
  absoluteCenter: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  roundedBackground: {
    backgroundColor: ANColor.primary200,
    ...padding(MetricsSizes.singlePadding),
    borderRadius: 6,
    overflow: 'hidden',
  },
  card: {
    borderRadius: MetricsSizes.cornerRadius,
    overflow: 'hidden',
    ...padding(MetricsSizes.screenHorizontalPadding),
  },

  roundedLabel: {
    ...padding(MetricsSizes.singlePadding),
    overflow: 'hidden',
    alignSelf: 'center',
    borderRadius: 20,
  },

  title: {
    ...Font.title,
    marginBottom: MetricsSizes.small,
  },
  body: {
    ...Font.title,
    marginVertical: MetricsSizes.small,
  },

  /* Row Layouts */
  rowCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowVCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rowHCenter: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  textfield: {
    height: 55,
    backgroundColor: ANColor.commonWhite,
    elevation: 1,
  },

  tertiaryButton: {
    backgroundColor: ANColor.commonWhite,
    borderColor: ANColor.primary500,
    borderWidth: 1,
  },

  absoluteFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: ANColor.primary100,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    paddingVertical: MetricsSizes.regular,
  },
  relativeFooter: {
    width: '100%',
    backgroundColor: ANColor.primary100,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    paddingVertical: MetricsSizes.regular,
  },
  footerShadow: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    elevation: 3,
  },
});
