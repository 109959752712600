import { Audio, AVPlaybackStatus } from 'expo-av';
import React, { useCallback, useEffect, useRef } from 'react';

const useAudioController = (
  onMediaEnd: (positionMillis: number) => void,
  timeFlagInMilli?: number,
  positionMillisUpdated?: (millis: number) => void,
  audioUrl?: string,
  /**
   * The duration when provided by a Podcast. Podcast duration are not read properly by the Expo AV status totalDurationMillis. So we use the duration provided by the Podcast RSS Feed.
   */
  itunesDuration?: string,
) => {
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isSeeking, setIsSeeking] = React.useState(false);
  const [positionMillis, setPositionMillis] = React.useState(0);
  const [playableDurationMillis, setPlayableDurationMillis] = React.useState(0); //Set the maximum range the user can seek to. This is due to an iOS Bug where for long duration audio, user can only seek into the range of the playable duration.
  const audioPlayer = useRef<Audio.Sound>();
  const [totalDuration, setTotalDuration] = React.useState<number | undefined>(undefined);

  //Allows to stop moving the thumb slider when seeking (causing an animation glitch)

  useEffect(() => {
    playAudio();
    console.log('play audio ', audioUrl);
    return () => {
      audioPlayer.current?.unloadAsync();
    };
  }, [audioUrl]);

  const rewind = useCallback(() => {
    audioPlayer.current?.setPositionAsync(positionMillis - 10000);
  }, [positionMillis]);

  const forward = useCallback(() => {
    audioPlayer.current?.setPositionAsync(positionMillis + 10000);
  }, [positionMillis]);

  const onPlay = useCallback(() => {
    audioPlayer.current?.playAsync();
  }, []);

  const onPause = useCallback(() => {
    audioPlayer.current?.pauseAsync();
  }, []);

  const onSeek = async (value: number) => {
    setIsSeeking(true);
    console.log('onSeek', value);
    console.log('totalduration', totalDuration);
    try {
      await audioPlayer.current?.pauseAsync();
      await audioPlayer.current?.setPositionAsync(value);
      await audioPlayer.current?.playAsync();
    } catch {
      console.log('error while seeking');
    }
    console.log('seek ended');
    setIsSeeking(false);
  };

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      if (!totalDuration) {
        const duration = status.durationMillis;
        //if not already set, set the total duration state
        if (duration) setTotalDuration(duration);
      }

      const playbaleMillis = status.playableDurationMillis;
      if (playbaleMillis && playbaleMillis !== playableDurationMillis) {
        setPlayableDurationMillis(playbaleMillis);
      }

      if (positionMillisUpdated) positionMillisUpdated(status.positionMillis);

      if (status.isPlaying) {
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }

      if (!isSeeking) {
        setPositionMillis(status.positionMillis);
      }

      if (status.didJustFinish) {
        onMediaEnd(status.positionMillis);
      }
    }
  };

  const playAudio = useCallback(async () => {
    if (audioUrl === undefined) return;
    console.log('Loading Sound');
    const { sound } = await Audio.Sound.createAsync(
      { uri: audioUrl },
      { shouldPlay: true, positionMillis: timeFlagInMilli },
    );
    audioPlayer.current = sound;
    const status = await sound.getStatusAsync();
    const duration = itunesDuration ?? status.durationMillis;

    if (duration) {
      setTotalDuration(duration);
    }

    sound.setProgressUpdateIntervalAsync(1000);
    sound.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate);
    console.log('time flag audio ', timeFlagInMilli, 'duration', duration);
    // if (timeFlagInMilli && timeFlagInMilli < duration) {
    //   console.log('Will move to time flag');
    //   sound.setPositionAsync(timeFlagInMilli);
    // }
  }, [audioUrl]);

  return {
    positionMillis,
    playableDurationMillis,
    totalDuration,
    forward,
    rewind,
    onSeek,
    isPlaying,
    onPause,
    onPlay,
  };
};
export default useAudioController;
