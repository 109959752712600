import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getDetailedFavorites } from '../api/request/user/explorer/favorites';
import SnackMessageContext from '../context/SnackMessageContext';
import UserContext from '../context/UserContext';
import useFavoriteStore from './useFavoritesStore';

const useDetailedFavoriteHandler = () => {
  const { user, isInvitedMode, isJWTset } = useContext(UserContext);
  const { displaySnackBarError } = useContext(SnackMessageContext);
  const favoritesIds = useFavoriteStore(state => state.favoritesIds);

  const { isLoading, data, refetch } = useQuery(
    ['getUserFavorites'],
    () => getDetailedFavorites(),
    {
      onError: error => displaySnackBarError(),
      enabled: false, //only triggered by refetch
    },
  );

  useEffect(() => {
    const unsubFavorites = useFavoriteStore.subscribe(state => state.favoritesIds);
    return () => {
      unsubFavorites();
    };
  }, []);

  useEffect(() => {
    console.log('Will refetch favorites');
    fetchFavorites();
  }, [isJWTset, favoritesIds]);

  const fetchFavorites = useCallback(() => {
    if (!isJWTset) {
      return;
    }
    refetch();
  }, [isJWTset]);

  const detailedFavorites = useMemo(() => {
    if (isInvitedMode) return undefined;
    else return data;
  }, [user, data, favoritesIds]);

  return {
    userFavorites: favoritesIds,
    detailedFavorites,
    fetchFavorites,
    isLoading,
  };
};
export default useDetailedFavoriteHandler;
