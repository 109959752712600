import { StyleSheet } from 'react-native';
import { ANColor, withOpacity } from '../../../theme/ANColor';
import Layout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { padding } from '../../../util/padding';

export default StyleSheet.create({
  portraitModeView: {
    ...Layout.container,
    backgroundColor: ANColor.common400,
    justifyContent: 'center',
    alignItems: 'center',
  },

  video: {
    backgroundColor: 'black',
    flex: 1,
  },
  videwWeb: {
    backgroundColor: 'black',
    flex: 1,
    marginTop: 50,
    marginBottom: 50,
  },
  play: {
    position: 'absolute',
  },
  countDown: {
    position: 'absolute',
    left: 24,
    top: 24,
  },
  closeButton: {
    position: 'absolute',
    top: 24,
    right: 24,
  },

  pauseButton: {
    position: 'absolute',
    left: 24,
  },
  speakerButton: {
    position: 'absolute',
    right: 24,
    bottom: 0,
  },
  nextPrevious: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
  },
  nextButton: {
    marginLeft: MetricsSizes.regular,
  },
  pauseButtonWeb: {
    marginHorizontal: MetricsSizes.regular,
  },

  bufferingView: {
    backgroundColor: withOpacity(50, ANColor.commonBlack),
    ...padding(10),
    height: 50,
    aspectRatio: 1,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    alignSelf: 'center',
    overflow: 'hidden',
    borderRadius: 10,
  },
});
