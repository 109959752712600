import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import I18n from '../../../i18n';
import { screenOptions } from '../../../navigation/options';
import { RootStackParamList, Route } from '../../../navigation/Route';
import ScheduleTrainingNotificationsScreen from '../../profile/ScheduleTraining/ScheduleTrainingNotificationsScreen';
import OnboardingScreen from './OnboardingScreen';

const Stack = createNativeStackNavigator<RootStackParamList>();

export const OnboardingNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ ...screenOptions, headerTitle: '' }}>
      <Stack.Screen
        name={Route.onboardingScreen}
        component={OnboardingScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={Route.trainingProgramNotificationsScreen}
        component={ScheduleTrainingNotificationsScreen}
        initialParams={{ openedInOnboarding: true }}
        options={{
          headerShown: true,
          headerBackVisible: false,
          headerLeft: () => null,
          headerTitle: I18n.t('program_notifications.title'),
        }}
      />
    </Stack.Navigator>
  );
};
