import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Notifications from 'expo-notifications';
import React, { useContext } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMutation } from 'react-query';
import { putTrainingSchedule } from '../../../api/request/user/putPatient';
import SnackMessageContext from '../../../context/SnackMessageContext';
import UserContext from '../../../context/UserContext';
import useNotifications from '../../../hooks/useNotifications';
import I18n from '../../../i18n';
import { ANTrainingSchedule, Day, Time } from '../../../model/ANTrainingSchedule';
import { RootStackParamList, Route } from '../../../navigation/Route';

export const days = [
  Day.Lundi,
  Day.Mardi,
  Day.Mercredi,
  Day.Jeudi,
  Day.Vendredi,
  Day.Samedi,
  Day.Dimanche,
];
type ScreenRouteProp = RouteProp<RootStackParamList, Route.trainingProgramNotificationsScreen>;

const useValidateScheduleTrainingNotification = () => {
  const {
    params: { openedInOnboarding },
  } = useRoute<ScreenRouteProp>();

  const { updateTrainingSchedule, user } = useContext(UserContext);

  const [scheduledNotifications, setScheduledNotifications] = React.useState<ANTrainingSchedule[]>(
    () => {
      return days.map(day => {
        const times: Time[] =
          user?.notificationsSchedule?.find(schedule => schedule.weekday === day)?.times ?? [];
        const schedule: ANTrainingSchedule = {
          weekday: day,
          times: times,
        };
        return schedule;
      });
    },
  );

  const { scheduleTrainingNotification, isNotificationAllowed, requestNotification } =
    useNotifications();

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { displaySnackBarError, displaySnackBarInfo } = useContext(SnackMessageContext);

  const { bottom } = useSafeAreaInsets();

  const { mutateAsync, isLoading: isSaving } = useMutation((schedule: ANTrainingSchedule[]) => {
    return putTrainingSchedule(schedule);
  });

  const putSchedule = async () => {
    try {
      const schedule = scheduledNotifications.filter(item => item.times.length > 0);
      const result = await mutateAsync(schedule);
      if (result) {
        updateTrainingSchedule(scheduledNotifications);
        displaySnackBarInfo(I18n.t('program_notifications.update_success'));
        dismiss();
      } else {
        displaySnackBarError();
      }
    } catch {
      displaySnackBarError();
    }
  };

  const dismiss = () => {
    if (openedInOnboarding) {
      navigation.getParent()?.goBack();
    } else {
      navigation.goBack();
    }
  };
  const validateSelection = async () => {
    await putSchedule();
    await Notifications.cancelAllScheduledNotificationsAsync();
    try {
      scheduledNotifications.forEach(item => {
        item.times.forEach(time => {
          scheduleTrainingNotification(item.weekday, time);
        });
      });
    } catch (error) {
      console.log('Notification schedule error', error);
      displaySnackBarError();
    }
  };

  const onScheduleChanged = (schedule: ANTrainingSchedule[]) => {
    setScheduledNotifications(schedule);
  };

  return {
    bottom,
    isNotificationAllowed,
    isSaving,
    openedInOnboarding,
    scheduledNotifications,
    requestNotification,
    validateSelection,
    onScheduleChanged,
    dismiss,
  };
};

export default useValidateScheduleTrainingNotification;
