import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import { RootStackParamList, Route } from '../navigation/Route';
import { ANColor, withOpacity } from '../theme/ANColor';
import { isDevice } from '../util/Device';
import ExpoIcon from './ExpoIcon';
import GuestPromotionModal from './modal/GuestPromotionModal';

/**
 * A component that overlays a media to lock the content access to patient only. Guests don't have access to this content.
 * @returns
 */
const LockedForGuestsOverlay = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const [isWebGuestModalVisible, setWebGuestModalVisible] = React.useState(false);

  const showPatientOnlyMedia = useCallback(() => {
    if (isDevice()) {
      navigation.navigate(Route.invitedModeScreen);
    } else {
      setWebGuestModalVisible(true);
    }
  }, []);

  return (
    <View style={StyleSheet.absoluteFill}>
      <Pressable style={styles.shade} onPress={showPatientOnlyMedia}>
        <ExpoIcon name="lock-outline" size={48} color={ANColor.commonWhite} />
      </Pressable>
      <GuestPromotionModal
        isVisible={isWebGuestModalVisible}
        shouldDismissWebModal={() => setWebGuestModalVisible(false)}
      />
    </View>
  );
};

export default LockedForGuestsOverlay;

const styles = StyleSheet.create({
  shade: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: withOpacity(40, ANColor.commonBlack),
  },
});
