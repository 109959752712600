import { yupResolver } from '@hookform/resolvers/yup';
import { RouteProp, useRoute } from '@react-navigation/native';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { SafeAreaView } from 'react-native-safe-area-context';
import { postNewPassword } from '../../api/request/user/postNewPassword';
import ANButton from '../../component/ANButton';
import AndrewLoadingScreen from '../../component/AndrewLoadingScreen';
import CGUCheckbox from '../../component/form/CGUCheckbox';
import FormView from '../../component/form/FormView';
import { InputGroup } from '../../component/form/InputGroup';
import SnackMessageContext from '../../context/SnackMessageContext';
import UserContext from '../../context/UserContext';
import I18n from '../../i18n';
import { RootStackParamList, Route } from '../../navigation/Route';
import { default as Font } from '../../theme/ANFont';
import { default as Layout } from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import {
  defineNewPasswordScheme,
  validatePasswordDifferentFromEmail,
} from '../../util/validationScheme';

export type DefinePasswordProps = {
  email: string;
};

type ScreenRouteProp = RouteProp<RootStackParamList, Route.authDefinePassword>;

type FormValues = {
  password: string;
  confirm_password: string;
  cgu_validation: boolean;
};

type ResetPasswordBody = {
  newPassword: string;
};

const AuthDefinePasswordScreen = () => {
  const {
    params: { email },
  } = useRoute<ScreenRouteProp>();

  const confirmPasswordRef = useRef<TextInput>();
  const { userLoggedIn, loadingProfile, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit, getValues } = useForm<FormValues>({
    resolver: yupResolver(defineNewPasswordScheme),
    mode: 'onBlur',
  });
  const { displaySnackBarError } = useContext(SnackMessageContext);

  ///Update password with extra validation step.
  const updatePassword = async () => {
    try {
      const password = getValues('confirm_password');
      if (!validatePasswordDifferentFromEmail(password, email)) {
        displaySnackBarError(I18n.t('auth_error.error_password_same_as_email'));
        return;
      }
      setIsLoading(true);

      const newPassword = getValues('confirm_password');
      const jwt = await postNewPassword(newPassword);
      if (jwt) userLoggedIn(jwt, false);
    } catch (error) {
      displaySigninError();
    }
    setIsLoading(false);
  };

  const displaySigninError = useCallback(() => {
    displaySnackBarError(I18n.t('error.unknown'));
  }, []);

  return (
    <SafeAreaView style={Layout.screenContainer} edges={['bottom', 'left', 'right']}>
      <KeyboardAwareScrollView>
        <Text style={[Font.h5Bold, styles.title]}>
          {I18n.t('auth.update_password_screen_title')}
        </Text>

        <Text style={[Font.textMediumRegular, { marginTop: MetricsSizes.small }]}>
          {I18n.t('auth.password_format')}
        </Text>

        <FormView>
          <View style={[Layout.container, styles.controllersContainer]}>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <InputGroup
                  label={I18n.t('auth.password')}
                  value={value}
                  placeholder={I18n.t('auth.password_placeholder')}
                  onChangeText={onChange}
                  password={true}
                  errorDetails={error?.message}
                  onBlur={onBlur}
                  returnKeyType="next"
                  onSubmit={() => {
                    confirmPasswordRef.current?.focus();
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="confirm_password"
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <InputGroup
                  inputRef={confirmPasswordRef}
                  label={I18n.t('auth.password_confirm')}
                  value={value}
                  placeholder={I18n.t('auth.password_confirm')}
                  password={true}
                  errorDetails={error?.message}
                  onChangeText={onChange}
                  returnKeyType="done"
                  style={{ marginTop: 10 }}
                  onBlur={onBlur}
                  onSubmit={handleSubmit(updatePassword)}
                />
              )}
            />

            <Controller
              control={control}
              name="cgu_validation"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <View style={{ marginTop: MetricsSizes.regular }}>
                  <CGUCheckbox value={value} errorMessage={error?.message} onChange={onChange} />
                </View>
              )}
            />
          </View>
        </FormView>
        <View>
          <ANButton
            onPress={handleSubmit(updatePassword)}
            isLoading={isLoading}
            title="Valider"
            cornerType="full"
            style={{ marginTop: MetricsSizes.big }}
          />
        </View>
      </KeyboardAwareScrollView>
      {loadingProfile && <AndrewLoadingScreen />}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  title: {
    marginTop: 90,
  },
  controllersContainer: {
    marginTop: 70,
  },
});

export default AuthDefinePasswordScreen;
