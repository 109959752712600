import AsyncStorage from '@react-native-async-storage/async-storage';
import ANUser from '../model/ANUser';

enum Keys {
  jwt = 'jwt',
  user = 'user',
  guest = 'guest',
  seenOnboarding = 'seenOnboardingV3',
  seenGuestOnboarding = 'seenGuestOnboarding',
  numberOfSessions = 'numberOfSessions',
}

export const storeJWT = async (jwt: string) => {
  try {
    await AsyncStorage.setItem(Keys.jwt, jwt);
  } catch (e) {
    console.log('error saving jwt');
  }
};

export const deleteLocalJWT = async () => {
  try {
    await AsyncStorage.removeItem(Keys.jwt);
  } catch (e) {
    console.log('error removing jwt');
  }
};

export const storeIsGuest = async (guest: boolean) => {
  try {
    await AsyncStorage.setItem(Keys.guest, guest.toString());
  } catch (e) {
    console.log('error saving guest', e);
  }
};

export const getLocalIsGuest = async () => {
  const stringBoolean = await AsyncStorage.getItem(Keys.guest);
  return stringBoolean ? stringBoolean == 'true' : false;
};

export const getLocalJWT = async () => {
  return await AsyncStorage.getItem(Keys.jwt);
};

export const storeUser = async (user: ANUser) => {
  try {
    await AsyncStorage.setItem(Keys.user, JSON.stringify(user));
  } catch (e) {
    console.log('error saving user');
  }
};

export const deleteLocalUser = async () => {
  try {
    await AsyncStorage.removeItem(Keys.user);
  } catch (e) {
    console.log('error removing user');
  }
};

export const getLocalUser = async () => {
  const json = await AsyncStorage.getItem(Keys.user);
  if (json) {
    const restoredUser: ANUser = JSON.parse(json);
    return restoredUser;
  } else {
    return undefined;
  }
};

export const storeOnboardingSeen = async (value: boolean) => {
  try {
    await AsyncStorage.setItem(Keys.seenOnboarding, value.toString());
  } catch (e) {
    console.log('error saving onboarding', e);
  }
};

export const isOnboardingSeen = async () => {
  const stringBoolean = await AsyncStorage.getItem(Keys.seenOnboarding);
  if (stringBoolean === null) return false;
  return stringBoolean ? Boolean(stringBoolean) : false;
};

export const storeGuestOnboardingSeen = async (value: boolean) => {
  try {
    await AsyncStorage.setItem(Keys.seenGuestOnboarding, value.toString());
  } catch (e) {
    console.log('error saving guest onboarding', e);
  }
};

export const isGuestOnboardingSeen = async () => {
  const stringBoolean = await AsyncStorage.getItem(Keys.seenGuestOnboarding);
  if (stringBoolean === null) return false;
  return stringBoolean ? Boolean(stringBoolean) : false;
};

export const incrementNumberOfSessions = async () => {
  const currentNumber = await getNumberOfSessions();
  const newValue = currentNumber + 1;
  console.log('incrementNumberOfSessions', newValue);
  try {
    AsyncStorage.setItem(Keys.numberOfSessions, newValue.toString());
  } catch (e) {
    console.log('error saving number of sessions', e);
  }
  return newValue;
};

export const getNumberOfSessions = async () => {
  const stringNumber = await AsyncStorage.getItem(Keys.numberOfSessions);
  if (stringNumber) {
    return Number(stringNumber);
  }
  return 0;
};
