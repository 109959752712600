import ANOsteo from '../model/ANOsteo';

/**
 * The avatar to display in the card. Depending on the context, it will be the therapist avatar, or the academie avatar, or the center avatar.
 */
export const osteoAvatarURL = (osteo?: ANOsteo) => {
  if (osteo?.academieAvatar !== undefined) {
    return osteo?.academieAvatar;
  }

  if (osteo?.centerAvatar !== undefined) {
    return osteo?.centerAvatar;
  }

  return osteo?.avatarThumb;
};

/**
 * The main name to display in the card. Depending on the context, it will be the therapist name, or the academie name, or the center name.
 */
export const osteoMainDisplayName = (osteo?: ANOsteo) => {
  if (osteo?.academieName !== undefined) {
    return osteo?.academieName;
  }

  if (osteo?.centerName !== undefined) {
    return osteo?.centerName;
  }

  return osteo?.displayname;
};

/**
 * If the last appointment was made in a center or an academie, we'll add an extra label to give the therapist name in addition of the center/academie name
 */
export const osteoShouldDisplayOsteoName = (osteo?: ANOsteo) => {
  const academie = osteo?.academieName !== undefined && osteo?.shouldDisplayStudentNameToPatients; //Some academies don't want to display the student name to patients
  const center = osteo?.centerName !== undefined;
  return academie || center;
};

/**
 * The URL to open when the user wants to take an appointment
 */
export const osteoAppointmentURL = (osteo?: ANOsteo) => {
  if (osteo?.academieURL !== undefined) {
    return osteo?.academieURL;
  }

  if (osteo?.centerURL !== undefined) {
    return osteo?.centerURL;
  }

  return osteo?.urlDoctolib;
};
