import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import I18n from '../i18n';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import BulletPointCheckmark from './BulletPointCheckmark';

type Props = {};

const bulletPoints = [
  I18n.t('app_promotion_invited.andrew_benefit_1'),
  I18n.t('app_promotion_invited.andrew_benefit_2'),
  I18n.t('app_promotion_invited.andrew_benefit_3'),
  I18n.t('app_promotion_invited.andrew_benefit_4'),
];

const OsteowPromotionBulletList = (props: Props) => {
  return (
    <View>
      <Text style={[ANFont.h6Bold, styles.join_osteow_title]}>
        {I18n.t('app_promotion_invited.join_andrew_title')}
      </Text>
      {bulletPoints.map((item, index) => {
        return <BulletPointCheckmark title={item} style={styles.bulletPoint} key={index} />;
      })}
    </View>
  );
};

export default OsteowPromotionBulletList;

const styles = StyleSheet.create({
  join_osteow_title: {
    color: ANColor.tertiary500,
    marginTop: 48,
    marginBottom: MetricsSizes.big,
  },
  bulletPoint: {
    marginVertical: 8,
  },
});
