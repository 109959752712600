import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { MetricsSizes } from '../theme/ANMetrics';

type Props = {
  horizontal?: boolean;
  size?: number;
};

const ListItemSeparator: FC<Props> = ({ horizontal = false, size = MetricsSizes.small }) => {
  return (
    <View
      style={
        horizontal
          ? {
              height: '100%',
              width: size,
            }
          : {
              width: '100%',
              height: size,
            }
      }
    />
  );
};

export default ListItemSeparator;

const styles = StyleSheet.create({});
