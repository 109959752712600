import { RouteProp, useRoute } from '@react-navigation/native';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Modal, View } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';
import ANVideoView from '../../../component/ANVideoView';
import ExpoIcon from '../../../component/ExpoIcon';
import TrainingSessionController from '../../../component/TrainingSessionController';
import TrainingSessionCountDown from '../../../component/TrainingSessionCountDown';
import ConfirmExitModal from '../../../component/modal/ConfirmExiteModal';
import I18n from '../../../i18n';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { ANColor, withOpacity } from '../../../theme/ANColor';
import ANLayout, { default as Layout } from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';
import TrainingSessionConfig from './TrainingSessionConfig';
import styles from './TrainingSessionScreenStyle';
import useTrainingSessionScreen from './useTrainingSessionScreen';

type ScreenRouteProp = RouteProp<RootStackParamList, Route.trainingSession>;

export type TrainingSessionScreenProps = {
  useRestrictedMode: boolean;
  onWebModalShouldDismiss: (cancelled: boolean) => void;
};

const TrainingSessionScreen: FC<TrainingSessionScreenProps> = ({
  useRestrictedMode,
  onWebModalShouldDismiss,
}) => {
  const {
    params: { isUsingRestrictedMode },
  } = isDevice()
    ? useRoute<ScreenRouteProp>()
    : { params: { isUsingRestrictedMode: useRestrictedMode } };

  const {
    video,
    isExitModalVisible,
    isInTransitionState,
    countDownOccurence,
    muteMusic,
    currentVideo,
    isFirstExercice,
    isLastExercice,
    isInLandscapeMode,
    onPreviousTapped,
    OnNextTapped,
    OnDismissExitModalTapped,
    onResumeExitModalTapped,
    onPlaybackStatusUpdate,
    onFinish,
    onExitTapped,
    playOrPauseTapped,
    audioSpeakerTapped,
    onSessionCompletes,
    isPlaying,
    isBuffering,
  } = useTrainingSessionScreen({ isUsingRestrictedMode, onWebModalShouldDismiss });

  //To control the overlay layer on hover
  const [showControls, setShowControlls] = React.useState(true);
  const timerRef = useRef(null);

  //To prevent capturing with screen recording
  //isDevice() && usePreventScreenCapture();
  useEffect(() => {
    if (showControls) {
      prepareFadeout();
    }
  }, [showControls]);

  const timerInterval = useCallback(() => {
    console.log('timerInterval 3s reached');
    setShowControlls(false);
  }, []);

  /**
   * Set a timeout to hide the video controller after 3s
   */
  const prepareFadeout = useCallback(() => {
    timerRef.current = setTimeout(timerInterval, 3000);
  }, [timerRef.current, timerInterval]);

  const onLayoutPress = useCallback(() => {
    setShowControlls(true);
  }, []);

  /**
   * Each time the user interact with the video controller, postpone the timeout by cancelling it and setting a new one
   */
  const onInteraction = useCallback(() => {
    timerRef.current && clearTimeout(timerRef.current);
    prepareFadeout();
  }, [timerRef.current, prepareFadeout]);

  const Body = () => {
    return (
      <div
        onMouseMove={() => {
          onLayoutPress();
        }}
        style={{ flex: 1 }}
      >
        <View style={{ ...Layout.container, height: '100%' }}>
          <ANVideoView
            videoRef={video}
            style={styles.video}
            isMuted={true}
            shouldPlay={true}
            uri={currentVideo.uri}
            // uri={
            //   'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
            // }
            useControls={false}
            isLooping
            onPlaybackStatusUpdate={onPlaybackStatusUpdate}
          />

          <View style={styles.countDown}>
            <TrainingSessionCountDown
              increment={1000}
              isBuffering={isBuffering}
              paused={!isPlaying || isBuffering}
              prepareNextExerciceState={isInTransitionState}
              countDownOccurence={countDownOccurence}
              totalTime={
                isInTransitionState
                  ? TrainingSessionConfig.videoTransitionTime
                  : currentVideo.duration
              }
              onFinish={onFinish}
              // onFinish={() => {}}
            />
          </View>

          {showControls && (
            <Animated.View
              entering={FadeIn}
              exiting={FadeOut}
              style={[
                ANLayout.absoluteCenter,
                { backgroundColor: withOpacity(30, ANColor.commonBlack) },
              ]}
            >
              <View style={[ANLayout.rowCenter, ANLayout.absoluteCenter]}>
                <TrainingSessionController
                  iconName={'skip-previous'}
                  eventKey="training_session_screen_previous"
                  onPress={() => {
                    onInteraction();
                    onPreviousTapped();
                  }}
                  size={70}
                  style={{ opacity: isFirstExercice ? 0.3 : 1 }}
                  backgroundColor={withOpacity(50, ANColor.tertiary100)}
                />
                <TrainingSessionController
                  eventKey="training_session_screen_play_pause"
                  iconName={isPlaying ? 'pause' : 'play-arrow'}
                  onPress={playOrPauseTapped}
                  backgroundColor={withOpacity(50, ANColor.tertiary100)}
                  size={70}
                  style={styles.pauseButtonWeb}
                />
                <TrainingSessionController
                  iconName={'skip-next'}
                  eventKey="training_session_screen_next"
                  onPress={() => {
                    onInteraction();
                    OnNextTapped();
                  }}
                  size={70}
                  style={{ opacity: isLastExercice ? 0.3 : 1 }}
                  backgroundColor={withOpacity(50, ANColor.tertiary100)}
                />
              </View>
              <TrainingSessionController
                iconName={'close'}
                eventKey="training_session_close"
                onPress={() => {
                  onInteraction();
                  onExitTapped();
                }}
                size={70}
                style={{ ...ANLayout.absolute, right: MetricsSizes.big, top: MetricsSizes.big }}
                backgroundColor={withOpacity(50, ANColor.tertiary100)}
              />

              <TrainingSessionController
                eventKey="training_session_screen_speaker_button"
                iconName={muteMusic ? 'volume-off' : 'volume-up'}
                onPress={() => {
                  onInteraction();
                  audioSpeakerTapped();
                }}
                size={70}
                style={{ position: 'absolute', left: MetricsSizes.big, bottom: MetricsSizes.big }}
                backgroundColor={withOpacity(50, ANColor.tertiary100)}
              />
            </Animated.View>
          )}
          <ConfirmExitModal
            isVisible={isExitModalVisible}
            onDismiss={OnDismissExitModalTapped}
            onResume={onResumeExitModalTapped}
            onDone={onSessionCompletes}
            title={I18n.t('training.exit_training')}
          />
        </View>
      </div>
    );
  };

  if (isDevice()) {
    return isInLandscapeMode ? (
      Body()
    ) : (
      <View style={styles.portraitModeView}>
        <ExpoIcon name="screen-rotation" size={46} color={ANColor.commonWhite} />
      </View>
    );
  } else {
    return <Modal>{Body()}</Modal>;
  }
};

export default TrainingSessionScreen;
