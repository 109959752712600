import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { ExpoIconPressable } from '../../component/ExpoIcon';
import PlayerSlider from '../../component/PlayerSlider';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import { MetricsSizes } from '../../theme/ANMetrics';
import useAudioController from './useAudioController';

type Props = {
  audioUrl?: string;
  /**
   * The duration when provided by a Podcast. Podcast duration are not read properly by the Expo AV status totalDurationMillis. So we use the duration provided by the Podcast RSS Feed.
   */
  itunesDuration?: string;
  timeFlagInMilli?: number;
  positionMillisUpdated: (positionMillis: number) => void;
  onMediaEnd: (positionMillis: number) => void;
};

const AudioController: FC<Props> = ({
  audioUrl,
  itunesDuration,
  timeFlagInMilli,
  positionMillisUpdated,
  onMediaEnd,
}) => {
  const {
    positionMillis,
    playableDurationMillis,
    totalDuration,
    forward,
    rewind,
    onSeek,
    isPlaying,
    onPause,
    onPlay,
  } = useAudioController(
    onMediaEnd,
    timeFlagInMilli,
    positionMillisUpdated,
    audioUrl,
    itunesDuration,
  );

  return (
    <View style={[styles.container, { opacity: audioUrl ? 1 : 0.5 }]}>
      <View style={styles.controllerContainer}>
        <ExpoIconPressable
          name="replay-10"
          size={44}
          color={ANColor.commonWhite}
          onPress={rewind}
        />
        <ExpoIconPressable
          name={isPlaying ? 'pause-circle-filled' : 'play-circle-filled'}
          size={44}
          color={ANColor.commonWhite}
          onPress={isPlaying ? onPause : onPlay}
          style={styles.pauseButton}
        />
        <ExpoIconPressable
          name="forward-10"
          size={44}
          color={ANColor.commonWhite}
          onPress={forward}
        />
      </View>
      <PlayerSlider
        totalDuration={totalDuration}
        positionMillis={positionMillis}
        playableDurationMillis={playableDurationMillis}
        onSeek={onSeek}
      />
    </View>
  );
};

export default AudioController;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: MetricsSizes.regular,
  },
  controllerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pauseButton: {
    marginHorizontal: MetricsSizes.big,
  },
  slider: {
    marginTop: MetricsSizes.small,
  },
  timeContainer: {
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'flex-start',
    justifyContent: 'space-between',
  },
  time: {
    ...ANFont.textSmallRegular,
    textAlign: 'center',
    color: ANColor.commonWhite,
  },
});
