import { useMutation } from 'react-query';
import {
  MediaLogBody,
  PodcastLogBody,
  logUserMediaUsed,
} from '../api/request/user/explorer/getTopics';
import { logPodcastUsed } from './../api/request/user/explorer/getTopics';
import useLocalMediaLogs from './useLocalMediaLogs';
import useLocalPodcastLogs from './useLocalPodcastLogs';

const useLogMedia = () => {
  const addLog = useLocalMediaLogs(state => state.addNewLog);
  const addPodcastLog = useLocalPodcastLogs(state => state.addNewLog);

  const saveLogMedia = async (body: MediaLogBody) => {
    logMedia(body);
    addLog(body);
  };

  const saveLogPodcast = async (body: PodcastLogBody) => {
    logPodcast(body);
    addPodcastLog(body);
  };

  const { mutateAsync: logMedia } = useMutation((body: MediaLogBody) => {
    return logUserMediaUsed(body);
  });

  const { mutateAsync: logPodcast } = useMutation((body: PodcastLogBody) => {
    return logPodcastUsed(body);
  });
  return { saveLogMedia, saveLogPodcast };
};

export default useLogMedia;
