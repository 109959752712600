import React, { FC, useEffect, useState } from 'react';
import { DeviceEventEmitter, Dimensions, StyleSheet, View } from 'react-native';
import DownloadMobileApp from './component/DownloadMobileApp.web';
import UserContext from './context/UserContext';
import { RootNavigator } from './navigation/RootNavigator';
import UsingWrongRoleScreen from './screen/auth/UsingWrongRoleScreen';
import { ANColor } from './theme/ANColor';
import ANLayout from './theme/ANLayout';
import { MetricsSizes } from './theme/ANMetrics';
import { unauthorizedAccessKey } from './util/eventEmitter';
import { enableAudioToWorkInSilentMode, webContainerWidth } from './util/utils';
interface Props {
  onLayout: () => void;
}

const windowWidth = Dimensions.get('window').width;

const AppContainer: FC<Props> = ({ onLayout }) => {
  const [appIsReady, setAppIsReady] = React.useState(false);

  const isMobileWidth = (width: number) => {
    return width <= 768;
  };

  const [isUsingMobile, setIsUsingMobile] = useState<boolean>(isMobileWidth(windowWidth));

  function handleWindowSizeChange(event: any) {
    const { width } = event.window;
    const isMobile = isMobileWidth(width);
    setIsUsingMobile(isMobile);
    console.log('isMobile', isMobile);
  }

  useEffect(() => {
    Dimensions.addEventListener('change', handleWindowSizeChange);
    return () => {
      Dimensions.removeEventListener('change', handleWindowSizeChange);
    };
  }, []);

  const { initUser, userLoggedOut, isUsingWrongRole } = React.useContext(UserContext);

  React.useEffect(() => {
    async function prepare() {
      try {
        await initUser();

        //await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }

    DeviceEventEmitter.addListener(unauthorizedAccessKey, event => {
      console.log('Received error');
      userLoggedOut();
    });

    enableAudioToWorkInSilentMode();
    prepare();

    return () => {
      DeviceEventEmitter.removeAllListeners();
    };
  }, []);

  if (isUsingMobile) {
    return <DownloadMobileApp />;
  }
  if (isUsingWrongRole) {
    console.log('isUsingWrongRole');
    return (
      <View style={[ANLayout.container]} onLayout={onLayout}>
        <UsingWrongRoleScreen />
      </View>
    );
  }

  if (appIsReady) {
    return (
      <View style={[ANLayout.container, { backgroundColor: ANColor.primary100 }]}>
        <View onLayout={onLayout} style={[ANLayout.container, styles.container]}>
          <RootNavigator />
        </View>
      </View>
    );
  } else {
    return <View onLayout={onLayout} style={styles.splashContainer} />;
  }
};

export default AppContainer;

const styles = StyleSheet.create({
  container: {
    backgroundColor: ANColor.primary100,
    maxWidth: webContainerWidth,
    width: '100%',
    alignSelf: 'center',
  },
  downloadAppBody: {
    marginVertical: MetricsSizes.regular,
  },
  splashContainer: {
    height: '100%',
    backgroundColor: ANColor.primary100,
    width: '100%',
  },
});
