import { RouteProp, useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Audio } from 'expo-av';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ANSound } from '../../../assets/audio/ANSound';
import HeaderInfoButton from '../../../component/HeaderInfoButton';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { isDevice } from '../../../util/Device';
import { EventKey, logEvent } from '../../../util/analytics';
import { hapticHeavy, openUrlInBrowser } from '../../../util/utils';

type ScreenRouteProp = RouteProp<RootStackParamList, Route.breathingExerciceScreen>;

const useBreathingExercice = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const linkTo = useLinkTo();

  const {
    params: { infoUrl },
  } = useRoute<ScreenRouteProp>();

  const [timerValue, setTimerValue] = useState<number>(5);
  const [exerciceFinished, setExerciceFinished] = useState(false);
  const [shouldVibrate, setShouldVibrate] = useState(true);
  const [muteAudio, setMuteAudio] = useState(false);
  const [ambiantMusic, setAmbiantMusic] = React.useState<Audio.Sound>();
  const [shouldPlayTimer, setShouldPlay] = useState(false);
  const openInfoPage = () => openUrlInBrowser(infoUrl);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return infoUrl && <HeaderInfoButton onPress={openInfoPage} />;
      },
    });
  }, []);

  useEffect(() => {
    playAmbientSound();
  }, []);

  useEffect(() => {
    if (muteAudio) {
      ambiantMusic?.pauseAsync();
    } else {
      ambiantMusic?.playAsync();
    }
  }, [muteAudio]);

  useEffect(() => {
    return ambiantMusic
      ? () => {
          ambiantMusic.unloadAsync();
        }
      : undefined;
  }, [ambiantMusic]);

  useEffect(() => {
    if (!exerciceFinished) return;
    if (ambiantMusic) ambiantMusic.unloadAsync();

    const playCongratsSound = async () => {
      const { sound } = await Audio.Sound.createAsync(ANSound.Achievement);
      sound.playAsync();
    };
    playCongratsSound();
  }, [exerciceFinished]);

  const onStart = useCallback(() => {
    logEvent(EventKey.breathing_exercice_started, { minute: timerValue });
    setShouldPlay(true);
  }, []);

  const onTimeSelected = useCallback((value: number) => {
    setTimerValue(value);
  }, []);

  const onTimerCompleted = useCallback(() => {
    logEvent(EventKey.breathing_exercice_completed, { minute: timerValue });
    setExerciceFinished(true);
  }, []);

  const createArrayFrom = useCallback((lowerMinute: number, higherMinute: number) => {
    const array = [];
    for (let i = lowerMinute; i <= higherMinute; i++) {
      array.push(i);
    }
    return array;
  }, []);
  const minutes = useMemo(() => createArrayFrom(1, 10), []);

  const onDone = () => {
    navigation.goBack();
    if (isDevice()) {
      navigation.goBack();
    } else {
      linkTo('/' + Route.explorerScreen);
    }
  };

  const playAmbientSound = useCallback(async () => {
    const { sound } = await Audio.Sound.createAsync(ANSound.BreathingMusic, {
      shouldPlay: true,
      isLooping: true,
      volume: 0.6,
    });
    setAmbiantMusic(sound);
  }, []);

  // const playGongSound = useCallback(async () => {
  //   try {
  //     console.log('Will play gong audio');
  //     const { sound } = await Audio.Sound.createAsync(ANSound.Gong, {
  //       shouldPlay: true,
  //       volume: 1,
  //     });
  //     sound.setOnPlaybackStatusUpdate(status => {
  //       if (!status.didJustFinish) return;
  //       console.log('Audio finished, Unloading ');
  //       sound.unloadAsync().catch(() => {}); //unload the sound when finised. This could help with the Android Expo issue "Player does not exist" that occurs after several audio play. See https://github.com/expo/expo/issues/1873#issuecomment-488912452
  //     });
  //   } catch (error) {
  //     console.log('Error playing gong sound', error);
  //   }
  // }, []);

  const audioSpeakerTapped = useCallback(() => {
    if (!ambiantMusic) return;
    const newState = !muteAudio;
    setMuteAudio(newState);
    ambiantMusic?.setIsMutedAsync(newState);
  }, [muteAudio, ambiantMusic]);

  const vibrateTapped = useCallback(() => {
    const newState = !shouldVibrate;
    setShouldVibrate(newState);
  }, [shouldVibrate]);

  const vibrate = useCallback(() => {
    if (shouldVibrate) {
      hapticHeavy();
      setTimeout(() => {
        hapticHeavy();
      }, 100);
    }
  }, [shouldVibrate]);

  const onBreathAnimationEnd = useCallback(() => {
    vibrate();
    // playGongSound();
  }, []);

  return {
    navigation,
    timerValue,
    onTimeSelected,
    onStart,
    onTimerCompleted,
    minutes,
    onDone,
    shouldVibrate,
    muteAudio,
    exerciceFinished,
    shouldPlayTimer,
    audioSpeakerTapped,
    vibrateTapped,
    onBreathAnimationEnd,
  };
};

export default useBreathingExercice;
