import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import { ANColor } from '../../theme/ANColor';
import ANLayout from '../../theme/ANLayout';
import CloseModaleButton from '../CloseModaleButton';

export type WebModalProps = {
  isVisible: boolean;
  style?: any;
  contentContainerStyle?: any;
  shouldDisplayCloseIcon?: boolean;
  closeIconColor?: string;
  shouldDismissWebModal: () => void;
};

type Props = {
  content: React.ReactNode;
};

const WebModal: FC<Props & WebModalProps> = ({
  isVisible,
  content,
  style,
  closeIconColor = ANColor.commonBlack,
  shouldDisplayCloseIcon = true,
  contentContainerStyle,
  shouldDismissWebModal,
}) => {
  return (
    <Portal>
      <Modal
        theme={{}}
        visible={isVisible}
        style={style ? style : styles.modal}
        contentContainerStyle={[ANLayout.modalContainer, styles.container, contentContainerStyle]}
        onDismiss={shouldDismissWebModal}
      >
        {shouldDisplayCloseIcon && (
          <CloseModaleButton
            color={closeIconColor}
            onPress={shouldDismissWebModal}
            style={styles.closeIcon}
          />
        )}
        {content}
      </Modal>
    </Portal>
  );
};

export default WebModal;

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    alignItems: 'center',
  },
  container: {
    width: 500,
    backgroundColor: ANColor.primary100,
    maxHeight: '90%',
    paddingBottom: 20,
    justifyContent: 'flex-end',
  },
  closeIcon: {
    margin: 8,
    alignSelf: 'flex-end',
  },
});
