import { useRef, useState } from 'react';

interface VideoWebModale {
  uri: string;
  mediaId: number;
  timeFlagInMilli?: number;
}

const useWebModaleVideoPlayer = () => {
  const videoRef = useRef<any>(null);
  const [videoToPlay, setVideoToPlay] = useState<VideoWebModale>();
  const [isVideoWebModalVisible, setVideoWebModalVisible] = useState(false);

  const onVideoTapped = async (id: number, uri: string, timeFlagInMilli?: number) => {
    console.log('video tapped', uri, videoToPlay);

    setVideoToPlay({ uri, mediaId: id, timeFlagInMilli }); //Update the uri
    //Same video, reset to 0
    await videoRef.current?.setPositionAsync(0); //Reset to 0 in case playing a same video again
    try {
      await videoRef.current?.playAsync();
    } catch (error) {
      console.log('Error while calling playAsync', error);
    }
    setVideoWebModalVisible(true);
  };

  return {
    videoRef,
    videoToPlay,
    isVideoWebModalVisible,
    setVideoWebModalVisible,
    onVideoTapped,
    setVideoToPlay,
  };
};

export default useWebModaleVideoPlayer;
