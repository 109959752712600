export const ANSound = {
  MidnightSolutions: require('./midnight_solutions.mp3'),
  BreathingMusic: require('./breath_relax_music.mp3'),
  Achievement: require('./achievement_bell.mp3'),
  ExerciceEnded: require('./exercice_ended.mp3'),
  PrepareOtherSide: require('./prepare_other_side.mp3'),
  Prepare: require('./prepare.mp3'),
  TrainingEnded: require('./training_ended.mp3'),
  YourTurn: require('./your_turn.mp3'),
};

export const exerciceFinishedDurationInMs = 7000;
