import React, { FC, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  cancelAnimation,
  Easing,
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { ANColor } from '../theme/ANColor';

type Props = {
  onAnimationEnds?: () => void;
  pace: number;
};

const BreathingAnimation: FC<Props> = ({ pace, onAnimationEnds }) => {
  //Translate on Y axis and reduce opacity when offsetY is greater than 0
  const animationValue = useSharedValue(0);

  const animationReached = () => {
    //WARNING : do not usecallback here, else vibration on/off will not work
    if (onAnimationEnds) onAnimationEnds();
  };

  const rStyle = useAnimatedStyle(() => {
    if (animationValue.value === 1 || animationValue.value === 0) {
      runOnJS(animationReached)();
    }
    return {
      transform: [
        {
          scale: interpolate(animationValue.value, [0, 1], [0.1, 1]),
        },
      ],
    };
  });

  useEffect(() => {
    cancelAnimation(animationValue);
    animationValue.value = 0;
    animationValue.value = withRepeat(
      withTiming(1, {
        duration: pace,
        easing: Easing.inOut(Easing.ease),
      }),
      -1,
      true,
    );
  }, [pace]);

  return (
    <View style={styles.outerCircle}>
      <Animated.View style={[StyleSheet.absoluteFillObject, styles.innerCircle, rStyle]} />
    </View>
  );
};

export default BreathingAnimation;

const styles = StyleSheet.create({
  outerCircle: {
    alignSelf: 'center',
    backgroundColor: ANColor.tertiary300,
    width: '100%',
    maxWidth: 600,
    aspectRatio: 1,
    borderRadius: 10000, //Big number to make it round
  },
  innerCircle: {
    alignSelf: 'center',
    backgroundColor: ANColor.tertiary600,
    width: '100%',
    maxWidth: 600,
    aspectRatio: 1,
    borderRadius: 10000, //Big number to make it round
  },
});
