import create from 'zustand';

export interface MediaLogStore {
  podcastLogs: ANPodcastExplorerLog[];
  addNewLog: (log: ANPodcastExplorerLog) => void;
  initLogs: (logs: ANPodcastExplorerLog[]) => void;
}

export type ANPodcastExplorerLog = {
  topicId: number; //for Podcast content, we don't have a media Id but a topicId & title to identify the media
  title: string; //for Podcast content, we don't have a media Id but a topicId & title to identify the media
  completed: boolean;
  timeFlagInMilli: number;
};

/**
 * A local way to store the media logs so that we don't have to trigger a new API call to refresh all the data each time the user watched/listen a new media to display his progress
 */
const useLocalPodcastLogs = create<MediaLogStore>()(set => ({
  podcastLogs: [],
  addNewLog: (log: ANPodcastExplorerLog) => {
    //Update the state, and push the id to the API to store the data
    set(state => ({
      podcastLogs: [
        ...state.podcastLogs.filter(item => {
          //remove log with matching media id
          return item.title !== log.title;
        }),
        log,
      ],
    }));
  },

  initLogs: (logs: ANPodcastExplorerLog[]) => set({ podcastLogs: logs }),
}));

export default useLocalPodcastLogs;
