import React, { FC, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import I18n from '../../i18n';
import { ANColor } from '../../theme/ANColor';
import Font from '../../theme/ANFont';
import Layout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import { openUrlInBrowser } from '../../util/utils';
import ANButton from '../ANButton';

type Props = {
  date: string;
  mainName: string;
  subtitle?: string;
  redirectionUrl?: string;
};

const AppointmentHistoryItem: FC<Props> = ({ date, mainName, subtitle, redirectionUrl }) => {
  const onActionTapped = useCallback(async () => {
    const url = redirectionUrl;
    openUrlInBrowser(url);
  }, [redirectionUrl]);

  return (
    <View style={[Layout.card, styles.card]}>
      <View>
        <Text style={Font.textSmallRegular}>{date}</Text>
        <Text style={[Font.h6Bold, styles.mainTitle]}>{mainName}</Text>
        {subtitle && (
          <Text style={[Font.textMediumRegular, styles.subtitle]}>
            {I18n.t('common.seen_by') + ' ' + subtitle}
          </Text>
        )}
      </View>
      {redirectionUrl && (
        <ANButton
          title={I18n.t('common.take_appointment')}
          onPress={onActionTapped}
          wrap={!isDevice()}
        />
      )}
    </View>
  );
};

export default AppointmentHistoryItem;

const styles = StyleSheet.create({
  card: {
    backgroundColor: ANColor.primary200,
    marginVertical: MetricsSizes.singlePadding,
    flexDirection: isDevice() ? 'column' : 'row',
    justifyContent: 'space-between',
  },
  date: {},
  mainTitle: {
    marginTop: MetricsSizes.small,
    marginBottom: MetricsSizes.regular,
    color: ANColor.tertiary500,
  },
  subtitle: {
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.regular,
  },
});
