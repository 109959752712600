import { parseLastReviews } from '../../../model/adapters';
import { Api } from '../../apiclient';
import { parseAPIError } from '../../data/ResponseError';

export enum DifficultyCheck {
  easy = 'easy',
  normal = 'normal',
  hard = 'hard',
}

/**
 * Post the user difficulty report evaluating videox from the last playlist
 * @param answer : easy, normal or hard
 * @param videoIds : list of video ids (if easy or hard)
 * @returns the new lastReview object or null if error
 */
export const postDifficultyReport = async (answer: DifficultyCheck, videoIds?: number[]) => {
  try {
    console.log('Post user difficulty report');

    const response = await Api.post('/patient/me/difficultyReport', {
      answer: answer,
      videoIds: videoIds,
    });
    const data = response.data;
    const result = parseLastReviews(data);
    return result;
  } catch (err) {
    throw parseAPIError(err);
  }
};
