import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import * as RNSplashScreen from 'expo-splash-screen';
import * as React from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Provider } from 'react-native-paper';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppContainer from './AppContainer';
import { SnackMessageProvider } from './context/SnackMessageContext';
import { UserProvider } from './context/UserContext';
import useCustomFonts from './hooks/useCustomFonts';
import Layout from './theme/ANLayout';

const App = () => {
  console.log('App launched');
  const queryClient = new QueryClient();

  const loadFonts = async () => {
    await useCustomFonts();
  };

  const [appIsReady, setAppIsReady] = React.useState(false);

  React.useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources

        await RNSplashScreen.preventAutoHideAsync();

        // Pre-load fonts, make any API calls you need to do here
        await loadFonts();

        //await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = React.useCallback(async () => {
    if (appIsReady) {
      await RNSplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackMessageProvider>
        <UserProvider>
          <GestureHandlerRootView style={Layout.container}>
            <BottomSheetModalProvider>
              <Provider>
                <AppContainer onLayout={onLayoutRootView} />
              </Provider>
            </BottomSheetModalProvider>
          </GestureHandlerRootView>
        </UserProvider>
      </SnackMessageProvider>
    </QueryClientProvider>
  );
};

export default App;
