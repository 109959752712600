import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useCallback, useContext, useMemo, useState } from 'react';
import { DeviceEventEmitter } from 'react-native';
import UserContext from '../../../context/UserContext';
import useWebModaleVideoPlayer from '../../../hooks/useWebModaleVideoPlayer';
import ANExercice from '../../../model/ANVideoGroup';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { isDevice } from '../../../util/Device';
import { DeviceEmitterEvent } from '../../../util/eventEmitter';
import { EventKey, logEvent } from './../../../util/analytics';

const useTrainingPresentationScreen = (
  levelSelectionBottomSheetRef?: React.RefObject<BottomSheetModal>,
  openLevelSelectionModal?: () => void,
  openTrainingEndModal?: () => void, //Used for web platform
  openVideoModal?: () => void, //Used for web platform
) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const [isInSession, setIsInSession] = useState(false);

  const { user, exercicesToPlay, updateLastMeeting } = useContext(UserContext);

  const { videoRef, videoToPlay, onVideoTapped } = useWebModaleVideoPlayer();

  const [videoGroupToSwitch, setVideoGroupToSwitch] = useState<ANExercice>();
  const [useRestrictedMode, setUseRestrictedMode] = useState<boolean>(false);

  const playlist = useMemo(() => {
    return user?.trainingPlaylist;
  }, [user]);

  const switchRestrictedModeSelected = () => {
    setUseRestrictedMode(!useRestrictedMode);
  };

  const openVideo = (id: number, uri: string) => {
    logEvent(EventKey.training_fullvideo_played, { item_id: id, link_url: uri });
    if (openVideoModal) openVideoModal();
    if (isDevice()) {
      navigation.navigate(Route.videoPlayerFullScreen, {
        uri: uri,
      });
    } else {
      onVideoTapped(id, uri, undefined);
    }
  };

  const exercices = useMemo(() => {
    return exercicesToPlay(useRestrictedMode);
  }, [useRestrictedMode, user]);

  /**
   * Open the level selection form. Use bottomsheet for native devices, and modal for web
   * @param id video group to switch within
   */
  const onLevelTapped = (id: number) => {
    console.log('Level tapped');
    const groupToSwitch = playlist?.exercices.find(group => group.id === id);
    setVideoGroupToSwitch(groupToSwitch);
    if (levelSelectionBottomSheetRef?.current) {
      console.log('Will prsesent sheet with ref : ', levelSelectionBottomSheetRef?.current);
      levelSelectionBottomSheetRef?.current?.present();
    } else if (openLevelSelectionModal) {
      openLevelSelectionModal();
    }
  };

  /**
   * On session completed, segue to modal navigator for native, present modal for web
   */
  const onSessionCompleted = useCallback(() => {
    //Open congratulation modal, with optional health or difficulty reports
    if (isDevice()) {
      navigation.navigate(Route.trainingSessionEndNavigator);
    } else {
      setIsInSession(false);
      if (openTrainingEndModal) openTrainingEndModal();
    }
  }, []);

  const onSessionCancelled = useCallback(() => {
    if (!isDevice()) {
      setIsInSession(false);
    }
  }, []);

  /**
   * Open the session screen. If the osteo last appoinment has not been rated yet, open the review modal.
   * Open the session by passing as parameter the selected mode : restriced or not.
   * @returns
   */
  const onSessionStartTapped = useCallback(() => {
    if (playlist) {
      logEvent(EventKey.start_training_session, { limited_mode: useRestrictedMode });
      if (isDevice()) {
        navigation.navigate(Route.trainingSession, {
          isUsingRestrictedMode: useRestrictedMode,
        });
      } else {
        setIsInSession(true);
      }
      //Add event listener to catch session completion.
      //First, remove potential previous listener
      DeviceEventEmitter.removeAllListeners(DeviceEmitterEvent.onSessionComplete);
      DeviceEventEmitter.addListener(DeviceEmitterEvent.onSessionComplete, event => {
        //Trigger session completed
        onSessionCompleted();
        //On session completed, remove listener
        DeviceEventEmitter.removeAllListeners(DeviceEmitterEvent.onSessionComplete);
      });
    }
  }, [playlist, useRestrictedMode]);

  return {
    videoRef,
    videoToPlay,
    playlist,
    videoGroupToSwitch,
    useRestrictedMode,
    exercices,
    isInSession,
    onSessionCancelled,
    openVideo,
    onSessionStartTapped,
    onLevelTapped,
    onSessionCompleted,
    switchRestrictedModeSelected,
  };
};

export default useTrainingPresentationScreen;
