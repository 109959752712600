import { ResizeMode, Video } from 'expo-av';
import React, { FC, useCallback, useEffect } from 'react';
import { isDevice } from '../util/Device';

type Props = {
  videoRef: any;
  uri?: string;
  localVideo?: any;
  useControls: boolean;
  style?: any;
  shouldPlay?: boolean;
  isMuted?: boolean;
  useNativeControls?: boolean;
  isLooping?: boolean;
  timeFlagInMilli?: number;
  onPlaybackStatusUpdate: (status: any) => void;
  onFullscreenUpdate?: (event: any) => void;
  onReadyForDisplay?: () => void;
  //Other props passed to the video component
};

/**
 * A video view that works on both web and mobile, by using the native video component on the web, and not the expo av which is failing to resize properly.
 * Native web video also enable option to remove download option, and right click to copy link
 */
const ANVideoView: FC<Props> = ({
  videoRef,
  uri = undefined,
  style,
  localVideo,
  useControls = true,
  isMuted = false,
  isLooping = false,
  shouldPlay = false,
  timeFlagInMilli,
  onPlaybackStatusUpdate,
  onFullscreenUpdate,
  onReadyForDisplay,
}) => {
  useEffect(() => {
    listenToEvents();

    return () => {
      videoRef.current?.removeEventListener('waiting', onWaiting);
    };
  }, []);

  /**
   * Hook called when the video ref is set, to seek to the time flag if it exists
   */
  useEffect(() => {
    console.log("Video ref changed, let's seek to time flag if it exists", videoRef.current);
    //Restart the video at the time flag if it exists
    if (timeFlagInMilli && timeFlagInMilli > 0 && videoRef?.current) {
      console.log("Time flag not null, let's seek to it : ", timeFlagInMilli);
      if (isDevice()) {
        videoRef.current?.setPositionAsync(timeFlagInMilli);
      } else {
        videoRef.current.currentTime = timeFlagInMilli / 1000;
      }
    }
  }, [videoRef?.current]);

  const listenToEvents = useCallback(() => {
    if (isDevice()) {
      //Ponly add listener for the web version
      return;
    }

    videoRef.current.addEventListener('waiting', onWaiting);
  }, []);

  const onWaiting = useCallback(() => {
    onPlaybackStatusUpdate({
      isLoaded: false,
      isPlaying: false,
      didJustFinish: false,
      isBuffering: true,
    });
  }, []);

  if (isDevice()) {
    return (
      <Video
        ref={videoRef}
        style={style}
        isMuted={isMuted}
        shouldPlay={shouldPlay}
        source={uri ? { uri: uri } : localVideo}
        useNativeControls={useControls}
        resizeMode={ResizeMode.CONTAIN}
        onFullscreenUpdate={onFullscreenUpdate}
        isLooping={isLooping}
        onPlaybackStatusUpdate={onPlaybackStatusUpdate}
        onReadyForDisplay={onReadyForDisplay}
      />
    );
  } else {
    return (
      <video
        ref={videoRef}
        controls={useControls}
        controlsList="nodownload" //Remove download button
        onContextMenu={event => event.preventDefault()} //remove the right click interaction menu to prevent downloading
        style={style}
        autoPlay={shouldPlay}
        muted={isMuted}
        disablePictureInPicture={true}
        loop={isLooping}
        onPlaying={() => {
          onPlaybackStatusUpdate({
            isLoaded: true,
            isPlaying: true,
            didJustFinish: false,
            isBuffering: false,
          });
        }}
        onPause={() => {
          onPlaybackStatusUpdate({
            isLoaded: true,
            isPlaying: false,
            didJustFinish: false,
            isBuffering: false,
          });
        }}
        onEnded={() => {
          console.log('On video ended properly');
          onPlaybackStatusUpdate({ isLoaded: true, isPlaying: false, didJustFinish: true });
        }}
        src={uri ?? localVideo}
      />
    );
  }
};

export default ANVideoView;
