import React from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import LogoLong from '../../../assets/svg/LogoLong';
import ANButton from '../../../component/ANButton';
import ANClearButton from '../../../component/ANClearButton';
import AndrewLoadingScreen from '../../../component/AndrewLoadingScreen';
import FormView from '../../../component/form/FormView';
import { InputGroup } from '../../../component/form/InputGroup';
import I18n from '../../../i18n';
import { ANColor } from '../../../theme/ANColor';
import ANFont, { ANFontFamily } from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';
import useAuthLogin from './useAuthLogin';

type Props = {};

const AuthLoginScreen = (props: Props) => {
  const {
    control,
    passwordRef,
    isLoading,
    loadingProfile,
    handleSubmit,
    signinTapped,
    onPasswordForgottonTapped,
    continueAnonymously,
  } = useAuthLogin();

  return (
    <ScrollView style={[ANLayout.screenContainer]}>
      <LogoLong style={styles.logo} />
      <FormView>
        <View style={styles.formContainer}>
          <View>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <InputGroup
                  label={I18n.t('auth.email')}
                  value={value}
                  placeholder={I18n.t('auth.email_placeholder')}
                  onChangeText={onChange}
                  type="email-address"
                  errorDetails={error?.message}
                  onBlur={onBlur}
                  returnKeyType="next"
                  onSubmit={() => {
                    passwordRef.current?.focus();
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <InputGroup
                  inputRef={passwordRef}
                  label={I18n.t('auth.password')}
                  value={value}
                  placeholder={I18n.t('auth.password_placeholder')}
                  password={true}
                  errorDetails={error?.message}
                  onChangeText={onChange}
                  returnKeyType="done"
                  style={styles.passwordGroup}
                  onBlur={onBlur}
                  onSubmit={handleSubmit(signinTapped)}
                />
              )}
            />
            <TouchableOpacity onPress={onPasswordForgottonTapped}>
              <Text style={styles.forgotPasssword}>{I18n.t('auth.forgotten_password')}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </FormView>
      <View style={styles.actionsContainer}>
        <ANButton
          isLoading={isLoading}
          onPress={handleSubmit(signinTapped)}
          title={I18n.t('auth.signin')}
          eventKey="signin_tapped"
          wrap={!isDevice()}
          cornerType="full"
          style={{ marginTop: MetricsSizes.big }}
        />
        <View style={styles.noAccountContainer}>
          <Text style={[ANFont.textLargeBold, styles.noAccountYetLabel]}>
            Pas encore de compte ?
          </Text>

          <ANClearButton
            onPress={continueAnonymously}
            eventKey="continue_anonymously_tapped"
            title={I18n.t('auth.continue_as_guest')}
            style={{ marginTop: MetricsSizes.regular }}
          />
        </View>
      </View>
      {loadingProfile && <AndrewLoadingScreen />}
    </ScrollView>
  );
  1;
};

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: MetricsSizes.singlePadding,
  },
  formContainer: {
    flex: 1,
    justifyContent: 'center',
    marginVertical: 48,
  },
  forgotPasssword: {
    fontFamily: ANFontFamily.MulishBold,
    fontSize: 14,
    color: ANColor.tertiary500,
    textDecorationLine: 'underline',
    paddingTop: 4,
    alignSelf: 'flex-end',
  },
  passwordGroup: {
    marginTop: 10,
  },
  actionsContainer: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    marginBottom: MetricsSizes.regular,
  },
  signin: {
    marginTop: MetricsSizes.big,
    alignSelf: 'center',
  },
  logo: {
    marginTop: 100,
    width: 214,
    alignSelf: 'center',
  },
  noAccountContainer: {
    marginTop: MetricsSizes.giant,
  },
  noAccountYetLabel: {
    textAlign: 'center',

    flex: 1,
  },
});

export default AuthLoginScreen;
