import * as React from 'react';
import { View, ViewProps } from 'react-native';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';
import { ANColor } from '../../theme/ANColor';
import ANLayout from '../../theme/ANLayout';

const ANAvatar = (props: SvgProps & ViewProps) => {
  return (
    <View style={[ANLayout.container, props.style]}>
      <Svg width={35} height={35} fill="none" {...props}>
        <Circle cx={20.567} cy={11} r={11} fill={ANColor.common500} />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.082 22.47C6.386 24.87 0 32.84 0 42.299v.002h41.134v-.002c0-9.46-6.386-17.427-15.082-19.827A10.587 10.587 0 0 1 20.567 24c-1.997 0-3.87-.557-5.485-1.53Z"
          fill={ANColor.common500}
        />
      </Svg>
    </View>
  );
};

export default ANAvatar;
