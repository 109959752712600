import React, { FC } from 'react';
import { ViewStyle } from 'react-native';
import { MetricsSizes } from '../../theme/ANMetrics';
import AudioRowExplorerItem from './AudioRowExplorerItem';

type Props = {
  title: string;
  duration: number;
  timeFlagInMilli?: number;
  style?: ViewStyle;
  onPress: () => void;
};

const PodcastRowItem: FC<Props> = ({ title, timeFlagInMilli, duration, style, onPress }) => {
  return (
    <AudioRowExplorerItem
      title={title}
      duration={duration / 1000}
      timeFlagInMilli={timeFlagInMilli}
      useInvitedMode={false}
      isLockedForGuests={false}
      isFavorite={false}
      onPress={onPress}
      onFavoriteTapped={undefined}
      style={{
        marginHorizontal: MetricsSizes.regular,
      }}
    />
  );
};

export default PodcastRowItem;
