import React, { FC, useMemo } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import I18n from '../i18n';
import ANVideo from '../model/ANVideo';
import ANExercice from '../model/ANVideoGroup';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import { hapticSelection } from '../util/utils';
import useVideoLevelSelection from './bottomsheet/useVideoLevelSelection';
import VideoLevelPicker from './VideoLevelPicker';

type Props = {
  exercice?: ANExercice;
  useLimitedMode: boolean;
  paddingBottom?: number;
  onClose: () => void;
};

const TrainingLevelSwitchBody: FC<Props> = ({
  paddingBottom,
  exercice,
  useLimitedMode,
  onClose,
}) => {
  const { onLevelSelected, selectableVideos } = useVideoLevelSelection(exercice, useLimitedMode);

  const hasSeveralLevels = useMemo(() => {
    return (exercice?.videos.length ?? 0) > 1;
  }, [exercice]);

  const levelSelected = (video: ANVideo) => {
    hapticSelection();
    onLevelSelected(video);
    onClose();
  };
  return (
    <View style={[styles.reviewSheetContainer, { paddingBottom: paddingBottom }]}>
      <Text style={[ANFont.h6Bold, styles.title]}>{I18n.t('training.switch_level.title')}</Text>
      {hasSeveralLevels ? (
        <View>
          <Text style={[ANFont.textLargeRegular, styles.body]}>
            {I18n.t('training.switch_level.description')}
          </Text>
          <FlatList
            data={selectableVideos}
            keyExtractor={({ id }) => {
              return String(id);
            }}
            renderItem={({ item }) => {
              return (
                <VideoLevelPicker
                  level={item.level}
                  selected={item.id === exercice!.selectedVideo.id}
                  onPress={() => levelSelected(item)}
                />
              );
            }}
          />
        </View>
      ) : (
        <Text style={[ANFont.textLargeBold, styles.noLevelAvailable]}>
          {I18n.t('training.switch_level.no_other_level')}
        </Text>
      )}
    </View>
  );
};

export default TrainingLevelSwitchBody;

const styles = StyleSheet.create({
  reviewSheetContainer: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  title: {
    color: ANColor.tertiary500,
    textAlign: 'center',
    alignSelf: 'center',
  },
  body: {
    marginVertical: MetricsSizes.regular,
  },
  noLevelAvailable: {
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: MetricsSizes.giant,
  },
});
