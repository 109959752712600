import React, { FC } from 'react';
import { View, ViewStyle } from 'react-native';

interface Props {
  style?: ViewStyle;
}
const FormView: FC<Props> = ({ children, style }) => {
  return (
    <View style={[style]} keyboardVerticalOffset={50}>
      {children}
    </View>
  );
};
export default FormView;
