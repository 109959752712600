import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';

import AppIntroSlider from 'react-native-app-intro-slider';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ANLocalVideos } from '../../../assets/video';
import CloseModaleButton from '../../../component/CloseModaleButton';
import UserContext from '../../../context/UserContext';
import useNotifications from '../../../hooks/useNotifications';
import I18n from '../../../i18n';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import { isDevice } from '../../../util/Device';
import { EventKey, logEvent } from '../../../util/analytics';
import OnboardingSlide1 from './OnboardingSlide1';
import OnboardingSlide2 from './OnboardingSlide2';
import OnboardingSlideNotifications from './OnboardingSlideNotifications';

type Props = {
  shouldDismissWebModal: () => void | undefined;
};

const OnboardingScreen = ({ shouldDismissWebModal }: Props) => {
  const navigation = isDevice()
    ? useNavigation<NativeStackNavigationProp<RootStackParamList>>()
    : undefined;
  const { top: _topInset, bottom } = isDevice() ? useSafeAreaInsets() : { top: 50, bottom: 0 };
  const { isNotificationAllowed, requestNotification } = useNotifications();

  const top = useMemo(() => {
    return isDevice() ? _topInset : 50;
  }, []);

  const { onboardingTriggered, onboardingSeen } = useContext(UserContext);
  const [playIntro, setPlayIntro] = React.useState(true);

  const slides = useMemo(() => {
    let slides = [
      <OnboardingSlide1 top={top} bottom={bottom} />,
      <OnboardingSlide2 top={top} bottom={bottom} />,
    ];

    if (Platform.OS === 'ios') {
      //No notification permission to ask on Android
      slides = [
        ...slides,
        <OnboardingSlideNotifications
          onNotificationPermissionRequest={requestNotification}
          top={top}
          notificationAllawed={isNotificationAllowed}
          bottom={bottom}
        />,
      ];
    }
    return slides;
  }, [isNotificationAllowed]);

  useEffect(() => {
    logEvent(EventKey.onboarding_screen_opened);
    if (isDevice()) {
      navigation?.navigate(Route.videoPlayerFullScreenModal, {
        localVideo: ANLocalVideos.introduction,
      });
    }
    onboardingTriggered();
  }, []);

  const onIntroductionVideoClosed = (completed: boolean) => {
    setPlayIntro(false);
    if (completed) {
      logEvent(EventKey.intro_video_completed);
    } else {
      logEvent(EventKey.intro_video_exit);
    }
  };

  const renderItem = ({ item }) => {
    return item;
  };
  const onDone = useCallback(() => {
    if (isDevice()) {
      navigation?.navigate(Route.trainingProgramNotificationsScreen, { openedInOnboarding: true });
    } else {
      shouldDismissWebModal?.();
    }
  }, []);

  const onCancel = useCallback(() => {
    if (isDevice()) {
      navigation?.getParent()?.goBack();
    } else {
      shouldDismissWebModal?.();
    }
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <AppIntroSlider
        renderItem={renderItem}
        data={slides}
        onDone={onDone}
        onSkip={onDone}
        showPrevButton
        activeDotStyle={styles.activeDot}
        dotStyle={styles.inactiveDot}
        style={styles.container}
        renderNextButton={() => {
          return (
            <Text style={[ANFont.h6Bold, styles.button]}>{I18n.t('onboarding.next_button')}</Text>
          );
        }}
        renderPrevButton={() => {
          return (
            <Text style={[ANFont.h6Bold, styles.button]}>{I18n.t('onboarding.prev_button')}</Text>
          );
        }}
        renderSkipButton={() => {
          return (
            <Text style={[ANFont.h6Bold, styles.button]}>{I18n.t('onboarding.skip_button')}</Text>
          );
        }}
        renderDoneButton={() => {
          return (
            <Text style={[ANFont.h6Bold, styles.button]}>
              {isDevice() ? I18n.t('onboarding.continue_button') : I18n.t('onboarding.done_button')}
            </Text>
          );
        }}
      />
      {onboardingSeen && isDevice() && (
        <CloseModaleButton color={ANColor.commonBlack} onPress={onCancel} />
      )}
    </View>
  );
};

export default OnboardingScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: ANColor.primary100,
  },
  button: {
    color: ANColor.primary500,
    textDecorationLine: 'underline',
    fontSize: 14,
    marginTop: 8,
  },
  activeDot: {
    backgroundColor: ANColor.tertiary500,
  },
  inactiveDot: {
    backgroundColor: ANColor.tertiary200,
  },
});
