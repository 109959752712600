import { ANTrainingSchedule } from '../../../model/ANTrainingSchedule';
import { Api } from '../../apiclient';
import { Patient } from '../../data/APIDataResponse';
import { parseAPIError } from '../../data/ResponseError';

export const putTrainingSchedule = async (trainingSchedule: ANTrainingSchedule[]) => {
  const path = '/patient/me/update';
  try {
    const response = await Api.put(path, { trainingSchedule });
    const result: Patient = response.data;
    console.log(result);
    if (result) {
      const schedule: ANTrainingSchedule[] = result.trainingSchedule;
      return schedule;
    }
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const putFCMToken = async (fcmToken: string) => {
  const path = '/patient/me/update';
  Api.put(path, { fcmToken });
};
