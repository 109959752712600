import React, { useState } from 'react';
import { KeyboardTypeOptions, StyleSheet, Text, TextInput, View, ViewStyle } from 'react-native';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import { MetricsSizes } from '../../theme/ANMetrics';
import { ExpoIconDesign, ExpoIconPressable } from '../ExpoIcon';

interface InputGroupProps {
  label?: string;
  placeholder?: string;
  value: string;
  password?: boolean;
  errorDetails?: string;
  type?: KeyboardTypeOptions;
  style?: ViewStyle;
  isDisabled?: boolean;
  inputRef?: React.RefObject<TextInput>;
  returnKeyType: 'next' | 'send' | 'done';
  multiline?: boolean;
  maxLength?: number;
  inputStyle?: ViewStyle;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  onSubmit?: () => void;
  onChangeText: (value: string) => void;
  onBlur?: () => void;
}
const HIT_SLOP = { bottom: 15, right: 15, left: 15, top: 15 };

export const InputGroup: React.FunctionComponent<InputGroupProps> = ({
  label,
  placeholder,
  value,
  password,
  type = 'default',
  style,
  errorDetails,
  maxLength,
  autoCapitalize = 'none',
  inputRef,
  returnKeyType,
  multiline = false,
  inputStyle,
  onSubmit,
  onChangeText,
  onBlur,
}) => {
  const [hiddenPassword, setHiddenPassword] = useState(true);

  const switchDisplayPassword = () => {
    setHiddenPassword(!hiddenPassword);
  };

  return (
    <View style={[styles.container, style]}>
      {Boolean(label) && <Text style={[ANFont.textMediumRegular, styles.label]}>{label}</Text>}
      <View style={styles.inputWithIconContainer}>
        <TextInput
          ref={inputRef}
          style={
            errorDetails
              ? [ANFont.textMediumRegular, styles.input, styles.inputError, inputStyle]
              : [ANFont.textMediumRegular, styles.input, inputStyle]
          }
          placeholder={placeholder}
          value={value}
          placeholderTextColor={ANColor.common400}
          onChangeText={onChangeText}
          onBlur={onBlur}
          onSubmitEditing={onSubmit}
          selectionColor={ANColor.commonBlack}
          autoCorrect={false}
          autoCapitalize={autoCapitalize}
          secureTextEntry={password && hiddenPassword}
          keyboardType={type}
          maxLength={maxLength}
          multiline={multiline}
          returnKeyType={returnKeyType}
        />
        {password && (
          <View style={styles.password}>
            <ExpoIconPressable
              onPress={switchDisplayPassword}
              hitSlop={HIT_SLOP}
              design={ExpoIconDesign.MaterialCommunity}
              name={hiddenPassword ? 'eye' : 'eye-off'}
            />
          </View>
        )}
      </View>
      {Boolean(errorDetails) && (
        <Text style={[ANFont.textSmallRegular, styles.errorLabel]}>{errorDetails}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    color: ANColor.commonBlack,
  },
  errorLabel: {
    color: ANColor.statusWarning500,
    marginBottom: 10,
  },
  input: {
    flex: 1,
    borderRadius: MetricsSizes.cornerRadius,
    paddingHorizontal: 5,
    textAlignVertical: 'top',
    overflow: 'hidden',
    paddingVertical: 8,
  },
  inputWithIconContainer: {
    marginVertical: 12,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: ANColor.commonWhite,
  },
  password: {
    position: 'absolute',
    paddingHorizontal: MetricsSizes.regular,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputError: {
    borderColor: ANColor.statusWarning500,
  },
  container: {
    paddingVertical: 2,
  },
});
