import { Api } from '../../apiclient';
import { UpdatePasswordResponse } from '../../data/AuthResponse';
import { parseAPIError } from '../../data/ResponseError';

export const postNewPassword = async (newPassword: string) => {
  try {
    console.log('Define new password');

    const response = await Api.post('/password/defineFirstPassword', {
      newPassword: newPassword,
    });
    const { jwt }: UpdatePasswordResponse = response.data;

    return jwt;
  } catch (err) {
    throw parseAPIError(err);
  }
};

export const postUpdatePassword = async (currentPassword: string, newPassword: string) => {
  try {
    console.log('Update password');

    const response = await Api.post('/password/update', {
      currentPassword: currentPassword,
      newPassword: newPassword,
    });

    const { jwt }: UpdatePasswordResponse = response.data;

    return jwt;
  } catch (err) {
    throw parseAPIError(err);
  }
};
