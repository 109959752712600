import { Api } from '../../apiclient';
import { parseAPIError } from '../../data/ResponseError';
import { parseExercice } from './../../../model/adapters';

export const putExerciceLevelUpdate = async (exerciceId: number, newVideoId: number) => {
  try {
    console.log('update exercice level');

    const response = await Api.put('/patient/me/updateExerciceLevelSelection', {
      exerciceId: exerciceId,
      newVideoId: newVideoId,
    });
    const data = response.data;
    if (data) return parseExercice(response.data);
  } catch (err) {
    throw parseAPIError(err);
  }
};
