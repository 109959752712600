import { DefinePasswordProps } from '../screen/auth/AuthDefinePasswordScreen';
import { BreathingExerciceScreenProps } from '../screen/explorer/BreathExercice/BreathingExerciceScreen.native';
import { ExplorerAudioPlayerProps } from '../screen/explorer/ExplorerAudioPlayerScreen.native';
import { DifficultyCheckScreenProps } from '../screen/home/DifficultyReport/DifficultyReportScreen';
import { DifficultyCheckThanksScreenProps } from '../screen/home/DifficultyReport/DifficultyReportThanksScreen';
import { OnboardingWebProps } from '../screen/home/Onboarding/OnboardingNavigator';
import { TrainingSessionScreenProps } from '../screen/home/TrainingSession/TrainingSessionScreen';
import { VideoFullScreenPlayerProps } from '../screen/home/VideoPlayerScreen/VideoFullScreenPlayerScreen';
import { ScheduleTrainingNotificationsScreenProps } from '../screen/profile/ScheduleTraining/ScheduleTrainingNotificationsScreen';
import { ExplorerTopicProps } from './../screen/explorer/ExplorerTopicScreen';
import { TrainingEndNavigatorProps } from './TrainingSessionEndNavigator.web';

export enum Route {
  trainingSession = 'training-session',
  trainingSessionEndNavigator = 'training-session-end',
  healthCheckupNavigator = 'health-checkup-navigator',
  trainingCongratsScreen = 'training-congrats',
  trainingPresentation = 'training-presentation',
  videoPlayerFullScreen = 'video-player-fullscreen',
  videoPlayerFullScreenModal = 'video-player-fullscreen-modal',
  authScreen = 'auth',
  authDefinePassword = 'auth-define-password',
  authResetPassword = 'auth-reset-password',
  onboardingNavigator = 'onboarding',
  onboardingScreen = 'onboarding-screen',
  explorerScreen = 'explorer',
  dashboardScreen = 'dashboard',
  profileScreen = 'profile',
  tabNavigator = 'tabs',
  appointmentsHistoryScreen = 'appointments-history',
  updatePasswordScreen = 'update-password',
  checkUpReviewScreen = 'checkup-review',
  difficultyCheckScreen = 'difficulty-report',
  difficultyExercicePickerScreen = 'difficulty-exercice-picker',
  difficultyCheckThanksScreen = 'difficulty-report-thanks',
  trainingProgramNotificationsScreen = 'training-program-notifications',
  explorerTopicScreen = 'explorer-topic',
  explorerAudioPlayerScreen = 'explorer-audio-player',
  explorerPodcastScreen = 'explorer-podcast',
  breathingExerciceScreen = 'breathing-exercice',
  invitedModeScreen = 'invited-mode',
  deleteAccountScreen = 'delete-account',
  notificationPreferenceScreen = 'notification-preference',
  explorerPodcastsListScreen = 'explorer-podcasts',
}

export type RootStackParamList = {
  [Route.trainingPresentation]: undefined;
  [Route.trainingSession]: TrainingSessionScreenProps;
  [Route.trainingSessionEndNavigator]: undefined;
  [Route.trainingCongratsScreen]: TrainingEndNavigatorProps;
  [Route.videoPlayerFullScreen]: VideoFullScreenPlayerProps;
  [Route.videoPlayerFullScreenModal]: VideoFullScreenPlayerProps;
  [Route.authScreen]: undefined;
  [Route.authDefinePassword]: DefinePasswordProps;
  [Route.authResetPassword]: undefined;
  [Route.onboardingNavigator]: undefined;
  [Route.onboardingScreen]: OnboardingWebProps;
  [Route.explorerScreen]: undefined;
  [Route.profileScreen]: undefined;
  [Route.dashboardScreen]: undefined;
  [Route.tabNavigator]: undefined;
  [Route.appointmentsHistoryScreen]: undefined;
  [Route.updatePasswordScreen]: undefined;
  [Route.checkUpReviewScreen]: undefined;
  [Route.difficultyCheckScreen]: undefined;
  [Route.difficultyExercicePickerScreen]: DifficultyCheckScreenProps;
  [Route.difficultyCheckThanksScreen]: DifficultyCheckThanksScreenProps;
  [Route.trainingProgramNotificationsScreen]: ScheduleTrainingNotificationsScreenProps;
  [Route.explorerTopicScreen]: ExplorerTopicProps;
  [Route.explorerPodcastScreen]: ExplorerTopicProps;
  [Route.explorerAudioPlayerScreen]: ExplorerAudioPlayerProps;
  [Route.breathingExerciceScreen]: BreathingExerciceScreenProps;
  [Route.invitedModeScreen]: undefined;
  [Route.deleteAccountScreen]: undefined;
  [Route.notificationPreferenceScreen]: undefined;
  [Route.explorerPodcastsListScreen]: undefined;
  [Route.healthCheckupNavigator]: undefined;
};
