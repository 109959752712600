import uuid from 'react-native-uuid';
import { ANTrainingSchedule, Day } from '../../model/ANTrainingSchedule';

const useScheduleTrainingNotification = (
  scheduledNotifications: ANTrainingSchedule[],
  onScheduleChanged: (newSchedule: ANTrainingSchedule[]) => void,
) => {
  const dayIsSelected = (day: Day) => {
    return scheduledNotifications
      .filter(item => {
        return item.times.length > 0;
      })
      .map(item => item.weekday)
      .includes(day);
  };

  const addNewTime = (day: Day) => {
    console.log('Add new time for day: ', day);
    const newScheduledNotifications = scheduledNotifications.map(item => {
      if (item.weekday === day) {
        return {
          ...item,
          times: [...item.times, { id: String(uuid.v4()), hour: 12, minute: 0 }],
        };
      }
      return item;
    });
    onScheduleChanged(newScheduledNotifications);
  };

  const removeTime = (day: Day, id: string) => {
    console.log('Remove', day, id);
    const newScheduledNotifications = scheduledNotifications.map(item => {
      if (item.weekday === day) {
        return {
          ...item,
          times: item.times.filter(time => time.id !== id),
        };
      }
      return item;
    });
    onScheduleChanged(newScheduledNotifications);
  };

  const updateTime = (day: Day, id: string, newHour: number, newMinute: number) => {
    console.log('Update', day, id, newHour, newMinute);
    const newScheduledNotifications = scheduledNotifications.map(item => {
      if (item.weekday === day) {
        return {
          ...item,
          times: item.times.map(time => {
            if (time.id == id) {
              return {
                id: id,
                hour: newHour,
                minute: newMinute,
              };
            }
            return time;
          }),
        };
      }
      return item;
    });
    onScheduleChanged(newScheduledNotifications);
  };

  const scheduledTimesForDay = (day: Day) => {
    const currentDay = scheduledNotifications.filter(item => item.weekday === day);
    if (currentDay.length > 0) {
      const timesToDisplay = currentDay[0].times;
      return timesToDisplay;
    }
    return [];
  };

  return {
    addNewTime,
    removeTime,
    updateTime,
    scheduledTimesForDay,
    dayIsSelected,
  };
};

export default useScheduleTrainingNotification;
