import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, StatusBar } from 'react-native';
import EmptyList from '../../component/EmptyList';
import ListItemSeparator from '../../component/ListItemSeparator';
import ANScreenContainer from '../../component/ScreenContainer';
import ExplorerAudioWebPlayerModal from '../../component/modal/ExplorerAudioWebPlayerModal';
import usePaginationLocalData from '../../hooks/usePaginationLocalData';
import ANMedia from '../../model/ANMedia';
import { ANColor } from '../../theme/ANColor';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import ExplorerTopicHeader from './ExplorerTopicHeader';
import PodcastRowItem from './PodcastRowItem';
import useExplorerPodcastScreen from './useExplorerPodcastScreen';

const _fadeDuration = 250;

const ExplorerPodcastScreen = () => {
  const navigation = useNavigation();

  const {
    podcast,
    podcastItemsWithProgress,
    selectedPodcast,
    isLoading,
    onPodcastPressed,
    onAudioExit,
  } = useExplorerPodcastScreen();

  const { loadNextPage, dataToDisplay } = usePaginationLocalData(podcast?.items);

  const [isAudioWebModalVisible, setAudioWebModalVisible] = useState(false);

  useEffect(() => {
    console.log('podcast', selectedPodcast);
    if (selectedPodcast) {
      setAudioWebModalVisible(true);
    } else {
      setAudioWebModalVisible(false);
    }
  }, [selectedPodcast]);

  useEffect(() => {
    navigation.setOptions({
      headerTitle: podcast?.name,
    });
  }, [podcast]);

  const podcastTapped = (podcast: ANMedia) => {
    onPodcastPressed(podcast);
  };

  const _renderItem = (item: ANMedia, index: number) => {
    return (
      <PodcastRowItem
        title={item.title}
        duration={item.duration}
        timeFlagInMilli={item.timeFlagInMilli}
        style={{ marginHorizontal: MetricsSizes.regular }}
        onPress={() => podcastTapped(item)}
      />
    );
  };

  const listHeader = useCallback(() => {
    return <ExplorerTopicHeader title={podcast?.name} selectedAuthor={podcast?.author} />;
  }, [podcast]);

  return (
    <ANScreenContainer useHorizontalPadding={false}>
      <StatusBar backgroundColor={ANColor.primary300} />

      {!isDevice() && (
        <ExplorerAudioWebPlayerModal
          media={selectedPodcast}
          timeFlag={selectedPodcast?.timeFlagInMilli}
          modalVisible={isAudioWebModalVisible}
          shouldDismiss={onAudioExit}
        />
      )}
      <FlatList
        data={podcastItemsWithProgress}
        scrollEventThrottle={16}
        ListHeaderComponent={listHeader()}
        ItemSeparatorComponent={() => {
          return <ListItemSeparator />;
        }}
        ListEmptyComponent={<EmptyList isLoading={isLoading} />}
        renderItem={({ item, index }) => {
          return _renderItem(item, index);
        }}
        keyExtractor={({ title }) => {
          return title;
        }}
        onEndReachedThreshold={0.9}
        onEndReached={loadNextPage}
      />
    </ANScreenContainer>
  );
};

export default ExplorerPodcastScreen;
