import { Api } from '../../../apiclient';
import { MediaFavorite } from '../../../data/APIDataResponse';
import { parseAPIError } from '../../../data/ResponseError';
import { parseMedia } from './../../../../model/adapters';
import { ExplorerMedia } from './../../../data/APIDataResponse';

export const getDetailedFavorites = async () => {
  const path = `/patient/me/getAllFavorites`;
  try {
    const response = await Api.get(path);

    const data: ExplorerMedia[] = response.data;
    return data.map(media => parseMedia(media));
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const addMediaFromFavorite = async (mediaId: number) => {
  const path = `/patient/me/addMediaFavorite`;
  try {
    const response = await Api.put(path, { mediaId });

    const data: MediaFavorite[] = response.data;

    return data;
  } catch (err) {
    console.log(err);
    throw parseAPIError(err, path);
  }
};

export const removeMediaFromFavorite = async (mediaId: number) => {
  const path = `/patient/me/removeMediaFavorite`;

  try {
    const response = await Api.put(path, { mediaId });

    const data: MediaFavorite[] = response.data;

    return data;
  } catch (err) {
    throw parseAPIError(err, path);
  }
};
