import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { useContext, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, TextInput, View } from 'react-native';
import { useMutation } from 'react-query';
import { postUpdatePassword } from '../../api/request/user/postNewPassword';
import ANButton from '../../component/ANButton';
import ANSafeAreView from '../../component/ANSafeAreView';
import FormView from '../../component/form/FormView';
import { InputGroup } from '../../component/form/InputGroup';
import SnackMessageContext from '../../context/SnackMessageContext';
import UserContext from '../../context/UserContext';
import I18n from '../../i18n';
import { RootStackParamList } from '../../navigation/Route';
import { default as ANLayout } from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { updatePasswordScheme } from '../../util/validationScheme';

type FormValues = {
  password: string;
  new_password: string;
  confirm_password: string;
};

type ResetPasswordBody = {
  currentPassword: string;
  newPassword: string;
};
const UpdatePasswordScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const newPasswordRef = useRef<TextInput>();
  const confirmPasswordRef = useRef<TextInput>();

  const { userLoggedIn } = useContext(UserContext);

  const { control, handleSubmit, getValues } = useForm<FormValues>({
    resolver: yupResolver(updatePasswordScheme),
    mode: 'onBlur',
  });
  const { displaySnackBarError, displaySnackBarInfo } = useContext(SnackMessageContext);

  const { mutateAsync, isLoading } = useMutation((body: ResetPasswordBody) => {
    return postUpdatePassword(body.currentPassword, body.newPassword);
  });

  const updatePassword = async () => {
    try {
      const jwt = await mutateAsync({
        currentPassword: getValues('password'),
        newPassword: getValues('new_password'),
      });
      if (jwt) userLoggedIn(jwt, false);
      displaySnackBarInfo(I18n.t('profile.password_updated'));
      navigation.goBack();
    } catch (error) {
      displayError();
    }
  };

  const displayError = () => {
    displaySnackBarError(I18n.t('error.unknown'));
  };

  return (
    <ANSafeAreView style={[ANLayout.screenContaineNoPadding]} ignoreTopInset>
      <View style={ANLayout.screenContainer}>
        <FormView>
          <View style={[styles.controllersContainer]}>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <InputGroup
                  label={I18n.t('profile.current_password')}
                  value={value}
                  onChangeText={onChange}
                  password={true}
                  errorDetails={error?.message}
                  onBlur={onBlur}
                  returnKeyType="next"
                  onSubmit={() => {
                    newPasswordRef.current?.focus();
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="new_password"
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <InputGroup
                  label={I18n.t('auth.new_password')}
                  value={value}
                  inputRef={newPasswordRef}
                  onChangeText={onChange}
                  password={true}
                  errorDetails={error?.message}
                  onBlur={onBlur}
                  returnKeyType="next"
                  onSubmit={() => {
                    confirmPasswordRef.current?.focus();
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="confirm_password"
              render={({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                <InputGroup
                  inputRef={confirmPasswordRef}
                  label={I18n.t('auth.password_confirm')}
                  value={value}
                  password={true}
                  errorDetails={error?.message}
                  onChangeText={onChange}
                  returnKeyType="done"
                  style={{ marginTop: 10 }}
                  onBlur={onBlur}
                  onSubmit={handleSubmit(updatePassword)}
                />
              )}
            />
          </View>
        </FormView>
        <View>
          <ANButton
            onPress={handleSubmit(updatePassword)}
            isLoading={isLoading}
            title="Valider"
            cornerType="full"
            style={{ marginTop: MetricsSizes.big, marginBottom: MetricsSizes.regular }}
          />
        </View>
      </View>
    </ANSafeAreView>
  );
};

const styles = StyleSheet.create({
  controllersContainer: {
    marginTop: MetricsSizes.giant,
  },
});

export default UpdatePasswordScreen;
