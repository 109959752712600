import React, { FC } from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import I18n from '../i18n';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';

type Props = {
  isLoading: boolean;
  emptyMessage?: string;
};

const EmptyList: FC<Props> = ({ isLoading, emptyMessage }) => {
  return (
    <View>
      {isLoading ? (
        <ActivityIndicator size="small" style={styles.activityIndicator} />
      ) : (
        <Text style={styles.emptyLabel}>
          {emptyMessage ? emptyMessage : I18n.t('common.no_data_list')}
        </Text>
      )}
    </View>
  );
};

export default EmptyList;

const styles = StyleSheet.create({
  emptyLabel: {
    ...ANFont.textMediumBold,
    textAlign: 'center',
  },
  activityIndicator: {
    marginTop: MetricsSizes.big,
  },
});
