import { AVPlaybackStatus } from 'expo-av';
import { useEffect } from 'react';
import { EventKey, logEvent } from '../../../util/analytics';
import { VideoPlayerProps } from './VideoFullScreenPlayerScreen';

const useWebVideoPlayer = ({ uri, videoRef, onExit }: VideoPlayerProps) => {
  useEffect(() => {
    videoRef.current?.setOnPlaybackStatusUpdate(onPlayBackUpdate);
    if (uri !== undefined) {
      logEvent(EventKey.video_view, { link_url: uri });
    }
  }, [uri]);

  //Check status update
  const onPlayBackUpdate = async (status: AVPlaybackStatus) => {
    if (status.didJustFinish) {
      //await videoRef.current?.dismissFullscreenPlayer();
      onExit(status.positionMillis, true);
      if (uri !== undefined) {
        logEvent(EventKey.video_completed, { link_url: uri });
      }
    }
  };
};

export default useWebVideoPlayer;
