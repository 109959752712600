import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Platform, Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native';
import ANProgressBar from '../../component/ANProgressBar';
import ExpoIcon from '../../component/ExpoIcon';
import FilterChip from '../../component/FilterChip';
import FullLoadingOverlay from '../../component/FullLoadingOverlay';
import HeartButton from '../../component/HeartButton';
import ANImageView from '../../component/Image';
import LockedForGuestsOverlay from '../../component/LockedForGuestsOverlay';
import useHour from '../../hooks/useHour';
import { ANAuthor } from '../../model/ANAuthor';
import { ANColor, withOpacity } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import { padding } from '../../util/padding';

type Props = {
  title: string;
  duration?: number;
  timeFlagInMilli?: number;
  previewUrl?: string;
  author?: ANAuthor;
  isFavorite: boolean;
  isLockedForGuests: boolean;
  difficulty: string;
  isLoading: boolean;
  horizontal?: boolean;
  style?: ViewStyle[];
  itemWidth?: number;
  itemHeight?: number;
  useInvitedMode: boolean;
  onFavoriteTapped: () => void;
  onPress: () => void;
};

const ITEM_HEIGHT_VERTICAL_LIST = Platform.OS == 'web' ? 275 : 175;

const VideoRowExplorerItem: React.FC<Props> = ({
  title,
  duration = 0,
  previewUrl,
  author,
  difficulty,
  timeFlagInMilli = 0,
  itemWidth,
  itemHeight,
  isLockedForGuests = false,
  onPress,
  isFavorite,
  isLoading,
  onFavoriteTapped,
  useInvitedMode,
  style,
  horizontal = false,
}) => {
  const { createDurationString } = useHour();

  const mediaPressed = () => {
    if (!isLoading) onPress();
  };
  return (
    <View
      style={[
        ANLayout.roundedBackground,
        styles.container,
        horizontal ? { width: itemWidth ?? 265 } : { height: ITEM_HEIGHT_VERTICAL_LIST },
        itemHeight ? { height: itemHeight } : {},
        !isDevice() && { width: itemWidth ?? 265, aspectRatio: 350 / 350 },
        style,
      ]}
    >
      <ANImageView source={{ uri: previewUrl }} style={styles.image} />
      <LinearGradient
        colors={[withOpacity(80, ANColor.commonBlack), withOpacity(0, ANColor.commonBlack)]}
        style={styles.gradient}
        start={[0, 1]}
        end={[0, 0]}
      />

      {difficulty && <FilterChip title={difficulty} isSelected style={styles.difficulty} />}

      <View style={styles.bottomContainer}>
        <View style={styles.textContainer}>
          <Text style={[ANFont.textLargeBold, styles.title]}>{title}</Text>
          {author && (
            <Text style={[ANFont.textSmallRegular, styles.subtitle]}>
              {author?.name + ' - ' + createDurationString(duration * 1000)}
            </Text>
          )}
          {timeFlagInMilli != 0 && (
            <ANProgressBar
              totalDurationInSeconds={duration ?? 0}
              flaggedDurationInMilli={timeFlagInMilli}
              style={styles.progressbar}
            />
          )}
        </View>
        <ExpoIcon name="play-circle-fill" color={ANColor.commonWhite} size={28} />
      </View>
      <Pressable
        onPress={mediaPressed}
        style={{ width: '100%', height: '100%', position: 'absolute' }}
      />
      {!useInvitedMode && (
        <View style={styles.heartContainer}>
          <HeartButton isFavorite={isFavorite} onPress={onFavoriteTapped} style={styles.heart} />
        </View>
      )}
      {isLoading && isDevice() && <FullLoadingOverlay canDisplayCancel={false} />}
      {isLockedForGuests && useInvitedMode && <LockedForGuestsOverlay />}
    </View>
  );
};

export default VideoRowExplorerItem;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    ...padding(0),
  },
  image: {
    position: 'absolute',
    backgroundColor: ANColor.primary200,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  bottomContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...padding(MetricsSizes.regular),
  },
  textContainer: {
    flex: 1,
  },
  title: {
    color: ANColor.commonWhite,
  },
  subtitle: {
    color: ANColor.commonWhite,
    marginRight: MetricsSizes.small,
  },
  progressbar: {
    marginTop: MetricsSizes.small,
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    height: '40%',
    left: 0,
    right: 0,
  },
  heartContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  heart: {
    ...padding(MetricsSizes.regular),
  },
  difficulty: {
    position: 'absolute',
    left: MetricsSizes.regular,
    top: MetricsSizes.small,
  },
});
