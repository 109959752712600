import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { DateTimePickerAndroid } from '@react-native-community/datetimepicker';
import React, { FC, useState } from 'react';
import { Platform, Pressable, StyleSheet, Text, ViewStyle } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';

import useHour from '../hooks/useHour';
import I18n from '../i18n';
import { Day } from '../model/ANTrainingSchedule';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { padding } from '../util/padding';
import TimePickerBottomsheet from './bottomsheet/IOSTimePickerBottomsheet';
import ExpoIcon, { ExpoIconPressable } from './ExpoIcon';

type Props = {
  id: number | string;
  selectedHours: number;
  selectedMinutes: number;
  day: Day;
  style?: ViewStyle;
  onDelete: (day: Day) => void;
  onTimeChanged: (day: Day, hour: number, minute: number) => void;
};

const DayTimePicker: FC<Props> = ({
  day,
  selectedHours,
  selectedMinutes,
  style,
  onDelete,
  onTimeChanged,
}) => {
  const timePickerSheetRef = React.useRef<BottomSheetModal>(null);
  const { createTimeString } = useHour();

  const [date, setDate] = useState<Date>(() => {
    const date = new Date();
    date.setHours(selectedHours);
    date.setMinutes(selectedMinutes);
    return date;
  });

  const timeChanged = (date: Date) => {
    setDate(date);
    onTimeChanged(day, date.getHours(), date.getMinutes());
    if (Platform.OS === 'ios') {
      timePickerSheetRef.current?.close();
    }
  };

  const configureHour = () => {
    if (Platform.OS === 'android') {
      DateTimePickerAndroid.open({
        value: new Date(Date.now()),
        onChange: (event, date) => {
          if (date) timeChanged(date);
        },
        mode: 'time',
        is24Hour: true,
      });
    } else {
      timePickerSheetRef.current?.present();
    }
  };

  return (
    <Animated.View style={[styles.container, style]} entering={FadeIn}>
      <Pressable style={styles.hourContainer} onPress={configureHour}>
        <Text style={styles.label}>{I18n.t('program_notifications.training_time')}</Text>
        <Text style={styles.time}>{createTimeString(selectedHours, selectedMinutes)}</Text>
        <ExpoIcon name="access-time" />
        <TimePickerBottomsheet
          selectedDate={date}
          sheetRef={timePickerSheetRef}
          onTimeSelected={timeChanged}
        />
      </Pressable>
      <ExpoIconPressable onPress={() => onDelete(day)} name="close" />
    </Animated.View>
  );
};

export default DayTimePicker;

const styles = StyleSheet.create({
  container: {
    ...ANLayout.rowHCenter,
    ...padding(MetricsSizes.singlePadding),
    backgroundColor: ANColor.commonWhite,
    overflow: 'hidden',
    borderRadius: MetricsSizes.cornerRadius,
  },
  hourContainer: {
    ...ANLayout.rowHCenter,
    flex: 1,
  },
  label: {
    ...ANFont.textLargeRegular,
  },
  time: {
    ...ANFont.textLargeBold,
    color: ANColor.primary500,
    marginHorizontal: MetricsSizes.small,
  },
  delete: {},
});
