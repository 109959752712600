import React, { FC } from 'react';
import { ScrollView, StyleSheet, ViewStyle } from 'react-native';
import I18n from '../i18n';
import { MetricsSizes } from '../theme/ANMetrics';
import FilterChip from './FilterChip';

type Props = {
  tags: string[];
  selectedTag?: string;
  i18nprefix: string;
  style?: ViewStyle;
  onTagSelected: (tag: string) => void;
};

const TagPickerListScroll: FC<Props> = ({
  tags: tags,
  selectedTag,
  style,
  i18nprefix,
  onTagSelected,
}) => {
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{ paddingHorizontal: MetricsSizes.screenHorizontalPadding }}
      style={style}
    >
      {tags &&
        tags.map((tag, index) => {
          return (
            <FilterChip
              key={index}
              title={I18n.t(i18nprefix + tag)}
              isSelected={selectedTag === tag}
              onPress={() => onTagSelected(tag)}
              style={styles.tagChip}
            />
          );
        })}
    </ScrollView>
  );
};

export default TagPickerListScroll;

const styles = StyleSheet.create({
  tagChipContainer: {
    marginVertical: MetricsSizes.small,
    overflow: 'visible',
  },
  tagChip: {
    marginRight: MetricsSizes.regular,
  },
});
