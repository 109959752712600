import {
  AVPlaybackStatus,
  Video,
  VideoFullscreenUpdate,
  VideoFullscreenUpdateEvent,
} from 'expo-av';
import { useCallback, useEffect } from 'react';
import { isDevice } from '../../../util/Device';

export type IntroVideoPlayerProps = {
  videoRef: React.RefObject<Video>;
  onExitPlayerFullscreen?: (completed: boolean) => void;
};

const useIntroductionVideo = ({ videoRef, onExitPlayerFullscreen }: IntroVideoPlayerProps) => {
  //Check status update
  const onPlayBackUpdate = useCallback(
    async (status: AVPlaybackStatus) => {
      if (status.didJustFinish) {
        await videoRef.current?.dismissFullscreenPlayer();
        if (onExitPlayerFullscreen) onExitPlayerFullscreen(true);
      }
    },
    [onExitPlayerFullscreen, videoRef],
  );

  const onFullscreenUpdate = useCallback(
    async (event: VideoFullscreenUpdateEvent) => {
      if (event.fullscreenUpdate == VideoFullscreenUpdate.PLAYER_DID_DISMISS) {
        const status = await videoRef.current?.getStatusAsync();
        if (status !== undefined && status.positionMillis !== status.durationMillis) {
          if (onExitPlayerFullscreen) onExitPlayerFullscreen(false);
        }
      }
    },
    [videoRef, onExitPlayerFullscreen],
  );

  /**
   * On Android full screen must be triggered immediately.
   */
  useEffect(() => {
    if (!isDevice()) return; //Do not display the video in fullscreen on web browser
    try {
      videoRef.current?.presentFullscreenPlayer();
    } catch {
      console.log('Fullscreen already active');
    }
  }, [videoRef.current]);

  /**
   * On iOS, full screen must be triggered on the onReadyForDisplay event
   */
  const onVideoReadyToDisplay = useCallback(() => {
    if (!isDevice()) return; //Do not display the video in fullscreen on web browser
    try {
      videoRef.current?.presentFullscreenPlayer();
    } catch {
      console.log('Fullscreen already active');
    }
  }, [videoRef.current]);

  return {
    onFullscreenUpdate,
    onPlayBackUpdate,
    onVideoReadyToDisplay,
  };
};

export default useIntroductionVideo;
