import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ANButton from '../../../component/ANButton';
import I18n from '../../../i18n';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { EventKey } from '../../../util/analytics';

type Props = {
  onTakeAppointementTapped: () => void;
  onShouldDismiss: () => void;
};

const HealthCheckupThanks: FC<Props> = ({ onShouldDismiss, onTakeAppointementTapped }) => {
  return (
    <View style={{ alignSelf: 'center' }}>
      <Text style={[ANFont.h6Bold, styles.title]}>{I18n.t('checkup_review.thanks.title')}</Text>
      <View style={styles.bodyContainer}>
        <Text style={[ANFont.textLargeRegular, styles.body]}>
          {I18n.t('checkup_review.thanks.body')}
        </Text>
        <ANButton
          style={styles.appointmentButton}
          title={I18n.t('checkup_review.thanks.contact_button')}
          theme="tertiary"
          onPress={onTakeAppointementTapped}
          eventKey={EventKey.health_checkup_appointment}
        />
      </View>
    </View>
  );
};

export default HealthCheckupThanks;

const styles = StyleSheet.create({
  safearea: {
    flex: 1,
    backgroundColor: ANColor.primary100,
  },
  container: {
    width: '100%',
    flex: 1,
    paddingTop: 40,
  },
  title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
  },
  bodyContainer: {
    flex: 1,
    marginTop: MetricsSizes.regular,
    justifyContent: 'center',
  },
  body: {
    textAlign: 'center',
  },

  appointmentButton: {
    marginTop: MetricsSizes.extraLarge,
  },
  slider: {
    marginVertical: MetricsSizes.singlePadding,
  },
});
