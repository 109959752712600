import React, { FC } from 'react';
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';

type Props = {
  title: string;
  isSelected: boolean;
  style?: ViewStyle;
  onPress?: () => void;
};

const FilterChip: FC<Props> = ({ isSelected, title, style, onPress }) => {
  return (
    <Pressable disabled={onPress === undefined} onPress={onPress} style={style}>
      <View
        style={[ANLayout.roundedLabel, isSelected ? styles.selectedContainer : styles.container]}
      >
        <Text style={[ANFont.textSmallRegular, styles.title]}>{title}</Text>
      </View>
    </Pressable>
  );
};

export default FilterChip;

const styles = StyleSheet.create({
  container: {
    borderRadius: 24,
    backgroundColor: ANColor.secondary100,
    borderColor: ANColor.secondary500,
    borderWidth: 1,
  },
  selectedContainer: {
    backgroundColor: ANColor.secondary500,
  },
  title: {
    textAlign: 'center',
  },
});
