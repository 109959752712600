import { MotiView } from 'moti';
import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { Animated, FlatList, Platform, StyleSheet } from 'react-native';
import ListItemSeparator from '../../component/ListItemSeparator';
import ExplorerAudioWebPlayerModal from '../../component/modal/ExplorerAudioWebPlayerModal';
import UserContext from '../../context/UserContext';
import useExplorerMediaPlayer from '../../hooks/useExplorerMedia';
import useFavoriteStore from '../../hooks/useFavoritesStore';
import useLocalMediaLogs from '../../hooks/useLocalMediaLogs';
import I18n from '../../i18n';
import ANMedia from '../../model/ANMedia';
import { isDevice } from '../../util/Device';
import VideoWebPlayerModal from '../home/VideoPlayerScreen/VideoWebPlayerModal';
import AudioWrappedRowItem from './AudioWrappedRowItem';
import VideoRowExplorerItem from './VideoRowExplorerItem';

type Props = {
  data?: ANMedia[];
  isLoading: boolean;
};

const ITEM_WIDTH = 265;
const CONTAINER_HEIGHT = 230;
const _fadeDuration = 500;

const HorizontalMediaList: FC<Props> = ({ data, isLoading }) => {
  const { isInvitedMode } = useContext(UserContext);
  const favoritesIds = useFavoriteStore(state => state.favoritesIds);
  const mediaLogs = useLocalMediaLogs(state => state.mediaLogs);
  const [dataWithPogress, setDataWithProgress] = useState<ANMedia[] | undefined>([]);

  /**
   * Listen to changes into the logs to update the time flag of the medias
   */
  useEffect(() => {
    const unsubLogs = useLocalMediaLogs.subscribe(state => state.mediaLogs);
    return () => {
      unsubLogs();
    };
  }, []);

  useEffect(() => {
    const updatedData = data?.map(media => {
      const log = mediaLogs.find(log => log.mediaId === media.id);
      if (log) {
        media.timeFlagInMilli = log.timeFlagInMilli;
      }
      return media;
    });
    setDataWithProgress(updatedData ?? []);
  }, [mediaLogs, data]);

  const {
    videoToPlay,
    audioToPlayWeb,
    isVideoWebModalVisible,
    setVideoWebModalVisible,
    onVideoExit,
    onMediaPressed,
    favoriteTapped,
    onAudioExit,
  } = useExplorerMediaPlayer();

  const [isAudioWebModalVisible, setAudioWebModalVisible] = useState(false);

  useEffect(() => {
    const unsubFavorites = useFavoriteStore.subscribe(state => state.favoritesIds);
    const unsubLogs = useLocalMediaLogs.subscribe(state => state.mediaLogs);
    return () => {
      unsubFavorites();
      unsubLogs();
    };
  }, []);

  if (Platform.OS === 'web') {
    useEffect(() => {
      if (videoToPlay) {
        setVideoWebModalVisible(true);
      } else {
        setVideoWebModalVisible(false);
      }
    }, [videoToPlay]);

    useEffect(() => {
      if (audioToPlayWeb) {
        setAudioWebModalVisible(true);
      } else {
        setAudioWebModalVisible(false);
      }
    }, [audioToPlayWeb]);
  }

  const _renderItem = (item: ANMedia, index: number) => {
    if (item.type === 'audio' || item.type === 'podcast') {
      return (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'timing',
            delay: index * 100,
            duration: _fadeDuration,
          }}
        >
          <AudioWrappedRowItem
            title={item.title}
            isLockedForGuests={item.requiresAuthentication}
            durationInSec={item.duration}
            timeFlagInMilli={item.timeFlagInMilli}
            itemWidth={ITEM_WIDTH}
            itemHeight={CONTAINER_HEIGHT}
            author={item.author}
            useInvitedMode={isInvitedMode}
            isFavorite={favoritesIds.includes(item.id)}
            onFavoriteTapped={() => favoriteTapped(item.id)}
            onPress={() => onMediaPressed(item)}
          />
        </MotiView>
      );
    } else {
      return (
        <MotiView
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'timing',
            delay: index * 100,
            duration: _fadeDuration,
          }}
        >
          <VideoRowExplorerItem
            title={item.title}
            duration={item.duration}
            timeFlagInMilli={item.timeFlagInMilli}
            horizontal
            isLockedForGuests={item.requiresAuthentication}
            itemHeight={CONTAINER_HEIGHT}
            useInvitedMode={isInvitedMode}
            isLoading={false}
            difficulty={
              item.difficulty ? I18n.t('common.difficulty.' + item.difficulty) : undefined
            }
            author={item.author}
            previewUrl={item.previewUrl}
            isFavorite={favoritesIds.includes(item.id)}
            onFavoriteTapped={() => favoriteTapped(item.id)}
            onPress={() => {
              console.log('Video row pressed');
              onMediaPressed(item);
            }}
          />
        </MotiView>
      );
    }
  };
  console.log('Video to play ', videoToPlay);
  return (
    <Animated.View>
      {!isDevice() && videoToPlay && (
        <VideoWebPlayerModal
          mediaId={videoToPlay.mediaId}
          uri={videoToPlay.uri}
          onExit={onVideoExit}
          modalVisible={isVideoWebModalVisible}
          timeFlag={videoToPlay?.timeFlagInMilli}
          shouldDismiss={() => setVideoWebModalVisible(false)}
        />
      )}

      {!isDevice() && (
        <ExplorerAudioWebPlayerModal
          media={audioToPlayWeb}
          timeFlag={audioToPlayWeb?.timeFlagInMilli}
          modalVisible={isAudioWebModalVisible}
          shouldDismiss={() => onAudioExit()}
        />
      )}

      <FlatList
        data={dataWithPogress}
        horizontal
        showsHorizontalScrollIndicator={!isDevice()}
        contentContainerStyle={{ height: CONTAINER_HEIGHT }}
        style={styles.container}
        snapToInterval={ITEM_WIDTH}
        snapToAlignment="center"
        decelerationRate={'fast'}
        renderItem={({ item, index }) => {
          return _renderItem(item, index);
        }}
        ItemSeparatorComponent={() => {
          return <ListItemSeparator horizontal />;
        }}
        keyExtractor={({ id }) => {
          return String(id);
        }}
      />
    </Animated.View>
  );
};

export default memo(HorizontalMediaList);

const styles = StyleSheet.create({
  container: {
    overflow: 'visible',
  },
});
