import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://e60db0b90b9443deb3a9ca06f4f7692e@o1385693.ingest.sentry.io/6705766',
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  environment: __DEV__ ? 'development' : 'production',
  tracesSampleRate: __DEV__ ? 1.0 : 0.8,
});

export const setSentryUserIdentifier = (userId: string, email: string) => {
  console.log('Set Sentry web user id: ' + userId + ' email: ' + email);
  Sentry.setUser({ id: userId, email: email });
};

export default Sentry;
