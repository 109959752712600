import { MotiText } from 'moti';
import React, { FC, useContext, useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { useQuery } from 'react-query';
import { getMotivationSentences } from '../../../../api/request/user/getMotivationSentence';
import UserContext from '../../../../context/UserContext';
import useHour from '../../../../hooks/useHour';
import I18n from '../../../../i18n';
import { ANColor } from '../../../../theme/ANColor';
import ANFont from '../../../../theme/ANFont';
import { MetricsSizes } from '../../../../theme/ANMetrics';
import { isDevice } from '../../../../util/Device';

const _fadeDuration = 1000;

type Props = {
  style?: ViewStyle;
};
const DashboardHeader: FC<Props> = ({ style }) => {
  const { isNight } = useHour();
  const { user, isInvitedMode } = useContext(UserContext);
  const { data } = useQuery('motivation', () => getMotivationSentences());

  const randomSentence = useMemo(() => {
    return data && data[Math.floor(Math.random() * data?.length)];
  }, [data]);

  return (
    <View style={[style]}>
      <View style={styles.containerView}>
        <MotiText
          style={[ANFont.h3Bold, styles.helloTitle]}
          from={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'timing',
            delay: 0,
            duration: _fadeDuration,
          }}
        >
          {isInvitedMode
            ? I18n.t('common.invited_mode')
            : isNight
            ? I18n.t('common.hello_late', {
                firstname: user?.firstname,
              })
            : I18n.t('common.hello', {
                firstname: user?.firstname,
              })}
        </MotiText>

        {randomSentence && (
          <MotiText
            from={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: 'timing',
              delay: _fadeDuration,
              duration: _fadeDuration,
            }}
            style={[ANFont.textMediumRegular, styles.motivation]}
          >
            {randomSentence}
          </MotiText>
        )}
      </View>
    </View>
  );
};

export default DashboardHeader;

const styles = StyleSheet.create({
  containerView: {
    paddingHorizontal: isDevice() ? MetricsSizes.screenHorizontalPadding : 0,
    paddingBottom: 20,
    minHeight: 130,
  },
  helloTitle: {
    marginTop: MetricsSizes.big,
    color: ANColor.tertiary500,
  },
  motivation: {
    marginTop: MetricsSizes.regular,
    color: ANColor.tertiary900,
  },
});
