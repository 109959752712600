import React, { FC, memo } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import I18n from '../i18n';
import ANMedia from '../model/ANMedia';
import HorizontalMediaList from '../screen/explorer/HorizontalMediaList';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import { isDevice } from '../util/Device';
import { padding } from '../util/padding';

type Props = {
  medias?: ANMedia[];
  isLoading: boolean;
  style?: ViewStyle;
};

const FreshestMedia: FC<Props> = ({ medias, isLoading, style }) => {
  return (
    <View style={[styles.freshestMediasContainer, style]}>
      <Text style={[ANFont.h5Bold, styles.newTitle]}>{I18n.t('explorer.freshest')}</Text>
      <HorizontalMediaList data={medias} isLoading={isLoading} listId="FreshestMedia" />
    </View>
  );
};

export default memo(FreshestMedia); //TOdo : Pourquoi ce component rerender alors que la liste des media ne change pas ?

const styles = StyleSheet.create({
  newTitle: {
    color: ANColor.tertiary100,
    marginBottom: MetricsSizes.small,
  },
  freshestMediasContainer: {
    backgroundColor: ANColor.tertiary500,
    borderRadius: !isDevice() ? MetricsSizes.webCornerRadius : undefined,
    ...padding(MetricsSizes.screenHorizontalPadding),
  },
});
