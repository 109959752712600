import { useLinkTo } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated from 'react-native-reanimated';
import LogoLong from '../assets/svg/LogoLong';
import I18n from '../i18n';
import { ANColor } from '../theme/ANColor';
import { MetricsSizes } from '../theme/ANMetrics';
import { Route } from './Route';
import SideBarButton from './SideBarButton.web';
import SideBarProfileButton from './SideBarProfileButton.web';

type Props = {};

const WebSideBar = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);

  const [profileInitialIndex, setProfileInitialIndex] = React.useState<number | undefined>(
    undefined,
  );

  const linkTo = useLinkTo();

  useEffect(() => {
    //Check window href to initialize the selected index
    if (window.location.href.includes(Route.dashboardScreen)) {
      setSelectedIndex(0);
    } else if (window.location.href.includes(Route.explorerScreen)) {
      setSelectedIndex(1);
    } else if (window.location.href.includes(Route.appointmentsHistoryScreen)) {
      setProfileInitialIndex(0);
    } else if (window.location.href.includes(Route.updatePasswordScreen)) {
      setProfileInitialIndex(1);
    }
  }, []);

  const goHome = () => {
    setSelectedIndex(0);
    linkTo('/' + Route.dashboardScreen);
  };
  const goExplorer = () => {
    setSelectedIndex(1);
    linkTo('/' + Route.explorerScreen);
  };

  const path = window.location.pathname;
  console.log('path', path);

  return (
    <View style={styles.leftContainer}>
      <Animated.View style={styles.sidebarContainer}>
        <SideBarButton
          title={I18n.t('bottombar.home')}
          icon="home"
          onPress={goHome}
          selected={selectedIndex === 0}
          style={styles.button}
        />
        <SideBarButton
          title={I18n.t('bottombar.explorer')}
          icon="explore"
          onPress={goExplorer}
          selected={selectedIndex === 1}
          style={styles.button}
        />

        <View style={styles.separator} />

        <SideBarProfileButton
          initialIndex={profileInitialIndex}
          onProfileIndexSelected={() => setSelectedIndex(undefined)}
          shouldDismiss={selectedIndex === 0 || selectedIndex === 1}
        />

        <LogoLong
          style={{ width: 100, marginTop: 40, alignSelf: 'center' }}
          color={ANColor.commonWhite}
        />
      </Animated.View>
    </View>
  );
};

export default WebSideBar;

const styles = StyleSheet.create({
  leftContainer: {
    height: '100%',
    backgroundColor: ANColor.primary100,
  },
  sidebarContainer: {
    marginTop: 40,
    marginRight: 24,
    backgroundColor: ANColor.tertiary400,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    paddingVertical: MetricsSizes.big,
    width: 200,
    borderRadius: MetricsSizes.webCornerRadius,
  },
  separator: {
    height: 1,
    backgroundColor: ANColor.commonWhite,
    width: '100%',
    marginBottom: MetricsSizes.regular,
  },
  button: {
    marginBottom: MetricsSizes.regular,
  },
});
