import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { AVPlaybackStatus, Audio, Video } from 'expo-av';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ANSound } from './../../../assets/audio/ANSound';
import { EventKey } from './../../../util/analytics';
import { createPlaylistFromExercices, selectAudioForNextVideo } from './utils';

import { DeviceEventEmitter, Platform, StatusBar } from 'react-native';
import Orientation from 'react-native-orientation-locker';
import UserContext from '../../../context/UserContext';
import { RootStackParamList } from '../../../navigation/Route';
import { isDevice } from '../../../util/Device';
import { logEvent } from '../../../util/analytics';
import { DeviceEmitterEvent } from '../../../util/eventEmitter';
import Playable from './Playable';

type Props = {
  isUsingRestrictedMode: boolean;
  onWebModalShouldDismiss: ((cancelled: boolean) => void) | undefined;
};
const useTrainingSessionScreen = ({ isUsingRestrictedMode, onWebModalShouldDismiss }: Props) => {
  const { user, exercicesToPlay } = useContext(UserContext);

  const playlist = useMemo(() => {
    return user!.trainingPlaylist!;
  }, [user]);

  const navigation = isDevice()
    ? useNavigation<NativeStackNavigationProp<RootStackParamList>>()
    : undefined;

  const videoRef = React.useRef<Video>(null);

  const [isExitModalVisible, setIsExitModalVisible] = React.useState<boolean>(false);
  const [isFirstExercice, setIsFirstExercice] = React.useState<boolean>(true);
  const [isLastExercice, setIsLastExercice] = React.useState<boolean>(false);
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState<number>(0);
  const [countDownOccurence, setCountDownOccurence] = React.useState<number>(0);
  const [isInLandscapeMode, setIsInLandscapeMode] = useState(false);

  const [status, setStatus] = React.useState<AVPlaybackStatus>();
  const [music, setMusic] = React.useState<Audio.Sound>();
  const [muteMusic, setMuteMusic] = React.useState<boolean>(false);

  const previousVideoUrl = React.useRef<string | undefined>(undefined);

  React.useEffect(() => {
    playAmbianceMusic();
    StatusBar.setHidden(true, 'slide'); //Hide the status bar (needed on Android specifically, already not visible on iOS)
    playAudioComment(ANSound.Prepare); //Play the "prepare" comment
    setTimeout(() => {
      console.log('lock to landscape');
      setIsInLandscapeMode(true);
      Orientation.lockToLandscapeLeft(); //this will lock the view to Landscape
    }, 500);
    return () => StatusBar.setHidden(false, 'slide');
  }, []);

  React.useEffect(() => {
    return music
      ? () => {
          console.log('Unloading Sound');
          music.unloadAsync();
        }
      : undefined;
  }, [music]);

  const videosToPlay: Playable[] = useMemo(() => {
    console.log('isUsingRestrictedMode', isUsingRestrictedMode);
    const exercices = exercicesToPlay(isUsingRestrictedMode)!;
    console.log('exercice 1', exercices[0]);
    return createPlaylistFromExercices(exercices);
  }, [playlist]);

  const playAmbianceMusic = useCallback(async () => {
    const { sound } = await Audio.Sound.createAsync(ANSound.MidnightSolutions, {
      volume: 0.5,
      shouldPlay: true,
      isLooping: true,
    });
    setMusic(sound);
  }, []);

  /**
   * Check if the current video is an introduction video or not
   */
  const isInTransitionState = useMemo(() => {
    return videosToPlay[currentVideoIndex].isIntroduction;
  }, [currentVideoIndex, videosToPlay]);

  /**
   * Call when a video has ended
   */
  const onVideoFinished = () => {
    playNextVideo();
  };

  const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    setStatus(status);
  };

  const analyticsEventProperties = useMemo(() => {
    return {
      exercice_count: playlist?.exercices.length ?? -1,
      limited_mode: isUsingRestrictedMode,
    };
  }, [playlist, isUsingRestrictedMode]);

  /**
   * Find the next video in array, and play audio sound if needed
   * Else if last video, call onComplete function
   */
  const playNextVideo = () => {
    if (currentVideoIndex < videosToPlay.length - 1) {
      const nextIndex = currentVideoIndex + 1;
      setNewVideo(nextIndex);
      if (isDevice()) videoRef.current?.replayAsync();
      else videoRef.current?.play();
      const nextVideo = videosToPlay[nextIndex];
      const audioToPlay = selectAudioForNextVideo(nextVideo);
      playAudioComment(audioToPlay);
    } else {
      onSessionCompletes();
    }
  };

  const playAudioComment = useCallback((audioToPlay: any) => {
    console.log('Will play audio comment');
    Audio.Sound.createAsync(audioToPlay, { shouldPlay: true })
      .then(({ sound }) => {
        sound.setOnPlaybackStatusUpdate(status => {
          if (!status.didJustFinish) return;
          console.log('Audio finished, Unloading ');
          sound.unloadAsync().catch(() => {}); //unload the sound when finised. This could help with the Android Expo issue "Player does not exist" that occurs after several audio play. See https://github.com/expo/expo/issues/1873#issuecomment-488912452
        });
      })
      .catch(error => {
        console.log('Error while playing audio comment', error);
      });
  }, []);

  /**
   * Check if the current video is the last exercice of the playlist
   * @param index current video index
   */
  const checkIsLastExercice = useCallback(
    (index: number) => {
      const currentId = videosToPlay[index].exerciceId;
      let isLast = true;
      for (let i = index; i < videosToPlay.length; i++) {
        if (videosToPlay[i].exerciceId !== currentId) {
          //Another id has been found, so it's not the last exercice
          isLast = false;
          break;
        }
      }
      return isLast;
    },
    [videosToPlay],
  );

  /**
   * Check if the current video is the first exercice of the playlist
   * @param index current video index
   * @returns
   */
  const checkIsFirstExercice = useCallback(
    (index: number) => {
      const currentId = videosToPlay[index].exerciceId;
      let isFirst = true;
      for (let i = 0; i < index; i++) {
        if (videosToPlay[i].exerciceId !== currentId) {
          //Another id has been found, so it's not the first exercice
          isFirst = false;
          break;
        }
      }
      return isFirst;
    },
    [videosToPlay],
  );

  /**
   * Update the state to play the next exercice
   */
  const setNewVideo = async (index: number) => {
    previousVideoUrl.current = videosToPlay[currentVideoIndex].uri;
    setCurrentVideoIndex(index);
    console.log('Update count down occurence. Current :', countDownOccurence);
    setCountDownOccurence(countDownOccurence + 1);
  };

  useEffect(() => {
    console.log('Ocurrence updated in useTrainingSessionScreen : ', countDownOccurence);
  }, [countDownOccurence]);

  /**
   * Call to check for a given index, if we're playing the first or last video in playlist.
   * Updates the state of isFirstExercice and isLastExercice
   */
  const updateIsFirstOrLastExerciceForIndex = useCallback((index: number) => {
    //Now check if there's another exercice ID after the current one.
    const isLast = checkIsLastExercice(index);
    setIsLastExercice(isLast);

    const isFirst = checkIsFirstExercice(index);
    setIsFirstExercice(isFirst);
  }, []);

  /**
   * Get the current video in the playlist
   */
  const currentVideo = useMemo(() => {
    const video = videosToPlay[currentVideoIndex];
    updateIsFirstOrLastExerciceForIndex(currentVideoIndex);
    return video;
  }, [currentVideoIndex]);

  /**
   * When updating the current video, check if it's a new uri. If so, reload the video to beginning
   */
  useEffect(() => {
    const rewind = async () => {
      console.log('rewind');
      if (isDevice()) {
        await videoRef.current?.setPositionAsync(0);
        videoRef.current?.setIsLoopingAsync(true);
        videoRef.current?.playAsync();
      } else {
        if (videoRef.current) videoRef.current.currentTime = 0;
      }
    };
    //On video change, rewind the playbale to its beginning
    if (previousVideoUrl.current !== currentVideo.uri) {
      rewind();
    }
  }, [currentVideo.uri]);

  /**
   * Trigger on training completed
   */
  const onSessionCompletes = () => {
    playAudioComment(ANSound.TrainingEnded);
    logEvent(EventKey.training_session_completed, analyticsEventProperties);
    Orientation.lockToPortrait();
    if (isDevice()) {
      navigation?.goBack();
    } else {
      onWebModalShouldDismiss?.(false);
    }

    DeviceEventEmitter.emit(DeviceEmitterEvent.onSessionComplete);
  };

  const onNextTapped = useCallback(() => {
    if (isLastExercice) return;
    console.log('On Next Tapped');

    let nextVideoFound = false;
    let i = currentVideoIndex;

    while (!nextVideoFound && i < videosToPlay.length - 1) {
      i++;
      if (videosToPlay[i].exerciceId !== currentVideo.exerciceId) {
        nextVideoFound = true;
      }
    }
    //Set next exercice if found
    if (nextVideoFound) {
      setNewVideo(i);
    }
  }, [isLastExercice, currentVideoIndex, videosToPlay, currentVideo, countDownOccurence]);

  const onPreviousTapped = useCallback(() => {
    if (isFirstExercice) return;

    let previousVideoFound = false;
    let i = currentVideoIndex;

    while (!previousVideoFound && i > 0) {
      i--;
      if (
        videosToPlay[i].exerciceId !== currentVideo.exerciceId &&
        videosToPlay[i].isIntroduction
      ) {
        previousVideoFound = true;
      }
    }
    //Set next exercice if found
    if (previousVideoFound) {
      setNewVideo(i);
    }
  }, [currentVideoIndex, isFirstExercice, currentVideo, countDownOccurence]);

  const onExitTapped = () => {
    setIsExitModalVisible(true);
    if (isDevice()) videoRef.current?.pauseAsync();
    else videoRef.current?.pause();
  };

  const OnDismissExitModalTapped = () => {
    logEvent(EventKey.training_session_cancelled, analyticsEventProperties);
    Orientation.lockToPortrait();
    setIsExitModalVisible(false);
    if (isDevice()) {
      navigation?.goBack();
    } else {
      onWebModalShouldDismiss?.(true);
    }
  };

  const onResumeExitModalTapped = () => {
    setIsExitModalVisible(false);
    if (isDevice()) videoRef.current?.playAsync();
    else videoRef.current?.play();
  };

  const playOrPauseTapped = () => {
    if (isDevice()) isPlaying ? videoRef.current?.pauseAsync() : videoRef.current?.playAsync();
    else isPlaying ? videoRef.current?.pause() : videoRef.current?.play();
  };

  const audioSpeakerTapped = () => {
    const newState = !muteMusic;
    setMuteMusic(newState);
    music?.setIsMutedAsync(newState);
  };

  const isPlaying: boolean = useMemo(() => {
    const value = status?.isPlaying ?? false;
    status;
    return value;
  }, [status]);

  const isBuffering: boolean = useMemo(() => {
    if (Platform.OS === 'android') return false;
    const value = status?.isBuffering ?? true;
    return value;
  }, [status]);

  return {
    video: videoRef,
    playlist,
    isExitModalVisible,
    isInTransitionState,
    countDownOccurence,
    muteMusic,
    isInLandscapeMode,
    currentVideo,
    isFirstExercice,
    isLastExercice,
    OnNextTapped: onNextTapped,
    onPreviousTapped,
    OnDismissExitModalTapped,
    onResumeExitModalTapped,
    onPlaybackStatusUpdate,
    setIsInLandscapeMode,
    onFinish: onVideoFinished,
    onExitTapped,
    playOrPauseTapped,
    audioSpeakerTapped,
    onSessionCompletes,
    isPlaying,
    isBuffering,
  };
};
export default useTrainingSessionScreen;
