import { useLinkTo, useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { Animated, StatusBar, StyleSheet } from 'react-native';
import { useQuery } from 'react-query';
import { getAllPodcasts } from '../../api/request/user/explorer/getTopics';
import EmptyList from '../../component/EmptyList';
import ListItemSeparator from '../../component/ListItemSeparator';
import ANScreenContainer from '../../component/ScreenContainer';
import { ANPodcast } from '../../model/ANPodcast';
import { RootStackParamList, Route } from '../../navigation/Route';
import { ANColor } from '../../theme/ANColor';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import PodcastListItem from './PodcastListItem';

type Props = {};

const ExplorerPodcastsListScreen = (props: Props) => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const linkTo = useLinkTo();

  const { data, isLoading } = useQuery(['getAllPodcasts'], () => getAllPodcasts());

  const onPodcastPress = (podcast: ANPodcast) => {
    const params = {
      id: podcast.id,
    };
    if (isDevice()) {
      navigation.navigate(Route.explorerPodcastScreen, params);
    } else {
      linkTo({ screen: Route.explorerPodcastScreen, params: params });
    }
  };
  const _renderItem = ({ item }: { item: ANPodcast }) => {
    return (
      <PodcastListItem
        title={item.title}
        illustrationURL={item.illustrationUrl}
        onPress={() => onPodcastPress(item)}
        style={[{ marginHorizontal: MetricsSizes.regular }]}
      />
      // <Text>{item.title}</Text>
    );
  };

  return (
    <ANScreenContainer useHorizontalPadding={false}>
      <StatusBar backgroundColor={ANColor.primary300} />

      <Animated.FlatList
        data={data}
        contentContainerStyle={{
          paddingTop: 24,
        }}
        ItemSeparatorComponent={() => {
          return <ListItemSeparator />;
        }}
        ListEmptyComponent={<EmptyList isLoading={isLoading} />}
        renderItem={_renderItem}
        keyExtractor={({ id }) => {
          return String(id);
        }}
      />
    </ANScreenContainer>
  );
};

export default ExplorerPodcastsListScreen;

const styles = StyleSheet.create({});
