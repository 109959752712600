import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ExpoIcon from '../../../../component/ExpoIcon';
import I18n from '../../../../i18n';
import { ANColor } from '../../../../theme/ANColor';
import ANFont from '../../../../theme/ANFont';
import { MetricsSizes } from '../../../../theme/ANMetrics';

type Props = {};

const OsteoAdviceGuestMode = (props: Props) => {
  return (
    <View>
      <View>
        <Text style={[ANFont.h6Bold, styles.adviceTitle]}>{I18n.t('osteo_advice_card.title')}</Text>
        <Text style={[ANFont.textMediumRegular]}>{I18n.t('osteo_advice_card.invited_mode')}</Text>
      </View>
      <ExpoIcon
        name="lock-outline"
        size={74}
        color={ANColor.common500}
        style={{ alignSelf: 'center', marginTop: MetricsSizes.big }}
      />
    </View>
  );
};

export default OsteoAdviceGuestMode;

const styles = StyleSheet.create({
  adviceTitle: {
    marginVertical: MetricsSizes.regular,
  },
});
