import React from 'react';
import { Platform } from 'react-native';
import BackNavigationButton from '../component/BackNavigationButton';
import { ANColor } from '../theme/ANColor';

export const screenOptions = {
  headerShown: true,
  headerTintColor: ANColor.common800,
  headerShadowVisible: false,
  headerLeft: () => {
    if (Platform.OS === 'ios') return <BackNavigationButton />;
    else return undefined;
  },
  headerBackTitleVisible: false,
  headerStyle: {
    backgroundColor: ANColor.primary100,
  },
};
