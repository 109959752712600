import jwt_decode from 'jwt-decode';

type jwt = {
  exp: number;
  iat: number;
};

export const isJWTExpired = (jwt: string) => {
  var decoded: jwt = jwt_decode(jwt);
  if (Date.now() <= decoded.exp * 1000) {
    return false;
  }
};
