import { ExplorerMedia } from './APIDataResponse';
import { ExplorerType } from './ExplorerCategory';
export interface MyProfile {
  id: number;
  username: string;
  email: string;
  passwordInitiated: boolean;
  blocked: boolean;
  patient: Patient;
  lastAppointment?: Appointment;
  lastPlaylist?: TrainingPlaylist;
  lastReviews?: LastReviews;
  trainingSchedule?: any;
  mediaFavorites: MediaFavorite[];
}

export interface SettingsResponse {
  data: {
    attributes: {
      minimumVersion: string;
    };
  };
}
export interface MediaFavorite {
  id: number;
}
export interface MediaFavoriteDetailed {
  id: number;
  title: string;
  media: Media;
  cover: ImageMedia;
}

export interface Patient {
  firstname: string;
  lastname: string;
  osteo: Osteo;
  trainingSchedule?: any;
}

export interface LastReviews {
  lastHealthCheckDate?: string;
  lastDifficultyReportDate?: string;
}

export interface Appointment {
  meetingDate: string;
  id: number;
  userReview?: {
    rating: number;
    review?: string;
  };
  osteoAdvice: string;
  trainingPlaylist: {
    id: number;
  };
  hasBeenRated: boolean;
  dateFormatted: string;
  osteo: Osteo;
  center: Center;
  academie: Academie;
}

export interface TrainingPlaylist {
  id: number;
  advice: string;
  exercices: Exercice[];
  completed: number;
  expiry?: string;
  expiryFormatted?: string;
  mainReason?: AppointmentReason;
  reasons?: AppointmentReason[];
}

export interface AppointmentReason {
  id: number;
  name: string;
}

export interface Exercice {
  id: number;
  duration: number;
  videoGroup: {
    id: number;
    title: string;
    videos: TrainingVideo[];
  };
  osteoSelection: TrainingVideo;
  userSelection?: TrainingVideo; //The user can change the video level within a same group.
}

export interface TrainingVideo {
  id: number;
  title: string;
  level: number;
  fullVideo: Media;
  loopVideo: Media;
  introductionVideo: Media;
  loopAlternativeVideo?: Media;
  introductionAlternativeVideo?: Media;
  limitedMode: boolean;
  preview?: ImageMedia;
}

export interface TrainingScheduleResponse {
  trainingSchedule: any; // JSON string of ANTrainingSchedule
}

export interface ImageMedia {
  formats: {
    thumbnail: {
      url: string;
    };
    large: {
      url: string;
    };
  };
}
export interface Media {
  id: number;
  url: string;
  mime: MIME;
}

export enum MIME {
  ImageJPEG = 'image/jpeg',
  VideoMp4 = 'video/mp4',
}

export interface Osteo {
  id: number;
  firstname: string;
  lastname: string;
  urlDoctolib: string;
  avatar?: Avatar;
}
interface Avatar {
  formats?: {
    thumbnail?: {
      url: string;
    };
  };
  url: string;
}

export interface Academie {
  id: number;
  name: string;
  locations: Array<AcademieLocation>;
  url?: string;
  shouldDisplayStudentNameToPatients: boolean;
}

interface AcademieLocation {
  id: number;
  name: string;
  avatar?: Avatar;
}

export interface Center {
  id: number;
  name: string;
  url?: string;
  avatar?: Avatar;
}
export type ExplorerMedia = {
  id: number;
  title: string;
  difficulty?: MediaDifficulty;
  tag?: ExplorerTag; //A custom tag, that can be used to filter medias.
  type: 'video' | 'audio' | 'podcast';
  requiresAuthentication: boolean;
  duration: number;
  media: Media;
  cover?: ImageMedia;
  author?: ExplorerAuthor;
  externalUrl?: string;
};

export interface FreshestExplorerMedia {
  id: number;
  title: string;
  difficulty?: MediaDifficulty;
  type: 'video' | 'audio' | 'podcast';
  requiresAuthentication: boolean;
  duration: number;
  media: Media;
  cover?: ImageMedia;
  author?: ExplorerAuthor;
  externalUrl?: string;
  topic?: {
    id: number;
  };
}
export interface ExplorerAuthor {
  id: number;
  displayName: string;
  description?: string;
  redirectUrl?: string;
  avatar: {
    formats:
      | {
          thumbnail:
            | {
                url: string;
              }
            | undefined;
        }
      | undefined;
  };
}

export type MediaDifficulty = 'easy' | 'normal' | 'hard';

export interface MotivationSentenceResponse {
  data: MotivationSentenceResponseData[];
}
export interface MotivationSentenceResponseData {
  attributes: {
    message: string;
  };
}
export const mediaDifficulties: MediaDifficulty[] = ['easy', 'normal', 'hard'];

export interface TopicsReponse {
  data: TopicReponseData[];
}

export interface ExplorerMediaLogResponse {
  timeFlagInMilli: number;
  completed: boolean;
  mediaId: number;
}
export interface ExplorerPodcastLogResponse {
  timeFlagInMilli: number;
  completed: boolean;
  topicId: number;
  title: string;
}
export interface TopicReponseData {
  id: number;
  attributes: TopicAtributes;
}

interface TopicAtributes {
  title: string;
  key: string;
  type: ExplorerType;
  infoUrl?: string;
  illustration: {
    data: {
      attributes: {
        formats: {
          large: {
            url: string;
          };
        };
      };
    };
  };
}

interface ExplorerTag {
  key: string;
}

export interface TopicDetailed {
  id: number;
  title: string;
  key: string;
  type: ExplorerType;
  infoUrl?: string;
  medias: ExplorerMedia[];
  availableTags?: ExplorerTag[];
}

export interface Podcast {
  info: {
    image?: string;
    title: string;
    description?: string;
    authorName?: string;
    link: string;
  };
  items: PodcastItem[];
}

export interface PodcastItem {
  title: string;
  url: string;
  duration: string;
}

export interface PodcastsResponse {
  data: PodcastsResponseData[];
}

export interface PodcastsResponseData {
  id: number;
  attributes: {
    rss: string;
    title: string;
    illustrationUrl: string;
  };
}
