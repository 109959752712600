import React, { FC } from 'react';
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import ExpoIcon from '../../component/ExpoIcon';
import ANImageView from '../../component/Image';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import { webContainerWidth } from '../../util/utils';

type Props = {
  title: string;
  illustrationURL?: string;
  style?: ViewStyle[];
  onPress: () => void;
};

const TopicItem: FC<Props> = ({ title, illustrationURL, style, onPress }) => {
  return (
    <Animated.View entering={FadeIn}>
      <Pressable style={[ANLayout.roundedBackground, styles.container, style]} onPress={onPress}>
        <ANImageView
          style={[StyleSheet.absoluteFill, { resizeMode: 'cover' }]}
          source={{ uri: illustrationURL }}
        />
        <View style={[ANLayout.rowHCenter]}>
          <Text style={[ANFont.h6Bold, styles.title]}>{title}</Text>
          <ExpoIcon name="chevron-right" color={ANColor.commonWhite} style={styles.arrow} />
        </View>
      </Pressable>
    </Animated.View>
  );
};

export default TopicItem;

const styles = StyleSheet.create({
  container: {
    aspectRatio: 358 / 120,
    width: isDevice() ? '100%' : webContainerWidth / 2,
    justifyContent: 'flex-end',
    marginBottom: MetricsSizes.small,
    backgroundColor: ANColor.tertiary700,
  },
  title: {
    color: ANColor.commonWhite,
  },
  arrow: {
    marginTop: 4,
  },
});
