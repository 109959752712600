import { useHover } from '@react-native-aria/interactions';
import { useLinkTo } from '@react-navigation/native';
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Animated from 'react-native-reanimated';

import ExpoIcon, { ExpoIconDesign } from '../component/ExpoIcon';
import UserContext from '../context/UserContext';
import I18n from '../i18n';
import { ANColor } from '../theme/ANColor';
import Font from '../theme/ANFont';
import { default as ANLayout } from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { padding } from '../util/padding';
import { Route } from './Route';
import SideBarButton from './SideBarButton.web';
import SideBarUserAvatar from './SideBarUserAvatar.web';

type Props = {
  initialIndex?: number;
  onProfileIndexSelected: (index: number) => void;
  shouldDismiss: boolean;
};

const SideBarProfileButton: FC<Props> = ({
  initialIndex,
  shouldDismiss,
  onProfileIndexSelected,
}) => {
  const { user, isInvitedMode, disconnectGuest, userLoggedOut } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(undefined);

  const linkto = useLinkTo();
  const ref = React.useRef();
  const { isHovered } = useHover({}, ref);
  const [isExpanded, setIsExpanded] = React.useState(false);

  useEffect(() => {
    console.log('initial index', initialIndex);
    if (initialIndex !== undefined) {
      setIsExpanded(true);
    }
    setSelectedIndex(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    console.log('Should dismiss', shouldDismiss);
    if (shouldDismiss && selectedIndex !== undefined) {
      setSelectedIndex(undefined);
      setIsExpanded(false);
    }
  }, [shouldDismiss, selectedIndex]);

  const displayName = useMemo(() => {
    return user?.firstname + ' ' + user?.lastname;
  }, [user]);

  const updatePassword = useCallback(() => {
    onProfileIndexSelected(1);
    setSelectedIndex(1);
    linkto('/' + Route.updatePasswordScreen);
  }, []);

  const displayHistory = useCallback(() => {
    onProfileIndexSelected(0);
    setSelectedIndex(0);
    linkto('/' + Route.appointmentsHistoryScreen);
  }, []);

  const renderExpanded = () => {
    if (!isExpanded) return;
    return (
      <Animated.View>
        <SideBarButton
          title={I18n.t('profile.appointments_history')}
          onPress={displayHistory}
          icon="calendar-blank"
          selected={selectedIndex === 0}
          design={ExpoIconDesign.MaterialCommunity}
          style={styles.button}
        />
        <SideBarButton
          title={I18n.t('profile.update_password')}
          onPress={updatePassword}
          icon="lock"
          selected={selectedIndex === 1}
          style={styles.button}
        />

        <SideBarButton
          title={I18n.t('profile.logout')}
          onPress={userLoggedOut}
          icon="logout"
          style={styles.button}
        />
      </Animated.View>
    );
  };

  const profileTapped = useCallback(() => {
    if (isInvitedMode) {
      disconnectGuest();
    } else {
      setIsExpanded(!isExpanded);
    }
  }, [isExpanded, isInvitedMode]);

  if (isInvitedMode) {
    return (
      <SideBarButton
        title={I18n.t('auth.signin')}
        onPress={disconnectGuest}
        icon="login"
        style={styles.button}
      />
    );
  } else {
    return (
      <View>
        <Pressable
          style={[
            ANLayout.rowHCenter,
            styles.buttonContainer,
            isHovered && styles.hover,
            isExpanded && styles.selected,
            styles.button,
          ]}
          ref={ref}
          onPress={profileTapped}
        >
          <View style={styles.container}>
            <SideBarUserAvatar title={user?.firstname.charAt(0) ?? ''} />
            <Text
              numberOfLines={2}
              style={[Font.textLargeRegular, styles.name, isExpanded && styles.expandedName]}
            >
              {displayName}
            </Text>
            <ExpoIcon
              name={isExpanded ? 'chevron-up' : 'chevron-down'}
              design={ExpoIconDesign.MaterialCommunity}
              color={isExpanded ? ANColor.commonBlack : ANColor.commonWhite}
            />
          </View>
        </Pressable>
        {renderExpanded()}
      </View>
    );
  }
};

export default SideBarProfileButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    color: ANColor.primary200,
    marginLeft: MetricsSizes.small,
    maxWidth: 100,
  },
  expandedName: {
    color: ANColor.common900,
  },
  buttonContainer: {
    ...padding(8),
    borderRadius: MetricsSizes.webCornerRadius,
  },
  icon: {
    marginRight: MetricsSizes.small,
  },
  hover: {
    backgroundColor: ANColor.tertiary300,
  },
  text: {
    color: ANColor.commonWhite,
  },
  selected: {
    backgroundColor: ANColor.tertiary300,
  },
  button: {
    marginBottom: MetricsSizes.regular,
  },
});
