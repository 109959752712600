import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import I18n from '../../../i18n';
import { default as ANFont, default as Font } from '../../../theme/ANFont';
import { MetricsSizes } from '../../../theme/ANMetrics';

type Props = {
  restrictedMode: boolean;
  duration?: number;
  advice?: string;
  expiry?: string;
};

const TrainingListHeader: FC<Props> = ({ restrictedMode, expiry }) => {
  return (
    <View style={styles.header}>
      {expiry && (
        <Text style={[ANFont.textLargeRegular, styles.expiry]}>
          {I18n.t('training.expiry_date', { date: expiry })}
        </Text>
      )}
      <Text style={Font.h6Bold}>{I18n.t('training.videos_list_title')}</Text>
      <Text style={[Font.textLargeRegular, styles.body]}>
        {restrictedMode
          ? I18n.t('training.videos_list_restricted_description')
          : I18n.t('training.videos_list_description')}
      </Text>
    </View>
  );
};

export default TrainingListHeader;

const styles = StyleSheet.create({
  expiry: {
    marginVertical: MetricsSizes.small,
  },
  header: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  body: {
    marginVertical: MetricsSizes.singlePadding,
  },
});
