import { StyleSheet } from 'react-native';
import { ANColor } from './ANColor';

export const ANFontFamily = {
  MontserratRegular: 'Montserrat-Regular',
  MontserratMedium: 'Montserrat-Medium',
  MontserratBold: 'Montserrat-Bold',
  MontserratSemiBold: 'Montserrat-SemiBold',
  MulishRegular: 'Mulish-Regular',
  MulishMedium: 'Mulish-Medium',
  MulishBold: 'Mulish-Bold',
};

const fontColor = ANColor.common700;

export default StyleSheet.create({
  button: {
    fontSize: 16,
    fontFamily: ANFontFamily.MontserratSemiBold,
  },
  h1Bold: {
    fontFamily: ANFontFamily.MontserratBold,
    fontSize: 36,
    color: fontColor,
  },
  h1Regular: {
    fontFamily: ANFontFamily.MontserratRegular,
    fontSize: 36,
    color: fontColor,
  },
  h2Bold: {
    fontFamily: ANFontFamily.MontserratBold,
    fontSize: 32,
    color: fontColor,
  },
  h2Regular: {
    fontFamily: ANFontFamily.MontserratRegular,
    fontSize: 32,
    color: fontColor,
  },
  h3Bold: {
    fontFamily: ANFontFamily.MontserratBold,
    fontSize: 28,
    color: fontColor,
  },
  h3Regular: {
    fontFamily: ANFontFamily.MontserratRegular,
    fontSize: 28,
    color: fontColor,
  },
  h4Bold: {
    fontFamily: ANFontFamily.MontserratBold,
    fontSize: 26,
    color: fontColor,
  },
  h4Regular: {
    fontFamily: ANFontFamily.MontserratRegular,
    fontSize: 26,
    color: fontColor,
  },
  h5Bold: {
    fontFamily: ANFontFamily.MulishBold,
    fontSize: 22,
    color: fontColor,
  },
  h5Regular: {
    fontFamily: ANFontFamily.MontserratRegular,
    fontSize: 22,
    color: fontColor,
  },
  h6Bold: {
    fontFamily: ANFontFamily.MontserratBold,
    fontSize: 18,
    color: fontColor,
  },
  h6Regular: {
    fontFamily: ANFontFamily.MontserratRegular,
    fontSize: 18,
    color: fontColor,
  },

  textLargeBold: {
    fontFamily: ANFontFamily.MulishBold,
    fontSize: 18,
    color: fontColor,
  },
  textLargeRegular: {
    fontFamily: ANFontFamily.MulishRegular,
    fontSize: 18,
    color: fontColor,
  },
  textMediumBold: {
    fontFamily: ANFontFamily.MulishBold,
    fontSize: 16,
    color: fontColor,
  },
  textMediumRegular: {
    fontFamily: ANFontFamily.MulishRegular,
    fontSize: 16,
    color: fontColor,
  },
  textSmallBold: {
    fontFamily: ANFontFamily.MulishBold,
    fontSize: 14,
    color: fontColor,
  },
  textSmallRegular: {
    fontFamily: ANFontFamily.MulishRegular,
    fontSize: 14,
    color: fontColor,
  },
});
