import React, { FC } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

import I18n from '../i18n';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { padding } from '../util/padding';
import ExpoIcon from './ExpoIcon';

type Props = {
  onPress: () => void;
};

const DayAddTime: FC<Props> = ({ onPress }) => {
  return (
    <Animated.View entering={FadeIn} exiting={FadeOut}>
      <Pressable style={styles.container} onPress={onPress}>
        <ExpoIcon name={'add'} color={ANColor.tertiary500} />
        <Text style={styles.label}>{I18n.t('program_notifications.add_time')}</Text>
      </Pressable>
    </Animated.View>
  );
};

export default DayAddTime;

const styles = StyleSheet.create({
  container: {
    ...ANLayout.rowHCenter,
    ...padding(MetricsSizes.singlePadding),
    backgroundColor: ANColor.primary100,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: ANColor.tertiary500,
    borderRadius: MetricsSizes.cornerRadius,
  },
  label: {
    ...ANFont.textSmallRegular,
    color: ANColor.tertiary500,
  },
});
