import React, { FC } from 'react';
import { Image, Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import ExpoIcon from '../../component/ExpoIcon';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';

type Props = {
  title: string;
  illustrationURL?: string;
  style?: ViewStyle[];
  onPress: () => void;
};

const PodcastListItem: FC<Props> = ({ title, illustrationURL, style, onPress }) => {
  return (
    <Animated.View entering={FadeIn}>
      <Pressable
        style={[ANLayout.roundedBackground, ANLayout.row, styles.container, style]}
        onPress={onPress}
      >
        <Image style={[styles.image]} source={{ uri: illustrationURL }} />

        <View style={[ANLayout.rowHCenter, styles.titleContainer]}>
          <Text style={[ANFont.h6Bold, styles.title]}>{title}</Text>
        </View>
        <ExpoIcon name="chevron-right" color={ANColor.commonBlack} style={styles.arrow} />
      </Pressable>
    </Animated.View>
  );
};

export default PodcastListItem;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: MetricsSizes.small,
    backgroundColor: ANColor.primary200,
  },
  title: {
    color: ANColor.commonBlack,
    flex: 1,
  },
  titleContainer: {
    flex: 1,
    marginLeft: MetricsSizes.regular,
  },
  arrow: {
    marginTop: 4,
  },
  image: {
    resizeMode: 'contain',
    aspectRatio: 1,
    width: 70,
    borderRadius: 5,
    overflow: 'hidden',
  },
});
