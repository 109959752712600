import { AnimatePresence } from 'moti';
import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import AuthorChip from '../../component/AuthorChip';
import AuthorContainer from '../../component/AuthorContainer';
import { ANAuthor } from '../../model/ANAuthor';
import { ANColor } from '../../theme/ANColor';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';

type Props = {
  title?: string;
  authors?: ANAuthor[];
  selectedAuthor?: ANAuthor;
  onAuthorSelected?: (author: ANAuthor) => void;
};

export const explorerWithAuthorHeaderHeight = 155;

const ExplorerTopicHeader: FC<Props> = ({ authors, selectedAuthor, onAuthorSelected }) => {
  //Translate on Y axis and reduce opacity when offsetY is greater than 0

  return (
    <View
      style={[
        styles.containerView,
        !isDevice() && {
          overflow: 'hidden',
          borderBottomLeftRadius: MetricsSizes.webCornerRadius,
          borderBottomRightRadius: MetricsSizes.webCornerRadius,
        },
      ]}
    >
      <View style={[styles.collapsedHeader]}>
        {authors && authors?.length > 1 && (
          <ScrollView
            horizontal
            style={styles.authorScrollContainer}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ paddingHorizontal: MetricsSizes.screenHorizontalPadding }}
          >
            {authors.map((author, index) => {
              return (
                <AuthorChip
                  name={author.name}
                  key={index}
                  avatarURL={author.avatarURL}
                  selected={author === selectedAuthor}
                  onPress={() => {
                    if (onAuthorSelected) onAuthorSelected(author);
                  }}
                />
              );
            })}
          </ScrollView>
        )}
      </View>
      <View>
        <AnimatePresence>
          {selectedAuthor && (
            <AuthorContainer
              style={styles.author}
              name={selectedAuthor.name}
              description={selectedAuthor.description}
              avatarURL={selectedAuthor.avatarURL}
              redirectUrl={selectedAuthor.redirectURL}
            />
          )}
        </AnimatePresence>
      </View>
    </View>
  );
};

export default ExplorerTopicHeader;

const styles = StyleSheet.create({
  containerView: {
    overflow: 'hidden',
    top: 0,
    width: '100%',
  },
  collapsedHeader: {
    backgroundColor: ANColor.primary300,
  },
  titleView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: MetricsSizes.regular,
    marginTop: 12,
  },
  title: {
    color: ANColor.commonBlack,
    maxWidth: '50%',
    marginLeft: MetricsSizes.regular,
  },
  author: {
    width: '100%',
    backgroundColor: ANColor.primary300,
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    marginBottom: MetricsSizes.regular,
    paddingVertical: MetricsSizes.regular,
  },
  authorScrollContainer: {
    marginVertical: MetricsSizes.regular,
    overflow: 'visible',
  },
});
