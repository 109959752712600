import React, { FC, useCallback, useMemo } from 'react';
import {
  ActivityIndicator,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { ANColor } from '../theme/ANColor';
import Font from '../theme/ANFont';
import Layout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { formatAnalyticsEvent, logEvent } from '../util/analytics';
import { isDevice } from '../util/Device';
import { hapticSelection } from '../util/utils';

export type ANButtonTheme = 'primary' | 'secondary' | 'tertiary';

type Props = {
  title: string;
  eventKey?: string;
  cornerType?: 'full' | 'light';
  wrap?: boolean; //Set to true to wrap the button width to its title size.
  theme?: ANButtonTheme;
  disabled?: boolean;
  isLoading?: boolean;
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<TextStyle>;
  leftAccessory?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  mobileInnerPadding?: number;
  contentAlignment?: 'center' | 'left';
};

const ANButton: FC<Props> = ({
  title,
  style,
  titleStyle,
  cornerType = 'full',
  eventKey,
  isLoading = false,
  wrap = false,
  disabled = false,
  leftAccessory,
  rightAccessory,
  contentAlignment = 'center',
  mobileInnerPadding = MetricsSizes.regular,
  theme = 'primary',
  onPress,
}) => {
  const formattedAnalyticsEvent: string | undefined = useMemo(() => {
    if (!eventKey) return undefined;
    return formatAnalyticsEvent(eventKey);
  }, [eventKey]);

  const pressed = useCallback(() => {
    hapticSelection();
    onPress();
    if (formattedAnalyticsEvent) logEvent(formattedAnalyticsEvent);
  }, [formattedAnalyticsEvent, onPress]);

  const containerStyle = useMemo(() => {
    const _style = [Layout.roundedLabel, styles.container, style];
    //configure background style
    if (cornerType === 'light') _style.push(styles.lightCorner);
    if (!wrap) _style.push(styles.containerFullWidth);
    if (theme === 'secondary') _style.push({ backgroundColor: ANColor.secondary500 });
    else if (theme === 'tertiary') _style.push(styles.tertiaryBackground);
    if (disabled) _style.push({ backgroundColor: ANColor.common300 });

    return _style;
  }, [theme, cornerType, wrap, disabled]);

  const alignment = useMemo(() => {
    switch (contentAlignment) {
      case 'center':
        return 'center';
      case 'left':
        return 'flex-start';
    }
  }, [contentAlignment]);
  const textStyle = useMemo(() => {
    //Configure title style
    const _textStyle = [
      Font.button,
      styles.title,
      titleStyle,
      { paddingHorizontal: isDevice() ? mobileInnerPadding : 48 },
    ];
    if (theme === 'secondary') _textStyle.push(styles.secondaryTitle);
    else if (theme === 'tertiary') _textStyle.push(styles.tertiaryTitle);
    return _textStyle;
  }, [theme, cornerType, wrap, disabled]);

  return (
    <View style={containerStyle}>
      <TouchableOpacity
        onPress={pressed}
        disabled={disabled}
        style={[styles.content, { justifyContent: alignment }]}
      >
        {leftAccessory}
        {isLoading ? (
          <ActivityIndicator
            color={theme === 'primary' ? ANColor.commonWhite : ANColor.common700}
          />
        ) : (
          <Text style={textStyle}>{title}</Text>
        )}

        {rightAccessory}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 24,
    maxWidth: 500,
    justifyContent: 'center',
    backgroundColor: ANColor.primary500,
    paddingVertical: isDevice() ? undefined : MetricsSizes.singlePadding,
  },

  content: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  containerFullWidth: {
    width: '100%',
  },
  lightCorner: {
    borderRadius: MetricsSizes.cornerRadius,
  },
  title: {
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: 0,
    color: ANColor.commonWhite,
    alignSelf: 'center',
  },
  secondaryTitle: {
    color: ANColor.commonBlack,
  },
  tertiaryTitle: {
    color: ANColor.primary500,
  },
  secondaryBackground: {
    backgroundColor: ANColor.secondary500,
  },
  tertiaryBackground: {
    backgroundColor: ANColor.commonWhite,
    borderColor: ANColor.primary500,
    borderWidth: 1,
  },
});

export default ANButton;
