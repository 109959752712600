import I18n from '../../i18n';

export type ExplorerType = 'mind' | 'body' | 'podcast';

export interface ExplorerTopic {
  id: number;
  title: string;
  key: string;
  type: ExplorerType;
  illustration: string;
  infoUrl?: string;
}

export interface ExplorerCategory {
  key: ExplorerType;
  title: string;
  data: ExplorerTopic[];
}

export const exploreCategories: ExplorerCategory[] = [
  {
    key: 'mind',
    title: I18n.t('explorer.mind'),
    data: [],
  },
  {
    key: 'body',
    title: I18n.t('explorer.body'),
    data: [],
  },
  {
    key: 'podcast',
    title: I18n.t('explorer.podcasts'),
    data: [],
  },
];
