import React, { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { NativeSafeAreaViewProps } from 'react-native-safe-area-context';

type Props = {
  ignoreTopInset?: boolean;
} & NativeSafeAreaViewProps;

const ANSafeAreView: FC<Props> = ({ ignoreTopInset, children, ...props }) => {
  return <View style={[props.style]}>{children}</View>;
};

export default ANSafeAreView;

const styles = StyleSheet.create({});
