import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Modal } from 'react-native-paper';
import I18n from '../../i18n';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import ANButton from '../ANButton';

type Props = {
  isVisible: boolean;
  title?: string;
  // message?: string;
  onDismiss: () => void;
  onResume: () => void;
  onDone?: () => void;
};

//TODO : la modal n'est pas centré de la bonne manière
const ConfirmExitModal: React.FC<Props> = ({
  title,
  // message,
  isVisible,
  onDismiss,
  onResume,
  onDone,
}) => {
  return (
    <Modal
      visible={isVisible}
      onDismiss={onResume}
      style={styles.modal}
      contentContainerStyle={[ANLayout.modalContainer, styles.container]}
    >
      <View>
        <Text style={[ANFont.h6Regular, styles.title]}>{title}</Text>
        {/* {message && <Text style={styles.message}>{message}</Text>} */}
        <ANButton title={I18n.t('common.exit_button')} onPress={onDismiss} theme="tertiary" />
        <ANButton
          title={I18n.t('common.resume_button')}
          onPress={onResume}
          style={{ marginTop: MetricsSizes.small }}
        />
        {__DEV__ && onDone && (
          <ANButton
            title={I18n.t('common.done_button')}
            onPress={onDone}
            style={{ marginTop: MetricsSizes.small }}
          />
        )}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    alignItems: 'center',
  },
  container: {
    alignSelf: 'center',
    margin: 20,
    paddingVertical: 20,
  },
  title: {
    marginBottom: 40,
    marginTop: 20,
    textAlign: 'center',
  },
});

export default ConfirmExitModal;
