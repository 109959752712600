import { Checkup } from '../../../screen/home/HealthCheckUp/HealthCheckUpScreen';
import { Api } from '../../apiclient';
import { parseAPIError } from '../../data/ResponseError';
import { parseLastReviews } from './../../../model/adapters';

export const postHealthCheckup = async (checkup: Checkup) => {
  const { pain, mobility, frequency, stress, mainReason, otherReasons } = checkup;
  try {
    console.log('checkup', checkup);
    console.log('Post user heatlh checkup', pain, mobility, frequency, mainReason, otherReasons);

    const response = await Api.post('/patient/me/healthCheckup', {
      pain,
      mobility,
      frequency,
      stress,
      mainReason,
      otherReasons,
    });
    const data = response.data;
    const result = parseLastReviews(data);
    return result;
  } catch (err) {
    throw parseAPIError(err);
  }
};
