import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { Platform, StatusBar } from 'react-native';
import UserContext from '../context/UserContext';
import { ANColor } from '../theme/ANColor';
import { setAnalyticsCurrentScreen } from '../util/analytics';
import { AppNavigator } from './AppNavigator';
import { AuthNavigator } from './AuthNavigator';

const prefix = Linking.createURL('/');
export const RootNavigator: FC<Props> = () => {
  const { user, isInvitedMode, isUsingWrongRole } = useContext(UserContext);

  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef<string | undefined>(undefined);

  const linking = {
    prefixes: [prefix],
  };

  useEffect(() => {
    StatusBar.setBarStyle('dark-content');
  }, []);
  return (
    <NavigationContainer
      linking={linking}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.getCurrentRoute()?.name;
        if (currentRouteName && previousRouteName !== currentRouteName) {
          setAnalyticsCurrentScreen(currentRouteName);
        }
        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      {Platform.OS === 'android' && <StatusBar backgroundColor={ANColor.primary100} />}
      {user !== undefined || isInvitedMode ? <AppNavigator /> : <AuthNavigator />}
    </NavigationContainer>
  );
};
