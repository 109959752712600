import React, { FC, memo } from 'react';
import { FlatList, StyleSheet, View, ViewStyle } from 'react-native';
import { ExplorerCategory } from '../../api/data/ExplorerCategory';
import ListItemSeparator from '../../component/ListItemSeparator';
import { EventKey, logEvent } from '../../util/analytics';
import ExplorerCategoryCard from './ExplorerCategoryCard';

type Props = {
  data: ExplorerCategory[];
  selectedCategory?: ExplorerCategory;
  contentStyle?: ViewStyle;
  onFilterTapped: (category: ExplorerCategory) => void;
};

const ExplorerCategoryFilter: FC<Props> = ({
  data,
  selectedCategory,
  contentStyle,
  onFilterTapped,
}) => {
  const filterTapped = (category: ExplorerCategory) => {
    logEvent(EventKey.explorer_filter_tapped, { category: category.key });
    onFilterTapped(category);
  };

  const items = () => {
    return data;
  };

  return (
    <View>
      <FlatList
        data={items()}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={[contentStyle]}
        renderItem={({ item }) => {
          return (
            <ExplorerCategoryCard
              category={item}
              selected={selectedCategory?.key === item.key}
              isFiltering={selectedCategory != null}
              onPress={() => filterTapped(item)}
            />
          );
        }}
        ItemSeparatorComponent={() => {
          return <ListItemSeparator horizontal />;
        }}
        keyExtractor={item => item.key}
      />
    </View>
  );
};

export default memo(ExplorerCategoryFilter);

const styles = StyleSheet.create({});
