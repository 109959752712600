import React, { FC, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import ANMedia from '../../model/ANMedia';
import ExplorerAudioPlayer from './ExplorerAudioPlayer';
import useExplorerAudioScreen from './useExplorerAudioScreen';

/**
 * The screen being displayed within a modal view (ExplorerAudioWebPlayerModal) in Web platform
 * Props are only used by the Web platform. Else, use the Route params.
 */
export type ExplorerAudioPlayerProps = {
  internalMediaId?: number; //If Id is not provided, Podcast MUST be provided
  podcast?: ANMedia; //If provided, will be used instead of the id
  timeFlag?: number;
  waveMaxWidth?: number;
  currentTimeDidChange: (time: number) => void;
} & WebExplorerAudioPlayerProps;

type WebExplorerAudioPlayerProps = {
  shouldDimiss?: () => void;
};

const ExplorerAudioPlayerScreen: FC<ExplorerAudioPlayerProps> = ({
  internalMediaId,
  waveMaxWidth,
  timeFlag,
  podcast,
  currentTimeDidChange,
  shouldDimiss,
}) => {
  const {
    media,
    audioUrl,
    positionInMilli,
    mediaFinished,
    updatePositionInMillis,
    onCancelPlayer,
  } = useExplorerAudioScreen(internalMediaId, podcast);

  const dismissScreen = useCallback(() => {
    if (shouldDimiss) shouldDimiss();
  }, []);

  const onCancel = useCallback(() => {
    onCancelPlayer();
    dismissScreen();
  }, []);

  const onFinish = useCallback((positionMillis: number) => {
    mediaFinished(positionMillis);
    dismissScreen();
  }, []);

  return (
    <ExplorerAudioPlayer
      waveMaxWidth={waveMaxWidth}
      audioUrl={audioUrl}
      title={media?.title ?? ''}
      onCanceled={onCancel}
      timeFlagInMilli={timeFlag}
      onFinished={onFinish}
      updatePositionInMillis={milli => {
        updatePositionInMillis(milli);
        currentTimeDidChange(milli);
      }}
    />
  );
};

export default ExplorerAudioPlayerScreen;

const styles = StyleSheet.create({});
