import { ANSound } from '../../../assets/audio/ANSound';
import ANExercice from '../../../model/ANVideoGroup';
import Playable from './Playable';

/**
 * Select the audio to play when playing the next video.
 * @param nextVideo next video to be played
 * @returns the audio file to be played
 */
export const selectAudioForNextVideo = (nextVideo: Playable) => {
  if (nextVideo.isIntroduction) {
    if (nextVideo.isAlternativeVideo) {
      return ANSound.PrepareOtherSide;
    } else {
      return ANSound.Prepare;
    }
  } else {
    return ANSound.YourTurn;
  }
};

/**
 * Parse the list of exercices and create a list of Playable items to be played in the Training Session
 * @param exercices
 * @returns
 */
export const createPlaylistFromExercices = (exercices: ANExercice[]) => {
  const videos = exercices.map(group => group.selectedVideo);
  const videosToPlay: Playable[] = [];
  videos.forEach(videoExercice => {
    //For each video, check if we have an introduction video. If not, use the loop video as introduction
    if (videoExercice.urlIntroduction) {
      videosToPlay.push({
        exerciceId: videoExercice.id,
        uri: videoExercice.urlIntroduction,
        isIntroduction: true,
        isAlternativeVideo: false,
        duration: videoExercice.duration,
      });
    } else {
      videosToPlay.push({
        exerciceId: videoExercice.id,
        uri: videoExercice.urlLoop,
        isIntroduction: true,
        isAlternativeVideo: false,
        duration: videoExercice.duration,
      });
    }

    //Then add the loop video
    videosToPlay.push({
      exerciceId: videoExercice.id,
      uri: videoExercice.urlLoop,
      isIntroduction: false,
      isAlternativeVideo: false,
      duration: videoExercice.duration,
    });

    //Now check if there's an alternative video available (mirror side). If so, add it
    if (videoExercice.urlLoopAlternative) {
      //same logic, use the introduction url if available, otherwise use the loop url
      if (videoExercice.urlIntroductionAlternative) {
        videosToPlay.push({
          exerciceId: videoExercice.id,
          uri: videoExercice.urlIntroductionAlternative,
          isIntroduction: true,
          isAlternativeVideo: true,
          duration: videoExercice.duration,
        });
      } else {
        //if no intro specificed for alternative version, use the standard intro video. Else use the loop
        const url = videoExercice.urlIntroduction
          ? videoExercice.urlIntroduction
          : videoExercice.urlLoop;
        videosToPlay.push({
          exerciceId: videoExercice.id,
          uri: url,
          isIntroduction: true,
          isAlternativeVideo: true,
          duration: videoExercice.duration,
        });
      }

      videosToPlay.push({
        exerciceId: videoExercice.id,
        uri: videoExercice.urlLoopAlternative,
        isIntroduction: false,
        isAlternativeVideo: true,
        duration: videoExercice.duration,
      });
    }
  });
  return videosToPlay;
};
