import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ExpoIcon, { ExpoIconDesign } from '../../../../component/ExpoIcon';
import I18n from '../../../../i18n';
import { ANColor } from '../../../../theme/ANColor';
import ANFont from '../../../../theme/ANFont';
import ANLayout from '../../../../theme/ANLayout';
import { MetricsSizes } from '../../../../theme/ANMetrics';
import { padding } from '../../../../util/padding';

type Props = {};

const NoFavorites = () => {
  console.log('Show no favorites');
  return (
    <View style={[ANLayout.roundedBackground, styles.noFavoritesContainer]}>
      <ExpoIcon name="heart" color={ANColor.primary500} design={ExpoIconDesign.MaterialCommunity} />
      <Text style={[ANFont.textMediumBold, styles.noFavoritesLabel]}>
        {I18n.t('explorer.add_favorite_label')}
      </Text>
    </View>
  );
};

export default NoFavorites;

const styles = StyleSheet.create({
  noFavoritesContainer: {
    ...padding(24),
    width: '100%',
    maxWidth: 400,
    //height: 130,
    backgroundColor: ANColor.primary200,
  },
  noFavoritesLabel: {
    textAlign: 'left',
    marginTop: MetricsSizes.regular,
  },
});
