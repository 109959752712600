import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Audio } from 'expo-av';
import React, { useContext, useEffect } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { useMutation } from 'react-query';
import { putPlaylistCompleted } from '../../../api/request/user/putPlaylistCompleted';
import { ANSound } from '../../../assets/audio/ANSound';
import ANImages from '../../../assets/image/ANimages';
import ANButton from '../../../component/ANButton';
import ANSafeAreView from '../../../component/ANSafeAreView.native';
import UserContext from '../../../context/UserContext';
import useTrainingChecker from '../../../hooks/useTrainingChecker';
import I18n from '../../../i18n';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';

type TrainingCompletedBody = {
  count: number;
};

type ScreenRouteProp = RouteProp<RootStackParamList, Route.trainingCongratsScreen>;

const TrainingCongratsScreen = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { params } = useRoute<ScreenRouteProp>();

  const { canTriggerDifficultyReport, canTriggerHealthCheckup } = useTrainingChecker();
  const { incrementTotalTrainingSession } = useContext(UserContext);

  const { mutateAsync } = useMutation((body: TrainingCompletedBody) => {
    return putPlaylistCompleted(body.count);
  });

  useEffect(() => {
    const playSound = async () => {
      const { sound } = await Audio.Sound.createAsync(ANSound.Achievement);
      sound.playAsync();
    };
    //Increment the counter of total training session
    console.log('incrementTotalTrainingSession');
    const occurence = incrementTotalTrainingSession();
    if (occurence) {
      //Post to server without waiting
      mutateAsync({ count: occurence });
    }
    playSound();
  }, []);

  const onDoneTapped = () => {
    console.log('traing congrats done tapped');
    if (canTriggerHealthCheckup()) {
      navigation.navigate(Route.checkUpReviewScreen);
      return;
    } else if (canTriggerDifficultyReport()) {
      navigation.navigate(Route.difficultyCheckScreen);
      return;
    } else {
      if (isDevice()) {
        navigation.goBack();
      } else {
        params.shouldDismissWebModal?.();
      }
    }
  };

  return (
    <ANSafeAreView style={ANLayout.screenContaineNoPadding}>
      <View style={ANLayout.screenContainer}>
        <View style={[ANLayout.screenContaineNoPadding, styles.content]}>
          <Text style={[ANFont.h4Bold, styles.title]}>{I18n.t('training.congrats.title')}</Text>
          <Text style={[ANFont.textLargeRegular, styles.description]}>
            {I18n.t('training.congrats.description')}
          </Text>
          <Image
            source={ANImages.CongratsIllustration}
            style={[!isDevice() && { width: 400, height: 300 }]}
          />
        </View>
        <ANButton title={I18n.t('common.continue_button')} onPress={onDoneTapped} />
      </View>
    </ANSafeAreView>
  );
};

export default TrainingCongratsScreen;

const styles = StyleSheet.create({
  content: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.regular,
  },
  description: {
    textAlign: 'center',
    marginBottom: 75,
    fontSize: 20,
  },
  close: {
    position: 'absolute',
    top: MetricsSizes.regular,
    right: MetricsSizes.regular,
  },
});
