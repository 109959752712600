import * as Notifications from 'expo-notifications';
import { useEffect, useState } from 'react';
import { AppState, Linking } from 'react-native';
import I18n from '../i18n';
import { Day, Time } from '../model/ANTrainingSchedule';
import { checkAndSendFCMToken, createAndroidNotificationChannel } from '../util/notifications';
import { isDevice } from './../util/Device';

const trainingNotificationChannelId = 'training';

const useNotifications = () => {
  const [isNotificationAllowed, setIsNotificationAllowed] = useState(false);

  async function allowsNotificationsAsync() {
    if (!isDevice()) return false; //Escape for web browser

    const settings = await Notifications.getPermissionsAsync();
    return settings.granted;
  }

  /**
   * Request the user to enable notifications (if not already done), else redirects to App settings.
   */
  async function requestNotification(allowRedirectToSettings = true) {
    if (!isDevice()) return; //Escape for web browser
    const settings = await Notifications.getPermissionsAsync();
    if (settings.canAskAgain) {
      const value = await Notifications.requestPermissionsAsync();
      setIsNotificationAllowed(value.granted);
    } else if (allowRedirectToSettings) {
      Linking.openSettings();
    }
  }

  useEffect(() => {
    if (!isDevice()) return; //Escape for web browser

    const checkPermissions = async () => {
      setIsNotificationAllowed(await allowsNotificationsAsync());
    };
    //Listen for app state (permission change, like notification) changes and handle them accordingly
    console.log('Check app state change');
    const subscription = AppState.addEventListener('change', checkPermissions);
    checkPermissions();

    return () => {
      subscription.remove();
      console.log('Remove app state change listner');
    };
  }, []);

  useEffect(() => {
    if (isNotificationAllowed) {
      //Create a MAX alert for training notification
      console.log('Register device to notifications');
      createAndroidNotificationChannel();
      checkAndSendFCMToken(false);
    }
  }, [isNotificationAllowed]);

  const scheduleTrainingNotification = async (day: Day, time: Time) => {
    console.log('Schedule local notification for day: ', day, ' at time: ', time);
    await Notifications.scheduleNotificationAsync({
      content: {
        title: I18n.t('program_notifications.notification_title'),
        body: I18n.t('program_notifications.notification_body'),
        sound: true,
        data: { url: 'open_training_session' },
      },
      trigger: {
        repeats: true,
        weekday: day,
        hour: time.hour,
        minute: time.minute,
        channelId: trainingNotificationChannelId,
      },
    });
  };
  return {
    isNotificationAllowed,
    scheduleTrainingNotification,
    requestNotification,
  };
};

export default useNotifications;
