import React, { FC } from 'react';
import { View, ViewStyle } from 'react-native';
import TrainingSessionCountDown from '../../../component/TrainingSessionCountDown';
import { ANColor } from '../../../theme/ANColor';
import BreathTimeSelector from './BreathTimeSelector';

type Props = {
  shouldPlayTimer: boolean;
  timerValue: number;
  onTimeSelected: (minutes: number) => void;
  onTimerCompleted: () => void;
  onStart: () => void;
  openTimeBottomSheet?: () => void;
  minutes: number[];
  style?: ViewStyle;
};

const DurationContainer: FC<Props> = ({
  shouldPlayTimer,
  timerValue,
  onTimeSelected,
  onTimerCompleted,
  onStart,
  openTimeBottomSheet,
  minutes,
  style,
}) => {
  return (
    <View style={style}>
      {!shouldPlayTimer && (
        <BreathTimeSelector
          timerValue={timerValue}
          onTimeSelected={onTimeSelected}
          onStart={onStart}
          openTimeBottomSheet={openTimeBottomSheet}
          minutes={minutes}
        />
      )}
      {shouldPlayTimer && (
        <TrainingSessionCountDown
          totalTime={timerValue * 60 * 1000}
          increment={1000}
          backgroundColor={ANColor.primary100}
          countDownOccurence={0}
          onFinish={onTimerCompleted}
        />
      )}
    </View>
  );
};

export default DurationContainer;
