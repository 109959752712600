import { yupResolver } from '@hookform/resolvers/yup';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { useMutation } from 'react-query';
import { postHealthCheckup } from '../../../api/request/user/postHealthCheckup';
import ANButton from '../../../component/ANButton';
import I18n from '../../../i18n';
import ANLastReviews from '../../../model/ANLastReviews';
import { ANAppointmentReason } from '../../../model/ANPlaylist';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { isDevice } from '../../../util/Device';
import { EventKey } from '../../../util/analytics';
import { checkUpSheme } from '../../../util/validationScheme';
import SliderRatingController from './SliderRatingController';

type Props = {
  mainReason?: ANAppointmentReason;
  otherReasons?: Array<ANAppointmentReason>;
  onCheckupPosted: (result?: ANLastReviews) => void;
};

export type Checkup = {
  mainReason?: number;
  otherReasons?: Array<number>;
} & RatingFormValues;

export type RatingFormValues = {
  pain: number;
  frequency: number;
  mobility: number;
  stress: number;
};

const HealthCheckupQuestions = ({ mainReason, otherReasons, onCheckupPosted }: Props, ref: any) => {
  const { control, handleSubmit, getValues } = useForm<RatingFormValues>({
    resolver: yupResolver(checkUpSheme),
    defaultValues: {
      pain: 0,
      mobility: 0,
      frequency: 0,
      stress: 0,
    },
    mode: 'onSubmit',
  });

  useImperativeHandle(ref, () => ({
    // This is used so the parent component can call a method from this child component
    submit: () => {
      postCheckup();
    },
  }));

  const produceCheckup = useCallback(() => {
    const checkup: Checkup = {
      pain: getValues('pain'),
      mobility: getValues('mobility'),
      frequency: getValues('frequency'),
      stress: getValues('stress'),
      mainReason: mainReason?.id,
      otherReasons: otherReasons?.map(reason => reason.id),
    };
    console.log('checkup', checkup);
    return checkup;
  }, [mainReason, otherReasons]);

  const { mutateAsync, isLoading } = useMutation((checkup: Checkup) => {
    return postHealthCheckup(checkup);
  });

  const postCheckup = async () => {
    console.log('Will post checkup');
    try {
      const result = await mutateAsync(produceCheckup());
      console.log('Healh result', result);
      onCheckupPosted(result);
    } catch {
      onCheckupPosted(undefined);
    }
  };

  return (
    <View>
      <View style={styles.subContainer}>
        <ScrollView
          bounces={false}
          showsVerticalScrollIndicator={!isDevice()}
          contentContainerStyle={styles.scrollContent}
        >
          <View style={styles.ratingContainer}>
            <Text style={[ANFont.h6Bold, styles.title]}>
              {I18n.t('checkup_review.title', { reason: mainReason?.name.toLowerCase() })}
            </Text>
            <SliderRatingController
              control={control}
              name="pain"
              title={I18n.t('checkup_review.pain')}
              subtitle={I18n.t('checkup_review.pain_subtitle')}
            />

            <SliderRatingController
              control={control}
              name="frequency"
              title={I18n.t('checkup_review.frequency')}
              subtitle={I18n.t('checkup_review.frequency_subtitle')}
            />

            <SliderRatingController
              control={control}
              name="mobility"
              title={I18n.t('checkup_review.mobility')}
              subtitle={I18n.t('checkup_review.mobility_subtitle')}
            />

            <SliderRatingController
              control={control}
              name="stress"
              title={I18n.t('checkup_review.stress')}
              subtitle={I18n.t('checkup_review.stress_subtitle')}
            />

            {!isDevice() && (
              <ANButton
                style={styles.webButton}
                onPress={postCheckup}
                title={I18n.t('common.send_button')}
                eventKey={EventKey.health_checkup_send}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default forwardRef(HealthCheckupQuestions);

const styles = StyleSheet.create({
  container: {
    paddingTop: MetricsSizes.regular,
  },
  subContainer: {
    paddingHorizontal: 24,
  },
  scrollContent: {
    paddingBottom: 200,
  },
  title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.regular,
  },
  symptom: {
    textAlign: 'center',
    marginBottom: MetricsSizes.big,
  },
  ratingContainer: {
    flex: 1,
  },
  webButton: {
    marginTop: MetricsSizes.big,
  },
});
