import { parseAppointment } from '../../../model/adapters';
import ANAppointment from '../../../model/ANAppointment';
import { Api } from '../../apiclient';
import { Appointment } from '../../data/APIDataResponse';
import { parseAPIError } from '../../data/ResponseError';

export const getAllAppointments = async () => {
  try {
    const path = `/patient/me/getAllAppointments`;

    const response = await Api.get(path);

    const data: Appointment[] = response.data;

    const appointment: ANAppointment[] = data.map(item => {
      return parseAppointment(item);
    });

    return appointment;
  } catch (err) {
    throw parseAPIError(err);
  }
};
