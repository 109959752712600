import React, { FC } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import ANImages from '../../../../assets/image/ANimages';
import I18n from '../../../../i18n';
import { ANColor } from '../../../../theme/ANColor';
import ANFont from '../../../../theme/ANFont';
import ANLayout from '../../../../theme/ANLayout';
import { MetricsSizes } from '../../../../theme/ANMetrics';
import { isDevice } from '../../../../util/Device';

type Props = {
  answeredToday: boolean;
  onAnswerTapped: () => void;
};

const DashboardHealthCheckupCard: FC<Props> = ({ answeredToday = false, onAnswerTapped }) => {
  console.log('Last heatlh checkup answered today: ', answeredToday);
  if (answeredToday) {
    return (
      <View style={[styles.cardAlreadyAnswered]}>
        <Text
          style={[
            isDevice() ? ANFont.textSmallRegular : ANFont.textLargeRegular,
            styles.thanksText,
          ]}
        >
          {I18n.t('health_checkup_card.after_answer')}
        </Text>
        <Image source={ANImages.CongratsIllustration} style={styles.congratsImage} />
      </View>
    );
  } else {
    return (
      <TouchableOpacity onPress={onAnswerTapped}>
        <View style={[ANLayout.card, styles.cardAskToAnswer]}>
          <Image source={ANImages.AndrewWaving} style={styles.image} />
          <Text
            style={[
              isDevice() ? ANFont.textMediumRegular : ANFont.textLargeRegular,
              styles.answerText,
            ]}
          >
            {I18n.t('health_checkup_card.title')}
          </Text>
          <Text style={[ANFont.textSmallBold, styles.action]}>
            {I18n.t('health_checkup_card.button')}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
};

export default DashboardHealthCheckupCard;

const styles = StyleSheet.create({
  cardAlreadyAnswered: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    paddingVertical: MetricsSizes.small,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 0,
    width: isDevice() ? undefined : 450,
    backgroundColor: ANColor.tertiary200,
  },
  cardAskToAnswer: {
    flex: undefined,
    justifyContent: 'flex-start',
    width: isDevice() ? undefined : 450,
    backgroundColor: ANColor.tertiary200,
  },
  answerText: {
    width: '90%',
  },
  thanksText: {
    flex: 1,
  },
  action: {
    color: ANColor.tertiary500,
    textAlign: 'left',
    paddingTop: MetricsSizes.small,
    textDecorationLine: 'underline',
    paddingLeft: 0,
  },
  image: {
    resizeMode: 'contain',
    position: 'absolute',
    bottom: 0,
    right: MetricsSizes.small,
  },
  congratsImage: {
    resizeMode: 'contain',
    width: isDevice() ? 50 : 100,
    aspectRatio: 1,
  },
});
