import { Api } from '../../apiclient';
import { parseAPIError } from '../../data/ResponseError';

export const putPlaylistCompleted = async (count: number) => {
  try {
    console.log('Increment playlist completed request');

    const response = await Api.put('/patient/me/updatePlaylistCompleted', {
      completed: count,
    });
  } catch (err) {
    throw parseAPIError(err);
  }
};
