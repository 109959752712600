import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPodcastItems } from '../../api/request/user/explorer/getTopics';
import SnackMessageContext from '../../context/SnackMessageContext';
import useLocalPodcastLogs from '../../hooks/useLocalPodcastLogs';
import ANMedia from '../../model/ANMedia';
import { RootStackParamList, Route } from '../../navigation/Route';
import { isDevice } from '../../util/Device';
import { EventKey, logEvent } from '../../util/analytics';

type ScreenRouteProp = RouteProp<RootStackParamList, Route.explorerTopicScreen>;

const useExplorerPodcastScreen = () => {
  const {
    params: { id },
  } = useRoute<ScreenRouteProp>();

  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  const [selectedPodcast, setSelectedPodcast] = useState<ANMedia | undefined>(undefined);
  const podcastLogs = useLocalPodcastLogs(state => state.podcastLogs);

  const { displaySnackBarError } = useContext(SnackMessageContext);
  const [podcastItemsWithProgress, setPodcastItemsWithProgress] = useState<ANMedia[] | undefined>();

  useEffect(() => {
    logEvent(EventKey.open_podcast_screen, { item_id: id });
  }, []);

  const onPodcastPressed = useCallback((media: ANMedia) => {
    console.log('Pressed', media);
    if (isDevice()) {
      console.log('open audio player');
      navigation.navigate(Route.explorerAudioPlayerScreen, {
        podcast: media,
        timeFlag: media.timeFlagInMilli,
      });
    } else {
      console.log('open audio modal');
      setSelectedPodcast(media);
    }
  }, []);

  const {
    isLoading,
    data: podcast,
    isError,
  } = useQuery({
    queryKey: ['podcast', id],
    queryFn: () => getPodcastItems(id),
    retryDelay: 3000, //Podcast RSS parsing process may take time the first time it is loaded. Retry must wait longer than usual
  });

  useEffect(() => {
    if (podcast === undefined) {
      return;
    }
    const updatedData = podcast?.items?.map(media => {
      const log = podcastLogs.find(log => log.title === media.title && log.topicId === id);
      if (log) {
        media.timeFlagInMilli = log.timeFlagInMilli;
      }
      return media;
    });

    setPodcastItemsWithProgress(updatedData ?? []);
  }, [podcast, podcastLogs]);

  useEffect(() => {
    //An error occurred while fetching the podcast
    if (isError) {
      displaySnackBarError();
    }
  }, [isError]);

  const onAudioExit = useCallback(() => {
    console.log('Audio exit');
    setSelectedPodcast(undefined);
  }, []);

  return {
    podcast,
    podcastItemsWithProgress,
    isLoading,
    selectedPodcast,
    onAudioExit,
    onPodcastPressed,
  };
};

export default useExplorerPodcastScreen;
