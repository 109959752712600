import React, { FC } from 'react';
import InvitedModeScreen from '../../screen/home/InvitedModeScreen';
import WebModal, { WebModalProps } from './WebModal';

const GuestPromotionModal: FC<WebModalProps> = ({ isVisible, shouldDismissWebModal }) => {
  return (
    <WebModal
      isVisible={isVisible}
      shouldDismissWebModal={shouldDismissWebModal}
      shouldDisplayCloseIcon={false}
      content={<InvitedModeScreen shouldDismissWebModal={shouldDismissWebModal} />}
    />
  );
};

export default GuestPromotionModal;
