import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ANButton from '../../component/ANButton';
import ANProgressBar from '../../component/ANProgressBar';
import AvatarImage from '../../component/AvatarImage';
import ExpoIcon, { ExpoIconDesign } from '../../component/ExpoIcon';
import HeartButton from '../../component/HeartButton';
import LockedForGuestsOverlay from '../../component/LockedForGuestsOverlay';
import useHour from '../../hooks/useHour';
import I18n from '../../i18n';
import { ANAuthor } from '../../model/ANAuthor';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { padding } from '../../util/padding';

type Props = {
  title: string;
  durationInSec?: number;
  timeFlagInMilli?: number;
  isFavorite: boolean;
  author?: ANAuthor;
  isLockedForGuests: boolean;
  itemWidth?: number;
  itemHeight?: number;
  useInvitedMode: boolean;
  onFavoriteTapped: () => void;
  onPress: () => void;
};

const AudioWrappedRowItem: FC<Props> = ({
  title,
  durationInSec,
  timeFlagInMilli = 0,
  author,
  itemWidth,
  isLockedForGuests = false,
  itemHeight,
  useInvitedMode,
  onPress,
  isFavorite,
  onFavoriteTapped,
}) => {
  const { createDurationString } = useHour();

  return (
    <View
      style={[
        ANLayout.roundedBackground,
        styles.container,
        itemWidth ? { width: itemWidth } : {},
        itemHeight ? { height: itemHeight } : {},
      ]}
    >
      {author && (
        <View style={styles.authorContainer}>
          <AvatarImage url={author.avatarURL} size={32} />
          <Text
            style={[ANFont.textLargeBold, styles.authorName]}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {author.name}
          </Text>
        </View>
      )}

      <View style={styles.textContainer}>
        <Text style={ANFont.textMediumBold}>{title}</Text>
        <Text style={[ANFont.textMediumBold, styles.duration]}>
          {durationInSec ? createDurationString(durationInSec * 1000) : '--:--'}
        </Text>
        {timeFlagInMilli != 0 && (
          <ANProgressBar
            totalDurationInSeconds={durationInSec ?? 0}
            flaggedDurationInMilli={timeFlagInMilli}
            style={styles.progressbar}
          />
        )}
      </View>
      <ANButton
        title={I18n.t('explorer.play_audio')}
        theme="tertiary"
        wrap={false}
        onPress={onPress}
        leftAccessory={
          <ExpoIcon
            name="play-circle"
            design={ExpoIconDesign.MaterialCommunity}
            size={16}
            color={ANColor.primary500}
            style={{
              marginRight: MetricsSizes.small,
              alignSelf: 'center',
            }}
          />
        }
      />
      {!useInvitedMode && (
        <View style={styles.heartContainer}>
          <HeartButton isFavorite={isFavorite} onPress={onFavoriteTapped} style={styles.heart} />
        </View>
      )}
      {isLockedForGuests && useInvitedMode && <LockedForGuestsOverlay />}
    </View>
  );
};

export default AudioWrappedRowItem;

const styles = StyleSheet.create({
  container: {
    ...padding(MetricsSizes.regular),
    paddingTop: MetricsSizes.extraLarge,
  },
  infoContainer: {
    flex: 1,
    flexDirection: 'row',
    marginRight: MetricsSizes.regular,
  },
  textContainer: {
    flex: 1,
    marginVertical: MetricsSizes.regular,
  },
  duration: {
    marginTop: MetricsSizes.small,
  },
  authorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressbar: {
    marginTop: MetricsSizes.small,
  },
  authorName: {
    marginHorizontal: MetricsSizes.small,
    flexWrap: 'wrap',
    flex: 1,
  },
  heartContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  heart: {
    ...padding(MetricsSizes.regular),
  },
});
