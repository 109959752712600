import React from 'react';
import { ActivityIndicator, Image, StyleSheet } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import ANImages from '../assets/image/ANimages';
import { ANColor } from '../theme/ANColor';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
type Props = {};

const AndrewLoadingScreen = () => {
  return (
    <Animated.View
      entering={FadeIn}
      style={[ANLayout.screenContaineNoPadding, ANLayout.columnHVCenter, StyleSheet.absoluteFill]}
    >
      <Image source={ANImages.LogoLong} />
      <ActivityIndicator
        size={'large'}
        color={ANColor.tertiary500}
        style={styles.activityIndicator}
      />
    </Animated.View>
  );
};

export default AndrewLoadingScreen;

const styles = StyleSheet.create({
  activityIndicator: {
    marginTop: MetricsSizes.big,
  },
});
