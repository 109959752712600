import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import { TrainingSessionEndNavigator } from '../../navigation/TrainingSessionEndNavigator';
import { ANColor } from '../../theme/ANColor';
import ANLayout from '../../theme/ANLayout';

type Props = {
  isVisible: boolean;
  shouldDismiss: (eventKey?: string) => void;
};

const TrainingEndModal: FC<Props> = ({ shouldDismiss, isVisible }) => {
  return (
    <Portal>
      <Modal
        visible={isVisible}
        style={styles.modal}
        dismissable={false}
        contentContainerStyle={[ANLayout.modalContainer, ANLayout.column, styles.container]}
      >
        <TrainingSessionEndNavigator shouldDismissWebModal={shouldDismiss} />
      </Modal>
    </Portal>
  );
};

export default TrainingEndModal;

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    alignItems: 'center',
  },
  container: {
    width: 500,
    backgroundColor: ANColor.primary100,
    maxHeight: '90%',
  },
});
