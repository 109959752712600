import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { Image, View } from 'react-native';
import ANImages from '../assets/image/ANimages';
import AuthDefinePasswordScreen from '../screen/auth/AuthDefinePasswordScreen';
import AuthLoginScreen from '../screen/auth/AuthLogin/AuthLoginScreen';
import AuthResetPasswordScreen from '../screen/auth/AuthResetPasswordScreen';
import ANLayout from '../theme/ANLayout';
import { screenOptions } from './options';
import { RootStackParamList, Route } from './Route';

const Stack = createNativeStackNavigator<RootStackParamList>();

export const AuthNavigator = () => {
  return (
    <View style={ANLayout.row}>
      <View style={[ANLayout.container, { backgroundColor: 'green' }]}>
        <Stack.Navigator
          screenOptions={{ ...screenOptions, headerLeft: undefined, headerTitle: '' }}
        >
          <Stack.Screen
            name={Route.authScreen}
            component={AuthLoginScreen}
            options={{ headerShown: false }}
          />

          <Stack.Screen name={Route.authDefinePassword} component={AuthDefinePasswordScreen} />
          <Stack.Screen
            name={Route.authResetPassword}
            component={AuthResetPasswordScreen}
            options={{ presentation: 'fullScreenModal' }}
          />
        </Stack.Navigator>
      </View>
      <Image source={ANImages.Login1} style={{ height: '100%', width: '50%' }}></Image>
    </View>
  );
};
