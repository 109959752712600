import ANMedia from '../../../../model/ANMedia';
import { Api } from '../../../apiclient';
import {
  ExplorerMedia,
  FreshestExplorerMedia,
  Podcast,
  PodcastsResponse,
  TopicDetailed,
  TopicsReponse,
} from '../../../data/APIDataResponse';
import { parseAPIError } from '../../../data/ResponseError';
import {
  parseMedia,
  parsePodcast,
  parsePodcastList,
  parseTopicDetailed,
} from './../../../../model/adapters';
import { exploreCategories } from './../../../data/ExplorerCategory';

export const getTopics = async () => {
  const path = `/explorer-topics?sort=order&populate=illustration`;
  try {
    const response = await Api.get(path);
    const result: TopicsReponse = response.data;

    const categoriesWithTopics = [...exploreCategories];
    categoriesWithTopics.forEach(category => {
      const filteredTopics = result.data
        .filter(topic => topic.attributes.type === category.key)
        .map(topic => {
          return {
            ...topic.attributes,
            illustration: topic.attributes.illustration?.data?.attributes?.formats.large.url,
            id: topic.id,
          };
        });
      category.data = filteredTopics;
    });

    return categoriesWithTopics;
  } catch (err) {
    throw parseAPIError(err, path);
  }
};
export const getAllPodcasts = async () => {
  const path = `/explorer-podcasts?sort=order`;
  try {
    const response = await Api.get(path);
    const result: PodcastsResponse = response.data;
    return parsePodcastList(result.data);
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const getTopic = async (id: number) => {
  console.log('Query topic');
  const path = `/topic/getTopic?topicId=${id}`;
  try {
    const response = await Api.get(path);
    const result: TopicDetailed = response.data;
    return parseTopicDetailed(result);
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const getMediasById = async (id?: number) => {
  if (!id) return;
  console.log('Fetch Media by ID: ', id);
  const path = `/medias/getMedia?id=${id}`;
  try {
    const response = await Api.get(path);
    const result: ExplorerMedia = response.data;
    return parseMedia(result);
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const getPodcastItems = async (podcastId: number) => {
  const path = `/podcast/getRSSFeed`;
  try {
    console.log('Will request podcast', podcastId);
    const response = await Api.get(path, { params: { podcastId } });
    const data: Podcast = response.data;
    return parsePodcast(data, podcastId);
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const getFreshestMedias = async () => {
  console.log('Will fetch freshest medias');
  const path = `/medias/getFreshestMedias`;
  try {
    const response = await Api.get(path);
    const data: FreshestExplorerMedia[] = response.data;
    const medias: ANMedia[] = data.map(media => parseMedia(media, media.topic?.id));
    return medias;
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export type ExplorerLogBody = {
  timeFlagInMilli: number;
  completed: boolean;
  isAuthenticated: boolean;
};

export type MediaLogBody = {
  mediaId: number;
} & ExplorerLogBody;

export type PodcastLogBody = {
  topicId: number;
  title: string;
} & ExplorerLogBody;
/**
 * Push a log event to the server with the media id being used, and the a time flag informing on the total time viewed for the media.
 * @param mediaId : media id being used
 * @param timeFlagInMilli : number in milliseconds
 * @param completed : true if completed
 */
export const logUserMediaUsed = async ({
  mediaId,
  timeFlagInMilli,
  completed,
  isAuthenticated,
}: MediaLogBody) => {
  if (!isAuthenticated) return;
  const path = `/patient/me/logMedia`;
  const roundedTime = Math.round(timeFlagInMilli);
  console.log('logUserMediaUsed', mediaId, roundedTime, completed);
  try {
    await Api.post(path, { mediaId, timeFlagInMilli: roundedTime, completed });
  } catch (err) {
    throw parseAPIError(err, path);
  }
};
export const logPodcastUsed = async ({
  topicId,
  title,
  timeFlagInMilli,
  completed,
  isAuthenticated,
}: PodcastLogBody) => {
  if (!isAuthenticated) return;
  const path = `/patient/me/logPodcast`;
  const roundedTime = Math.round(timeFlagInMilli);
  console.log('Round time flag to post', roundedTime);
  try {
    await Api.post(path, { topicId, timeFlagInMilli: roundedTime, title, completed });
  } catch (err) {
    throw parseAPIError(err, path);
  }
};
