import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React, { FC, useContext, useMemo } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { DifficultyCheck } from '../../../api/request/user/postDifficultyReport';
import ANSafeAreView from '../../../component/ANSafeAreView.native';
import ExercicePicker from '../../../component/ExercicePicker';
import FooterOverlayButton from '../../../component/FooterOverlayButton';
import UserContext from '../../../context/UserContext';
import I18n from '../../../i18n';
import ANExercice from '../../../model/ANVideoGroup';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import { EventKey } from '../../../util/analytics';

export type DifficultyCheckScreenProps = {
  difficulty: DifficultyCheck;
};

type SelectedExercice = {
  exercice: ANExercice;
  selected: boolean;
};

type ScreenRouteProp = RouteProp<RootStackParamList, Route.difficultyExercicePickerScreen>;

const DifficultyReportExercicePickerScreen: FC<DifficultyCheckScreenProps> = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { top, bottom } = useSafeAreaInsets();

  const {
    params: { difficulty },
  } = useRoute<ScreenRouteProp>();

  const { user } = useContext(UserContext);
  const [exercices, setExercices] = React.useState<SelectedExercice[]>(
    user!.trainingPlaylist!.exercices!.map((exercice: ANExercice) => {
      return { exercice: exercice, selected: false };
    }),
  );

  //get selected videos from exercices
  const selectedVideos = useMemo(() => {
    const selectedExercices = exercices.filter(exercice => exercice.selected);
    const selectedVideos = selectedExercices.map(exercice => exercice.exercice);
    return selectedVideos;
  }, [exercices]);

  const continueTapped = () => {
    navigation.navigate(Route.difficultyCheckThanksScreen, {
      difficulty,
      videos: selectedVideos.map(exercice => exercice.selectedVideo),
    });
  };

  const onItemPressed = (exercice: ANExercice) => {
    const newExercices = [...exercices];
    const index = newExercices.findIndex(
      selectableExercice => selectableExercice.exercice.id === exercice.id,
    );
    newExercices[index].selected = !newExercices[index].selected;
    setExercices(newExercices);
  };

  return (
    <ANSafeAreView
      style={[ANLayout.screenContaineNoPadding, { paddingTop: top + MetricsSizes.regular }]}
      ignoreTopInset
    >
      <FlatList
        bounces={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 16, backgroundColor: ANColor.primary100 }}
        ListHeaderComponent={
          <View>
            <Text style={[ANFont.h6Bold, styles.title]}>
              {difficulty === DifficultyCheck.easy
                ? I18n.t('difficulty_check.too_easy_screen_title')
                : I18n.t('difficulty_check.too_hard_screen_title')}
            </Text>
            <Text style={[ANFont.textLargeRegular, styles.body]}>
              {I18n.t('difficulty_check.remark')}
            </Text>
          </View>
        }
        data={exercices}
        renderItem={({ item, index }) => {
          return (
            <ExercicePicker
              index={index}
              exercice={item.exercice}
              selected={item.selected}
              onPress={() => onItemPressed(item.exercice)}
            />
          );
        }}
        ListFooterComponent={<View style={{ height: bottom + 60 }} />} //This is to add a bottom extra padding (to avoid footer overlay)
        keyExtractor={({ exercice: video }) => {
          return String(video.id);
        }}
      />
      <FooterOverlayButton
        type="continue"
        onPress={continueTapped}
        paddingBottom={bottom}
        eventKey={EventKey.difficulty_review_validate}
      />
    </ANSafeAreView>
  );
};

export default DifficultyReportExercicePickerScreen;

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.regular,
  },
  body: {
    textAlign: 'center',
    color: ANColor.commonBlack,
    marginBottom: 50,
  },
  ratingContainer: {
    flex: 1,
  },
  picker: {
    marginTop: MetricsSizes.small,
  },
});
