import { Api } from '../../apiclient';
import { parseAPIError } from '../../data/ResponseError';

export const postForgotPassword = async (email: string) => {
  try {
    console.log('Will send email forgot password');

    const response = await Api.post('/auth/forgot-password', {
      email: email,
    });
    return response;
  } catch (err) {
    throw parseAPIError(err);
  }
};
