import { Audio } from 'expo-av';
import * as Haptics from 'expo-haptics';
import * as WebBrowser from 'expo-web-browser';
import { Linking, Platform } from 'react-native';
import { isDevice } from './Device';

export const webContainerWidth = 1920;

export const openUrlInBrowser = async (url?: string) => {
  try {
    if (isDevice()) {
      if (url) {
        const canOpen = await Linking.canOpenURL(url);
        if (canOpen) {
          WebBrowser.openBrowserAsync(url);
        }
      }
    } else {
      if (url) {
        Linking.openURL(url);
      }
    }
  } catch {
    console.log('could not open url', url);
  }
};

export const openAndrewWebSiteInBrowser = () => {
  openUrlInBrowser('https://andrewapp.fr/');
};

export const findAnOsteoURL = 'https://www.andrewapp.fr/nos-therapeutes';

export const openFindAnOsteoInBrowser = () => {
  openUrlInBrowser(findAnOsteoURL);
};

export const hapticSelection = async () => {
  if (Platform.OS === 'ios' || Platform.OS === 'android')
    await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
};

export const hapticHeavy = async () => {
  if (Platform.OS === 'ios' || Platform.OS === 'android')
    await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
};
//Enable the app to play sound even in sildent mode
export const enableAudioToWorkInSilentMode = async () => {
  await Audio.setAudioModeAsync({
    staysActiveInBackground: true,
    playsInSilentModeIOS: true,
  });
};

/**
 * Compare the current version to the minimum version of the app the. Version string can be in format x.x.x or x.x
 * @param currentVersion version to compare to
 * @param minimumVersion minimum version OK
 * @returns boolean
 */
export const isCurrentVersionLateEnough = (
  currentVersion: string,
  minimumVersion: string,
): boolean => {
  const currentVersionParts = currentVersion.split('.');
  const minimumVersionParts = minimumVersion.split('.');

  for (let i = 0; i < minimumVersionParts.length; i++) {
    const currentPart = parseInt(currentVersionParts[i] ?? '0');
    const minimumPart = parseInt(minimumVersionParts[i] ?? '0');

    if (currentPart > minimumPart) {
      return true;
    } else if (currentPart < minimumPart) {
      return false;
    }
  }

  return true;
};
