import React from 'react';
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { ExplorerCategory, ExplorerTopic } from '../../api/data/ExplorerCategory';
import FreshestMedia from '../../component/FreshestMedia';
import I18n from '../../i18n';
import { ANColor } from '../../theme/ANColor';
import { default as ANFont, default as Font } from '../../theme/ANFont';
import { MetricsSizes } from '../../theme/ANMetrics';
import ExplorerCategoryFilter from './ExplorerCategoryFilter';
import TopicItem from './TopicItem';
import useExplorerScreen from './useExplorerScreen';

const ExplorerScreen = () => {
  const {
    categoriesData,
    selectedCategory,
    filteredCategories,
    mostRecentMedias,
    isLoadingNewestMedis,
    onTopicPressed,
    setFilteredCategories,
    setSelectedCategory,
  } = useExplorerScreen();

  const { top } = useSafeAreaInsets();

  const [layoutWidth, setLayoutWidth] = React.useState<number>(928);

  const _renderItem = ({ index, item }: { index: number; item: ExplorerTopic }) => {
    return (
      <TopicItem
        title={item.title}
        topicKey={item.key}
        illustrationURL={item.illustration}
        onPress={() => onTopicPressed(item)}
        style={[
          { width: (layoutWidth - 2 * MetricsSizes.regular) / 2 },
          index % 2 == 0
            ? { marginRight: MetricsSizes.regular }
            : { marginLeft: MetricsSizes.regular },
        ]}
      />
    );
  };

  /**
   * Filter topics by category (mind, body ...)
   */
  const categoryFilterTapped = (category: ExplorerCategory) => {
    if (category.key === selectedCategory?.key) {
      setFilteredCategories(undefined);
      setSelectedCategory(undefined);
    } else {
      const filteredTopics = categoriesData.filter(item => item.key === category.key);
      setFilteredCategories(filteredTopics);
      setSelectedCategory(category);
    }
  };

  const onLayout = (event: LayoutChangeEvent) => {
    const { width: screenWidth } = event.nativeEvent.layout;
    setLayoutWidth(screenWidth);
  };

  return (
    <View style={[styles.container]} onLayout={onLayout}>
      <View style={styles.headerContainer}>
        <Text style={[ANFont.h3Bold, styles.title, { paddingTop: top }]}>
          {I18n.t('explorer.title')}
        </Text>

        <ExplorerCategoryFilter
          data={categoriesData}
          selectedCategory={selectedCategory}
          onFilterTapped={categoryFilterTapped}
          contentStyle={styles.filterContainer}
        />
        {!selectedCategory && (
          <FreshestMedia
            medias={mostRecentMedias}
            isLoading={isLoadingNewestMedis}
            style={styles.freshetNewsContainer}
          />
        )}
      </View>
      {(filteredCategories ?? categoriesData).map(section => {
        return (
          <View>
            <Text style={[styles.sectionContainer, Font.h5Bold, styles.sectionTitle]}>
              {section.title}
            </Text>
            <FlatList
              data={section.data}
              numColumns={2}
              keyExtractor={(item, index) => item.key}
              renderItem={_renderItem}
            />
          </View>
        );
      })}
    </View>
  );
};

export default ExplorerScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: ANColor.primary100,
    flex: 1,
    overflow: 'scroll',
    paddingRight: MetricsSizes.regular,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: ANColor.primary100,
  },
  headerContainer: {},

  contentContainer: {},
  title: {
    color: ANColor.tertiary500,
  },
  sectionContainer: {
    paddingVertical: MetricsSizes.small,
  },
  sectionTitle: {
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.small,
  },
  filterContainer: {
    marginTop: 32,
  },
  freshetNewsContainer: {
    marginVertical: 32,
  },
});
