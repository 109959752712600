import { parseAppointment, parseOsteo } from '../../../model/adapters';
import ANAppointment from '../../../model/ANAppointment';
import ANOsteo from '../../../model/ANOsteo';
import ANPlaylist from '../../../model/ANPlaylist';
import { ANTrainingSchedule } from '../../../model/ANTrainingSchedule';
import ANUser from '../../../model/ANUser';
import ANVideo from '../../../model/ANVideo';
import ANExercice from '../../../model/ANVideoGroup';
import TrainingSessionConfig from '../../../screen/home/TrainingSession/TrainingSessionConfig';
import { Api } from '../../apiclient';
import { Appointment, MyProfile } from '../../data/APIDataResponse';
import { parseAPIError } from '../../data/ResponseError';
import { parseExercice, parseLastReviews } from './../../../model/adapters';

const totalMinutes = (playlist: ANVideo[] | undefined) => {
  if (playlist === undefined || playlist.length == 0) return 0;

  const totalDurationVideoInSeconds = playlist
    ?.map(item => item.duration)
    .reduce((accumulateur, valeurCourante) => accumulateur + valeurCourante);

  if (totalDurationVideoInSeconds === undefined) return 0;
  const total =
    totalDurationVideoInSeconds +
    (playlist?.length ?? 0) * TrainingSessionConfig.videoTransitionTime;
  const minutes = (total ?? 0) / (60 * 1000);
  return Math.round(minutes);
};

export const getMyProfile = async () => {
  const path = `/patient/me`;
  try {
    const response = await Api.get(path);
    const data: MyProfile = response.data;

    const osteoData = data.patient.osteo;

    const userOsteo: ANOsteo = parseOsteo(osteoData);

    const lastReviews = parseLastReviews(data.lastReviews);
    let user: ANUser = {
      ...data,
      ...data.patient,
      osteo: userOsteo,
      lastReviews,
    };

    //Convert optional lastAppointment to ANAppointment
    if (data.lastAppointment) {
      const appointment: Appointment = data.lastAppointment;
      const lastMeeting: ANAppointment = parseAppointment(appointment);

      user = {
        ...user,
        lastMeeting,
        osteo: lastMeeting.osteo, //override the osteo using the one from the last appointment
      };
    }

    //Set training schedule (notifications time for training)
    const trainingSchedule = data.patient.trainingSchedule;
    if (trainingSchedule) {
      const notificationsSchedule: ANTrainingSchedule[] = trainingSchedule;
      user = {
        ...user,
        notificationsSchedule,
      };
    }

    //convert Playlist to ANPlaylist
    const exercices = data.lastPlaylist?.exercices;
    if (exercices) {
      const parsedExercices: ANExercice[] = exercices
        .map(item => parseExercice(item))
        .filter(item => item !== undefined);

      var expired = false;
      if (data.lastPlaylist?.expiry) {
        const expiryDate = new Date(data.lastPlaylist?.expiry);
        expired = expiryDate < new Date();
      }

      const videos = parsedExercices.flatMap(item => item.selectedVideo ?? []);
      try {
        const trainingPlaylist: ANPlaylist = {
          exercices: parsedExercices,
          advice: data.lastPlaylist?.advice,
          duration: totalMinutes(videos),
          mainReason: data.lastPlaylist?.mainReason,
          reasons: data.lastPlaylist?.reasons,
          completed: data.lastPlaylist?.completed ?? 0,
          expired: expired,
          expiryDate: data.lastPlaylist?.expiryFormatted,
        };

        user = {
          ...user,
          trainingPlaylist,
        };
      } catch (err) {
        console.log('Error while parsing playlist', err);
      }
    }

    return user;
  } catch (err) {
    throw parseAPIError(err, path);
  }
};

export const deleteMyProfile = async () => {
  const path = '/patient/deleteMyAccount';
  const response = await Api.get(path);
  return response;
};
