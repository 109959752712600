import React, { FC } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { NativeSafeAreaViewProps } from 'react-native-safe-area-context';
import ANLayout from '../theme/ANLayout';
import ANSafeAreView from './ANSafeAreView.native';

type Props = {
  useSafeArea?: boolean;
  useHorizontalPadding: boolean;
} & ViewProps &
  NativeSafeAreaViewProps;

const ANScreenContainer: FC<Props> = ({
  useHorizontalPadding,
  useSafeArea,
  children,
  ...props
}) => {
  if (useSafeArea) {
    return (
      <ANSafeAreView style={ANLayout.screenContaineNoPadding}>
        <View
          style={[
            useHorizontalPadding ? ANLayout.screenContainer : ANLayout.screenContaineNoPadding,
            props.style,
          ]}
          {...props}
        >
          {children}
        </View>
      </ANSafeAreView>
    );
  } else {
    return (
      <View
        style={[
          useHorizontalPadding ? ANLayout.screenContainer : ANLayout.screenContaineNoPadding,
          props.style,
        ]}
        {...props}
      >
        {children}
      </View>
    );
  }
};

export default ANScreenContainer;

const styles = StyleSheet.create({});
