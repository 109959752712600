import { FontAwesome, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { useHover } from '@react-native-aria/interactions';
import React, { FC } from 'react';
import { Pressable, StyleSheet, Text, TextStyle, ViewStyle } from 'react-native';
import ExpoIcon, { ExpoIconDesign } from '../component/ExpoIcon';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import { padding } from '../util/padding';

type Props = {
  design?: ExpoIconDesign;
  title: string;
  icon?:
    | keyof typeof MaterialIcons.glyphMap
    | keyof typeof FontAwesome.glyphMap
    | keyof typeof MaterialCommunityIcons.glyphMap;
  selected?: boolean;
  style?: ViewStyle;
  iconStyle?: ViewStyle;
  textStyle?: TextStyle;
  onPress: () => void;
};

const SideBarButton: FC<Props> = ({
  title,
  icon,
  design,
  selected = false,
  style,
  iconStyle,
  textStyle,
  onPress,
}) => {
  const ref = React.useRef();
  const { isHovered } = useHover({}, ref);
  return (
    <Pressable
      style={[
        ANLayout.rowHCenter,
        styles.container,
        isHovered && styles.hover,
        selected && styles.selected,
        style,
      ]}
      ref={ref}
      onPress={onPress}
    >
      {icon && (
        <ExpoIcon
          name={icon}
          color={ANColor.commonWhite}
          style={[iconStyle, styles.icon]}
          design={design}
        />
      )}
      <Text style={[ANFont.textLargeRegular, textStyle, styles.text]}>{title}</Text>
    </Pressable>
  );
};

export default SideBarButton;

const styles = StyleSheet.create({
  container: {
    ...padding(8),
    borderRadius: MetricsSizes.webCornerRadius,
  },
  icon: {
    marginRight: MetricsSizes.small,
  },
  hover: {
    backgroundColor: ANColor.tertiary300,
  },
  text: {
    color: ANColor.commonWhite,
  },
  selected: {
    backgroundColor: ANColor.tertiary500,
  },
});
