import messaging from '@react-native-firebase/messaging';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import { putFCMToken } from '../api/request/user/putPatient';
import { isDevice } from './Device';

export const checkAndSendFCMToken = async (isLogged: boolean) => {
  if (!isDevice()) return; //Escape for web browser

  try {
    const isRegisteredToFCM = messaging().isDeviceRegisteredForRemoteMessages;
    if (isRegisteredToFCM) {
      const token = await messaging().getToken();

      if (isLogged) {
        putFCMToken(token);
      }
    }
  } catch {
    console.log('Error while sending FCM token');
  }
};

export const registerForRemoteMessages = () => {
  const isRegisteredToFCM = messaging().isDeviceRegisteredForRemoteMessages;
  console.log('isRegisteredToFCM', isRegisteredToFCM);

  try {
    if (!isRegisteredToFCM) {
      console.log('Register to FCM');
      messaging().registerDeviceForRemoteMessages();
    }
  } catch {}
};

export const unRegisterForRemoteMessages = () => {
  messaging().unregisterDeviceForRemoteMessages();
};
export const deactivateFIRMessagingAutoRegister = () => {
  messaging().setAutoInitEnabled(false);
};

export const createAndroidNotificationChannel = () => {
  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
    });
  }
};

export const configureLocalNotificationsHandler = () => {
  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: false,
    }),
  });
};
