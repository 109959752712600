import { useNavigation } from '@react-navigation/native';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Image, StatusBar, StyleSheet, Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ANImages from '../../assets/image/ANimages';
import { ANLocalVideos } from '../../assets/video';
import ANButton from '../../component/ANButton';
import ANSafeAreView from '../../component/ANSafeAreView';
import CloudsAnimated from '../../component/AnimatedClouds';
import FooterOverlayButton from '../../component/FooterOverlayButton';
import OsteowPromotionBulletList from '../../component/OsteowPromotionBulletList';
import UserContext from '../../context/UserContext';
import useNotifications from '../../hooks/useNotifications';
import I18n from '../../i18n';
import { Route } from '../../navigation/Route';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import { isDevice } from '../../util/Device';
import { EventKey, logEvent } from '../../util/analytics';
import { openFindAnOsteoInBrowser } from '../../util/utils';

type Props = {
  shouldDismissWebModal: () => void;
};

const InvitedModeScreen: FC<Props> = ({ shouldDismissWebModal }) => {
  const redirectToOsteoWebsite = useCallback(() => {
    openFindAnOsteoInBrowser();
  }, []);

  const safeArea = isDevice() ? useSafeAreaInsets() : undefined;
  const navigation = isDevice() ? useNavigation() : undefined;
  const [playIntro, setPlayIntro] = React.useState(false);
  const { guestOnboardingTriggered, guestOnboardingSeen } = useContext(UserContext);

  const { requestNotification } = useNotifications();

  const goBack = useCallback(() => {
    if (isDevice()) {
      requestNotification(false); //ask to enable notification right now to have a chance to send this guest a notification later
      navigation?.goBack();
    } else {
      shouldDismissWebModal();
    }
  }, []);

  const [footerHeight, setFooterHeight] = useState<number>(100);

  const footerHeightCalculated = (height: number) => {
    setFooterHeight(height);
  };

  useEffect(() => {
    //For guest, we want to show the intro video only if the guest has not seen it before
    if (!guestOnboardingSeen) {
      if (isDevice()) {
        //For web, video is displayed in a modal over the dashboard context
        navigation?.navigate(Route.videoPlayerFullScreenModal, {
          localVideo: ANLocalVideos.teaser,
        });
      } else {
        setPlayIntro(true);
      }

      StatusBar.setHidden(true, 'slide');
    }
    guestOnboardingTriggered();
  }, []);

  const onIntroductionVideoClosed = (completed: boolean) => {
    setPlayIntro(false);
    StatusBar.setHidden(false, 'slide');
    if (completed) {
      logEvent(EventKey.teaser_video_completed);
    } else {
      logEvent(EventKey.teaser_video_exit);
    }
  };

  return (
    <ANSafeAreView style={[ANLayout.screenContaineNoPadding]}>
      {isDevice() && <CloudsAnimated />}

      <ScrollView contentContainerStyle={[styles.scrollContainer, { paddingBottom: footerHeight }]}>
        <Image source={ANImages.LogoLong} style={styles.logo} />
        <Text style={[ANFont.h5Bold, styles.title]}>{I18n.t('app_promotion_invited.title')}</Text>
        <Text style={[ANFont.h6Bold, styles.headline]}>
          {I18n.t('app_promotion_invited.headline')}
        </Text>
        <Text style={[ANFont.h6Bold, styles.unlock_title]}>
          {I18n.t('app_promotion_invited.unlock_all_features_title')}
        </Text>
        <Text style={[ANFont.textMediumRegular, styles.unlock_all_features_headline]}>
          {I18n.t('app_promotion_invited.unlock_all_features_headline')}
        </Text>
        <OsteowPromotionBulletList />
      </ScrollView>

      <FooterOverlayButton
        type="done"
        onPress={goBack}
        paddingBottom={safeArea?.bottom}
        theme={'tertiary'}
        onHeightCalculated={footerHeightCalculated}
      >
        <ANButton
          title={I18n.t('app_promotion_invited.discover')}
          onPress={redirectToOsteoWebsite}
          eventKey="guest_prompt_discover_andrew_tapped"
          style={styles.discoverAndrew}
        />
      </FooterOverlayButton>
    </ANSafeAreView>
  );
};

export default InvitedModeScreen;

const styles = StyleSheet.create({
  scrollContainer: {
    marginHorizontal: 16,
  },
  contentContainer: {
    flex: 1,
  },
  logo: {
    marginVertical: MetricsSizes.big,
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
  },
  headline: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: 68,
  },
  unlock_title: {
    textAlign: 'center',
    color: ANColor.tertiary500,
    marginBottom: MetricsSizes.singlePadding,
  },
  unlock_all_features_headline: {
    textAlign: 'center',
  },

  discoverAndrew: {
    marginVertical: MetricsSizes.singlePadding,
  },
});
