import React, { FC } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import I18n from '../i18n';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import AvatarImage from './AvatarImage';

type Props = {
  osteoName?: string;
  avatarURL?: string;
  style?: ViewStyle;
  introductionLabel?: string;
};

const OsteoContainer: FC<Props> = ({
  introductionLabel = I18n.t('common.my_osteo'),
  osteoName,
  avatarURL,
  style,
}) => {
  return (
    <View style={[styles.osteoContainer, style]}>
      <AvatarImage url={avatarURL} />
      <View style={styles.osteoTextContainer}>
        <Text style={ANFont.textSmallRegular}>{introductionLabel}</Text>
        <Text style={[ANFont.textLargeRegular]}>{osteoName}</Text>
      </View>
    </View>
  );
};

export default OsteoContainer;

const styles = StyleSheet.create({
  osteoContainer: {
    flexDirection: 'row',
  },
  osteoTextContainer: {
    justifyContent: 'space-around',
    flex: 1,
    paddingHorizontal: MetricsSizes.singlePadding,
  },
});
