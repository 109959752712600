import { Api } from '../../apiclient';
import { MotivationSentenceResponse } from '../../data/APIDataResponse';
import { parseAPIError } from '../../data/ResponseError';

export const getMotivationSentences = async () => {
  try {
    const response = await Api.get('/motivation-sentences');
    const result: MotivationSentenceResponse = response.data;
    return result.data.map(sentence => sentence.attributes.message);
  } catch (err) {
    throw parseAPIError(err);
  }
};
