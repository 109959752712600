import React from 'react';
import { StyleSheet } from 'react-native';

import ANExercice from '../../model/ANVideoGroup';
import { ANColor } from '../../theme/ANColor';
import ANFont from '../../theme/ANFont';
import ANLayout from '../../theme/ANLayout';
import { MetricsSizes } from '../../theme/ANMetrics';
import TrainingLevelSwitchBody from '../TrainingLevelSwitchBody';
import WebModal from './WebModal';

interface Props {
  isVisible: boolean;
  exercice?: ANExercice;
  useLimitedMode: boolean;
  onClose: () => void;
}

//Bottom sheet utilisé pour noter la dernière consultation avec son oste
const VideoLevelSelectionModal: React.FunctionComponent<Props> = ({
  isVisible,
  exercice,
  useLimitedMode,
  onClose,
  //onLevelSelected,
}) => {
  return (
    <WebModal
      isVisible={isVisible}
      shouldDismissWebModal={onClose}
      style={styles.modal}
      contentContainerStyle={[ANLayout.modalContainer, styles.modalContainer]}
      content={
        <TrainingLevelSwitchBody
          exercice={exercice}
          onClose={onClose}
          useLimitedMode={useLimitedMode}
        />
      }
    />
  );
};

export default VideoLevelSelectionModal;

const styles = StyleSheet.create({
  reviewSheetContainer: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
  },
  modal: {
    position: 'absolute',
    alignItems: 'center',
  },
  modalContainer: {
    alignSelf: 'center',
    width: 500,
  },

  modalTitle: {
    color: ANColor.tertiary500,
    textAlign: 'center',
    alignSelf: 'center',
  },
  modalBody: {
    ...ANFont.textSmallRegular,
    marginVertical: MetricsSizes.regular,
  },
});
