import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useCallback, useContext, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import UserContext from '../../../context/UserContext';
import useDetailedFavoriteHandler from '../../../hooks/useDetailedFavoriteHandler';
import useFavoriteStore from '../../../hooks/useFavoritesStore';
import useTrainingChecker from '../../../hooks/useTrainingChecker';
import { RootStackParamList, Route } from '../../../navigation/Route';
import { incrementNumberOfSessions } from '../../../util/ANStorage';
import { checkConditionsAndRateTheApp } from '../../../util/reviewApp';
import { openFindAnOsteoInBrowser } from '../../../util/utils';
import HorizontalMediaList from '../../explorer/HorizontalMediaList';
import NoFavorites from './component/NoFavorites';

const useDashboard = (
  navigationRef: React.MutableRefObject<NativeStackNavigationProp<RootStackParamList>>,
) => {
  const { fetchUser, loadingProfile, onboardingSeen, isInvitedMode, hasPlaylist } =
    useContext(UserContext);

  const { isLoading: loadingFavorites, detailedFavorites } = useDetailedFavoriteHandler();

  const { canTriggerHealthCheckup } = useTrainingChecker();

  const onDiscoverOsteowTapped = useCallback(() => {
    openFindAnOsteoInBrowser();
  }, []);

  const openTrainingPlaylistScreen = () => {
    navigationRef.current.navigate(Route.trainingPresentation);
  };

  useEffect(() => {
    const unsubFavorites = useFavoriteStore.subscribe(state => state.favoritesIds);
    console.log('Use effect dashboard triggered');

    const rateTheApp = async () => {
      const numberOfSessions = await incrementNumberOfSessions();
      console.log('number of sessions', numberOfSessions);
      checkConditionsAndRateTheApp(numberOfSessions);
    };

    //Execute rateTheApp after 3 seconds
    setTimeout(() => {
      rateTheApp();
    }, 3000);

    return () => {
      unsubFavorites();
    };
  }, []);

  const renderFavoritesSection = () => {
    console.log('loadingFavorites', loadingFavorites);
    console.log('detailedFavorites?.length', detailedFavorites?.length ?? 0);
    if (!loadingFavorites && (detailedFavorites?.length ?? 0) > 0) {
      return <HorizontalMediaList data={detailedFavorites} isLoading={loadingFavorites} />;
    } else {
      return <NoFavorites />;
    }
  };

  return {
    loadingProfile,
    fetchUser,
    isInvitedMode,
    hasPlaylist,
    onboardingSeen,
    renderFavoritesSection,
    canTriggerHealthCheckup,
    openTrainingPlaylistScreen,
    onDiscoverOsteowTapped,
  };
};

export default useDashboard;

const styles = StyleSheet.create({});
