import React, { FC } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import { MetricsSizes } from '../theme/ANMetrics';
import ExpoIcon from './ExpoIcon';

type Props = {
  title: string;
  bulletIcon?: React.ReactChild;
  style?: ViewStyle;
};

const BulletPointCheckmark: FC<Props> = ({
  title,
  bulletIcon = <ExpoIcon name="check-circle" color={ANColor.primary500} style={styles.check} />,
  style,
}) => {
  return (
    <View style={[styles.row, style]}>
      {bulletIcon}
      <View style={styles.textContainer}>
        <Text style={[ANFont.textLargeRegular, styles.label]}>{title}</Text>
      </View>
    </View>
  );
};

export default BulletPointCheckmark;

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  textContainer: {
    flex: 1,
  },
  label: {
    marginLeft: MetricsSizes.singlePadding,
  },
  check: {
    marginTop: 2,
  },
});
