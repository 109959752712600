import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';

type Props = {
  title: string;
};

const SideBarUserAvatar: FC<Props> = ({ title }) => {
  return (
    <View style={styles.container}>
      <Text style={[ANFont.h6Bold, styles.title]}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: ANColor.secondary500,
    height: 25,
    width: 25,
    alignSelf: 'center',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    overflow: 'hidden',
  },
  title: {},
});
export default SideBarUserAvatar;
