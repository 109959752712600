import { Picker } from '@react-native-picker/picker';
import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ANButton from '../../../component/ANButton';
import I18n from '../../../i18n';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';

type Props = {
  timerValue: number;
  onTimeSelected: (minutes: number) => void;
  onStart: () => void;
  openTimeBottomSheet?: () => void;
  minutes: number[];
};

const BreathTimeSelector: FC<Props> = ({ timerValue, onTimeSelected, onStart, minutes }) => {
  return (
    <View style={styles.container}>
      <View style={ANLayout.rowHCenter}>
        <Text style={[ANFont.h5Bold, styles.selectDurationLabel]}>
          {I18n.t('breath_exercice.define_minute')}
        </Text>
        <Picker
          selectedValue={timerValue}
          onValueChange={onTimeSelected}
          dropdownIconColor={ANColor.tertiary500}
          style={[ANFont.h5Bold, styles.androidPicker]}
        >
          {minutes.map(min => {
            return <Picker.Item key={min} label={`${min} min`} value={min} />;
          })}
        </Picker>
      </View>
      <ANButton title={I18n.t('breath_exercice.action')} onPress={onStart} />
    </View>
  );
};

export default BreathTimeSelector;

const styles = StyleSheet.create({
  container: {
    marginBottom: MetricsSizes.big,
    alignItems: 'center',
  },
  selectDurationLabel: {
    marginRight: MetricsSizes.regular,
  },
  selectedMinutes: {
    textDecorationLine: 'underline',
    marginTop: MetricsSizes.small,
    marginBottom: MetricsSizes.big,
  },
  androidPicker: {
    marginVertical: MetricsSizes.big,
    width: 80,
  },
});
