import React, { FC } from 'react';
import { StyleSheet, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { ANColor } from '../theme/ANColor';
import ANFont from '../theme/ANFont';
import ANLayout from '../theme/ANLayout';
import { MetricsSizes } from '../theme/ANMetrics';
import AvatarImage from './AvatarImage';

type Props = {
  avatarURL?: string;
  name: string;
  selected: boolean;
  onPress?: () => void;
};

const AuthorChip: FC<Props> = ({ name, selected, avatarURL, onPress }) => {
  return (
    <TouchableOpacity
      style={[ANLayout.roundedLabel, styles.container, selected && styles.selected]}
      onPress={onPress}
    >
      <AvatarImage url={avatarURL} size={32} fullRounded />
      <Text style={[ANFont.textMediumRegular, styles.name, selected && styles.nameSelected]}>
        {name}
      </Text>
    </TouchableOpacity>
  );
};

export default AuthorChip;

const styles = StyleSheet.create({
  container: {
    height: 48,
    borderRadius: 24,
    backgroundColor: ANColor.primary100,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: MetricsSizes.regular,
  },
  name: {
    paddingLeft: MetricsSizes.singlePadding,
  },
  nameSelected: {
    color: ANColor.commonWhite,
  },
  selected: {
    backgroundColor: ANColor.primary500,
    borderWidth: 1,
    borderColor: ANColor.primary600,
  },
});
