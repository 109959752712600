import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import ANSafeAreView from '../../../component/ANSafeAreView';
import BreathingAnimation from '../../../component/BreathingAnimation';
import ExpoIcon, { ExpoIconDesign, ExpoIconPressable } from '../../../component/ExpoIcon';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';
import AnimatedBreathDescription from './AnimatedBreathDescription';
import BreathCongrats from './BreathCongrats';
import DurationContainer from './DurationContainer';
import useBreathingExercice from './useBreathingExercice';

const pace = 5000;
export type BreathingExerciceScreenProps = {
  infoUrl?: string;
};

const BreathingExerciceScreen = () => {
  const {
    timerValue,
    onTimeSelected,
    onStart,
    openTimeBottomSheet,
    onTimerCompleted,
    minutes,
    onDone,
    muteAudio,
    exerciceFinished,
    shouldPlayTimer,
    audioSpeakerTapped,
    vibrate,
  } = useBreathingExercice();

  if (exerciceFinished) {
    return <BreathCongrats onDone={onDone} />;
  }
  return (
    <ANSafeAreView style={ANLayout.screenContainer} ignoreTopInset>
      <ExpoIcon
        name="heart"
        design={ExpoIconDesign.MaterialCommunity}
        color={'#FCDFD0'}
        style={styles.heartBackground}
        size={240}
      />
      <ScrollView bounces={false} contentContainerStyle={styles.container}>
        {shouldPlayTimer && <AnimatedBreathDescription />}

        <DurationContainer
          timerValue={timerValue}
          onTimeSelected={onTimeSelected}
          onStart={onStart}
          openTimeBottomSheet={openTimeBottomSheet}
          minutes={minutes}
          onTimerCompleted={onTimerCompleted}
          shouldPlayTimer={shouldPlayTimer}
          style={styles.durationContainer}
        />
        <BreathingAnimation pace={pace} onAnimationEnds={vibrate} />

        <View style={styles.controllers}>
          <ExpoIconPressable
            style={styles.control}
            eventKey="breathing_exercice_speaker_button"
            name={muteAudio ? 'volume-off' : 'volume-up'}
            onPress={audioSpeakerTapped}
            size={36}
          />
        </View>
      </ScrollView>
    </ANSafeAreView>
  );
};

export default BreathingExerciceScreen;

const styles = StyleSheet.create({
  container: {
    marginTop: MetricsSizes.big,
    flex: 1,
  },
  durationContainer: {
    marginBottom: MetricsSizes.regular,
  },

  controllers: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: MetricsSizes.regular,
  },
  control: {
    marginHorizontal: MetricsSizes.small,
    padding: MetricsSizes.small,
  },
  plus: {
    marginRight: MetricsSizes.regular,
  },

  heartBackground: {
    position: 'absolute',
    top: 0,
    right: -80,
  },
});
