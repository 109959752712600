import React, { FC } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import Animated, { FadeIn } from 'react-native-reanimated';
import OsteowPromotionBulletList from '../../../component/OsteowPromotionBulletList';
import I18n from '../../../i18n';
import { ANColor } from '../../../theme/ANColor';
import ANFont from '../../../theme/ANFont';
import ANLayout from '../../../theme/ANLayout';
import { MetricsSizes } from '../../../theme/ANMetrics';

type Props = {
  top: number;
  bottom: number;
};

const OnboardingSlide2: FC<Props> = ({ top, bottom }) => {
  return (
    <View style={[ANLayout.container]}>
      <ScrollView
        style={styles.scroll}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[{ paddingTop: top, marginBottom: bottom + 100, flex: 1 }]}
      >
        <View style={[ANLayout.columnVCenter, styles.slide]}>
          <Animated.Text style={[ANFont.h5Bold, styles.title]} entering={FadeIn.delay(200)}>
            {I18n.t('onboarding.slide_one.title')}
          </Animated.Text>
          <Animated.View>
            <OsteowPromotionBulletList />
          </Animated.View>
        </View>
      </ScrollView>
    </View>
  );
};

export default OnboardingSlide2;

const styles = StyleSheet.create({
  scroll: {
    backgroundColor: ANColor.primary100,
  },
  scrollContainer: {
    flex: 1,
    paddingHorizontal: 8,
    paddingVertical: MetricsSizes.extraLarge,
  },
  slide: {
    paddingHorizontal: MetricsSizes.screenHorizontalPadding,
    paddingBottom: MetricsSizes.extraLarge,
    marginBottom: 30,
  },
  title: {
    textAlign: 'center',
    marginVertical: MetricsSizes.singlePadding,
    color: ANColor.tertiary500,
  },

  body: {
    marginVertical: MetricsSizes.singlePadding,
    textAlign: 'center',
  },
});
