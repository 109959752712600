import create from 'zustand';

export interface MediaLogStore {
  mediaLogs: ANMediaExplorerLog[];
  addNewLog: (log: ANMediaExplorerLog) => void;
  initLogs: (logs: ANMediaExplorerLog[]) => void;
}

export type ANMediaExplorerLog = {
  mediaId: number; //for Explorer media content
  completed: boolean;
  timeFlagInMilli: number;
};

/**
 * A local way to store the media logs so that we don't have to trigger a new API call to refresh all the data each time the user watched/listen a new media to display his progress
 */
const useLocalMediaLogs = create<MediaLogStore>()(set => ({
  mediaLogs: [],
  addNewLog: (log: ANMediaExplorerLog) => {
    //Update the state, and push the id to the API to store the data
    set(state => ({
      mediaLogs: [
        ...state.mediaLogs.filter(item => {
          //remove log with matching media id
          return item.mediaId !== log.mediaId;
        }),
        log,
      ],
    }));
  },

  initLogs: (logs: ANMediaExplorerLog[]) => set({ mediaLogs: logs }),
}));

export default useLocalMediaLogs;
